import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { PriceRuleAccessorialOverride, PriceRuleAmcOverride } from '@xpo-ltl/sdk-dynamicpricing';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AcOverrideService } from './ac-override.service';

@Injectable({
  providedIn: 'root',
})
export class AcOverrideDataSourceService extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber = new Unsubscriber();
  private acOverrides: Array<PriceRuleAccessorialOverride>;
  get currentState(): XpoBoardState {
    return this.stateCache;
  }

  get list(): Array<PriceRuleAccessorialOverride> {
    return this.acOverrides;
  }

  constructor(private acOverrideService: AcOverrideService) {
    super();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.acOverrideService.acList$.pipe(
      takeUntil(this.unsubscriber.done),
      map((resp) => {
        this.acOverrides = resp;
        return new XpoBoardData(
          state,
          resp.filter((d) => d.listActionCd !== ActionCd.DELETE),
          resp.filter((d) => d.listActionCd !== ActionCd.DELETE).length,
          resp.filter((d) => d.listActionCd !== ActionCd.DELETE).length
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
