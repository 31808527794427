import { Injectable, Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: any): any {
    if (typeof value !== 'string' || value === '') {
      return value;
    }

    return _.startCase(value);
  }
}
