<app-dynamic-rule-list-filters [defaultFilters]="defaultFilters" (searchClick)="onSearchClick($event)">
  <div role="header" class="filter-header">
    <div class="title">
      <h1><mat-icon>view_module</mat-icon>Dynamic Rule List</h1>
    </div>
    <div class="switch-list">
      <button mat-button color="primary" (click)="navigateToList()">Return to new rule list</button>
    </div>
  </div>
</app-dynamic-rule-list-filters>

<xpo-board
  class="ruleListGrid__board"
  data-test="ruleListGridBoard"
  [dataSource]="dataSource"
  [views]="views"
  [viewTemplates]="viewTemplates"
  [boardOptions]="boardOptions"
  (boardReady)="onBoardReady($event)"
>
  <xpo-board-actions>
    <app-download-info></app-download-info>
  </xpo-board-actions>
  <xpo-board-actions>
    <button mat-button (click)="onDownloadClick()" [disabled]="isDownloadButtonDisable">
      Download
    </button>
  </xpo-board-actions>
  <xpo-board-actions>
    <button mat-button (click)="onDownloadAllClick()" [disabled]="isDownloadAllButtonDisable">
      Download All
    </button>
  </xpo-board-actions>
  <xpo-board-actions>
    <button mat-button (click)="onEditClick()" [disabled]="isEditButtonDisable">Edit Rule</button>
  </xpo-board-actions>

  <xpo-applied-filters #filters></xpo-applied-filters>
  <xpo-ag-grid-board
    rowSelection="multiple"
    enableMovableColumns="true"
    rowModelType="client-side"
    enableSorting="true"
    [customGridOptions]="customGridOptions"
    (gridReady)="onGridReady($event)"
  >
  </xpo-ag-grid-board>
  <xpo-board-total-records></xpo-board-total-records>
</xpo-board>
