import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { ListInfo, RuleOperatorCd, XrtSearchRespHeader, XrtSortExpression } from '@xpo-ltl/sdk-common';
import {
  ListDynamicPricingRuleHeadersRqst,
  SearchDynamicPriceRuleUnfilteredResp,
  SearchDynamicPriceRuleUnfilteredRqst,
} from '@xpo-ltl/sdk-dynamicpricing';
import moment from 'moment';
import { Observable, of } from 'rxjs/index';
import { map, takeUntil, tap } from 'rxjs/internal/operators';
import { SearchRuleApiName } from '../../enums';
import { RuleListFieldNames } from '../../enums/field-names/rule-list-field-names-enum';
import { ConstantsService } from '../constants/constants.service';
import { DynamicRuleRequestTransformerService } from '../dr-request-transformer/dynamic-rule-request-transformer.service';
import { DynamicPricingService } from '../dynamic-pricing/dynamic-pricing.service';
import { RuleListService } from '../dynamic-rule-list/rule-list.service';

enum XpoSortDirectionEnum {
  ascending = 'asc',
  descendant = 'desc',
}

@Injectable({
  providedIn: 'root',
})
export class DynamicRuleDataSource extends XpoBoardDataSource implements OnDestroy {
  get MaxResultCount(): number {
    return this.maxResultCount;
  }

  get currentState(): XpoBoardState {
    return this.stateCache;
  }

  constructor(
    private constants: ConstantsService,
    private dynamicPricingService: DynamicPricingService,
    private ruleListService: RuleListService,
    private transformerService: DynamicRuleRequestTransformerService
  ) {
    super();
    this.pageSize = this.constants.PAGE_SIZE_ELASTIC;
    this.maxResultCount = this.constants.DATA_SIZE_ELASTIC;
  }
  private unsubscriber = new Unsubscriber();
  protected errorOnFetch: boolean = false;
  protected maxResultCount: number;
  c;

  setState(state: XpoBoardState): void {
    super.setState(state);
  }
  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const request = <SearchDynamicPriceRuleUnfilteredRqst>{
      filter: state.criteria ? this.transformerService.transform(state.criteria) : null,
      pageNumber: state.pageNumber,
      pageSize: this.pageSize,
      sortExpressions: state.sortOrder.map((value) => {
        const result = new XrtSortExpression();
        result.column = this.transformerService.getElasticColumnNameForSorting(value.column);
        result.direction = value.direction;
        result.isDescendingSort = value.direction === XpoSortDirectionEnum.descendant;
        return result;
      }),
    };

    const pricingDetailView = this.ruleListService.getClearCache();
    const showAll = pricingDetailView
      ? pricingDetailView && state.criteria && Object.keys(state.criteria).length
      : true;
    if (pricingDetailView) {
      return this.getDataPricingDetails(state);
    }
    return this.dynamicPricingService.getDynamicRulesFromElastic(request).pipe(
      takeUntil(this.unsubscriber.done),
      tap(({ header }: SearchDynamicPriceRuleUnfilteredResp) =>
        showAll ? this.checkForMaxResultsFromResponse(header) : 0
      ),
      map((resp) => {
        return new XpoBoardData(
          state,
          showAll ? resp.result : null,
          showAll ? resp.header.resultCount : 0,
          this.pageSize
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private checkForMaxResultsFromResponse(header: XrtSearchRespHeader, setMaxRecords: boolean = true): void {
    if (setMaxRecords) {
      this.ruleListService.MaxRecordsDisplayed = header.resultCount;
    }
    if (header.resultCount > this.maxResultCount || header.pageNumber === this.maxResultCount / this.pageSize) {
      header.resultCount = this.maxResultCount;
    }
  }

  private getDataPricingDetails(state): any {
    const request = new ListDynamicPricingRuleHeadersRqst();
    const acctMadCd =
      state.criteria && state.criteria.ruleDescription ? state.criteria.ruleDescription.conditions[0].value : null;
    let effectiveDate =
      state.criteria && state.criteria.effectiveDate
        ? moment(state.criteria.effectiveDate.conditions[0].value).format('YYYY-MM-DD')
        : null;
    let expiryDate =
      state.criteria && state.criteria.expiryDate
        ? moment(state.criteria.expiryDate.conditions[0].value).format('YYYY-MM-DD')
        : null;

    if (!acctMadCd) {
      return of(new XpoBoardData(state, 0, 0, 0));
    }

    request.dynamicPriceRuleFilter = [
      {
        fieldName: 'acctMadCd',
        fieldValue: acctMadCd.trim().toUpperCase(),
        operatorCd: RuleOperatorCd.EQUAL_TO,
      },
    ];

    if (acctMadCd && !effectiveDate && !expiryDate) {
      effectiveDate = moment(new Date()).format('YYYY-MM-DD');
      expiryDate = moment(new Date()).format('YYYY-MM-DD');
    }

    if (effectiveDate) {
      request.dynamicPriceRuleFilter.push({
        fieldName: RuleListFieldNames.effectiveDate,
        fieldValue: effectiveDate,
        operatorCd: RuleOperatorCd.LESS_THAN_OR_EQUAL,
      });
    }

    if (expiryDate) {
      request.dynamicPriceRuleFilter.push({
        fieldName: RuleListFieldNames.expiryDate,
        fieldValue: expiryDate,
        operatorCd: RuleOperatorCd.GREATER_THAN_OR_EQUAL,
      });
    }

    request.listInfo = new ListInfo();
    request.listInfo.levelOfDetail = this.constants.SEARCH_BY_CUSTOMER;
    if (!acctMadCd) {
      return of(new XpoBoardData(state, 0, 0, 0));
    }

    return this.dynamicPricingService.requestDynamicPricingRulesList(request, null).pipe(
      map((resp) => {
        return new XpoBoardData(state, resp, resp.length, this.pageSize);
      })
    );
  }
}
