import { Injectable } from '@angular/core';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { PriceRuleAmcOverride, RatingAmcDetail } from '@xpo-ltl/sdk-dynamicpricing';
import _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { RatingRuleType } from '../../enums';
import { DynamicRule } from '../../models/dynamic-rule';

@Injectable({
  providedIn: 'root',
})
export class AmcOverrideService {
  amcListSubject = new BehaviorSubject<Array<any>>([]);
  amcList$ = this.amcListSubject.asObservable();
  amcListCopy: Array<PriceRuleAmcOverride>;
  errorMsg = {};
  get AmcList(): Array<PriceRuleAmcOverride> {
    return this.amcListSubject.value;
  }

  constructor() {}

  processAmcOverride(rule: DynamicRule) {
    const amc = new PriceRuleAmcOverride();
    this.amcListSubject.next(rule && rule.amcOverrides.length ? rule.amcOverrides : []);
  }

  getAmcListFlat(): Array<PriceRuleAmcOverride> {
    return _.flatMap(this.AmcList, (period) => period).filter((result: any) => !!result.listActionCd);
  }

  isNumber(n): boolean {
    return !isNaN(n) && !isNaN(parseFloat(n));
  }

  setErrorMessage(field, msg): void {
    this.errorMsg[field] = msg;
  }

  isValid(): boolean {
    let valid = null;
    this.getAmcListFlat().forEach((amc) => {
      valid =
        this.isNumber(amc.chargeAmount) &&
        ((amc.typeCd === RatingRuleType.amcMiles && amc.maxMilesNbr !== null && amc.minMilesNbr !== null) ||
          (amc.typeCd !== undefined && amc.typeCd !== RatingRuleType.amcMiles))
          ? null
          : ' ';
    });
    Object.keys(this.errorMsg).forEach((d) => {
      if (this.errorMsg[d]) {
        valid = valid ? valid : '';
        valid += ` ${d.replace(/[0-9]/g, '')} ${this.errorMsg[d]} `;
      }
    });
    return valid;
  }

  addAmcOverride(): void {
    const amcList = this.AmcList;
    const amc = new PriceRuleAmcOverride();
    amc.listActionCd = ActionCd.ADD;
    const maxSequenceNbr = this.getMaxSequenceNumber();
    amc.amcOverrideSequenceNbr = maxSequenceNbr + 1;
    amcList.push(amc);
  }

  replaceAmcList(amcList) {
    this.amcListSubject.next(this.AmcList.concat(amcList));
  }

  replaceAllAmcList(amcList) {
    this.amcListSubject.next(amcList);
  }

  deleteAll(): void {
    this.errorMsg = {};
    for (let i = 0; i < this.AmcList.length; i++) {
      if (this.AmcList[i].listActionCd === ActionCd.NO_ACTION || this.AmcList[i].listActionCd === ActionCd.DELETE) {
        this.AmcList[i].listActionCd = ActionCd.DELETE;
      } else {
        this.AmcList.splice(i, 1);
        i--;
      }
    }
    this.amcListSubject.next(this.AmcList);
  }

  deleteAmc(amc: PriceRuleAmcOverride): void {
    if (amc.listActionCd === ActionCd.ADD) {
      const amcIndex = this.AmcList.findIndex((data) => data.amcOverrideSequenceNbr === amc.amcOverrideSequenceNbr);
      this.AmcList.splice(amcIndex, 1);
    } else {
      amc.listActionCd = ActionCd.DELETE;
    }
  }

  getAmcFromNumber(sequenceNbr: number): PriceRuleAmcOverride {
    const amc = this.AmcList.find((range) => range.amcOverrideSequenceNbr === sequenceNbr);
    return amc;
  }

  getMaxSequenceNumber() {
    const sequenceNumbers = _.flatMap(this.AmcList, (range) => range).map((amc) => amc.amcOverrideSequenceNbr);
    return sequenceNumbers.length ? Math.max(...sequenceNumbers) : 0;
  }
}
