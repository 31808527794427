import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  BulkUpdateDynamicPricingRuleResp,
  BulkUpdateDynamicPricingRuleRqst,
  DynamicPriceRule,
} from '@xpo-ltl/sdk-dynamicpricing';
import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { take } from 'rxjs/internal/operators';
import { AlertDialogComponent } from '../../../../dialogs/alert/alert-dialog.component';
import { BulkUpdateRulesUpdateType, EditRuleFormFieldNames } from '../../../../shared/enums';
import { ConstantsService } from '../../../../shared/services/constants/constants.service';
import { DynamicPricingService } from '../../../../shared/services/dynamic-pricing/dynamic-pricing.service';
import { FormatDateService } from '../../../../shared/services/format-date/format-date.service';
import { PricingWorkbenchService } from '../../../../shared/services/pricing-workbench/pricing-workbench.service';

@Component({
  selector: 'app-edit-rule',
  templateUrl: './edit-rule.component.html',
  styleUrls: ['./edit-rule.component.scss'],
})
export class EditRuleComponent implements OnInit {
  editRuleForm: FormGroup;
  minExpiryDate;
  rowsSelectedForUpdate: DynamicPriceRule[];
  onSave: EventEmitter<boolean> = new EventEmitter(false);
  chunkSize;
  updateTypeSubject = new BehaviorSubject<BulkUpdateRulesUpdateType>(undefined);
  updateType$ = this.updateTypeSubject.asObservable();
  readonly EditRuleFormFieldNames = EditRuleFormFieldNames;

  get updateType(): BulkUpdateRulesUpdateType {
    return this.updateTypeSubject.value;
  }

  constructor(
    public dialog: MatDialog,
    private formatDate: FormatDateService,
    private constants: ConstantsService,
    private dynamicPricingService: DynamicPricingService,
    public dialogRef: MatDialogRef<EditRuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {}

  ngOnInit() {
    this.pricingWorkbenchService.getPricingCodes().subscribe((response: PricingCode[]) => {
      const updateSize = _.find(response, { subCategory: this.constants.BULK_UPDATE_SIZE });
      this.chunkSize = updateSize ? updateSize.value : 200;
    });
    this.minExpiryDate = new Date();
    this.rowsSelectedForUpdate = this.data;
    this.editRuleForm = new FormGroup({
      expiryDate: new FormControl(),
      status: new FormControl(),
      designationType: new FormControl(null, [Validators.maxLength(20)]),
    });

    this.editRuleForm.get(EditRuleFormFieldNames.expiryDate).valueChanges.subscribe((value) => {
      const nextValue = value ? BulkUpdateRulesUpdateType.EXPIRY : undefined;
      this.updateTypeSubject.next(nextValue);
    });

    this.editRuleForm.get(EditRuleFormFieldNames.status).valueChanges.subscribe((value) => {
      const nextValue = value ? BulkUpdateRulesUpdateType.ACTIVE : undefined;
      this.updateTypeSubject.next(nextValue);
    });

    this.editRuleForm.get(EditRuleFormFieldNames.designationType).valueChanges.subscribe((value) => {
      const nextValue = value ? BulkUpdateRulesUpdateType.DESIGNATION : undefined;
      this.updateTypeSubject.next(nextValue);
    });

    this.updateType$.subscribe((value) => {
      let enableFields = [];
      let disableFields = [];
      switch (value) {
        case BulkUpdateRulesUpdateType.EXPIRY:
          disableFields = [EditRuleFormFieldNames.status, EditRuleFormFieldNames.designationType];
          break;

        case BulkUpdateRulesUpdateType.ACTIVE:
          disableFields = [EditRuleFormFieldNames.expiryDate, EditRuleFormFieldNames.designationType];
          break;

        case BulkUpdateRulesUpdateType.DESIGNATION:
          disableFields = [EditRuleFormFieldNames.status, EditRuleFormFieldNames.expiryDate];
          break;

        default:
          enableFields = [
            EditRuleFormFieldNames.status,
            EditRuleFormFieldNames.expiryDate,
            EditRuleFormFieldNames.designationType,
          ];
          break;
      }

      enableFields.forEach((field) => {
        this.editRuleForm.get(field).enable({
          onlySelf: true,
          emitEvent: false,
        });
      });

      disableFields.forEach((field) => {
        this.editRuleForm.get(field).disable({
          onlySelf: true,
          emitEvent: false,
        });
      });
    });
  }

  clearForm() {
    this.editRuleForm.reset();
  }

  save() {
    if (this.updateType) {
      this.dialogRef.close();
      this.updateSelectedRules().subscribe((resp: string[]) => {
        this.dynamicPricingService.clearRulesListCache();
        this.onSave.emit(true);
        const successCount = this.rowsSelectedForUpdate.length - resp.length;
        if (successCount !== 0) {
          resp.unshift(`${successCount} rules are updated Successfully`);
        }
        this.dialog.open(AlertDialogComponent, {
          data: {
            message: this.buildSuccessMsg(resp),
          },
          width: '600px',
        });
      });
    }
  }

  buildSuccessMsg(data: string[]): string {
    let successMsg = '';
    data.forEach((element) => {
      successMsg += `${element}<br/>`;
    });
    return successMsg;
  }

  updateSelectedRules(): Observable<string[]> {
    const chunks = _.chunk(this.rowsSelectedForUpdate, this.chunkSize); // process in batches
    return new Observable<string[]>((observer) => {
      this.batchUpdateRules(chunks, observer);
    });
  }

  private batchUpdateRules(chunks: DynamicPriceRule[][], observer: Observer<string[]>, returnArray: string[] = []) {
    if (!chunks || chunks.length === 0) {
      observer.next(returnArray);
      observer.complete();
      return;
    }
    const chunk = chunks.shift();
    const request = new BulkUpdateDynamicPricingRuleRqst();
    request.updateType = this.updateType;
    request.sourceDynPrcRule = new DynamicPriceRule();
    request.sourceDynPrcRule.expiryDate = this.formatDate.formatDatePicker(this.editRuleForm.value.expiryDate);
    request.sourceDynPrcRule.designationType = this.editRuleForm.value.designationType;
    request.dynPrcRules = [];
    _.forEach(chunk, (row: DynamicPriceRule) => {
      const rule = new DynamicPriceRule();
      rule.ruleVersionNbr = row.ruleVersionNbr;
      rule.priceRuleId = row.priceRuleId;
      rule.logicalRuleId = row.logicalRuleId;
      request.dynPrcRules.push(rule);
    });

    this.dynamicPricingService
      .bulkUpdateRules(request)
      .pipe(take(1))
      .subscribe(
        (response: BulkUpdateDynamicPricingRuleResp) => {
          if (response) {
            response.ruleIdsAndStatuses.forEach((value: string) => {
              returnArray.push(value);
            });
          }
          this.batchUpdateRules(chunks, observer, returnArray);
        },
        (error) => {
          this.batchUpdateRules(chunks, observer, returnArray);
        }
      );
  }
}
