<h1 mat-dialog-title>
  Edit Rule
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>

<div mat-dialog-content class="edit-rule">
  <form [formGroup]="editRuleForm">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <mat-form-field class="full-width" floatLabel="always">
          <input
            matInput
            [matDatepicker]="expiryDatePicker"
            [formControlName]="EditRuleFormFieldNames.expiryDate"
            placeholder="Expiry Date"
            [min]="minExpiryDate"
          />
          <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #expiryDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-sm-6">
        <mat-form-field class="full-width" floatLabel="always">
          <mat-select [formControlName]="EditRuleFormFieldNames.status" placeholder="Status">
            <mat-option></mat-option>
            <mat-option value="Active">Active</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-sm-6">
        <mat-form-field class="full-width" floatLabel="always">
          <input [formControlName]="EditRuleFormFieldNames.designationType" matInput placeholder="Designation Type" />
          <mat-error>
            <div *ngIf="editRuleForm.controls.designationType.hasError('maxlength')">
              Designation Type must be less than or equal to 20 characters
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="edit-rule__clear-btn">
          <button mat-stroked-button (click)="clearForm()">Clear</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-flat-button (click)="save()" [disabled]="!(updateType$ | async)">Save</button>
</div>
