<button
  mat-icon-button
  [xpoLtlpopoverTriggerFor]="basicPopover"
  matTooltip="Download fields information"
  aria-tooltip="Download fields info"
  class="button-info-columns-download"
  aria-label="Download info"
>
  <mat-icon iconName="info">info</mat-icon>
</button>

<xpo-ltl-popover #basicPopover [position]="'before'" [caretPosition]="'start'">
  <xpo-ltl-popover-header>Download fields information </xpo-ltl-popover-header>
  <xpo-ltl-popover-content class="ui-PopoverBasicSample-content">
    <mat-table #table [dataSource]="responseDataSource" style="min-width: 600px; max-height: 45vh; overflow: auto;">
      <ng-container matColumnDef="displayOrder">
        <mat-cell mat-header-cell *matHeaderCellDef> Pos. </mat-cell>
        <mat-header-cell mat-cell *matCellDef="let element"> {{ element.displayOrder }} </mat-header-cell>
      </ng-container>

      <ng-container matColumnDef="code">
        <mat-cell mat-header-cell *matHeaderCellDef> Column Name </mat-cell>
        <mat-header-cell mat-cell *matCellDef="let element"> {{ element.code }} </mat-header-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-cell mat-header-cell *matHeaderCellDef> Column Description </mat-cell>
        <mat-header-cell mat-cell *matCellDef="let element"> {{ element.name }} </mat-header-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="responseColumns; sticky: true"> </mat-header-row>
      <mat-row *matRowDef="let element; columns: responseColumns"></mat-row>
    </mat-table>
  </xpo-ltl-popover-content>
</xpo-ltl-popover>
