export enum OptimizedPricing {
  opp= 'OPP',
  optimized_prc_pgm = 'OptimizedPricingProgram',
  cdi_min = 'CdiMinimum',
  cdi_max = 'CdiMaximum',
  quarterly_cdi_max = 'QuarterlyCdiMaximum',
  class_min = 'ClassMinimum',
  class_max = 'ClassMaximum',
  active = 'Active',
  location = 'Location',
  country = 'Country',
  orig_include = 'OriginInclude',
  dest_include = 'DestinationInclude',
  weight_min = 'WeightMinimum',
  weight_max = 'WeightMaximum',
  pct_rate_by_linehaul = 'PctRateByLinehaul',
  ac_portfolio = 'AC PORTFOLIO',
  applicable_type = 'ApplicableType',
  opp_discount = 'OppDiscount',
  ac = 'AC',
  amc = 'AMC',
  amc_miles = 'AMCMILES',
  standard = 'STANDARD',
  quarterly = 'QUARTERLY'
}
