<div class="header">
  <ng-content select="div[role=header]"></ng-content>
</div>
<form class="rule-form" [formGroup]="rulesSearchForm">
  <div class="rule-form__search">
    <xpo-facet-filter-section sectionName="Customer Code" *ngIf="filtersToShow.customerCode && newForm">
      <mat-form-field class="full-width">
        <input matInput formControlName="customerCode" />
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Effective as of Date" *ngIf="filtersToShow.effectiveDate">
      <mat-form-field class="full-width">
        <input matInput formControlName="effectiveDate" [matDatepicker]="effectiveDatePicker" />
        <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #effectiveDatePicker></mat-datepicker>
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Effective Date" *ngIf="filtersToShow.exactEffectiveDatePicker">
      <mat-form-field class="full-width">
        <input matInput formControlName="exactEffectiveDate" [matDatepicker]="exactEffectiveDatePicker" />
        <mat-datepicker-toggle matSuffix [for]="exactEffectiveDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #exactEffectiveDatePicker></mat-datepicker>
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Expiry Date" *ngIf="filtersToShow.expiryDatePicker">
      <mat-form-field class="full-width">
        <input matInput formControlName="expiryDate" [matDatepicker]="expiryDatePicker" />
        <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expiryDatePicker></mat-datepicker>
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Rule Id" *ngIf="filtersToShow.ruleId">
      <mat-form-field class="full-width">
        <input matInput formControlName="ruleId" type="number" />
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Dynamic Code" *ngIf="filtersToShow.dynamicCode">
      <mat-form-field class="full-width">
        <input matInput formControlName="dynamicCode" />
        <mat-error>
          <div *ngIf="rulesSearchForm.controls.dynamicCode.hasError('maxlength')">
            Dynamic Code must be less than or equal to 10 characters
          </div>
        </mat-error>
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Customer Code" *ngIf="filtersToShow.acctMadCd && !newForm">
      <mat-form-field class="full-width">
        <input matInput formControlName="acctMadCd" />
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Description" *ngIf="filtersToShow.customerCode && !newForm">
      <mat-form-field class="full-width">
        <input matInput formControlName="customerCode" />
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Status" *ngIf="filtersToShow.statusCd">
      <mat-form-field class="full-width">
        <mat-select formControlName="statusCd">
          <mat-option></mat-option>
          <mat-option *ngFor="let status of statusDropDown" [value]="status.code">
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Designation Type" *ngIf="filtersToShow.designationType">
      <mat-form-field class="full-width">
        <input matInput formControlName="designationType" />
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Lane" *ngIf="filtersToShow.laneId">
      <mat-form-field class="full-width">
        <input matInput formControlName="laneId" />
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Logical Rule Id" *ngIf="filtersToShow.logicalRuleId">
      <mat-form-field class="full-width">
        <input matInput formControlName="logicalRuleId" />
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Select override" *ngIf="filtersToShow.overrideCd">
      <mat-form-field class="full-width" floatLabel="always">
        <mat-select formControlName="overrideCd" xpoSelect>
          <mat-option value="0"> AMC override</mat-option>
          <mat-option value="1"> AC Override </mat-option>
          <mat-option value="2"> BOTH </mat-option>
          <mat-option value="null"> NONE </mat-option>
        </mat-select>
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="Select trial period" *ngIf="filtersToShow.trialPeriodTypeCd">
      <mat-form-field class="full-width" floatLabel="always">
        <mat-select formControlName="trialPeriodTypeCd" xpoSelect>
          <mat-option value="NONE">NONE</mat-option>
          <mat-option value="STANDARD">STANDARD</mat-option>
          <mat-option value="QUARTERLY"> QUARTERLY </mat-option>
        </mat-select>
      </mat-form-field>
    </xpo-facet-filter-section>
    <xpo-facet-filter-section sectionName="" *ngIf="filtersToShow.marsInd">
      <mat-checkbox class="flag-filter" formControlName="marsInd">MARS</mat-checkbox>
    </xpo-facet-filter-section>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <xpo-button-group class="rule-form__search-btns">
        <button mat-flat-button (click)="onSearch()" [disabled]="searchButtonDisabled">Search</button>
        <button mat-stroked-button (click)="onClearForm()">Clear</button>
      </xpo-button-group>
    </div>
  </div>
</form>
