export enum AmcOverrideEnum {
  chargeAmount = 'chargeAmount',
  chargeAmountFieldName = 'Charge Amount',
  effectiveDate = 'effectiveDate',
  effectiveDateFieldName = 'Effective Date',
  expiryDate = 'expiryDate',
  expiryDateFieldName = 'Expiry Date',
  typeCd = 'typeCd',
  typeCdFieldName = 'Type',
  minMilesNbr = 'minMilesNbr',
  minMilesNbrFieldName = 'Min Miles',
  maxMilesNbr = 'maxMilesNbr',
  maxMilesNbrFieldName = 'Max Miles',
}
