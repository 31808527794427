import { Injectable } from '@angular/core';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { PriceRuleAccessorialOverride } from '@xpo-ltl/sdk-dynamicpricing';
import _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { AcOverrideEnum } from '../../../dynamic-rule/components/ac-override/ac-override-grid.enum';
import { DynamicRule } from '../../models/dynamic-rule';

interface PriceRuleAccessorial extends PriceRuleAccessorialOverride {
  override: boolean;
  copy: PriceRuleAccessorialOverride;
}
@Injectable({
  providedIn: 'root',
})
export class AcOverrideService {
  acListSubject = new BehaviorSubject<Array<any>>([]);
  acList$ = this.acListSubject.asObservable();
  acListCopy: Array<PriceRuleAccessorialOverride>;

  get AcList(): Array<PriceRuleAccessorialOverride> {
    return this.acListSubject.value;
  }
  errorMsg = {};
  constructor() {}

  processAcOverride(rule: DynamicRule) {
    const ac = new PriceRuleAccessorialOverride();
    this.acListSubject.next(null);
    this.acListSubject.next(rule && rule.acOverrides.length ? rule.acOverrides : []);
  }

  isNumber(n): boolean {
    return !isNaN(n) && !isNaN(parseFloat(n));
  }

  setErrorMessage(field, msg): void {
    this.errorMsg[field] = msg;
  }

  isValid(): boolean {
    let valid = null;
    this.getAcListFlat().forEach((ac) => {
      valid = !!ac.typeCd && !!ac.chargeTypeCd && this.isNumber(ac.chargeAmount) && !!ac.unitOfMeasureCd ? null : ' ';
    });
    Object.keys(this.errorMsg).forEach((d) => {
      if (this.errorMsg[d]) {
        valid = valid ? valid : '';
        valid += ` ${d.replace(/[0-9]/g, '')} ${this.errorMsg[d]} `;
      }
    });
    return valid;
  }

  getAcListFlat(): Array<PriceRuleAccessorialOverride> {
    return _.flatMap(this.AcList, (period) => period).filter((result: any) => !!result.listActionCd);
  }

  addAcOverride(): void {
    const acList = this.AcList;
    const ac = new PriceRuleAccessorialOverride();
    ac.listActionCd = ActionCd.ADD;
    const maxSequenceNbr = this.getMaxSequenceNumber();
    ac.accessorialOverrideSequenceNbr = maxSequenceNbr + 1;
    acList.push(ac);
  }

  addAcList(list: PriceRuleAccessorial[]): void {
    const acList = this.AcList;
    let listToAdd = acList.map((ac: PriceRuleAccessorial) => {
      ac.override = true;
      return ac;
    });
    list.forEach((ac: PriceRuleAccessorial) => {
      const acFound: PriceRuleAccessorial = listToAdd.find(
        (newAc: PriceRuleAccessorial) => newAc[AcOverrideEnum.type] === ac[AcOverrideEnum.type]
      );
      if (!acFound) {
        listToAdd.push(ac);
      } else {
        acFound.copy = ac;
      }
    });
    if (!listToAdd.length) {
      listToAdd = list;
    }
    this.replaceAcList(listToAdd);
  }

  replaceAcList(acList) {
    this.acListSubject.next(acList);
  }

  removeErrors(id: any): void {
    const sizeId = id.toString().length;
    Object.keys(this.errorMsg).forEach((error) => {
      if (error.slice(-sizeId) === id.toString()) {
        this.errorMsg[error] = null;
      }
    });
  }

  deleteAc(ac: PriceRuleAccessorialOverride, id: any): void {
    this.removeErrors(id);
    if (ac.listActionCd === ActionCd.ADD) {
      const acIndex = this.AcList.findIndex(
        (data) => data.accessorialOverrideSequenceNbr === ac.accessorialOverrideSequenceNbr
      );
      this.AcList.splice(acIndex, 1);
    } else {
      ac.listActionCd = ActionCd.DELETE;
    }
  }

  getAcFromNumber(sequenceNbr: number): PriceRuleAccessorialOverride {
    const ac = this.AcList.find((range) => range.accessorialOverrideSequenceNbr === sequenceNbr);
    return ac;
  }

  getMaxSequenceNumber() {
    const sequenceNumbers = _.flatMap(this.AcList, (range) => range).map((ac) => ac.accessorialOverrideSequenceNbr);
    return sequenceNumbers.length ? Math.max(...sequenceNumbers) : 0;
  }
}
