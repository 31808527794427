import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppRoutes } from '../../../../shared/enums/app-routes.enum';
import { SearchRule } from '../../../../shared/models/search-rule';
import { ConstantsService } from '../../../../shared/services/constants/constants.service';
import { RuleListService } from '../../../../shared/services/dynamic-rule-list/rule-list.service';
import { PricingWorkbenchService } from '../../../../shared/services/pricing-workbench/pricing-workbench.service';

@Component({
  selector: 'app-dynamic-rule-list-filters',
  templateUrl: './dynamic-rule-list-filters.component.html',
  styleUrls: ['./dynamic-rule-list-filters.component.scss'],
})
export class DynamicRuleListFiltersComponent implements OnInit, OnDestroy {
  statusDropDown: PricingCode[];
  rulesSearchForm: FormGroup;
  routerSubscription: Subscription;
  dynamicRuleListUrl: string = `${AppRoutes.DYNAMIC_RULE}`;
  dynamicRuleDetailUrl: string = `${AppRoutes.UPDATE_PRICING_RULE}`;
  previousUrl: string = `${AppRoutes.DYNAMIC_RULE}`;
  routerUnsubscribe: boolean = false;
  filtersToShow: any = {};
  newForm: boolean = false;
  showAllFilters: boolean = true;
  @Input() defaultFilters: SearchRule;
  @Output() searchClick = new EventEmitter<any>();
  @Output() clearClick = new EventEmitter<any>();

  get searchButtonDisabled(): boolean {
    return !this.ruleList.checkValueInForm(this.rulesSearchForm.value);
  }

  constructor(
    private ruleList: RuleListService,
    private constants: ConstantsService,
    private router: Router,
    private route: ActivatedRoute,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
        const dynamicRulePrevious = this.previousUrl.search(this.dynamicRuleDetailUrl) === -1;
        const dynamicRuleListPrevious = this.previousUrl.search(this.dynamicRuleListUrl) === -1;
        if (dynamicRulePrevious && dynamicRuleListPrevious) {
          this.ruleList.setFilterCache(null);
          this.routerUnsubscribe = true;
        }
      });
  }

  ngOnDestroy() {
    if (this.routerUnsubscribe) {
      this.routerUnsubscribe = false;
      this.routerSubscription.unsubscribe();
    }
  }

  private getCacheFilter(): any {
    return this.ruleList.getFilterCache();
  }

  getClearCache(): any {
    return this.ruleList.getClearCache();
  }

  private mixCacheAndDefaultFilter(cache) {
    Object.keys(cache).forEach((data) => {
      this.defaultFilters[data] = cache[data] ? cache[data] : null;
    });
  }

  ngOnInit() {
    this.showAllFilters = !this.route.snapshot?.data?.filters;
    const cache = this.getCacheFilter();
    this.mixCacheAndDefaultFilter(cache);
    this.pricingWorkbenchService.getPricingCodes().subscribe((response: PricingCode[]) => {
      this.statusDropDown = _.filter(response, { subCategory: this.constants.STATUS_CD });
    });
    this.rulesSearchForm = new FormGroup({
      effectiveDate: new FormControl(
        this.defaultFilters && this.defaultFilters.effectiveDate ? new Date(this.defaultFilters.effectiveDate) : null
      ),
      dynamicCode: new FormControl(this.defaultFilters ? this.defaultFilters.dynamicCode : null),
      customerCode: new FormControl(this.defaultFilters ? this.defaultFilters.customerCode : null),
      acctMadCd: new FormControl(this.defaultFilters ? this.defaultFilters.acctMadCd : null),
      statusCd: new FormControl(this.defaultFilters ? this.defaultFilters.statusCd : null),
      ruleId: new FormControl(this.defaultFilters ? this.defaultFilters.ruleId : null),
      exactEffectiveDate: new FormControl(this.defaultFilters ? this.defaultFilters.exactEffectiveDate : null),
      expiryDate: new FormControl(this.defaultFilters ? this.defaultFilters.expiryDate : null),
      designationType: new FormControl(this.defaultFilters ? this.defaultFilters.designationType : null),
      laneId: new FormControl(this.defaultFilters ? this.defaultFilters.laneId : null),
      logicalRuleId: new FormControl(this.defaultFilters ? this.defaultFilters.logicalRuleId : null),
      marsInd: new FormControl(this.defaultFilters ? this.defaultFilters.marsInd : null),
      overrideCd: new FormControl(this.defaultFilters ? this.defaultFilters.overrideCd : null),
      fakInd: new FormControl(this.defaultFilters ? this.defaultFilters.fakInd : null),
      trialPeriodTypeCd: new FormControl(this.defaultFilters ? this.defaultFilters.trialPeriodTypeCd : null),
    });
    setTimeout(() => {
      const assign = Object.keys(cache).length ? this.searchClick.emit(this.rulesSearchForm.value) : null;
    });

    Object.keys(this.rulesSearchForm.controls).forEach((key) => {
      const show = !this.showAllFilters ? this.showFilters(this.route.snapshot?.data?.filters, key) : true;
      this.filtersToShow[key] = show;
    });
    if (!this.showAllFilters) {
      this.onClearForm();
      this.newForm = true;
      this.rulesSearchForm.controls.customerCode.setValidators(Validators.required);
    }
  }

  showFilters(list, key): boolean {
    return !!list.find((element) => element === key);
  }

  onSearch() {
    this.searchClick.emit(this.rulesSearchForm.value);
    this.ruleList.setFilterCache(this.rulesSearchForm.value);
  }

  onClearForm() {
    this.rulesSearchForm.reset();
    this.clearClick.emit();
    this.ruleList.setFilterCache(null);
  }
}
