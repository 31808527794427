import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RatingApiService, RtgRule, ValidateRatingRulesRqst } from '@xpo-ltl-2.0/sdk-rating';
import {
  DynamicPricingApiService,
  ListRatingAccessorialDetailsQuery,
  RatingAccessorialDetail,
} from '@xpo-ltl/sdk-dynamicpricing';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RatingRuleGroup, RatingRuleType } from '../../enums';
import { ConstantsService } from '../constants/constants.service';
import { DynamicPricingStorageService } from '../dynamic-pricing/dynamic-pricing-storage.service';
import { FormatDateService } from '../format-date/format-date.service';

@Injectable({
  providedIn: 'root',
})
export class AccessorialChargeService {
  refreshList = new BehaviorSubject<any>(false);
  refreshList$ = this.refreshList.asObservable();

  detailData = new BehaviorSubject<any>(null);
  detailData$ = this.detailData.asObservable();

  constructor(
    private dynamicPricingApiService: DynamicPricingApiService,
    private ratingService: RatingApiService,
    private storageService: DynamicPricingStorageService,
    private constants: ConstantsService,
    private formatDateService: FormatDateService
  ) {}

  listAccessorialCharges(): Observable<Array<RatingAccessorialDetail>> {
    const filters: any = this.storageService.getAccessorialChargeListParams();
    const request: ListRatingAccessorialDetailsQuery = {
      beginDate: filters.beginDate
        ? this.formatDateService.transformDateWithFormat(filters.beginDate, this.constants.dateServiceFormat)
        : null,
      endDate: null,
      effectiveDate: filters.effectiveDate
        ? this.formatDateService.transformDateWithFormat(filters.effectiveDate, this.constants.dateServiceFormat)
        : null,
      expiryDate: filters.expiryDate
        ? this.formatDateService.transformDateWithFormat(filters.expiryDate, this.constants.dateServiceFormat)
        : null,
      typeCd: filters.typeCd,
    };
    return this.dynamicPricingApiService
      .listRatingAccessorialDetails(request)
      .pipe(map((value) => value.ratingAccessorialDetails));
  }

  floatToString(value: number) {
    if (value || !isNaN(Number(value))) {
      return value.toFixed(2).toString();
    }
    return null;
  }

  stringToFloat(value: string) {
    return value ? parseFloat(value.toString().replace(/[^\d\.*]+/g, '')) : null;
  }

  onAmountFocus(control: AbstractControl) {
    if (control.value && control.value.toString().length <= 5) {
      const stringValue = control.value;
      control.setValue(this.stringToFloat(stringValue));
      control.updateValueAndValidity();
    }
  }

  onAmountBlur(control: AbstractControl) {
    if (control.value && control.value.toString().length <= 5) {
      const floatValue = parseFloat(control.value);
      control.setValue(this.floatToString(floatValue));
      control.updateValueAndValidity();
    }
  }

  validateRatingRules(ratingAccessorialDetail: RatingAccessorialDetail, keyEffectiveDate?: string) {
    const rating = new RtgRule();

    rating.effectiveDate = ratingAccessorialDetail.effectiveDate;
    rating.expiryDate = ratingAccessorialDetail.expiryDate;
    rating.groupCd = RatingRuleGroup.dynamicPricing;
    rating.typeCd = RatingRuleType.ac;
    rating.qualifierCd = ratingAccessorialDetail.typeCd;
    rating.value1 = ratingAccessorialDetail.typeCd;
    rating.value2 = ratingAccessorialDetail.chargeAmount.toFixed(2);

    const request = new ValidateRatingRulesRqst();
    request.ratingRules = new Array<RtgRule>();
    request.ratingRules.push(rating);
    request.keyEffectiveDate = keyEffectiveDate ?? ratingAccessorialDetail.effectiveDate;

    return this.ratingService.validateRatingRules(request);
  }

  setDetailData(data): void {
    this.detailData.next(data);
  }
}
