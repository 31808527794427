import { CommonModule, DatePipe, KeyValuePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
  XpoGridAppliedFiltersModule,
  XpoGridFacetFilterModule,
  XpoGridToolbarModule,
  XpoNgxGridModule,
} from '@xpo-ltl/ngx-grid';
import { XpoBoardModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoCheckboxModule } from '@xpo-ltl/ngx-ltl-core/checkbox';
import { XpoCommonModule } from '@xpo-ltl/ngx-ltl-core/common';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoDatePickerModule } from '@xpo-ltl/ngx-ltl-core/datepicker';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoEmptyStateModule } from '@xpo-ltl/ngx-ltl-core/empty-state';
import { XpoFacetFilterModule } from '@xpo-ltl/ngx-ltl-core/facet-filter';
import { XpoFormFieldModule } from '@xpo-ltl/ngx-ltl-core/form-field';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { XpoTooltipModule } from '@xpo-ltl/ngx-ltl-core/tooltip';
import { XpoTrayModule } from '@xpo-ltl/ngx-ltl-core/tray';
import { XpoLtlPopoverModule } from '@xpo-ltl/ngx-ltl-core/xpo-ltl-popover';
import { AgGridModule } from 'ag-grid-angular';
import { PapaParseModule } from 'ngx-papaparse';
import { DecimalEditorComponent } from '../../lib/ag-grid/cell-editors/decimal-editor/decimal-editor.component';
import { NumericEditorComponent } from '../../lib/ag-grid/cell-editors/numeric-editor/numeric-editor.component';
import { StringEditorComponent } from '../../lib/ag-grid/cell-editors/string-editor/string-editor.component';
import { SharedModule } from '../shared/shared.module';
import { UiIconAcActionComponent } from './components/ac-override/ui-icon-action/ui-icon-action.component';
import { UiIconActionComponent } from './components/amc-override/ui-icon-action/ui-icon-action.component';
import { DynamicRuleCreationModule } from './components/detail/dynamic-rule-creation/dynamic-rule-creation.module';
import { DownloadTypeComponent } from './components/dialogs/download-type/download-type.component';
import { EditRuleComponent } from './components/dialogs/edit-rule/edit-rule.component';
import { DownloadInfoComponent } from './components/download-info/download-info.component';
import { DynamicRuleUploadComponent } from './components/dynamic-rule-upload/dynamic-rule-upload.component';
import { IconActionCellRendererComponent } from './components/lane-discounts/cell-renderers';
import { NewPeriodModalComponent } from './components/lane-discounts/new-period-modal/new-period-modal.component';
import { RuleIdRendererComponent } from './components/list/cell-render/rule-id-renderer.component';
import { DynamicRuleListClassicComponent } from './components/list/classic/dynamic-rule-list-classic.component';
import { DiscountCellComponent } from './components/list/discount-cell/discount-cell.component';
import { DynamicRuleListComponent } from './components/list/dynamic-rule-list.component';
import { DynamicRuleListFiltersComponent } from './components/list/filters/dynamic-rule-list-filters.component';
import { SelectCellEditorComponent } from './components/select-cell-editor/select-cell-editor.component';
import { DynamicRuleComponent } from './dynamic-rule.component';

@NgModule({
  declarations: [
    DynamicRuleComponent,
    DynamicRuleListComponent,
    DynamicRuleUploadComponent,
    RuleIdRendererComponent,
    EditRuleComponent,
    DynamicRuleListFiltersComponent,
    DynamicRuleListClassicComponent,
    IconActionCellRendererComponent,
    NumericEditorComponent,
    DecimalEditorComponent,
    StringEditorComponent,
    NewPeriodModalComponent,
    DownloadTypeComponent,
    DownloadInfoComponent,
    UiIconActionComponent,
    UiIconAcActionComponent,
    SelectCellEditorComponent,
    DiscountCellComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    XpoCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    XpoAgGridBoardModule,
    XpoBoardModule,
    XpoIconModule,
    XpoButtonModule,
    XpoDatePickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatDialogModule,
    XpoSnackBarModule,
    PapaParseModule,
    XpoConfirmDialogModule,
    XpoDialogModule,
    AgGridModule.withComponents([RuleIdRendererComponent]),
    XpoCommonModule,
    XpoFormFieldModule,
    XpoCheckboxModule,
    XpoTabsModule,
    XpoEmptyStateModule,
    XpoTooltipModule,
    XpoLtlPopoverModule,
    MatTableModule,
    MatExpansionModule,
    XpoNgxGridModule,
    XpoFacetFilterModule,
    XpoGridFacetFilterModule,
    XpoGridToolbarModule,
    MatSidenavModule,
    XpoTrayModule,
    MatPaginatorModule,
    XpoGridAppliedFiltersModule,
    DynamicRuleCreationModule,
    RouterModule,
  ],
  providers: [DatePipe, KeyValuePipe],
})
export class DynamicRuleModule {}
