import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LaneTypeDiscountRange } from '../../models';
import { ConstantsService } from '../constants/constants.service';
import { LaneDiscountService } from '../lane-discount/lane-discount.service';

@Injectable({
  providedIn: 'root',
})
export class LaneDiscountDataSource extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber = new Unsubscriber();
  private discountsCache: any;

  get currentState(): XpoBoardState {
    return this.stateCache;
  }

  get state() {
    return super.state$;
  }

  constructor(private laneDiscountService: LaneDiscountService, private constants: ConstantsService) {
    super();
    this.pageSize = this.constants.DATA_SIZE;
  }

  setState(state: XpoBoardState): void {
    super.setState(state);
  }

  putExpirationReason(resp) {
    const reversed = resp.slice().reverse();
    if (this.discountsCache !== resp) {
      this.discountsCache = resp;
      reversed.forEach((laneDiscount, i) => {
        laneDiscount.data.forEach((element) => {
          element.expirationReasonCopy = reversed[i + 1] ? reversed[i + 1].data[0].expirationReason : null;
        });
      });
    }
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.laneDiscountService.discountList$.pipe(
      takeUntil(this.unsubscriber.done),
      map((resp) => {
        this.putExpirationReason(resp);
        const period = resp.find((range: LaneTypeDiscountRange) => range.id === state.viewId);
        const data = period
          ? period.data
              .filter((discount) => discount.listActionCd !== ActionCd.DELETE)
              .map((discount) => Object.assign({}, discount))
              .sort((a, b) => a.minimumWeight - b.minimumWeight)
          : [];
        return new XpoBoardData(state, data, data.length, data.length);
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
