import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { PriceRuleAmcOverride } from '@xpo-ltl/sdk-dynamicpricing';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LaneTypeDiscountRange } from '../../models';
import { DynamicRule } from '../../models/dynamic-rule';
import { LaneDiscountService } from '../lane-discount/lane-discount.service';
import { AmcOverrideService } from './amc-override.service';

@Injectable({
  providedIn: 'root',
})
export class AmcOverrideDataSourceService extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber = new Unsubscriber();
  amcOverrides: Array<PriceRuleAmcOverride>;
  get currentState(): XpoBoardState {
    return this.stateCache;
  }

  get list(): Array<PriceRuleAmcOverride> {
    return this.amcOverrides;
  }

  constructor(private amcOverrideService: AmcOverrideService) {
    super();
  }

  setState(state: XpoBoardState): void {
    super.setState(state);
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.amcOverrideService.amcList$.pipe(
      takeUntil(this.unsubscriber.done),
      map((resp) => {
        resp.sort((f, s) => f.typeCd.localeCompare(s.typeCd) || f.minimumMilesNbr - s.minimumMilesNbr);
        this.amcOverrides = resp;
        return new XpoBoardData(
          state,
          resp.filter((d) => d.listActionCd !== ActionCd.DELETE),
          resp.filter((d) => d.listActionCd !== ActionCd.DELETE).length,
          resp.filter((d) => d.listActionCd !== ActionCd.DELETE).length
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
