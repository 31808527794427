import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  XpoBoardApi,
  XpoBoardOptions,
  XpoBoardReadyEvent,
  XpoBoardState,
  XpoBooleanFilterComponent,
  XpoDateOnlyFilterComponent,
  XpoEnumFilterComponent,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
} from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { DynamicPriceStatusCd } from '@xpo-ltl/sdk-common';
import { AgGridEvent, GridApi, GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { AppRoutes } from '../../../../shared/enums/app-routes.enum';
import { SearchRule } from '../../../../shared/models/search-rule';
import { ConstantsService } from '../../../../shared/services/constants/constants.service';
import { DownloadService } from '../../../../shared/services/download/download.service';
import { DynamicRuleDataSourceClassic } from '../../../../shared/services/dr-data-source-classic/dynamic-rule-data-source-classic';
import { DynamicRuleRequestTransformerService } from '../../../../shared/services/dr-request-transformer/dynamic-rule-request-transformer.service';
import { DynamicPricingStorageService } from '../../../../shared/services/dynamic-pricing/dynamic-pricing-storage.service';
import { DynamicPricingService } from '../../../../shared/services/dynamic-pricing/dynamic-pricing.service';
import { RuleListService } from '../../../../shared/services/dynamic-rule-list/rule-list.service';
import { EditRuleComponent } from '../../dialogs/edit-rule/edit-rule.component';

@Component({
  selector: 'app-dynamic-rule-list-classic',
  templateUrl: './dynamic-rule-list-classic.component.html',
  styleUrls: ['./dynamic-rule-list-classic.component.scss'],
})
export class DynamicRuleListClassicComponent implements OnDestroy {
  private unsubscriber = new Unsubscriber();
  private gridApi: GridApi;
  private boardApi: XpoBoardApi;

  boardOptions: XpoBoardOptions = {
    preloadViewData: false,
    suppressViewSwitcher: true,
    enableQueryParamStatePersistance: true,
  };

  customGridOptions: GridOptions;

  defaultFilters: SearchRule = {
    effectiveDate: null,
    dynamicCode: null,
    customerCode: null,
    acctMadCd: null,
    statusCd: DynamicPriceStatusCd.ACTIVE,
    ruleId: null,
    exactEffectiveDate: null,
    expiryDate: null,
    designationType: null,
    laneId: null,
    logicalRuleId: null,
    marsInd: null,
    overrideCd: null,
    fakInd: null,
    trialPeriodTypeCd: null,
  };

  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  selectedRows = [];

  get isEditButtonDisable() {
    return !this.selectedRows || this.selectedRows.length === 0;
  }

  get isDownloadButtonDisable() {
    return this.gridApi && this.gridApi.getDisplayedRowCount() === 0 ? true : false;
  }

  get isDownloadAllButtonDisable() {
    return this.downloadService.disabledBySearchInd;
  }

  constructor(
    private constants: ConstantsService,
    private ruleList: RuleListService,
    private storageService: DynamicPricingStorageService,
    private dynamicPricingService: DynamicPricingService,
    private transformerService: DynamicRuleRequestTransformerService,
    private downloadService: DownloadService,
    private router: Router,
    private route: ActivatedRoute,
    public dataSource: DynamicRuleDataSourceClassic,
    public dialog: MatDialog
  ) {
    this.dataSource.pageSize = this.constants.RULES_DATA_SIZE;
    this.viewTemplates = this.getBoardViewTemplates();
    this.views = this.getBoardViews(this.viewTemplates[0]);
    this.customGridOptions = {
      enableCellTextSelection: true,
      pagination: false,
      frameworkComponents: {
        xpoStringFilterComponent: XpoStringFilterComponent,
        xpoNumberFilterComponent: XpoNumberFilterComponent,
        xpoBooleanFilterComponent: XpoBooleanFilterComponent,
        xpoDateOnlyFilterComponent: XpoDateOnlyFilterComponent,
        XpoEnumFilterComponent: XpoEnumFilterComponent,
      },
      defaultColDef: {
        resizable: true,
        icons: {
          menu: `<mat-icon class="material-icons" style="font-size: 18px;">filter_list</mat-icon>`,
          filter: ' ',
        },
      },
      onSelectionChanged: (event) => this.onSelectionChanged(event),
    };
    this.dataSource.connect(this).subscribe((state) => {
      if (state.source === this.constants.FILTER_CHANGE && !Object.keys(state.criteria).length) {
        this.ruleList.setFilterCache(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onSelectionChanged(event: any) {
    this.selectedRows = event.api.getSelectedRows();
  }

  onSearchClick(event: SearchRule) {
    this.storageService.setDynamicRulesQueryParams(event);
    this.dynamicPricingService.dynamicRuleListCache$ = null;
    this.downloadService.setDisabledBySearchInd(false);
    this.dataSource.setState({
      source: this.constants.BOARD_ACTIVATING_VIEW,
      criteria: this.transformerService.transformSearchRuleToXpoFilterCriteria(event),
      pageNumber: 1,
    });
  }

  onDownloadAllClick() {
    this.downloadService.downloadBySearch();
  }

  onDownloadClick() {
    const stamp = moment().format(this.constants.STAMP_FORMAT);
    const fileName = `Dynamic_Rules - ${stamp}.csv`;
    this.gridApi.exportDataAsCsv({
      columnKeys: this.ruleList.getColumnsForDownload(),
      fileName,
    });
  }

  navigateToList() {
    this.router.navigate([`../${AppRoutes.LIST_PAGE}`], { relativeTo: this.route });
  }

  onBoardReady(event: XpoBoardReadyEvent): void {
    this.boardApi = event.boardApi;
  }

  onGridReady(event: AgGridEvent) {
    this.gridApi = event.api;
    this.gridApi.addEventListener('filterChanged', () => {
      this.clearSelectedRows();
    });
    this.gridApi.addEventListener('sortChanged', () => {
      this.clearSelectedRows();
    });
  }

  onEditClick(): void {
    const dialogRef = this.dialog.open(EditRuleComponent, {
      data: this.selectedRows,
      width: '600px',
      disableClose: true,
    });
    dialogRef.componentInstance.onSave.subscribe(() => {
      this.selectedRows = [];
      this.dataSource.refresh();
    });
  }

  private clearSelectedRows() {
    this.selectedRows = [];
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  private getBoardViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    return [
      viewTemplate.createView({
        id: this.constants.SEARCH,
        name: _.startCase(_.toLower(this.constants.SEARCH)),
      }),
    ];
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'dynamic-rule-list',
        name: 'Dynamic-Rule-List',
        keyField: 'priceRuleId',
        availableColumns: this.ruleList.getColumnsForGrid(),
      }),
    ];
  }
}
