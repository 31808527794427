import { Injectable } from '@angular/core';
import { XpoNgxGridColumns } from '@xpo-ltl/ngx-grid';
import { Operators } from '@xpo-ltl/ngx-ltl-board';
import { DynamicPriceStatusCd, ListInfo, RuleOperatorCd } from '@xpo-ltl/sdk-common';
import { DynamicPriceRule, ListDynamicPricingRuleHeadersRqst } from '@xpo-ltl/sdk-dynamicpricing';
import 'ag-grid-enterprise';
import * as _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { RuleIdRendererComponent } from '../../../dynamic-rule/components/list/cell-render/rule-id-renderer.component';
import { DiscountCellComponent } from '../../../dynamic-rule/components/list/discount-cell/discount-cell.component';
import { RuleListFieldNames } from '../../enums/field-names/rule-list-field-names-enum';
import { SearchRule } from '../../models/search-rule';
import { YesNoPipe } from '../../pipes/yes-no/yes-no.pipe';
import { ConstantsService } from '../constants/constants.service';
import { FormatDateService } from '../format-date/format-date.service';

export interface MaxRecordsExceededMessage {
  resultCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class RuleListService {
  private startClear: boolean = false;
  private maxRecordsDisplayedSubject: BehaviorSubject<MaxRecordsExceededMessage> = new BehaviorSubject({
    resultCount: 0,
  });
  maxRecordsDisplayed$ = this.maxRecordsDisplayedSubject.asObservable();

  get MaxRecordsDisplayed() {
    return this.maxRecordsDisplayedSubject.value.resultCount;
  }

  set MaxRecordsDisplayed(value: number) {
    this.maxRecordsDisplayedSubject.next({
      resultCount: value,
    });
  }

  private ruleListFiltersCache$: {};

  ruleData = new BehaviorSubject<any>(null);
  accessorialData = this.ruleData.asObservable();

  constructor(
    private constants: ConstantsService,
    private yesNoPipe: YesNoPipe,
    private formatDate: FormatDateService
  ) {}

  handleRulesListParams(params: SearchRule, levelOfDetail) {
    const request = new ListDynamicPricingRuleHeadersRqst();
    request.dynamicPriceRule = new DynamicPriceRule();
    request.listInfo = new ListInfo();
    request.dynamicPriceRule.effectiveDate = params.effectiveDate
      ? this.formatDate.formatDatePicker(params.effectiveDate)
      : null;
    request.dynamicPriceRule.statusCd = params.statusCd;
    request.dynamicPriceRule.ruleDescription = params.customerCode;
    request.dynamicPriceRule.externalRuleId = params.dynamicCode;
    request.dynamicPriceRule.priceRuleId = params.ruleId;
    request.dynamicPriceRuleFilter = [
      {
        fieldName: 'effectiveDate',
        fieldValue: params.exactEffectiveDate ? this.formatDate.transformDate(params.exactEffectiveDate) : null,
        operatorCd: RuleOperatorCd.EQUAL_TO,
      },
      {
        fieldName: 'expiryDate',
        fieldValue: params.expiryDate ? this.formatDate.transformDate(params.expiryDate) : null,
        operatorCd: RuleOperatorCd.EQUAL_TO,
      },
      {
        fieldName: 'designationType',
        fieldValue: params.designationType ? params.designationType : null,
        operatorCd: RuleOperatorCd.MATCHES,
      },
      {
        fieldName: 'laneId',
        fieldValue: params.laneId ? params.laneId : null,
        operatorCd: RuleOperatorCd.MATCHES,
      },
    ];
    request.listInfo.levelOfDetail = levelOfDetail;
    // uncomment this to limit the response size
    // request.listInfo.numberOfRows = this.constants.DATA_SIZE;
    return request;
  }

  rulesListEditableOption(rules: DynamicPriceRule[]) {
    _.forEach(rules, (rule: DynamicPriceRule) => {
      switch (rule.statusCd) {
        case this.constants.IN_ACTIVE:
          _.assign(rule, { editable: false });
          break;
        case this.constants.PENDING:
          _.assign(rule, { editable: true });
          if (
            moment(rule.effectiveDate).isSameOrAfter(new Date(), 'day') &&
            moment(rule.expiryDate).isSameOrAfter(new Date(), 'day')
          ) {
            _.assign(rule, { checkboxInd: true });
          }
          break;
        case this.constants.ACTIVE:
          if (moment(rule.expiryDate).isSameOrAfter(new Date(), 'day')) {
            _.assign(rule, { editable: true, checkboxInd: true });
          } else {
            _.assign(rule, { editable: false });
          }
          break;
      }
    });
    return rules;
  }

  checkValueInForm(value, exclusions = []) {
    return Object.keys(value).some((k) => {
      if (!exclusions.includes(k)) {
        return value[k];
      }
    });
  }

  // Temporary fix - will remove next week when service returns a date string.
  // DynamicPriceRules is specified as type - as string is not assignable to Date
  // but need to format  to string for the download purpose so specified as any.

  formatDateForDownload(rules: any) {
    return _.forEach(rules, (rule: any) => {
      rule.effectiveDate = this.formatDate.transformDate(rule.effectiveDate);
      rule.expiryDate = this.formatDate.transformDate(rule.expiryDate);
      if (rule.auditInfo) {
        _.assign(rule, rule.auditInfo);
        delete rule.auditInfo;
        rule.createdTimestamp = this.formatDate.transformDateTime(rule.createdTimestamp);
        rule.updatedTimestamp = this.formatDate.transformDateTime(rule.updatedTimestamp);
      }
    });
  }

  getColumnsForQuickSearch() {
    return [
      RuleListFieldNames.priceRuleId,
      RuleListFieldNames.logicalRuleId,
      RuleListFieldNames.externalRuleId,
      RuleListFieldNames.ruleVersionNbr,
      RuleListFieldNames.ruleDescription,
      RuleListFieldNames.statusCd,
      RuleListFieldNames.ruleRate,
      RuleListFieldNames.rateTypeCd,
      RuleListFieldNames.applicableTypeCd,
    ];
  }

  getColumnsForDownload(): Array<string> {
    return [
      RuleListFieldNames.priceRuleId,
      RuleListFieldNames.logicalRuleId,
      RuleListFieldNames.ruleVersionNbr,
      RuleListFieldNames.externalRuleId,
      RuleListFieldNames.ruleDescription,
      RuleListFieldNames.statusCd,
      RuleListFieldNames.designationType,
      RuleListFieldNames.laneId,
      RuleListFieldNames.ruleRate,
      RuleListFieldNames.applicableTypeCd,
      RuleListFieldNames.rateTypeCd,
      RuleListFieldNames.effectiveDate,
      RuleListFieldNames.expiryDate,
      RuleListFieldNames.dynShipmentCountRateQuoteDateCount,
      RuleListFieldNames.dynShipmentCountRateQuoteDateAmount,
      RuleListFieldNames.dynShipmentCountShipmentCount,
      RuleListFieldNames.dynShipmentCountShipmentAmount,
    ];
  }

  getPricingDetailColumnsForGrid(hideSelectAll?) {
    const stringOperators = [
      Operators.Equals,
      Operators.NotEquals,
      Operators.Contains,
      Operators.NotContains,
      Operators.StartsWith,
      Operators.EndsWith,
      Operators.OneOf,
      Operators.Empty,
      Operators.NotEmpty,
    ];
    //
    const columns = [
      {
        ...XpoNgxGridColumns.SELECTION_WITH_SELECT_ALL,
        pinned: 'left',
      },
      {
        headerName: 'Reference Number',
        field: RuleListFieldNames.priceRuleId,
        width: 200,
        cellRendererFramework: RuleIdRendererComponent,
        pinned: 'left',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Status',
        field: RuleListFieldNames.statusCd,
        width: 100,
        filter: false,
      },
      {
        headerName: 'Designation Type',
        field: RuleListFieldNames.designationType,
        width: 150,
        filter: 'agTextColumnFilter',
        filterParams: {
          operators: stringOperators,
        },
        sortable: true,
      },
      {
        headerName: 'Applicable Type',
        field: RuleListFieldNames.applicableTypeCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Discount',
        field: RuleListFieldNames.ruleRate,
        width: 100,
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Type',
        field: RuleListFieldNames.rateTypeCd,
        width: 150,
        filter: 'agTextColumnFilter',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Effective Date',
        field: RuleListFieldNames.effectiveDate,
        width: 150,
        valueGetter: (params: any) => {
          return params.data ? this.formatDate.transformDate(params.data.effectiveDate) : '';
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Expiry Date',
        field: RuleListFieldNames.expiryDate,
        width: 125,
        valueGetter: (params: any) => {
          return params.data ? this.formatDate.transformDate(params.data.expiryDate) : '';
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'AMC Override',
        field: RuleListFieldNames.amcOverrideCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'AC Override',
        field: RuleListFieldNames.accessorialOverrideCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'FAK Override',
        field: RuleListFieldNames.fakInd,
        width: 150,
        valueFormatter: (params) => {
          return params.data ? this.yesNoPipe.transform(params.data[RuleListFieldNames.fakInd]) : 'N';
        },
        //  filter: 'xpoBooleanFilterComponent',
      },
      {
        headerName: 'DPT Type',
        field: RuleListFieldNames.trialPeriodTypeCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
    ];
    if (hideSelectAll) {
      columns.shift();
    }
    return columns;
  }

  getColumnsForGrid(hideSelectAll?, codes?) {
    const stringOperators = [
      Operators.Equals,
      Operators.NotEquals,
      Operators.Contains,
      Operators.NotContains,
      Operators.StartsWith,
      Operators.EndsWith,
      Operators.OneOf,
      Operators.Empty,
      Operators.NotEmpty,
    ];
    //
    const columns = [
      {
        ...XpoNgxGridColumns.SELECTION_WITH_SELECT_ALL,
        pinned: 'left',
      },
      {
        headerName: 'Rule Id',
        field: RuleListFieldNames.priceRuleId,
        width: 100,
        cellRendererFramework: RuleIdRendererComponent,
        pinned: 'left',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Logical Id',
        field: RuleListFieldNames.logicalRuleId,
        width: 100,
        pinned: 'left',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Version',
        field: RuleListFieldNames.ruleVersionNbr,
        width: 100,
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Dynamic Code',
        field: RuleListFieldNames.externalRuleId,
        width: 100,
        filter: true,
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Description',
        field: RuleListFieldNames.ruleDescription,
        width: 450,
        filter: true,
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Status',
        field: RuleListFieldNames.statusCd,
        width: 100,
        filter: false,
      },
      {
        headerName: 'Effective Date',
        field: RuleListFieldNames.effectiveDate,
        width: 150,
        valueGetter: (params: any) => {
          return params.data ? this.formatDate.transformDate(params.data.effectiveDate) : '';
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Expiry Date',
        field: RuleListFieldNames.expiryDate,
        width: 125,
        valueGetter: (params: any) => {
          return params.data ? this.formatDate.transformDate(params.data.expiryDate) : '';
        },
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Designation Type',
        field: RuleListFieldNames.designationType,
        width: 150,
        filter: 'agTextColumnFilter',
        filterParams: {
          operators: stringOperators,
        },
        sortable: true,
      },
      {
        headerName: 'Lane',
        field: RuleListFieldNames.laneId,
        width: 150,
        filter: 'agTextColumnFilter',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Applicable Type',
        field: RuleListFieldNames.applicableTypeCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Trial Period',
        field: RuleListFieldNames.trialPeriodTypeCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Discount',
        filter: 'agNumberColumnFilter',
        width: 120,
        cellRendererFramework: DiscountCellComponent,
      },
      {
        headerName: 'Quotes',
        field: RuleListFieldNames.dynShipmentCountRateQuoteDateCount,
        width: 100,
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Quoted Revenue',
        field: RuleListFieldNames.dynShipmentCountRateQuoteDateAmount,
        width: 160,
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Booked',
        field: RuleListFieldNames.dynShipmentCountShipmentCount,
        width: 100,
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Booked Revenue',
        field: RuleListFieldNames.dynShipmentCountShipmentAmount,
        width: 160,
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'MARS',
        field: RuleListFieldNames.marsInd,
        width: 100,
        valueFormatter: (params) => {
          return params.data ? this.yesNoPipe.transform(params.data[RuleListFieldNames.marsInd]) : 'N';
        },
        filter: 'xpoBooleanFilterComponent',
      },
      {
        headerName: 'AMC Override',
        field: RuleListFieldNames.amcOverrideCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'AC Override',
        field: RuleListFieldNames.accessorialOverrideCd,
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'FAK Override',
        field: RuleListFieldNames.fakInd,
        width: 150,
        valueFormatter: (params) => {
          return params.data ? this.yesNoPipe.transform(params.data[RuleListFieldNames.fakInd]) : 'N';
        },
      },
      {
        headerName: 'Group Id',
        field: RuleListFieldNames.customerGroupId,
        width: 150,
        filter: 'agNumberColumnFilter',
      },
      /**
      {
        headerName: 'Rate Type',
        field: RuleListFieldNames.rateTypeCd,
        width: 250,
        filter: 'agTextColumnFilter',
        filterParams: {
          operators: stringOperators,
        },
        valueFormatter: (params) => {
          return params.value ? codes.rateTypes.find((code) => code.code === params.value)?.name : null;
        },
      },
      {
        headerName: 'Rule Type',
        field: RuleListFieldNames.ruleTypeCd,
        width: 250,
        filter: 'agTextColumnFilter',
        valueFormatter: (params) => {
          return params.value ? codes.ruleTypes.find((code) => code.code === params.value)?.name : null;
        },
      },
      */
    ];
    if (hideSelectAll) {
      columns.shift();
    }
    return columns;
  }

  isEditableRule(statusCd: string, expiryDate: Date): boolean {
    let result = false;
    switch (statusCd) {
      case this.constants.PENDING:
        result = true;
        break;
      case this.constants.ACTIVE:
        if (moment(expiryDate).isSameOrAfter(new Date(), 'day')) {
          result = true;
        }
        break;
    }
    return result;
  }

  isSelectableRule(statusCd: string, effectiveDate: Date, expiryDate: Date): boolean {
    let result = false;
    switch (statusCd) {
      case this.constants.PENDING:
        if (
          moment(effectiveDate).isSameOrAfter(new Date(), 'day') &&
          moment(expiryDate).isSameOrAfter(new Date(), 'day')
        ) {
          result = true;
        }
        break;
      case this.constants.ACTIVE:
        if (moment(expiryDate).isSameOrAfter(new Date(), 'day')) {
          result = true;
        }
        break;
    }
    return result;
  }

  setFilterCache(filters): void {
    this.ruleListFiltersCache$ = filters;
  }

  getFilterCache(): any {
    const cache = this.ruleListFiltersCache$ ? this.ruleListFiltersCache$ : [];
    return cache;
  }

  setClearCache(data: boolean): void {
    this.startClear = data;
  }

  getClearCache(): boolean {
    return this.startClear;
  }

  setDetailData(data: any): void {
    this.ruleData.next(data);
  }
}
