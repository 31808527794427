<input
  type="text"
  (focusout)="onFocusOut($event)"
  (focusin)="onFocusIn()"
  [maxlength]="maxLength"
  class="xpo-AgGrid-cellEditor--decimal-input"
  #input
  (keydown)="onKeyDown($event)"
  [(ngModel)]="value"
/>
