import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import moment from 'moment';
import { LaneDiscountFieldLabels } from '../../../../shared/enums/field-labels/lane-discount-field-labels.enum';
import { LaneDiscountFieldNames } from '../../../../shared/enums/field-names/lane-discount-field-names.enum';
import { ConstantsService } from '../../../../shared/services/constants/constants.service';
import { FormatDateService } from '../../../../shared/services/format-date/format-date.service';
import { LaneDiscountService } from '../../../../shared/services/lane-discount/lane-discount.service';

@Component({
  selector: 'app-new-period-modal',
  templateUrl: './new-period-modal.component.html',
  styleUrls: ['./new-period-modal.component.scss'],
})
export class NewPeriodModalComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  minEffectiveDate: Date;
  maxEffectiveDate: Date;
  submitting: boolean;
  unsubscriber = new Unsubscriber();
  readonly LaneDiscountFieldNames = LaneDiscountFieldNames;
  readonly LaneDiscountFieldLabels = LaneDiscountFieldLabels;

  constructor(
    private constants: ConstantsService,
    private formatDateService: FormatDateService,
    private laneDiscountService: LaneDiscountService,
    public dialogRef: MatDialogRef<NewPeriodModalComponent>
  ) {}

  ngOnInit() {
    const basePeriod = this.laneDiscountService.DiscountList.find((period) => period.isActive);
    const baseDiscount = basePeriod.data[0];

    this.minEffectiveDate = moment()
      .startOf('day')
      .add(1, 'day')
      .toDate();

    this.maxEffectiveDate = moment(this.laneDiscountService.dynamicRule.expiryDate)
      .startOf('day')
      .subtract(1, 'day')
      .toDate();

    this.formGroup = new FormGroup({
      [LaneDiscountFieldNames.effectiveDate]: new FormControl(this.minEffectiveDate, [Validators.required]),
      [LaneDiscountFieldNames.ruleCode]: new FormControl(baseDiscount.externalRuleCdHeadHaul, [Validators.required]),
      [LaneDiscountFieldNames.ruleDiscount]: new FormControl(baseDiscount.headHaulDiscountPercentage, [
        Validators.required,
        Validators.pattern(this.constants.RULE_RATE_PATTERN_OPTIONAL_DECIMALS),
        Validators.min(0),
        Validators.max(1.0),
      ]),
      [LaneDiscountFieldNames.ruleDescription]: new FormControl(null),
      [LaneDiscountFieldNames.expirationReason]: new FormControl(null),
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  submit() {
    const effectiveDate = this.formGroup.get(LaneDiscountFieldNames.effectiveDate).value;
    const effectiveDateAsString = effectiveDate
      ? this.formatDateService.transformDateWithFormat(effectiveDate, this.constants.dateServiceFormat)
      : null;
    const data = {
      ...this.formGroup.getRawValue(),
      [LaneDiscountFieldNames.effectiveDate]: effectiveDateAsString,
    };
    if (effectiveDateAsString) {
      this.submitting = true;
      const newPeriodId = this.laneDiscountService.createNewPeriod(data);
      this.dialogRef.close(newPeriodId);
    }
  }

  getErrorMessage(fieldName: string) {
    const fieldControl = this.formGroup.get(fieldName);
    let message = null;
    if (fieldControl.hasError('required')) {
      message = 'Required field';
    } else if (fieldName === LaneDiscountFieldNames.effectiveDate && fieldControl.hasError('matDatepickerParse')) {
      message = 'Invalid date';
    } else if (fieldName === LaneDiscountFieldNames.effectiveDate && fieldControl.hasError('matDatepickerMin')) {
      message = 'The effective date should be greater than current date';
    } else if (fieldName === LaneDiscountFieldNames.ruleDiscount && fieldControl.hasError('min')) {
      return 'Should be greater or equal than 0';
    } else if (fieldName === LaneDiscountFieldNames.ruleDiscount && fieldControl.hasError('max')) {
      return 'Should be less or equal than 1';
    } else if (fieldName === LaneDiscountFieldNames.ruleDiscount && fieldControl.hasError('pattern')) {
      return 'Format must be 0.0000';
    } else {
      message = null;
    }
    return message;
  }
}
