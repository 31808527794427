export enum PricingLocationFieldLabels {
  pricingLocationId = 'Id',
  description = 'Description',
  accountNumber = 'Account Nbr',
  locationType = 'Location Type',
  effectiveDate = 'Effective Date',
  effectiveAsOfDate = 'Effective as of Date',
  expiryDate = 'Expiry Date',
  origin = 'Origin Lane',
  destination = 'Destination Lane',
  lanePairType = 'Lane Pair Type',
  ruleSet = 'Rule Set',
  madCode = 'MAD Code',
}
