import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SpotQuoteTrayService } from './../../../services/spot-quote-tray.service';

@Component({
  selector: 'app-spot-quote-id-renderer',
  templateUrl: './spot-quote-id-renderer.component.html',
  styleUrls: ['./spot-quote-id-renderer.component.scss'],
})
export class SpotQuoteIdRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  spotQuoteId: number;

  constructor(private spotQuoteTrayService: SpotQuoteTrayService) {}

  agInit(params: any): void {
    this.spotQuoteId = params.value;
  }

  openDetail() {
    this.spotQuoteTrayService.setDetailData({ showTray: true, spotQuoteId: this.spotQuoteId });
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.spotQuoteId = params.value;
    return true;
  }
}
