<div class="content">
  <h1>Bulk Create Rules</h1>
  <xpo-upload #uploadInput [config]="basicConfig" multiple="false"></xpo-upload>
  <form [formGroup]="formGroup">
    <div class="options">
      <div class="field-label">File Type</div>
      <mat-radio-group formControlName="uploadType">
        <mat-radio-button [value]="UploadTypeEnum.DP_RULES_CREATE">
          Rule Create/version Change
        </mat-radio-button>
        <mat-radio-button [value]="UploadTypeEnum.DP_RULE_DISCOUNTS_UPDATE">
          Rule Discount Change
        </mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="formGroup.controls.uploadType.hasError('required')">
        Please indicate the type of file to upload
      </mat-error>
    </div>
  </form>
  <div class="actionButtons">
    <button
      mat-flat-button
      (click)="!processing && basicConfig.uploadFileTrigger()"
      [disabled]="!hasFiles || processing || formGroup.invalid"
    >
      Upload File
    </button>
  </div>
</div>
