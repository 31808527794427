<mat-drawer-container class="dynamic-rule-drawer" #extendedTray hasBackdrop="false">
  <mat-drawer xpoTrayDrawer mode="over" position="end" extended (openedChange)="openedChange($event)">
    <xpo-tray-panel>
      <xpo-tray-panel-header>
        <xpo-tray-panel-header-title>
          <span *ngIf="!pricingDetailView">{{
            ruleDetailData && ruleDetailData.priceRuleId ? 'Update Rule' : 'Add new Rule'
          }}</span>
          <span *ngIf="pricingDetailView">{{ 'Rule Details' }}</span>
        </xpo-tray-panel-header-title>
      </xpo-tray-panel-header>
      <xpo-tray-panel-content>
        <app-dynamic-rule-detail-page *ngIf="trayOpen" [pricingRuleId]="ruleDetailData?.priceRuleId">
        </app-dynamic-rule-detail-page>
      </xpo-tray-panel-content>
    </xpo-tray-panel>
  </mat-drawer>

  <mat-drawer-content>
    <xpo-card-content class="dynamic-card-content">
      <xpo-ngx-grid
        [gridConfig]="customGridOptions"
        (gridReady)="onGridReady($event)"
        #gridRef
        class="dynamic-rules-grid"
      >
        <xpo-grid-facet-filter>
          <xpo-facet-filter #facetFilter [facetContracted]="false">
            <app-dynamic-rule-list-filters
              [defaultFilters]="defaultFilters"
              (searchClick)="onSearchClick($event)"
              (clearClick)="onClearClick()"
            >
            </app-dynamic-rule-list-filters>
          </xpo-facet-filter>
        </xpo-grid-facet-filter>

        <xpo-grid-toolbar [gridRef]="gridRef">
          <xpo-grid-toolbar-settings-group>
            <button
              class="dynamic-button-effect"
              xpo-action-button
              (click)="onUploadClick()"
              *ngIf="!pricingDetailView"
            >
              <mat-icon>upload</mat-icon>
              <span>Bulk Create Rules</span>
            </button>
            <button class="dynamic-button-effect" xpo-action-button (click)="onAddClick()" *ngIf="!pricingDetailView">
              <mat-icon>add</mat-icon>
              <span>Create Rule</span>
            </button>
            <button
              class="dynamic-button-effect"
              xpo-action-button
              (click)="onEditClick()"
              [disabled]="isEditButtonDisable"
              *ngIf="!pricingDetailView"
            >
              <mat-icon>edit</mat-icon>
              <span>Update Rule</span>
            </button>
            <button
              class="dynamic-button-effect"
              xpo-action-button
              (click)="onDownloadClick()"
              [disabled]="isDownloadButtonDisable"
              *ngIf="!pricingDetailView"
            >
              <mat-icon>download</mat-icon>
              <span>Download</span>
            </button>
            <app-download-info *ngIf="!pricingDetailView"></app-download-info>
            <xpo-grid-toolbar-row-height-button></xpo-grid-toolbar-row-height-button>
            <xpo-grid-toolbar-settings-button></xpo-grid-toolbar-settings-button>
          </xpo-grid-toolbar-settings-group>
        </xpo-grid-toolbar>

        <mat-paginator
          #paginator
          [length]="dataLength"
          [pageSize]="pageSize"
          (page)="pageEvent = onPaginationChanged($event)"
          aria-label="Select page"
          class="storage-paginator"
        >
        </mat-paginator>
      </xpo-ngx-grid>
    </xpo-card-content>
  </mat-drawer-content>
</mat-drawer-container>
