import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DynamicPriceRule, DynamicShipmentCount, GetDynamicPricingRuleResp } from '@xpo-ltl/sdk-dynamicpricing';
import * as _ from 'lodash';
import { concat, Observable, of } from 'rxjs/index';
import { concatMap, map, mergeMap } from 'rxjs/internal/operators';
import { DynamicPricingService } from '../dynamic-pricing/dynamic-pricing.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicRuleDetailResolverService {
  constructor(private dynamicPricingService: DynamicPricingService) {}

  getDynamicRule(priceRuleId: number, getImplementationDate: boolean = false): Observable<any> {
    // const priceRuleId = route.params.pricingRuleId;
    // TODO: use this code once we have the rule header data on elasticsearch
    // ------------------------
    // return this.dynamicPricingService.getElasticDocumentDynamicPriceRule(priceRuleId).pipe(
    //   concatMap((response: GetElasticDocumentDynamicPriceRuleResp) => {
    //     const rule: DynamicPriceRule = {
    //       ...response.record,
    //       parentAcctId: null,
    //       auditInfo: null,
    //     };
    //     const ruleCds = {
    //       dynRuleCodes: [{ externalRuleCd: rule.externalRuleId }],
    //     };
    // ------------------------
    return this.dynamicPricingService.getDynamicRuleBasedOnId(priceRuleId).pipe(
      concatMap((response: GetDynamicPricingRuleResp) => {
        const rule = response.dynPrcRule,
          ruleCds = {
            dynRuleCodes: [{ priceRuleHeaderId: rule.priceRuleId }],
          };

        return this.dynamicPricingService.getShipmentCountsList(ruleCds).pipe(
          map((countResp: DynamicShipmentCount[]) => {
            countResp.sort(function(a, b) {
              return b.rateQuoteDateCount - a.rateQuoteDateCount;
            });
            const validCount = _.find(countResp, (count) => {
              return rule.priceRuleId.toString() === count.externalRuleId.split('-')[0];
            });
            return _.assign(rule, this.getShipmentCountObj(validCount));
          }),
          concatMap((shipmentResponse: any) => {
            return shipmentResponse.ruleVersionNbr > 1 && getImplementationDate
              ? this.dynamicPricingService.getDynamicRuleBasedOnId(shipmentResponse.logicalRuleId).pipe(
                  map((result) => {
                    shipmentResponse.implementationDate = result.dynPrcRule.effectiveDate;
                    return shipmentResponse;
                  })
                )
              : of(shipmentResponse);
          })
        );
      })
    );
  }

  private getShipmentCountObj(count) {
    return {
      rateQuoteDateCount: count ? count.rateQuoteDateCount : 0,
      rateQuoteDateAmount: count ? count.rateQuoteDateAmount : 0,
      shipmentCount: count ? count.shipmentCount : 0,
      shipmentAmount: count ? count.shipmentAmount : 0,
    };
  }
}
