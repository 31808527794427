import { Injectable } from '@angular/core';
import { ListDynamicPriceRuleCustomerExcludesRqst, ListDynamicShipmentsQuery } from '@xpo-ltl/sdk-dynamicpricing';
import { SearchAccessorialCharge } from '../../models/search-accessorial-charge';
import { SearchLocationInformation } from '../../models/search-location-information';
import { SearchLocationRuleSetInformation } from '../../models/search-location-rule-set-information';
import { SearchRule } from '../../models/search-rule';
import { SearchSpotQuote } from '../../models/search-spot-quote';

@Injectable({
  providedIn: 'root',
})
export class DynamicPricingStorageService {
  private dynamicShipmentsQueryParams: ListDynamicShipmentsQuery;
  private dynamicRulesQueryParams: SearchRule;
  private spotQuoteListQueryParams: SearchSpotQuote;
  private accessorialChargeListQueryParams: SearchAccessorialCharge;
  private pricingLocationInformationListQueryParams: SearchLocationInformation;
  private pricingLocationRulesetInformationListQueryParams: SearchLocationRuleSetInformation;
  customerExcludeListCache: any;
  customerExcludeListParams: ListDynamicPriceRuleCustomerExcludesRqst;

  constructor() {}

  setDynamicShipmentsQueryParams(params: ListDynamicShipmentsQuery): void {
    this.dynamicShipmentsQueryParams = params;
  }

  getDynamicShipmentsQueryParams(): ListDynamicShipmentsQuery {
    return this.dynamicShipmentsQueryParams;
  }

  setDynamicRulesQueryParams(params: SearchRule): void {
    this.dynamicRulesQueryParams = params;
  }

  getDynamicRulesQueryParams(): SearchRule {
    return this.dynamicRulesQueryParams;
  }

  setCustomerExcludeListCache(data: any): any {
    this.customerExcludeListCache = data;
  }

  getCustomerExcludeListCache(): any {
    return this.customerExcludeListCache;
  }

  setCustomerExcludeListParams(params: ListDynamicPriceRuleCustomerExcludesRqst): void {
    this.customerExcludeListParams = params;
  }

  getCustomerExcludeListParams(): ListDynamicPriceRuleCustomerExcludesRqst {
    return this.customerExcludeListParams;
  }

  setSpotQuoteListParams(params: SearchSpotQuote): void {
    this.spotQuoteListQueryParams = params;
  }

  getSpotQuoteListParams(): SearchSpotQuote {
    return this.spotQuoteListQueryParams;
  }

  setAccessorialChargeListParams(params: SearchAccessorialCharge): void {
    this.accessorialChargeListQueryParams = params;
  }

  getAccessorialChargeListParams(): SearchAccessorialCharge {
    return this.accessorialChargeListQueryParams;
  }

  setPricingLocationInformationListParams(params: SearchLocationInformation): void {
    this.pricingLocationInformationListQueryParams = params;
  }

  getPricingLocationInformationListParams(): SearchLocationInformation {
    return this.pricingLocationInformationListQueryParams;
  }

  setPricingLocationRulesetInformationListParams(params: SearchLocationRuleSetInformation): void {
    this.pricingLocationRulesetInformationListQueryParams = params;
  }

  getPricingLocationRulesetInformationListParams(): SearchLocationRuleSetInformation {
    return this.pricingLocationRulesetInformationListQueryParams;
  }

  getExcludeListColumnsForQuickSearch() {
    return [
      'priceRuleCustomerExcludeId',
      'customerNbr',
      'madCode',
      'customerName',
      'effectiveDate',
      'expiryDate',
      'restrictionTypeCd',
      'programType',
      'locationTypeCd',
    ];
  }
}
