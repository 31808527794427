import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConstantsService } from '../../../shared/services/constants/constants.service';
import { PricingWorkbenchService } from '../../../shared/services/pricing-workbench/pricing-workbench.service';

@Component({
  selector: 'app-download-info',
  templateUrl: './download-info.component.html',
  styleUrls: ['./download-info.component.scss'],
})
export class DownloadInfoComponent implements OnInit, OnDestroy {
  codes: Subscription;
  responseDataSource: any = [{ code: 'No items' }];
  readonly responseColumns: string[] = ['displayOrder', 'code', 'name'];
  constructor(private pwbService: PricingWorkbenchService, private constantsService: ConstantsService) {}

  ngOnInit(): void {
    this.getCodes();
  }

  getCodes() {
    this.codes = this.pwbService.requestDownloadFieldsCodes().subscribe((result) => {
      this.responseDataSource = result;
    });
  }

  doFilter = (value: string) => {
    this.responseDataSource.filter = value.trim().toLocaleLowerCase();
  }

  ngOnDestroy() {
    this.codes.unsubscribe();
  }
}
