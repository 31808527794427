import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DynamicRuleTrayService } from '../../../service/dynamic-rule-tray.service';

@Component({
  selector: 'app-rule-id-renderer',
  templateUrl: './rule-id-renderer.component.html',
  styleUrls: ['./rule-id-renderer.component.scss'],
})
export class RuleIdRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  ruleId: number;
  readOnly: boolean;
  constructor(private route: ActivatedRoute, private dynamicRuleTrayService: DynamicRuleTrayService) {}

  agInit(params: any): void {
    this.readOnly = !!this.route.snapshot?.data?.filters;
    this.ruleId = params.value;
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.ruleId = params.value;
    return true;
  }

  openDetail() {
    this.dynamicRuleTrayService.setDetailData({ showTray: true, ...this.params.data });
  }
}
