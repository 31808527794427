import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SearchFormService {
  constructor() {}

  checkValueInForm(value) {
    return Object.keys(value).some((k) => {
      return value[k];
    });
  }

  validateFormFields(formControls) {
    Object.keys(formControls).forEach((field) => {
      const control = formControls[field];
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    const firstElementWithError = document.querySelector('.ng-invalid');
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
