import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { Code, DynamicPriceStatusCd, User } from '@xpo-ltl/sdk-common';
import { DynamicPriceRule } from '@xpo-ltl/sdk-dynamicpricing';
import { AccountDetail } from '@xpo-ltl/sdk-pricing';
import { CustomerHierarchyAccount } from '@xpo-ltl/sdk-pricingagmtmgmt';
import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable, Observer } from 'rxjs';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { take } from 'rxjs/operators';
import { AlertDialogComponent } from '../../../dialogs/alert/alert-dialog.component';
import { DynamicRuleUploadData } from '../../../dynamic-rule/components/dynamic-rule-upload/dynamic-rule-upload-data';
import { requireAtleastOneCheckboxValidator } from '../../custom-validators/require-atleast-one-checkbox.validator';
import { RequireMatch } from '../../custom-validators/required-match.validator';
import { ThresholdPeriodEmptyValidator } from '../../custom-validators/threshold-period-empty.validator';
import { DynamicRulesFileExportOperationEnum } from '../../enums';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { DynamicRule } from '../../models/dynamic-rule';
import { ConstantsService } from '../constants/constants.service';
import { DynamicPricingService } from '../dynamic-pricing/dynamic-pricing.service';
import { FormatDateService } from '../format-date/format-date.service';
import { PricingService } from '../pr/pricing.service';
import { PrcAgmtMgmtApiService } from '../pricing-agreement-management/prc-agmt-mgmt-api.service';
import { PricingWorkbenchService } from '../pricing-workbench/pricing-workbench.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicRuleService {
  private user: User;
  thresholdAmountValidators = [Validators.min(1), Validators.max(99999999)];
  minimumShipmentsNbrValidators = [Validators.min(1), Validators.max(9999)];

  constructor(
    private dynamicPricingService: DynamicPricingService,
    private http: HttpClient,
    private fb: FormBuilder,
    private constantsService: ConstantsService,
    private dialog: MatDialog,
    private formatDate: FormatDateService,
    private loginService: XpoLtlLoggedInUserService,
    private config: ConfigManagerService,
    private pricingWorkbenchService: PricingWorkbenchService,
    private prcAgmtService: PrcAgmtMgmtApiService
  ) {
    // Load all pricing
    this.pricingWorkbenchService
      .getPricingCodes()
      .pipe(take(1))
      .subscribe((response: PricingCode[]) => {
        this.STATE_VALUES = _.filter(response, { subCategory: 'State' });
        this.SIC_VALUES = _.filter(response, { subCategory: 'Sic' });
        this.RATE_TYPES = _.filter(response, { subCategory: 'RateType' });
      });

    this.loginService
      .getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  STATE_VALUES: PricingCode[] = [];
  SIC_VALUES: PricingCode[] = [];
  RATE_TYPES: PricingCode[] = [];

  FIELD_NAMES_MAP = new Map<string, string>([
    ['DYNAMIC_CODE', 'dynamicCode'],
    ['DYNAMIC_RULE_NAME', 'dynamicRuleName'],
    ['EFFECTIVE_DATE', 'effectiveDate'],
    ['EXPIRATION_DATE', 'expiryDate'],
    ['PERCENT_OFF_TOTAL_CHARGES', 'pctOff'],
    ['RATE_TYPE', 'rateType'],
    ['MINIMUM_O/R', 'minOr'],
    ['MINIMUM_YIELD', 'minYield'],
    ['MINIMUM_WEIGHT', 'minWt'],
    ['MAXIMUM_WEIGHT', 'maxWt'],
    ['CUSTOMER_SHIPMENT_DAYS', 'customerShipmentDays'],
    ['LANE_SHIPMENT_DAYS', 'laneShipmentDays'],
    ['TRAFFIC_TYPE_INTERSTATE', 'interStateInd'],
    ['TRAFFIC_TYPE_INTRASTATE', 'intraStateInd'],
    ['TRAFFIC_TYPE_INTERNATIONAL', 'internationalInd'],
    ['APPLICATION_TYPE_OB/PPD', 'outboundPrepaidInd'],
    ['APPLICATION_TYPE_OB/COL', 'outboundColInd'],
    ['APPLICATION_TYPE_IB/COL', 'inboundPrepaidInd'],
    ['APPLICATION_TYPE_THIRDPARTY', 'thirdPartyInd'],
  ]);

  agreementAcctCdDisabled(ruleData: DynamicRule): boolean {
    if (!ruleData.priceRuleId) {
      return false;
    }

    switch (ruleData.statusCd) {
      case DynamicPriceStatusCd.PENDING:
        return false;

      case DynamicPriceStatusCd.ACTIVE:
        return moment(ruleData.effectiveDate)
          .startOf('day')
          .isAfter(moment().startOf('day'))
          ? false
          : true;

      default:
        return true;
    }
  }

  createDynamicRuleForm(dynamicRuleData: DynamicRule): FormGroup {
    const effectiveDate = dynamicRuleData ? new Date(dynamicRuleData.effectiveDate) : '';
    const expiryDate = dynamicRuleData ? new Date(dynamicRuleData.expiryDate) : '';
    const selectedValues =
      dynamicRuleData && dynamicRuleData.qualifyBy && dynamicRuleData.qualifyBy.length !== 0
        ? this.getSelectedRows(dynamicRuleData.qualifyBy)
        : this.getOrigDestFormArray();
    const thresholdPeriod = dynamicRuleData ? dynamicRuleData.thresholdPeriod : null;
    return new FormGroup({
      dynamicRuleName: new FormControl(dynamicRuleData ? dynamicRuleData.ruleDescription : '', [Validators.required]),
      effectiveDate: new FormControl(effectiveDate, [Validators.required]),
      expiryDate: new FormControl(expiryDate, [Validators.required]),
      status: new FormControl({
        value: dynamicRuleData ? dynamicRuleData.statusCd : this.constantsService.PENDING,
        disabled: true,
      }),
      minClass: new FormControl(dynamicRuleData ? dynamicRuleData.minimumCommodityClassNbr : '', [
        Validators.required,
        Validators.min(0.0),
        Validators.max(500),
      ]),
      maxClass: new FormControl(dynamicRuleData ? dynamicRuleData.maximumCommodityClassNbr : '', [
        Validators.required,
        Validators.min(0.0),
        Validators.max(500),
      ]),
      customerShipmentDays: new FormControl(dynamicRuleData ? dynamicRuleData.customerLastShipmentInDays : '', [
        Validators.min(0),
        Validators.max(9999),
      ]),
      customerGroupId: new FormControl(
        dynamicRuleData && !!dynamicRuleData.customerGroupId ? dynamicRuleData.customerGroupId : '',
        [RequireMatch]
      ),
      laneShipmentDays: new FormControl(dynamicRuleData ? dynamicRuleData.laneLastShipmentInDays : '', [
        Validators.min(0),
        Validators.max(9999),
      ]),
      origQualifyBy: new FormControl(),
      destQualifyBy: new FormControl(),
      origInput: new FormControl(),
      destInput: new FormControl(),
      origLowZip: new FormControl(null, [Validators.pattern(this.constantsService.US_CANADA_ZIP)]),
      origHighZip: new FormControl(null, [Validators.pattern(this.constantsService.US_CANADA_ZIP)]),
      destLowZip: new FormControl(null, [Validators.pattern(this.constantsService.US_CANADA_ZIP)]),
      destHighZip: new FormControl(null, [Validators.pattern(this.constantsService.US_CANADA_ZIP)]),
      origInEx: new FormControl(),
      destInEx: new FormControl(),
      origOptionList: new FormArray([]),
      destOptionList: new FormArray([]),
      origSelectedList: selectedValues.get('originList'),
      destSelectedList: selectedValues.get('destList'),
      trafficType: new FormGroup(
        {
          interStateInd: new FormControl(dynamicRuleData ? dynamicRuleData.interStateInd : false),
          intraStateInd: new FormControl(dynamicRuleData ? dynamicRuleData.intraStateInd : false),
          internationalInd: new FormControl(dynamicRuleData ? dynamicRuleData.internationalInd : false),
        },
        requireAtleastOneCheckboxValidator()
      ),
      applicationType: new FormGroup(
        {
          outboundPrepaidInd: new FormControl(dynamicRuleData ? dynamicRuleData.outboundPrepaidInd : false),
          outboundColInd: new FormControl(dynamicRuleData ? dynamicRuleData.outboundColInd : false),
          inboundPrepaidInd: new FormControl(dynamicRuleData ? dynamicRuleData.inboundPrepaidInd : false),
          thirdPartyInd: new FormControl(dynamicRuleData ? dynamicRuleData.thirdPartyInd : false),
        },
        requireAtleastOneCheckboxValidator()
      ),
      rateType: new FormControl(
        {
          value: dynamicRuleData ? dynamicRuleData.rateTypeCd : null,
          disabled: true,
        },
        [Validators.required]
      ),
      ruleTypeCd: new FormControl(
        {
          value: dynamicRuleData ? dynamicRuleData.ruleTypeCd : null,
          disabled: true,
        },
        [Validators.required]
      ),
      ratingTariff: new FormControl(dynamicRuleData ? dynamicRuleData.ratingTariff : '', [Validators.maxLength(6)]),
      agreementAcctCd: new FormControl({
        value: dynamicRuleData ? dynamicRuleData.agreementAcctCd : '',
        disabled: dynamicRuleData ? this.agreementAcctCdDisabled(dynamicRuleData) : false,
      }),
      currentOwnerEmployeeId: new FormControl({
        value: dynamicRuleData ? dynamicRuleData.currentOwnerEmployeeId : null,
        disabled: true,
      }),
      agreementAcctId: new FormControl({
        value: dynamicRuleData ? dynamicRuleData.agreementAcctId : null,
        disabled: true,
      }),
      designationType: new FormControl(dynamicRuleData ? dynamicRuleData.designationType : null, [
        Validators.maxLength(20),
      ]),
      laneId: new FormControl(dynamicRuleData ? dynamicRuleData.laneId : null, [Validators.maxLength(100)]),
      cdiRuleInd: new FormControl(dynamicRuleData ? dynamicRuleData.cdiRuleInd : false),
      thresholdPeriod: new FormControl(thresholdPeriod, [ThresholdPeriodEmptyValidator(thresholdPeriod)]),
      thresholdAmount: new FormControl(
        dynamicRuleData ? dynamicRuleData.thresholdAmount : null,
        this.thresholdAmountValidators
      ),
      minimumShipmentsNbr: new FormControl(
        dynamicRuleData ? dynamicRuleData.minimumShipmentsNbr : null,
        this.minimumShipmentsNbrValidators
      ),
      applicableType: new FormControl(dynamicRuleData ? dynamicRuleData.applicableTypeCd : ''),
      autoAdjustAllowedInd: new FormControl(dynamicRuleData ? dynamicRuleData.autoAdjustAllowedInd : false),
      autoAdjustGroup: new FormControl(
        dynamicRuleData ? dynamicRuleData.applicationType : this.constantsService.SELF_ADJUSTMENT_RULES_DEFAULT_GROUP
      ),
      marsInd: new FormControl(dynamicRuleData ? dynamicRuleData.marsInd : false),
      fakInd: new FormControl({ value: dynamicRuleData ? dynamicRuleData.fakInd : false, disabled: true }),
      trialPeriodTypeCd: new FormControl({
        value:
          dynamicRuleData && dynamicRuleData.applicableTypeCd === this.constantsService.OPP
            ? dynamicRuleData.trialPeriodTypeCd
            : null,
        disabled: !(dynamicRuleData && dynamicRuleData.applicableTypeCd === this.constantsService.OPP),
      }),
    });
  }

  getSicStateOptions() {
    const sicStateForm = new FormGroup({
      sic: new FormArray([]),
      state: new FormArray([]),
    });
    this.pricingWorkbenchService.getPricingCodes().subscribe((response: PricingCode[]) => {
      _.sortBy(response, ['name']).forEach((i) => {
        if (i.subCategory.toUpperCase() === this.constantsService.SIC) {
          const value = this.fb.group({
            name: i.name,
            code: i.code,
            selected: false,
          });
          (<FormArray>sicStateForm.get('sic')).push(value);
        } else if (i.subCategory === this.constantsService.STATE) {
          const value = this.fb.group({
            name: i.name,
            code: i.code,
            selected: false,
          });
          (<FormArray>sicStateForm.get('state')).push(value);
        }
      });
    });
    return sicStateForm;
  }

  getCountryOptions() {
    return new FormArray([
      this.fb.group({
        name: 'US',
        code: 'US',
        selected: false,
      }),
      this.fb.group({
        name: 'CN',
        code: 'CN',
        selected: false,
      }),
    ]);
  }

  getSelectedRows(data) {
    const selectedList = this.getOrigDestFormArray();
    data.forEach((value) => {
      value.origInclusions = value.origInclusions.split(',').filter(Boolean);
      value.origExclusions = value.origExclusions.split(',').filter(Boolean);
      value.origInclusions.forEach((i) => {
        const originValue = this.fb.group({
          qualifyCd: value.qualifierCd,
          origValue: i,
          inExValue: this.constantsService.IN,
          selected: false,
        });
        (<FormArray>selectedList.get('originList')).push(originValue);
      });
      value.origExclusions.forEach((i) => {
        const originValue = this.fb.group({
          qualifyCd: value.qualifierCd,
          origValue: i,
          inExValue: this.constantsService.EX,
          selected: false,
        });
        (<FormArray>selectedList.get('originList')).push(originValue);
      });
      value.destInclusions = value.destInclusions.split(',').filter(Boolean);
      value.destExclusions = value.destExclusions.split(',').filter(Boolean);
      value.destInclusions.forEach((i) => {
        const destValue = this.fb.group({
          qualifyCd: value.qualifierCd,
          destValue: i,
          inExValue: this.constantsService.IN,
          selected: false,
        });
        (<FormArray>selectedList.get('destList')).push(destValue);
      });
      value.destExclusions.forEach((i) => {
        const destValue = this.fb.group({
          qualifyCd: value.qualifierCd,
          destValue: i,
          inExValue: this.constantsService.EX,
          selected: false,
        });
        (<FormArray>selectedList.get('destList')).push(destValue);
      });
    });
    return selectedList;
  }

  getTrafficTypes() {
    return [
      {
        name: this.constantsService.INTER_STATE,
        formControl: 'interStateInd',
      },
      {
        name: this.constantsService.INTRA_STATE,
        formControl: 'intraStateInd',
      },
      {
        name: this.constantsService.INTERNATIONAL,
        formControl: 'internationalInd',
      },
    ];
  }

  getApplicationTypes() {
    return [
      {
        name: this.constantsService.OB_PPD,
        formControl: 'outboundPrepaidInd',
      },
      {
        name: this.constantsService.OB_COL,
        formControl: 'outboundColInd',
      },
      {
        name: this.constantsService.IB_COL,
        formControl: 'inboundPrepaidInd',
      },
      {
        name: this.constantsService.THIRD_PARTY,
        formControl: 'thirdPartyInd',
      },
    ];
  }

  addItemToSelectedList(form, dynamicRuleUploadData: DynamicRuleUploadData) {
    const option = this.getOrigDestOptions(dynamicRuleUploadData.originDestination);
    const selectedList = <FormArray>form.get(option.selectedList);

    selectedList.push(
      this.fb.group({
        qualifyCd: dynamicRuleUploadData.type,
        [option.value]: dynamicRuleUploadData.value,
        inExValue: dynamicRuleUploadData.includeExclude,
        selected: false,
      })
    );
    form.controls[option.inEx].reset();
    form.controls[option.input].reset();
    form.markAsDirty();
    return form;
  }

  updateField(form, name: string, value: string) {
    if (this.FIELD_NAMES_MAP.has(name)) {
      const controlName = this.FIELD_NAMES_MAP.get(name);
      if (controlName.endsWith('Date')) {
        form.controls[controlName].setValue(new Date(value));
      } else if (controlName.endsWith('Ind')) {
        form.controls[controlName].setValue(value.toUpperCase() === 'Y');
      } else {
        form.controls[controlName].setValue(value);
      }
      form.markAsDirty();
    }
  }

  lookupAccountNumbers(madCodes: string[]): Observable<Map<string, string>> {
    const chunks = _.chunk(madCodes, 100); // process in batches of 100
    const observable = new Observable<Map<string, string>>((observer) => {
      this.getBatchCustomerDetails(chunks, observer);
    });
    return observable;
  }

  private getBatchCustomerDetails(
    chunks: string[][],
    observer: Observer<Map<string, string>>,
    returnMap: Map<string, string> = new Map()
  ) {
    if (!chunks || chunks.length === 0) {
      observer.next(returnMap);
      observer.complete();
      return;
    }

    const chunk = chunks.shift();
    // split account numbers between madcodes and account numbers
    const accountNumbers: string[] = [];
    const madCodes: string[] = [];
    chunk.forEach((value) => {
      if (isNumeric(value)) {
        accountNumbers.push(value);
      } else {
        madCodes.push(value);
      }
    });
    this.prcAgmtService
      .listCustomerHierarchy(madCodes, accountNumbers)
      .pipe(take(1))
      .subscribe(
        (response) => {
          if (response) {
            response.forEach((value: CustomerHierarchyAccount) => {
              const { madCd: acctMadCd, customerNbr: acctInstId } = value;
              returnMap.set(acctMadCd, acctInstId);
              returnMap.set(acctInstId, acctMadCd);
            });
          }
          this.getBatchCustomerDetails(chunks, observer, returnMap);
        },
        (error) => {
          this.getBatchCustomerDetails(chunks, observer, returnMap);
        }
      );
  }

  addToSelectedList(form, type) {
    const value = form.value,
      option = this.getOrigDestOptions(type),
      selectedList = <FormArray>form.get(option.selectedList);
    switch (value[option.qualifyBy]) {
      case this.constantsService.SIC:
      case this.constantsService.STATE:
      case this.constantsService.COUNTRY:
        const selectedOptions = _.filter(value[option.optionList], { selected: true });
        selectedOptions.forEach((item) => {
          selectedList.push(
            this.fb.group({
              qualifyCd: value[option.qualifyBy],
              [option.value]: item.name,
              inExValue: value[option.inEx],
              selected: false,
            })
          );
        });
        form.setControl(
          option.optionList,
          this.removeSelectedRows(selectedOptions, form.get(option.optionList), 'name', 'name')
        );
        form.controls[option.inEx].reset();
        return form;
      case this.constantsService.LOCATION:
        const madCds = [],
          instIds = [];
        if (isNumeric(value[option.input])) {
          instIds.push(value[option.input].trim());
        } else {
          madCds.push(value[option.input].trim());
        }
        this.prcAgmtService.listCustomerHierarchy(madCds, instIds).subscribe((response: CustomerHierarchyAccount[]) => {
          if (response.length > 0) {
            const { madCd: acctMadCd, customerNbr: acctInstId } = response[0];
            selectedList.push(
              this.fb.group({
                qualifyCd: value[option.qualifyBy],
                [option.value]: `${acctMadCd}-${acctInstId}`,
                inExValue: value[option.inEx],
                selected: false,
              })
            );
            form.controls[option.inEx].reset();
            form.controls[option.input].reset();
            return form;
          } else {
            this.dialog.open(AlertDialogComponent, {
              data: { message: 'Location entered is not valid.' },
            });
            form.controls[option.inEx].reset();
            form.controls[option.input].reset();
            return form;
          }
        });
        break;
      case this.constantsService.ZIPCODE:
        const zipRange = this.getAutoCompletedZipCode(
          value[option.lowZip],
          value[option.highZip] || value[option.lowZip]
        );
        if (zipRange.lowZip <= zipRange.highZip) {
          selectedList.push(
            this.fb.group({
              qualifyCd: value[option.qualifyBy],
              [option.value]: `${zipRange.lowZip}-${zipRange.highZip}`,
              inExValue: value[option.inEx],
              selected: false,
            })
          );
          form.controls[option.inEx].reset();
          form.controls[option.lowZip].reset();
          form.controls[option.highZip].reset();
          return form;
        } else {
          this.dialog.open(AlertDialogComponent, {
            data: { message: 'Low Zip Range should be less than the High Zip Range.' },
          });
          form.controls[option.inEx].reset();
          form.controls[option.lowZip].reset();
          form.controls[option.highZip].reset();
          return form;
        }

      default:
        selectedList.push(
          this.fb.group({
            qualifyCd: value[option.qualifyBy],
            [option.value]: value[option.input],
            inExValue: value[option.inEx],
            selected: false,
          })
        );
        form.controls[option.inEx].reset();
        form.controls[option.input].reset();
        return form;
    }
  }

  clearSelectedLists(form) {
    form.setControl(this.getOrigDestOptions(this.constantsService.ORIG).selectedList, new FormArray([]));
    form.setControl(this.getOrigDestOptions(this.constantsService.DEST).selectedList, new FormArray([]));
  }

  removeFromSelectedList(form, type) {
    const value = form.value,
      option = this.getOrigDestOptions(type),
      selectedList = _.filter(value[option.selectedList], { selected: true });
    switch (value[option.qualifyBy]) {
      case this.constantsService.STATE:
      case this.constantsService.SIC:
      case this.constantsService.COUNTRY:
        selectedList.forEach((i) => {
          if (value[option.qualifyBy] === i.qualifyCd) {
            form.get(option.optionList).push(
              this.fb.group({
                name: i[option.value],
                code: i[option.value],
                selected: false,
              })
            );
          }
        });
        form.setControl(
          option.selectedList,
          this.removeSelectedRows(selectedList, form.get(option.selectedList), option.value, option.value)
        );
        return form;
      default:
        form.setControl(
          option.selectedList,
          this.removeSelectedRows(selectedList, form.get(option.selectedList), option.value, option.value)
        );
        return form;
    }
  }

  removeSelectedRows(selectedList, optionList, compareVal1, compareVal2) {
    selectedList.forEach((i) => {
      const index = optionList.value.findIndex((val) => {
        return val[compareVal1] === i[compareVal2];
      });
      if (index >= 0) {
        optionList.removeAt(index);
      }
    });
    return optionList;
  }

  getQualifiedData(data) {
    const qualifiedList = [];
    let optionList = data.origSelectedList.concat(data.destSelectedList);
    optionList = _.groupBy(optionList, 'qualifyCd');
    Object.entries(optionList).forEach(([key, value]: [string, {}]) => {
      const inExOptions = _.groupBy(value, 'inExValue');
      const option = {
        qualifierCd: key,
        origInclusions: '',
        origExclusions: '',
        destInclusions: '',
        destExclusions: '',
      };
      Object.entries(inExOptions).forEach(([keyOption, valueOption]) => {
        if (keyOption === 'In') {
          option.origInclusions = _.compact(_.map(valueOption, 'origValue')).toString();
          option.destInclusions = _.compact(_.map(valueOption, 'destValue')).toString();
        } else if (keyOption === 'Ex') {
          option.origExclusions = _.compact(_.map(valueOption, 'origValue')).toString();
          option.destExclusions = _.compact(_.map(valueOption, 'destValue')).toString();
        }
      });
      qualifiedList.push(option);
    });
    return qualifiedList;
  }

  saveDynamicRuleData(data: any, update: boolean, status?: string): DynamicPriceRule {
    return {
      ...new DynamicPriceRule(),
      externalRuleId: data.dynamicCode,
      ruleDescription: data.dynamicRuleName,
      effectiveDate: data.effectiveDate ? this.formatDate.formatDatePicker(data.effectiveDate) : null,
      expiryDate: data.expiryDate ? this.formatDate.formatDatePicker(data.expiryDate) : null,
      statusCd: status ? status : data.status,
      ruleRate: data.pctOff,
      minimumWeight: data.minWt,
      maximumWeight: data.maxWt,
      outboundPrepaidInd: data.applicationType.outboundPrepaidInd,
      outboundColInd: data.applicationType.outboundColInd,
      inboundPrepaidInd: data.applicationType.inboundPrepaidInd,
      thirdPartyInd: data.applicationType.thirdPartyInd,
      interStateInd: data.trafficType.interStateInd,
      intraStateInd: data.trafficType.intraStateInd,
      internationalInd: data.trafficType.internationalInd,
      maximumCommodityClassNbr: data.maxClass,
      minimumCommodityClassNbr: data.minClass,
      qualifyBy: this.getQualifiedData(data),
      customerLastShipmentInDays: data.customerShipmentDays,
      laneLastShipmentInDays: data.laneShipmentDays,
      rateTypeCd: data.rateType,
      ruleTypeCd: data.ruleTypeCd,
      ratingTariff: data.ratingTariff ? data.ratingTariff.toUpperCase() : data.ratingTariff,
      agreementAcctId: data.agreementAcctId ? data.agreementAcctId : null,
      agreementAcctCd: data.agreementAcctCd ? data.agreementAcctCd : '',
      currentOwnerEmployeeId: update ? data.currentOwnerEmployeeId : this.user.employeeId,
      designationType: data.designationType,
      laneId: data.laneId,
      cdiRuleInd: data.cdiRuleInd,
      thresholdPeriod: data.thresholdPeriod ? data.thresholdPeriod : '',
      thresholdAmount: data.thresholdAmount ? data.thresholdAmount : 0,
      minimumShipmentsNbr: data.minimumShipmentsNbr ? data.minimumShipmentsNbr : 0,
      applicableTypeCd: data.applicableType ? data.applicableType : '',
      priceRuleDiscounts: data.priceRuleDiscounts,
      autoAdjustAllowedInd: data.autoAdjustAllowedInd,
      applicationType: data.autoAdjustAllowedInd ? data.autoAdjustGroup : '',
      customerGroupId: data.customerGroupId ? data.customerGroupId : 0,
      amcOverrides: data.amcOverrides ? data.amcOverrides : null,
      acOverrides: data.acOverrides ? data.acOverrides : null,
      marsInd: data.marsInd ? data.marsInd : false,
      accessorialOverrideCd: data.accessorialOverrideCd,
      amcOverrideCd: data.amcOverrideCd,
      trialPeriodTypeCd: data.trialPeriodTypeCd ? data.trialPeriodTypeCd : this.constantsService.NONE,
    };
  }

  createDynamicRule(data) {
    return this.dynamicPricingService.createDynamicRuleHdr(this.saveDynamicRuleData(data, false));
  }

  updateDynamicRule(data, ruleDetails) {
    return this.dynamicPricingService.updateDynamicRuleHdrBasedOnId(this.saveDynamicRuleData(data, true), ruleDetails);
  }

  updateDynamicRuleStatus(data, status, ruleDetails) {
    return this.dynamicPricingService.updateDynamicRuleHdrBasedOnId(
      this.saveDynamicRuleData(data, true, status),
      ruleDetails
    );
  }

  createNewVersion(data, ruleDetails) {
    return this.dynamicPricingService.createNewVersion(this.saveDynamicRuleData(data, false), ruleDetails);
  }

  getOrigDestFormArray() {
    return new FormGroup({
      originList: new FormArray([], [Validators.required]),
      destList: new FormArray([], [Validators.required]),
    });
  }

  getOrigDestOptions(type) {
    return {
      input: `${type}Input`,
      inEx: `${type}InEx`,
      optionList: `${type}OptionList`,
      selectedList: `${type}SelectedList`,
      value: `${type}Value`,
      qualifyBy: `${type}QualifyBy`,
      lowZip: `${type}LowZip`,
      highZip: `${type}HighZip`,
    };
  }

  getDropDownOptions() {
    const pricingCodes = new PricingCodesObj();
    this.pricingWorkbenchService.getPricingCodes().subscribe((response: PricingCode[]) => {
      pricingCodes.rateTypes = _.filter(response, { subCategory: this.constantsService.RATE_TYPE });
      pricingCodes.ruleTypes = _.filter(response, { subCategory: this.constantsService.RULE_TYPE });
      pricingCodes.qualifyByOptions = _.filter(response, { subCategory: this.constantsService.QUALIFIER_TYPE });
      pricingCodes.ratingTariff = _.filter(response, { subCategory: this.constantsService.RATING_TARIFF }); // TODO: Not used.
      pricingCodes.applicableType = _.filter(response, { subCategory: this.constantsService.APPLICABLE_TYPE });
      pricingCodes.trialPeriod = _.filter(response, { subCategory: this.constantsService.TRIAL_PERIOD });
      pricingCodes.salesRfp = _.filter(response, {
        category: this.constantsService.SALES_RFP,
        subCategory: this.constantsService.DP_RULE,
      });
      pricingCodes.autoAdjustGroups = _.filter(response, {
        code: this.constantsService.APPLICATION_TYPE,
        subCategory: this.constantsService.AUTO_ADJUST,
      }).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    });

    return pricingCodes;
  }

  getAutoCompletedZipCode(lowZip, highZip) {
    const zipRange = {
      lowZip: '',
      highZip: '',
    };
    const ZIPCODE_RANGE_START = '0',
      ZIPCODE_RANGE_END = '9',
      ZIPCODE_CA_RANGE_START_ENDING = '0A0',
      ZIPCODE_CA_RANGE_END_ENDING = '9Z9';
    if (isNumeric(lowZip.toString().charAt(0))) {
      zipRange.lowZip = lowZip.concat(ZIPCODE_RANGE_START.repeat(5 - lowZip.length));
    } else {
      zipRange.lowZip =
        lowZip.length === 6 ? lowZip.toUpperCase() : lowZip.concat(ZIPCODE_CA_RANGE_START_ENDING).toUpperCase();
    }
    if (isNumeric(highZip.toString().charAt(0))) {
      zipRange.highZip = highZip.concat(ZIPCODE_RANGE_END.repeat(5 - highZip.length));
    } else {
      zipRange.highZip =
        highZip.length === 6 ? highZip.toUpperCase() : highZip.concat(ZIPCODE_CA_RANGE_END_ENDING).toUpperCase();
    }
    return zipRange;
  }

  uploadDynamicPricingRulesFile(file: File, type: DynamicRulesFileExportOperationEnum): Observable<any> {
    const formData: FormData = new FormData();
    let endpointUrl = '';
    switch (type) {
      case DynamicRulesFileExportOperationEnum.DP_RULES_CREATE:
        endpointUrl = '/pricing-rules/files';
        formData.append('rulesFile', file, file.name);
        break;
      case DynamicRulesFileExportOperationEnum.DP_RULE_DISCOUNTS_UPDATE:
        endpointUrl = '/files';
        formData.append('file', file, file.name);
        formData.append('operationCd', DynamicRulesFileExportOperationEnum.DP_RULE_DISCOUNTS_UPDATE);
        break;
    }
    const headers = new HttpHeaders().set(this.constantsService.InterceptorSkipHeader, '');
    const apiUrl: string = this.config.getSetting(ConfigManagerProperties.apiUrl);
    const dynamicpricingApiEndpoint: string = this.config.getSetting(ConfigManagerProperties.dynamicpricingApiEndpoint);
    const url = apiUrl + dynamicpricingApiEndpoint + endpointUrl;
    return this.http.post(url, formData, { headers });
  }
}

export class PricingCodesObj {
  rateTypes: Array<PricingCode>;
  qualifyByOptions: Array<PricingCode>;
  ratingTariff: Array<PricingCode>;
  applicableType: Array<PricingCode>;
  autoAdjustGroups: Array<PricingCode>;
  trialPeriod: Array<PricingCode>;
  ruleTypes: Array<PricingCode>;
  salesRfp: Array<PricingCode>;
}
