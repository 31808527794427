<h1 class="xpo-file-upload" mat-dialog-title>Dynamic Rule File Upload</h1>

<div mat-dialog-content>
  <div class="xpo-file-upload">
    <div class="xpo-file-upload-content">
      <div *ngIf="!recordsToAdd || recordsToAdd.length == 0">
        <input hidden #uploader type="file" id="file" (change)="handleFileInput($event.target.files)" />
        <button mat-flat-button (click)="uploader.click()" *ngIf="!errorMessages && !fileToUpload">Select File</button>
        <button mat-flat-button (click)="processUploadFile()" *ngIf="fileToUpload">
          Process {{ fileToUpload.name }}
        </button>
      </div>
      <div *ngIf="isProcessing">
        <span>Please wait. Processing...</span>
      </div>
      <div *ngIf="errorMessages">
        <div *ngFor="let errorMessage of errorMessages">
          <span>{{ errorMessage }}</span>
        </div>
        <br />
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <div
    *ngIf="!isProcessing && errorMessages && recordsToAdd && recordsToAdd.length > 0"
    class="xpo-file-upload-actionButton"
  >
    <button mat-flat-button (click)="processPendingUpload()">Process With Errors</button>
  </div>
  <div>
    <button mat-flat-button (click)="dialogRef.close()">Cancel</button>
  </div>
</div>
