export enum SearchRuleApiName {
  effectiveDate = 'effectiveDate',
  dynamicCode = 'externalRuleId',
  customerCode = 'ruleDescription',
  statusCd = 'statusCd',
  ruleId = 'priceRuleId',
  exactEffectiveDate = 'exactEffectiveDate',
  expiryDate = 'expiryDate',
  designationType = 'designationType',
  laneId = 'laneId',
}
