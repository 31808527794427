<xpo-shell>
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <ng-container *ngFor="let route of userRoutes$ | async; let i = index">
      <button class="xpo-shell-menu" *ngIf="!route.children" [routerLink]="route.path" routerLinkActive="xpo-selected">
        {{ route.label }}
      </button>

      <ng-container *ngIf="route.children">
        <button class="xpo-shell-menu" [matMenuTriggerFor]="linkItemMenu">
          <span>{{ route.label }} <mat-icon>arrow_drop_down</mat-icon></span>
        </button>
        <mat-menu #linkItemMenu="matMenu">
          <button
            class="xpo-shell-submenu"
            *ngFor="let child of route.children"
            [routerLink]="child.path"
            routerLinkActive="xpo-selected"
            mat-menu-item
          >
            {{ child.label }}
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </xpo-header-navigation>

  <xpo-header-actions>
    <!-- <xpo-account-popover *ngIf="accountPopoverConfig" [config]="accountPopoverConfig"></xpo-account-popover> -->
    <!-- <xpo-app-switcher-popover [applications]="apps$ | async"></xpo-app-switcher-popover> -->
  </xpo-header-actions>
  <xpo-shell-sidebar-actions class="app-Container-sidebar-actions">
    <div *ngIf="feedbackService.feedbackConfig$ | async">
      <a (click)="handleFeedbackClick()">Feedback</a>
    </div>
    <div>
      <a (click)="handleReleaseNotesClick()">Release Notes</a>
    </div>
  </xpo-shell-sidebar-actions>
  <router-outlet></router-outlet>
</xpo-shell>

<app-notification></app-notification>
