export enum AcOverrideEnum {
  type = 'typeCd',
  typeFieldName = 'Type',
  chargeAmount = 'chargeAmount',
  chargeAmountFieldName = 'Charge Amount',
  effectiveDate = 'effectiveDate',
  effectiveDateFieldName = 'Effective Date',
  expiryDate = 'expiryDate',
  expiryDateFieldName = 'Expiry Date',
  chargeTypeCd = 'chargeTypeCd',
  chargeTypeCdFieldName = 'Charge Type',
  minAmount = 'minAmount',
  minAmountFieldName = 'Min Amount',
  maxAmount = 'maxAmount',
  maxAmountFieldName = 'Max Amount',
  unitOfMeasureCd = 'unitOfMeasureCd',
  unitOfMeasureCdFieldName = 'Unit Of Measure',
}
