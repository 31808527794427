export enum ShipmentType {
  shipper = 'Shpr',
  consignee = 'Cons',
  billTo = 'BillToOtb',
}

export enum AccesorialsType {
  commoditySurcharge = 'CCS',
  asWeigth = 'AsWgt',
  fuelSurcharge = 'FSC',
  discountLine = 'DiscLn',
  discountXss = 'XSS',
  netLinehaul = 'NL',
  commodityCharge = 'CCH',
  accesorials = 'AAA',
  netTotalCharge = 'NTC',
  totalCharge = 'TC',
}

export enum AccesorialDescType {
  linehaul = 'Net Linehaul',
  commodityCharge = 'Commodity Charge',
  accesorials = 'Accesorials',
  netTotalCharge = 'Net Total Charges',
  totalCharge = 'Total Charges',
  asWeigth = 'As Weight',
  discountLine = 'XPO discount',
  FSC = 'Fuel Surcharge',
}
