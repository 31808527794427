import { NgModule } from '@angular/core';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { ErrorPipe } from './pipes/error/error.pipe';
import { HumanizePipe } from './pipes/humanize/humanize.pipe';
import { YesNoPipe } from './pipes/yes-no/yes-no.pipe';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    ErrorPipe,
    HumanizePipe,
    YesNoPipe,
    // Pipes/Directives
  ],
  imports: [
    // Pipes/Directives
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    HumanizePipe,
    // Pipes/Directives
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
