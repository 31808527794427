<h1 mat-dialog-title>
  File Type Selection
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>

<div mat-dialog-content class="">
  <form [formGroup]="formGroup">
    <div class="options">
      <div class="field-label">File Type</div>
      <mat-radio-group formControlName="fileFormat">
        <mat-radio-button [value]="RuleListFileFormat.DP_RULES_CREATE">
          Rule Create/version Change
        </mat-radio-button>
        <mat-radio-button [value]="RuleListFileFormat.DP_RULE_DISCOUNTS_UPDATE">
          Rule Discounts Change
        </mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="formGroup.controls.fileFormat.hasError('required')">
        Please indicate the type of file to download
      </mat-error>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-flat-button (click)="submit()" [disabled]="formGroup.invalid">Download</button>
</div>
