export enum CustomerExcludeFieldNames {
  priceRuleCustomerExcludeId = 'priceRuleCustomerExcludeId',
  customerNbr = 'customerNbr',
  madCode = 'madCode',
  customerName = 'customerName',
  effectiveDate = 'effectiveDate',
  expiryDate = 'expiryDate',
  restrictionTypeCd = 'restrictionTypeCd',
  programType = 'programType',
  locationType = 'locationTypeCd',
}
