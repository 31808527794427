import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { DynamicPriceRule } from '@xpo-ltl/sdk-dynamicpricing';
import { XrtSearchRequest } from '@xpo/ngx-xrt';
import _ from 'lodash';
import { Observable } from 'rxjs/index';
import { map, takeUntil } from 'rxjs/internal/operators';
import { ConstantsService } from '../constants/constants.service';
import { DynamicRuleXrtService } from '../dr-xrt/dynamic-rule-xrt.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicRuleDataSourceClassic extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber = new Unsubscriber();

  get currentState(): XpoBoardState {
    return this.stateCache;
  }

  setState(state: XpoBoardState): void {
    super.setState(state);
  }

  constructor(private xrtService: DynamicRuleXrtService, private constants: ConstantsService) {
    super();
    this.pageSize = this.constants.DATA_SIZE;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const request = <XrtSearchRequest>{
      filter: state.criteria,
      sortExpressions: state.sortOrder,
      pageNumber: 1,
      pageSize: this.pageSize,
    };

    return this.xrtService.search(DynamicPriceRule, request).pipe(
      takeUntil(this.unsubscriber.done),
      map((resp) => {
        return new XpoBoardData(state, resp.items, resp.resultCount, resp.resultCount);
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
