import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  XpoLtlAppSwitcherService,
  XpoLtlFeedbackService,
  XpoLtlLoggedInUserService,
  XpoLtlReleaseNotesService,
} from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoFeedback } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { catchError, delay, filter, retryWhen, skipWhile, take, tap } from 'rxjs/operators';
import { UserPermission } from './shared/enums';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppName } from './shared/enums/app-name.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { UserPermissionService } from './shared/services/user-permission/user-permission.service';
import { UserService } from './shared/services/user/user.service';

interface XpoShellRouteSecure extends XpoShellRoute {
  permission?: UserPermission;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title: string;
  build: string;
  // apps$: Observable<XpoAppSwitcherApplication[]>;
  accountPopoverConfig: XpoAccountPopoverConfig;
  userRoutes = new BehaviorSubject<XpoShellRoute[]>([]);
  userRoutes$ = this.userRoutes.asObservable();
  routes: Array<any> = [
    {
      label: 'Dashboard',
      path: AppRoutes.DYNAMIC_PRICING,
      permission: UserPermission.Dashboard,
    },
    {
      label: 'Dynamic Rules',
      path: `${AppRoutes.DYNAMIC_RULE}/${AppRoutes.LIST_PAGE}`,
      permission: UserPermission.DynamicRuleList,
    },
    {
      label: 'Customers',
      path: AppRoutes.CUSTOMER_EXCLUDE_LIST,
      permission: UserPermission.CustomerList,
    },
    {
      label: 'Customers Groups',
      path: AppRoutes.CUSTOMER_GROUP,
      permission: UserPermission.CustomerGroups,
    },
    {
      label: 'Dynamic Shipments',
      path: AppRoutes.DYNAMIC_SHIPMENTS,
      permission: UserPermission.DynamicShipmentList,
    },
    {
      label: 'Spot Quotes',
      path: null,
      children: [
        {
          label: 'Spot Quotes',
          path: `${AppRoutes.SPOT_QUOTE}/${AppRoutes.LIST_PAGE}`,
          permission: UserPermission.SpotQuoteList,
        },
        {
          label: 'Spot Quote Parameters',
          path: `${AppRoutes.SPOT_QUOTE}/${AppRoutes.SPOT_QUOTE_PARAMETERS}`,
          permission: UserPermission.SpotQuoteParameters,
        },
      ],
    },
    {
      label: 'Test Harness',
      path: null,
      children: [
        {
          label: 'Shipment Visibility',
          path: AppRoutes.SHIPMENT_VISIBILITY,
          permission: UserPermission.ShipmentVisibility,
        },
        {
          label: 'Test Harness',
          path: AppRoutes.RATE_QUOTE,
          permission: UserPermission.TestHarness,
        },
      ],
    },
    {
      label: 'Admin',
      path: null,
      children: [
        {
          label: 'Accessorial Charges',
          path: `${AppRoutes.ACCESSORIAL_CHARGE}/${AppRoutes.LIST_PAGE}`,
          permission: UserPermission.AccessorialCharges,
        },
        {
          label: 'Absolute Minimum Charges',
          path: `${AppRoutes.ABSOLUTE_MINIMUM_CHARGE}/${AppRoutes.LIST_PAGE}`,
          permission: UserPermission.AbsoluteMinimumCharges,
        },
        {
          label: 'DPT Discount',
          path: `${AppRoutes.DPT_DISCOUNT}`,
          permission: UserPermission.DynamicPricingForSales,
        },
        {
          label: 'Admin',
          path: AppRoutes.DYNAMIC_PRICING_ADMIN_TAB,
          permission: UserPermission.Admin,
        },
        {
          label: 'Pricing Locations',
          path: `${AppRoutes.PRICING_LOCATION}/${AppRoutes.LIST_PAGE}`,
          permission: UserPermission.PricingLocationList,
        },
        {
          label: 'Create DPT Rule',
          path: AppRoutes.CREATE_OPP_RULE,
          permission: UserPermission.CreateDynamicRule,
        },
        {
          label: 'Pricing Codes',
          path: AppRoutes.PRICING_CODES,
          permission: UserPermission.PricingCodes,
        },
      ],
    },
    {
      label: 'Pricing Details',
      path: `${AppRoutes.PRICING_DETAILS}/${AppRoutes.LIST_PAGE}`,
      permission: UserPermission.DynamicPricingForSales,
    },
    {
      label: 'RFP',
      path: `${AppRoutes.RFP}/${AppRoutes.LIST_PAGE}`,
      permission: UserPermission.DynamicPricingForSales,
    },
    /*
    {
      label: 'List Customer Exclude',
      path: AppRoutes.CUSTOMER_EXCLUDE_LIST,
    },
      label: 'Lane Maintenance',
      path: AppRoutes.LANE_MAINTENANCE,
    },
    {
      label: 'Rate Quote',
      path: AppRoutes.RATE_QUOTE,
    },
    */
  ];

  constructor(
    private configManagerService: ConfigManagerService,
    public feedbackService: XpoLtlFeedbackService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private loginService: XpoLtlLoggedInUserService,
    private dialog: MatDialog,
    // private appSwitcherService: XpoLtlAppSwitcherService,
    private userPermissionService: UserPermissionService,
    private userService: UserService,
    private authService: XpoLtlAuthenticationService,
    private router: Router
  ) {
    /** Shell setup */
    this.title = AppName.dynamic_pricing;
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      console.log(info);
      // Extra config or API calls here if needed
    });
    this.handleLoggedInUser();
    this.loginService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });
    this.userPermissionService.userPermissions$
      .pipe(filter((permissions) => permissions !== undefined))
      .subscribe((permissions) => {
        const hasPermissions = permissions && permissions.length > 0 ? true : false;
        if (hasPermissions) {
          const permissionsBasedRoutes = this.getUserRoutes(this.routes, permissions);
          this.userRoutes.next(permissionsBasedRoutes);
          if (this.router.url === AppRoutes.ROOT) {
            if (permissions.includes(UserPermission.CreateDynamicRule)) {
              this.router.navigate([`${AppRoutes.DYNAMIC_RULE}/${AppRoutes.LIST_PAGE}`]);
            } else if (permissions.includes(UserPermission.DynamicRuleList)) {
              this.router.navigate([`${AppRoutes.DYNAMIC_RULE}/${AppRoutes.LIST_PAGE}`]);
            }
          }
        } else {
          this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
        }
      });
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  private getUserRoutes(routes: XpoShellRouteSecure[], permissions: Array<string>): XpoShellRoute[] {
    const result = routes
      .filter((route: XpoShellRouteSecure) => {
        if (!route.children) {
          return !route.permission || permissions.includes(route.permission) ? true : false;
        } else {
          const child = route.children.filter(
            (children: any) => !children.permission || permissions.includes(children.permission)
          );
          return !!child.length;
        }
      })
      .map((route: XpoShellRouteSecure) => {
        return {
          label: route.label,
          path: route.path,
          children: route.children
            ? route.children.filter(
                (children: any) => !children.permission || permissions.includes(children.permission)
              )
            : null,
        };
      });
    return result;
  }

  handleFeedbackClick(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private handleLoggedInUser(): void {
    const loggedInUserRoot: string = this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot);
    this.loginService
      .getLoggedInUser(loggedInUserRoot)
      .pipe(
        retryWhen((errors) => errors.pipe(delay(1000), take(5))),
        catchError((error) => {
          console.error(error);
          return of(undefined);
        })
      )
      .subscribe(
        (user: User) => {
          this.userService.userSource.next(user);
          this.setDynatraceUserIdentity(user);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loginService.clear();
  }

  private populateAppSwitcher(): void {
    //    this.apps$ = this.appSwitcherService.getAppList();
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'PND_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
}
