import { Injectable } from '@angular/core';
import { XpoFilterCriteria } from '@xpo-ltl/ngx-ltl-board';
import { ListInfo, RuleOperatorCd, XrtAttributeFilter, XrtSortExpression } from '@xpo-ltl/sdk-common';
import {
  DynamicPriceRuleSearchFilter,
  ListDynamicPricingRuleHeadersRqst,
  ListDynamicPricingShipmentsQuery,
  SearchDynamicPriceRuleUnfilteredRqst,
} from '@xpo-ltl/sdk-dynamicpricing';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RuleListFieldNames } from '../../shared/enums/field-names/rule-list-field-names-enum';
import { ConstantsService } from '../../shared/services/constants/constants.service';
import { DynamicRuleRequestTransformerService } from '../../shared/services/dr-request-transformer/dynamic-rule-request-transformer.service';
import { DynamicPricingService } from '../../shared/services/dynamic-pricing/dynamic-pricing.service';

enum XpoSortDirectionEnum {
  ascending = 'asc',
  descendant = 'desc',
}

export interface DynamicRuleRequestEnum {
  criteria: XpoFilterCriteria;
  pageNumber: number;
  sortOrder: XrtSortExpression[];
  dynamicPricingDetail: boolean;
  filterOnGrid: any;
}
@Injectable({
  providedIn: 'root',
})
export class DynamicRuleGridService {
  private pageSize: number = 0;
  constructor(
    private dynamicPricingService: DynamicPricingService,
    private constants: ConstantsService,
    private transformerService: DynamicRuleRequestTransformerService
  ) {
    this.pageSize = this.constants.PAGE_SIZE_ELASTIC;
  }

  getDynamicList(dynamicRuleRequest: DynamicRuleRequestEnum) {
    const request = <SearchDynamicPriceRuleUnfilteredRqst>{
      filter: { ...this.transformerService.transform(dynamicRuleRequest.criteria), ...dynamicRuleRequest.filterOnGrid },
      pageNumber: dynamicRuleRequest.pageNumber,
      pageSize: this.pageSize,
      sortExpressions: dynamicRuleRequest.sortOrder.map((value) => {
        const result = new XrtSortExpression();
        result.column = this.transformerService.getElasticColumnNameForSorting(value.column);
        result.direction = value.direction;
        result.isDescendingSort = value.direction === XpoSortDirectionEnum.descendant;
        return result;
      }),
    };

    return dynamicRuleRequest.dynamicPricingDetail
      ? this.getDataPricingDetails(dynamicRuleRequest)
      : this.dynamicPricingService.getDynamicRulesFromElastic(request);
  }

   getDataPricingDetails(dynamicRuleRequest: DynamicRuleRequestEnum): any {
    const request = new ListDynamicPricingRuleHeadersRqst();
    const acctMadCd = dynamicRuleRequest.dynamicPricingDetail &&
      dynamicRuleRequest.criteria && dynamicRuleRequest.criteria.ruleDescription
        ? dynamicRuleRequest.criteria.ruleDescription.conditions[0].value
        : dynamicRuleRequest.criteria.acctMadCd.conditions[0].value;
    const effectiveDate =
      dynamicRuleRequest.criteria && dynamicRuleRequest.criteria.effectiveDate
        ? moment(dynamicRuleRequest.criteria.effectiveDate.conditions[0].value).format('YYYY-MM-DD')
        : null;
    const expiryDate =
      dynamicRuleRequest.criteria && dynamicRuleRequest.criteria.expiryDate
        ? moment(dynamicRuleRequest.criteria.expiryDate.conditions[0].value).format('YYYY-MM-DD')
        : null;

    if (!acctMadCd) {
      return of([]);
    }

    request.dynamicPriceRuleFilter = [
      {
        fieldName: 'acctMadCd',
        fieldValue: acctMadCd,
        operatorCd: RuleOperatorCd.EQUAL_TO,
      },
    ];

    /*if (acctMadCd && !effectiveDate && !expiryDate) {
      effectiveDate = moment(new Date()).format('YYYY-MM-DD');
      expiryDate = moment(new Date()).format('YYYY-MM-DD');
    }*/

    if (effectiveDate) {
      request.dynamicPriceRuleFilter.push({
        fieldName: RuleListFieldNames.effectiveDate,
        fieldValue: effectiveDate,
        operatorCd: RuleOperatorCd.LESS_THAN_OR_EQUAL,
      });
    }

    if (expiryDate) {
      request.dynamicPriceRuleFilter.push({
        fieldName: RuleListFieldNames.expiryDate,
        fieldValue: expiryDate,
        operatorCd: RuleOperatorCd.GREATER_THAN_OR_EQUAL,
      });
    }

    request.listInfo = new ListInfo();
    request.listInfo.levelOfDetail = this.constants.SEARCH_BY_CUSTOMER;
    request.listInfo.startAt = dynamicRuleRequest.pageNumber * this.pageSize;
    request.listInfo.numberOfRows = this.pageSize;
    console.log(request);
    return this.dynamicPricingService.requestDynamicPricingRulesList(request, null, {}, true);
  }

  getDataPricingDetailsByPro(proNbr: string): Observable<any> {
    const queryParams = new ListDynamicPricingShipmentsQuery();
    const request = new SearchDynamicPriceRuleUnfilteredRqst();
    request.filter = new DynamicPriceRuleSearchFilter();
    request.filter.priceRuleId = new XrtAttributeFilter();
    request.sortExpressions = [
      {
        column: 'effectiveDate',
        direction: XpoSortDirectionEnum.ascending,
        isDescendingSort: true,
      },
    ];
    queryParams.proNbr = proNbr;
    return this.dynamicPricingService.listDynamicPricingShipments(queryParams).pipe(
      mergeMap((result) => {
        const ids = [];
        result.dynamicPricingShipments.forEach((shipment) => {
          ids.push(shipment.priceRule.priceRuleHeaderId);
        });
        request.filter.priceRuleId.values = ids;
        return ids.length ? this.dynamicPricingService.getDynamicRulesFromElastic(request) : of(null);
      })
    );
  }
}
