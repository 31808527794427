import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  /** App constants go here */
  BACK_HAUL = 'BackHaul';
  HEAD_HAUL = 'HeadHaul';
  NEUTRAL = 'Neutral';

  SIC = 'SIC';
  STATE = 'State';
  COUNTRY = 'Country';
  LOCATION = 'Location';
  ZIPCODE = 'ZipCode';
  DISTRICT = 'District';
  REGION = 'Region';

  INTER_STATE = 'Inter State';
  INTRA_STATE = 'Intra State';
  INTERNATIONAL = 'International';

  OB_PPD = 'OB/PPD';
  OB_COL = 'OB/COL';
  IB_COL = 'IB/COL';
  THIRD_PARTY = 'Third Party';

  IN = 'In';
  EX = 'Ex';

  ORIG = 'orig';
  DEST = 'dest';

  CHECK_BOX_OPTION = 'checkbox';
  INPUT_OPTION = 'input';
  ZIP_OPTION = 'zip-range';

  DATA_SIZE_ELASTIC = 100000;
  PAGE_SIZE_ELASTIC = 100;

  GENERIC_DATA_SIZE = 1000000;
  DATA_SIZE = 10000;
  RULES_DATA_SIZE = 1000000;

  // Pricing Codes Category & subCategory
  PRICING = 'Pricing';
  SALES_RFP = 'SalesRfp';
  PRICING_ANALYSIS = 'PricingAnalysis';
  DYN_PRICING = 'DynPricing';
  DYN_PRICING_BATCH = 'DynPricingBatch';
  QUALIFIER_TYPE = 'QualifierType';
  RESTRICTION_TYPE = 'RestrictionType';
  APPLICATION_TYPE = 'ApplicationType';
  APPLICABLE_TYPE = 'ApplicableType';
  TRIAL_PERIOD = 'trialPeriodType';
  RATE_TYPE = 'RateType';
  RULE_TYPE = 'RuleType';
  STATUS_CD = 'StatusCd';
  BULK_UPDATE_SIZE = 'BulkUpdateSize';
  RATING_TARIFF = 'RatingTariff';
  LOCATION_TYPE = 'LocationType';
  PROGRAM_TYPE = 'ProgramType';
  ELASTICSEARCH_ON = 'ElasticsearchOn';
  ELASTICSEARCH_ON_RULE_LIST = 'RuleList';
  DYNAMIC_PRICING = 'DynamicPricing';
  SECURITY = 'Security';
  AC_CHARGE_TYPE = 'AcChargeType';
  JOHN_DEERE_LOCATION = 'John Deere Location';
  JOHN_DEERE_LANE_PAIR = 'John Deere Lane Pair';
  AUTO_ADJUST = 'AutoAdjust';
  DOWNLOAD_HELP_TEXT = 'DownLoadHelpText';
  AC_UOM = 'AcUom';
  OPP = 'OPP';
  DP_RULE = 'DpRule';
  EXPORT_RULES = 'ExportRules';
  UPLOAD_RULES = 'UploadRules';
  CUSTOMER_GROUPS = 'CustomerGroups';
  DPT = 'DPT';
  SALES_REGION = 'Sales Region';
  RFP_REASON_CODES = 'RfpReasonCodes';

  // EXPORT & UPLOAD PAGESIZE CODE

  PAGE_SIZE = 'PageSize';

  // STATUS
  PENDING = 'Pending';
  ACTIVE = 'Active';
  IN_ACTIVE = 'Inactive';

  // Date Format
  DATE_FORMAT = 'MM/dd/yyyy';
  DATE_FORMAT_MOMENT = 'MM/DD/YYYY';
  DATE_FORMAT_TIME = 'MM/DD/YYYY HH:mm z';
  dateServiceFormat = 'YYYY-MM-DD';
  PST_TIMEZONE = 'America/Los_Angeles';
  STAMP_FORMAT = 'YYYY-M-D-hhmmss';
  // view ids && levelOfDetail
  ALL = 'all';
  SEARCH = 'search';
  DOWNLOAD = 'download';
  DOWNLOAD_GRID = 'Download_Grid';

  // EDIT QUERYPARAMS

  TRUE = 'true';
  FALSE = 'false';

  // Customer & Specials
  CUSTOMER = 'C';
  SPECIALS = 'S';
  MADCODE = 'M';

  // ZIP CODE PATTERNS
  US_CANADA_ZIP = '^\\d{1,5}$|^[A-Za-z]\\d[A-Za-z]\\d[A-Za-z]\\d$|^[A-Za-z]\\d[A-Za-z]$';
  US_ZIP = '^\\d{1,5}$';
  CANADA_ZIP = '^[A-Za-z]\\d[A-Za-z]\\d[A-Za-z]\\d$|^[A-Za-z]\\d[A-Za-z]$';

  // DECIMAL PATTERNS
  RULE_RATE_PATTERN = '^[0]+\\.\\d{0,4}$';
  RULE_RATE_PATTERN_OPTIONAL_DECIMALS = '^[0](\\.\\d{0,4})?$';
  ONLY_NUMBERS_PATTERN = '^[0-9]*$';

  // HEADER
  InterceptorSkipHeader = 'XPO-Skip-Interceptor';

  // DATA SOURCE EVENTS
  BOARD_ACTIVATING_VIEW = 'BOARD-ACTIVATING-VIEW';
  FILTER_CHANGE = 'FILTER-CHANGE';
  AG_GRID_SORT = 'AG-GRID-SORT';
  AG_GRID_PAGE = 'AG-GRID-PAGE';

  // DECIMAL STRING FORMATS
  DECIMAL_STRING_FORMAT_DEFAULT = '1.2-2';
  DECIMAL_STRING_FORMAT_WITHOUT_DECIMALS = '1.0-0';

  // ROLE DESCRIPTIONS
  ROLE_ACCOUNT_EXECUTIVE = '3';
  ROLE_NATIONAL_ACCOUNT_EXECUTIVE = '4';

  DEFAULT_EXPIRATION_DATE_STRING = '2099-12-31';

  // SELF ADJUSTMENT RULES
  SELF_ADJUSTMENT_RULES_DEFAULT_GROUP = 'AUTODEFAULT';

  // APPLICABLE TYPES
  EARNED_DISCOUNT = 'EarnedDiscount';

  // ACCT TYPE
  CORPORATE = 'C';
  SEARCH_BY_CUSTOMER = 'SEARCH_BY_CUSTOMER';

  // RULE_TYPE
  DISCOFTOTAL = 'DISCOFTOTAL';
  DISCOFLINEHAUL = 'DISCOFLINEHAUL';
  DISCOFCONTRACT = 'DISCOFCONTRACT';
  OVERRIDECONTRACTDISC = 'OVERRIDECONTRACTDISC';
  CDIDISC = 'CDIDISC';

  // RATE_TYPE
  LINEHAUL_AC_FSC = 'LINEHAUL_AC_FSC';
  LINEHAUL = 'LINEHAUL';
  LINEHAUL_FSC = 'LINEHAUL_FSC';

  NONE = 'NONE';
}
