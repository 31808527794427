export enum AbsoluteMinimumChargeFieldLabels {
  absoluteMinimumChargeNbr = 'AMC Number',
  versionNbr = 'Version Nbr',
  beginDate = 'Begin Date',
  endDate = 'End Date',
  effectiveDate = 'Effective Date',
  expiryDate = 'Expiry Date',
  typeCd = 'Type',
  minMiles = 'Min Miles',
  maxMiles = 'Max Miles',
  chargeAmount = 'Charge Amount',
  minChargeAmount = 'Min Charge Amount',
  maxChargeAmount = 'Max Charge Amount',
}
