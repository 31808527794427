import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-rule',
  templateUrl: './dynamic-rule.component.html',
  styleUrls: ['./dynamic-rule.component.scss'],
})
export class DynamicRuleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
