import { Injectable } from '@angular/core';
import {
  ConditionalFilterCombiner,
  Operators,
  XpoFilterColumnCriteria,
  XpoFilterCriteria,
} from '@xpo-ltl/ngx-ltl-board';
import { XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import {
  DynamicPriceRuleSearchFilter,
  DynamicPriceRuleSearchFilterAnd,
  DynamicPriceRuleSearchFilterNot,
  DynamicPriceRuleSearchFilterOr,
} from '@xpo-ltl/sdk-dynamicpricing';
import {
  chain as _chain,
  defaultTo as _defaultTo,
  filter as _filter,
  find as _find,
  first as _first,
  forEach as _forEach,
  get as _get,
  hasIn as _hasIn,
  isArray as _isArray,
  isEmpty as _isEmpty,
  isNaN as _isNaN,
  isNull as _isNull,
  isString as _isString,
  isUndefined as _isUndefined,
  map as _map,
  reduce as _reduce,
  size as _size,
  toString as _toString,
} from 'lodash';
import _ from 'lodash';
import moment from 'moment-timezone';
import { RuleListFieldNames } from '../../enums/field-names/rule-list-field-names-enum';
import { YesNoEnum } from '../../enums/util.enum';
import { SearchRule } from '../../models/search-rule';
import { ConstantsService } from '../constants/constants.service';
import { FormatDateService } from '../format-date/format-date.service';

const isNotOperatorCriteriaFilterType = (operatorType: Operators): boolean => {
  const negativeOperatorTypes: Operators[] = [
    Operators.NotBetween,
    Operators.NotContains,
    Operators.NotEmpty,
    Operators.NotEquals,
  ];

  return negativeOperatorTypes.includes(operatorType);
};

@Injectable({
  providedIn: 'root',
})
export class DynamicRuleRequestTransformerService {
  constructor(private constants: ConstantsService, private formatDateService: FormatDateService) {}

  getElasticColumnName(columnName: string) {
    const transformerMap = {
      priceRuleId: 'priceRuleId',
      logicalRuleId: 'logicalRuleId',
      ruleVersionNbr: 'ruleVersionNbr',
      externalRuleId: 'externalRuleId',
      ruleDescription: 'ruleDescription',
      statusCd: 'statusCd',
      designationType: 'designationType',
      laneId: 'laneId',
      ruleRate: 'ruleRate',
      rateTypeCd: 'rateTypeCd',
      applicableTypeCd: 'applicableTypeCd',
      effectiveDate: 'effectiveDate',
      expiryDate: 'expiryDate',
      'dynShipmentCount.rateQuoteDateCount': 'dynShipmentCount_rateQuoteDateCount',
      'dynShipmentCount.rateQuoteDateAmount': 'dynShipmentCount_rateQuoteDateAmount',
      'dynShipmentCount.shipmentCount': 'dynShipmentCount_shipmentCount',
      'dynShipmentCount.shipmentAmount': 'dynShipmentCount_shipmentAmount',
      'priceRuleDiscounts.externalRuleCdHeadHaul': 'priceRuleDiscounts_externalRuleCdHeadHaul',
      'priceRuleDiscounts.externalRuleCdBackHaul': 'priceRuleDiscounts_externalRuleCdBackHaul',
      'priceRuleDiscounts.externalRuleCdNeutral': 'priceRuleDiscounts_externalRuleCdNeutral',
      marsInd: 'marsInd',
      accessorialOverrideCd: 'accessorialOverrideCd',
      amcOverrideCd: 'amcOverrideCd',
      fakInd: 'fakInd',
      trialPeriodTypeCd: 'trialPeriodTypeCd',
      proNumber: 'proNumber',
      customerGroupId: 'customerGroupId',
      acctMadCd: 'acctMadCd'
    };

    const result = transformerMap[columnName];

    if (!result) {
      throw new Error(`${columnName} does not exist into elastic column names map`);
    }

    return result;
  }

  getElasticColumnNameForSorting(columnName: string) {
    const transformerMap = [
      'externalRuleId',
      'ruleDescription',
      'statusCd',
      'designationType',
      'laneId',
      'rateTypeCd',
      'accessorialOverrideCd',
      'amcOverrideCd',
      'applicableTypeCd',
      'trialPeriodTypeCd',
    ];
    return transformerMap.includes(columnName) ? `${columnName}.keyword` : columnName;
  }

  transform(inputFilterCriteria: XpoFilterCriteria): DynamicPriceRuleSearchFilter {
    const filterCriteria = _.clone(inputFilterCriteria);
    const result = new DynamicPriceRuleSearchFilter();
    const andFilters: DynamicPriceRuleSearchFilterAnd[] = [];
    const orFilters: DynamicPriceRuleSearchFilterOr[] = [];

    Object.keys(filterCriteria).forEach((filterName) => {
      const criteria = filterCriteria[filterName];
      const apiFieldName = this.getElasticColumnName(filterName);

      // based on operator/combiner/condition length primary filter attributes set to and/or/not filter types
      let primaryFilter: DynamicPriceRuleSearchFilterAnd | DynamicPriceRuleSearchFilterOr;
      let primaryFiltersArray: any[]; // set to andFilters | orFilters | notFilters based on operator/combiner type
      let primaryAttributeFilter: XrtAttributeFilter;

      if (criteria.combiner && criteria.combiner === ConditionalFilterCombiner.Or) {
        primaryFilter = new DynamicPriceRuleSearchFilterOr();
        primaryFiltersArray = orFilters;
      } else {
        primaryFilter = new DynamicPriceRuleSearchFilterAnd();
        primaryFiltersArray = andFilters;
      }

      const conditions = _.clone(criteria.conditions);
      const conditionsToAddNotEquals = conditions.filter((condition: any) =>
        [Operators.Before, Operators.Less, Operators.After, Operators.Greater].includes(condition.operator)
      );

      conditionsToAddNotEquals.forEach((condition: any) => {
        conditions.push({
          operator: Operators.NotEquals,
          value: condition.value,
        });
      });

      conditions.forEach((condition: any) => {
        const { operator, value, valueTo } = condition;

        // stacked case statements differ in attributeFilter type (ie. Not filter vs. And/Or filter)
        switch (operator) {
          case Operators.Empty:
            primaryAttributeFilter = this.toFilterIsEmpty();
            break;

          case Operators.NotEmpty:
            primaryAttributeFilter = this.toFilterIsEmpty();
            break;

          case Operators.Equals:
          case Operators.NotEquals:
            if (value instanceof Date) {
              primaryAttributeFilter = this.toFilterEqualsDate(value);
            } else {
              primaryAttributeFilter = _isArray(value) ? this.toFilterValues(value) : this.toFilterEquals(value);
            }
            break;

          case Operators.Contains:
          case Operators.NotContains:
            primaryAttributeFilter = this.toFilterContains(value);
            break;

          case Operators.Before:
          case Operators.Less:
            primaryAttributeFilter = this.toFilterMax(value);
            break;

          case Operators.After:
          case Operators.Greater:
            primaryAttributeFilter = this.toFilterMin(value);
            break;

          case Operators.OnOrBefore:
          case Operators.LessEqual:
            primaryAttributeFilter = this.toFilterMax(value);
            break;

          case Operators.OnOrAfter:
          case Operators.GreaterEqual:
            primaryAttributeFilter = this.toFilterMin(value);
            break;

          case Operators.True:
            primaryAttributeFilter = this.toFilterEquals(true);
            break;

          case Operators.NotTrue:
            primaryAttributeFilter = this.toFilterEquals(false);
            break;

          case Operators.StartsWith:
            primaryAttributeFilter = this.toFilterStartsWith(value);
            break;

          case Operators.EndsWith:
            primaryAttributeFilter = this.toFilterEndsWith(value);
            break;

          case Operators.OneOf:
            primaryAttributeFilter = this.toFilterValues(value);
            break;

          case Operators.Between:
            primaryAttributeFilter = this.toFilterEqualsRange(value, valueTo);
            break;

          case Operators.NotBetween:
            primaryAttributeFilter = this.toFilterEqualsRange(value, valueTo);
            break;

          case Operators.Relative:
            primaryAttributeFilter = this.toFilterRelative(value);
            break;
        }

        if (isNotOperatorCriteriaFilterType(operator)) {
          primaryFiltersArray.push({
            ...primaryFilter,
            not: [
              {
                ...new DynamicPriceRuleSearchFilterNot(),
                and: [
                  {
                    ...new DynamicPriceRuleSearchFilter(),
                    [apiFieldName]: primaryAttributeFilter,
                  },
                ],
              },
            ],
          });
        } else {
          primaryFiltersArray.push({
            ...primaryFilter,
            [apiFieldName]: primaryAttributeFilter,
          });
        }
      });
    });

    return {
      ...result,
      and: andFilters,
      or: orFilters,
    };
  }

  transformSearchRuleToXpoFilterCriteria(filterCriteria: SearchRule): XpoFilterCriteria {
    let result: XpoFilterCriteria;
    // TODO: create enum for field names
    const transformerMap = {
      effectiveDate: {
        filterName: 'effectiveDate',
        custom: true,
      },
      exactEffectiveDate: {
        filterName: 'effectiveDate',
        operator: Operators.Equals,
        type: 'date',
      },
      expiryDate: {
        filterName: 'expiryDate',
        operator: Operators.Equals,
        type: 'date',
      },
      ruleId: {
        filterName: 'priceRuleId',
        operator: Operators.Equals,
        type: 'string',
      },
      dynamicCode: {
        custom: true,
      },
      acctMadCd: {
        filterName: 'acctMadCd',
        operator: Operators.Equals,
        type: 'string',
      },
      customerCode: {
        filterName: 'ruleDescription',
        operator: Operators.Contains,
        type: 'string',
      },
      statusCd: {
        filterName: 'statusCd',
        operator: Operators.Equals,
        type: 'array',
      },
      designationType: {
        filterName: 'designationType',
        operator: Operators.Contains,
        type: 'string',
      },
      laneId: {
        filterName: 'laneId',
        operator: Operators.Contains,
        type: 'string',
      },
      logicalRuleId: {
        filterName: 'logicalRuleId',
        operator: Operators.Equals,
        type: 'string',
      },
      marsInd: {
        filterName: 'marsInd',
        operator: Operators.Equals,
        type: 'boolean',
      },
      overrideCd: {
        filterName: 'amcOverrideCd',
        operator: Operators.Equals,
        type: 'string',
      },
      amcOverrideCd: {
        filterName: 'amcOverrideCd',
        operator: Operators.Equals,
        type: 'string',
      },
      accessorialOverrideCd: {
        filterName: 'accessorialOverrideCd',
        operator: Operators.Equals,
        type: 'string',
      },
      fakInd: {
        filterName: 'fakInd',
        operator: Operators.Equals,
        type: 'boolean',
      },
      trialPeriodTypeCd: {
        filterName: 'trialPeriodTypeCd',
        operator: Operators.Equals,
        type: 'string',
      },
      proNumber: {
        filterName: 'proNumber',
        operator: Operators.Equals,
        type: 'string',
      },
    };

    // custom filter
    if (filterCriteria.effectiveDate) {
      const filterValue = moment(filterCriteria.effectiveDate, 'MM/DD/YYYY').format(this.constants.dateServiceFormat);
      const filterLabel = moment(filterCriteria.effectiveDate, 'MM/DD/YYYY').format(this.constants.DATE_FORMAT_MOMENT);
      result = {
        ...result,
        [transformerMap.effectiveDate.filterName]: <XpoFilterColumnCriteria>{
          conditions: [
            {
              operator: Operators.OnOrBefore,
              value: filterValue,
              display: filterLabel,
            },
          ],
        },
        [transformerMap.expiryDate.filterName]: <XpoFilterColumnCriteria>{
          conditions: [
            {
              operator: Operators.OnOrAfter,
              value: filterValue,
              display: filterLabel,
            },
          ],
        },
      };
    }

    if (filterCriteria.dynamicCode) {
      result = {
        ...result,
        ['priceRuleDiscounts.externalRuleCdHeadHaul']: {
          conditions: [
            {
              operator: Operators.Equals,
              value: filterCriteria.dynamicCode,
            },
          ],
          combiner: ConditionalFilterCombiner.Or,
        },
        ['priceRuleDiscounts.externalRuleCdBackHaul']: {
          conditions: [
            {
              operator: Operators.Equals,
              value: filterCriteria.dynamicCode,
            },
          ],
          combiner: ConditionalFilterCombiner.Or,
        },
        ['priceRuleDiscounts.externalRuleCdNeutral']: {
          conditions: [
            {
              operator: Operators.Equals,
              value: filterCriteria.dynamicCode,
            },
          ],
          combiner: ConditionalFilterCombiner.Or,
        },
      };
    }

    if (filterCriteria.overrideCd) {
      switch (filterCriteria.overrideCd) {
        case '0':
          filterCriteria[RuleListFieldNames.amcOverrideCd] = YesNoEnum.shortYes;
          break;
        case '1':
          filterCriteria[RuleListFieldNames.accessorialOverrideCd] = YesNoEnum.shortYes;
          break;
        case '2':
          filterCriteria[RuleListFieldNames.amcOverrideCd] = YesNoEnum.shortYes;
          filterCriteria[RuleListFieldNames.accessorialOverrideCd] = YesNoEnum.shortYes;
          break;
        default:
          filterCriteria[RuleListFieldNames.amcOverrideCd] = null;
          filterCriteria[RuleListFieldNames.accessorialOverrideCd] = null;
          break;
      }

      delete filterCriteria.overrideCd;
    }

    Object.keys(filterCriteria).forEach((fieldName: string) => {
      const transformer = transformerMap[fieldName];
      const filterValue = filterCriteria[fieldName];

      if (!transformer.custom && filterValue) {
        let value;

        switch (transformer.type) {
          case 'date':
            value = this.formatDateService.transformDateWithFormat(filterValue, this.constants.dateServiceFormat);
            break;

          case 'array':
            value = [filterValue];
            break;

          case 'string':
            value = filterValue.toString();
            break;

          case 'boolean':
            value = filterValue;
            break;
        }

        const filter: XpoFilterColumnCriteria = {
          conditions: [
            {
              operator: transformer.operator,
              value,
            },
          ],
        };

        result = {
          ...result,
          [transformer.filterName]: filter,
        };
      }
    });

    return result;
  }

  toFilterValues(values: any): XrtAttributeFilter {
    return !_isEmpty(values) ? { ...new XrtAttributeFilter(), values } : undefined;
  }

  toFilterEquals(equals: any): XrtAttributeFilter {
    return _isEmpty(equals) && _isNaN(equals) ? undefined : { ...new XrtAttributeFilter(), equals };
  }

  toFilterIsEmpty(): XrtAttributeFilter {
    return { ...new XrtAttributeFilter(), exists: false } as any;
  }

  toFilterIsNotEmpty(): XrtAttributeFilter {
    return { ...new XrtAttributeFilter(), contains: '*' } as any;
  }

  toFilterStartsWith(startsWith: any): XrtAttributeFilter {
    return !_isEmpty(startsWith) ? { ...new XrtAttributeFilter(), startsWith } : undefined;
  }

  toFilterEndsWith(endsWith: any): XrtAttributeFilter {
    const lowerCaseValue = endsWith.toLowerCase();
    return !_isEmpty(lowerCaseValue) ? { ...new XrtAttributeFilter(), contains: `*${lowerCaseValue}` } : undefined;
  }

  toFilterMin(min: any): XrtAttributeFilter {
    if (min instanceof Date) {
      return !_isUndefined(min) && moment(min).isValid()
        ? { ...new XrtAttributeFilter(), min: moment(min).format(this.constants.dateServiceFormat) }
        : undefined;
    } else {
      return !_isEmpty(min) ? { ...new XrtAttributeFilter(), min } : undefined;
    }
  }

  toFilterMax(max: any): XrtAttributeFilter {
    return !_isEmpty(max) ? { ...new XrtAttributeFilter(), max: max } : undefined;
  }

  toFilterContains(contains: any): XrtAttributeFilter {
    const lowerCaseValue = contains.toLowerCase();
    return !_isEmpty(contains) ? { ...new XrtAttributeFilter(), ...{ contains: lowerCaseValue } } : undefined;
  }

  toFilterQuery(query: any): XrtAttributeFilter {
    return !_isEmpty(query) ? { ...new XrtAttributeFilter(), query } : undefined;
  }

  toFilterEqualsRange(min: any, max: any): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };

    if (min === max) {
      filter.equals = min;
    } else {
      if (min) {
        filter.min = min;
      }
      if (max) {
        filter.max = max;
      }
    }

    return !_isUndefined(filter.min) || !_isUndefined(filter.max) || !_isUndefined(filter.equals) ? filter : undefined;
  }

  toFilterEqualsDate(date: Date): XrtAttributeFilter {
    return !_isUndefined(date) && moment(date).isValid()
      ? { ...new XrtAttributeFilter(), equals: moment(date).format(this.constants.dateServiceFormat) }
      : undefined;
  }

  toFilterEqualsDateRange(min: Date, max: Date): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };

    const minDate =
      !_isUndefined(min) && moment(min).isValid() ? moment(min).format(this.constants.dateServiceFormat) : undefined;
    const maxDate =
      !_isUndefined(max) && moment(max).isValid() ? moment(max).format(this.constants.dateServiceFormat) : undefined;

    if (minDate === maxDate) {
      filter.equals = minDate;
    } else {
      if (minDate) {
        filter.min = minDate;
      }
      if (maxDate) {
        filter.max = maxDate;
      }
    }

    return !_isUndefined(filter.min) || !_isUndefined(filter.max) || !_isUndefined(filter.equals) ? filter : undefined;
  }

  toFilterEqualsTimeRange(min: string, max: string): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };
    const formatTimeString = (timeString: string) => {
      const timeSeparatorCount = _filter(timeString, (char: string) => char === ':').length;
      return timeString && timeSeparatorCount === 1 ? `${timeString}:00` : timeString;
    };

    min = !!min ? formatTimeString(min) : min;
    max = !!max ? formatTimeString(max) : max;

    if (min === max) {
      filter.equals = min;
    } else {
      if (min) {
        filter.min = min;
      }
      if (max) {
        filter.max = max;
      }
    }

    return !_isUndefined(filter.min) || !_isUndefined(filter.max) || !_isUndefined(filter.equals) ? filter : undefined;
  }

  toFilterEqualsTime(time: string): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };

    time = !!time ? `${time}:00` : time;
    filter.equals = time;

    return !_isUndefined(time) && moment(time, 'HH:mm:ss').isValid()
      ? { ...new XrtAttributeFilter(), equals: time }
      : undefined;
  }

  toFilterEqualsDateTimeRange(min: Date, max: Date): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };

    const minDate = !_isUndefined(min) && moment(min).isValid() ? moment(min).toISOString() : undefined;
    const maxDate = !_isUndefined(max) && moment(max).isValid() ? moment(max).toISOString() : undefined;

    if (minDate === maxDate) {
      filter.equals = minDate;
    } else {
      if (minDate) {
        filter.min = minDate;
      }
      if (maxDate) {
        filter.max = maxDate;
      }
    }

    return !_isUndefined(filter.min) || !_isUndefined(filter.max) || !_isUndefined(filter.equals) ? filter : undefined;
  }

  toFilterRelative(value: string): XrtAttributeFilter {
    const [DAYS, TODAY, YESTERDAY, TOMORROW, THIS_WEEK, LAST_WEEK, NEXT_WEEK] = [
      'days',
      '0d',
      '-1d',
      '+1d',
      '0w',
      '-1w',
      '+1w',
    ];

    const currentDate = moment().toDate();
    let filter: XrtAttributeFilter, start: Date, end: Date;

    switch (value) {
      case TODAY:
        filter = this.toFilterEqualsDate(currentDate);
        break;

      case TOMORROW:
        const tomorrow = moment(currentDate)
          .add(1, 'days')
          .toDate();
        filter = this.toFilterEqualsDate(tomorrow);
        break;

      case YESTERDAY:
        const yesterday = moment(currentDate)
          .subtract(1, 'days')
          .toDate();
        filter = this.toFilterEqualsDate(yesterday);
        break;

      case THIS_WEEK:
        start = moment(currentDate)
          .startOf('week')
          .toDate();
        end = moment(currentDate)
          .endOf('week')
          .toDate();
        filter = this.toFilterEqualsDateRange(start, end);
        break;

      case LAST_WEEK:
        start = moment(currentDate)
          .subtract(1, 'weeks')
          .startOf('week')
          .toDate();
        end = moment(currentDate)
          .subtract(1, 'weeks')
          .endOf('week')
          .toDate();
        filter = this.toFilterEqualsDateRange(start, end);
        break;

      case NEXT_WEEK:
        start = moment(currentDate)
          .add(1, 'weeks')
          .startOf('week')
          .toDate();
        end = moment(currentDate)
          .add(1, 'weeks')
          .endOf('week')
          .toDate();
        filter = this.toFilterEqualsDateRange(start, end);
        break;
    }

    return filter;
  }
}
