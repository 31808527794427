import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';

export const PricingWorkbenchCodes: PricingCode[] = [
  {
    pricingCodeId: 88,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Labour Hour',
    name: 'Labour Hour',
    value: 'NULL',
    displayOrder: '1',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 89,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: '1/4 Hour',
    name: '1/4 Hour',
    value: 'NULL',
    displayOrder: '2',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 90,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: '1/2 Hour',
    name: '1/2 Hour',
    value: 'NULL',
    displayOrder: '3',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 91,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'CWT',
    name: 'CWT',
    value: 'NULL',
    displayOrder: '4',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 92,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'CWT/Day',
    name: 'CWT/Day',
    value: 'NULL',
    displayOrder: '5',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 93,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Check',
    name: 'Check',
    value: 'NULL',
    displayOrder: '6',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 94,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Correction',
    name: 'Correction',
    value: 'NULL',
    displayOrder: '7',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 95,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Day',
    name: 'Day',
    value: 'NULL',
    displayOrder: '8',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 96,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Document',
    name: 'Document',
    value: 'NULL',
    displayOrder: '9',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 97,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Package',
    name: 'Package',
    value: 'NULL',
    displayOrder: '10',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 98,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Shipment',
    name: 'Shipment',
    value: 'NULL',
    displayOrder: '11',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 99,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Vehicle',
    name: 'Vehicle',
    value: 'NULL',
    displayOrder: '12',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 100,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Mile',
    name: 'Mile',
    value: 'NULL',
    displayOrder: '13',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 101,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Pound',
    name: 'Pound',
    value: 'NULL',
    displayOrder: '14',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 102,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Not applicable',
    name: 'Not applicable',
    value: 'NULL',
    displayOrder: '15',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 103,
    category: 'Pricing',
    subCategory: 'AcChargeType',
    code: 'FlatRate',
    name: 'Flat Rate per UOM',
    value: 'NULL',
    displayOrder: '1',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 104,
    category: 'Pricing',
    subCategory: 'AcChargeType',
    code: 'PctCharge',
    name: '% of Specified Charge type',
    value: 'NULL',
    displayOrder: '2',
    effDate: '2016-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7382,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'AB',
    name: 'AB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7383,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'AK',
    name: 'AK',
    displayOrder: '2',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7384,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'AL',
    name: 'AL',
    displayOrder: '3',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7385,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'AR',
    name: 'AR',
    displayOrder: '4',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7386,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'AZ',
    name: 'AZ',
    displayOrder: '5',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7387,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'BC',
    name: 'BC',
    displayOrder: '6',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7388,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'CA',
    name: 'CA',
    displayOrder: '7',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7389,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'CO',
    name: 'CO',
    displayOrder: '8',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7390,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'CT',
    name: 'CT',
    displayOrder: '9',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7391,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'DC',
    name: 'DC',
    displayOrder: '10',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7392,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'DE',
    name: 'DE',
    displayOrder: '11',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7393,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'FL',
    name: 'FL',
    displayOrder: '12',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7394,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'GA',
    name: 'GA',
    displayOrder: '13',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7395,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'HI',
    name: 'HI',
    displayOrder: '14',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7396,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'IA',
    name: 'IA',
    displayOrder: '15',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7397,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'ID',
    name: 'ID',
    displayOrder: '16',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7398,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'IL',
    name: 'IL',
    displayOrder: '17',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7399,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'IN',
    name: 'IN',
    displayOrder: '18',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7400,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'KS',
    name: 'KS',
    displayOrder: '19',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7401,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'KY',
    name: 'KY',
    displayOrder: '20',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7402,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'LA',
    name: 'LA',
    displayOrder: '21',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7403,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MA',
    name: 'MA',
    displayOrder: '22',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7404,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MB',
    name: 'MB',
    displayOrder: '23',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7405,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MD',
    name: 'MD',
    displayOrder: '24',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7406,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'ME',
    name: 'ME',
    displayOrder: '25',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7407,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MI',
    name: 'MI',
    displayOrder: '26',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7408,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MN',
    name: 'MN',
    displayOrder: '27',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7409,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MO',
    name: 'MO',
    displayOrder: '28',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7410,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MS',
    name: 'MS',
    displayOrder: '29',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7411,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'MT',
    name: 'MT',
    displayOrder: '30',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7412,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NB',
    name: 'NB',
    displayOrder: '31',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7413,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NC',
    name: 'NC',
    displayOrder: '32',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7414,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'ND',
    name: 'ND',
    displayOrder: '33',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7415,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NE',
    name: 'NE',
    displayOrder: '34',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7416,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NH',
    name: 'NH',
    displayOrder: '35',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7417,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NJ',
    name: 'NJ',
    displayOrder: '36',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7418,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NL',
    name: 'NL',
    displayOrder: '37',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7419,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NM',
    name: 'NM',
    displayOrder: '38',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7420,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NS',
    name: 'NS',
    displayOrder: '39',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7421,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NV',
    name: 'NV',
    displayOrder: '40',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7422,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'NY',
    name: 'NY',
    displayOrder: '41',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7423,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'OH',
    name: 'OH',
    displayOrder: '42',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7424,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'OK',
    name: 'OK',
    displayOrder: '43',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7425,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'ON',
    name: 'ON',
    displayOrder: '44',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7426,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'OR',
    name: 'OR',
    displayOrder: '45',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7427,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'PA',
    name: 'PA',
    displayOrder: '46',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7428,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'PE',
    name: 'PE',
    displayOrder: '47',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7429,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'PR',
    name: 'PR',
    displayOrder: '48',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7430,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'QC',
    name: 'QC',
    displayOrder: '49',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7431,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'RI',
    name: 'RI',
    displayOrder: '50',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7432,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'SC',
    name: 'SC',
    displayOrder: '51',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7433,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'SD',
    name: 'SD',
    displayOrder: '52',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7434,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'SK',
    name: 'SK',
    displayOrder: '53',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7435,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'TN',
    name: 'TN',
    displayOrder: '54',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7436,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'TX',
    name: 'TX',
    displayOrder: '55',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7437,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'UT',
    name: 'UT',
    displayOrder: '56',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7438,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'VA',
    name: 'VA',
    displayOrder: '57',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7439,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'VT',
    name: 'VT',
    displayOrder: '58',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7440,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'WA',
    name: 'WA',
    displayOrder: '59',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7441,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'WI',
    name: 'WI',
    displayOrder: '60',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7442,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'WV',
    name: 'WV',
    displayOrder: '61',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7443,
    category: 'DynPricing',
    subCategory: 'State',
    code: 'WY',
    name: 'WY',
    displayOrder: '62',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7445,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LAB',
    name: 'LAB',
    displayOrder: '2',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7444,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LAA',
    name: 'LAA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7446,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LAO',
    name: 'LAO',
    displayOrder: '3',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7447,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LBL',
    name: 'LBL',
    displayOrder: '4',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7448,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LBY',
    name: 'LBY',
    displayOrder: '5',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7449,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LCX',
    name: 'LCX',
    displayOrder: '6',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7450,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LDA',
    name: 'LDA',
    displayOrder: '7',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7451,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LDE',
    name: 'LDE',
    displayOrder: '8',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7452,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LEP',
    name: 'LEP',
    displayOrder: '9',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7453,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LFS',
    name: 'LFS',
    displayOrder: '10',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7454,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LFW',
    name: 'LFW',
    displayOrder: '11',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7455,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LFY',
    name: 'LFY',
    displayOrder: '12',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7456,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LGP',
    name: 'LGP',
    displayOrder: '13',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7457,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LGT',
    name: 'LGT',
    displayOrder: '14',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7458,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LGV',
    name: 'LGV',
    displayOrder: '15',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7459,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LHG',
    name: 'LHG',
    displayOrder: '16',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7460,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LHO',
    name: 'LHO',
    displayOrder: '17',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7461,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LHW',
    name: 'LHW',
    displayOrder: '18',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7462,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LJB',
    name: 'LJB',
    displayOrder: '19',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7463,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LJS',
    name: 'LJS',
    displayOrder: '20',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7464,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LLA',
    name: 'LLA',
    displayOrder: '21',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7465,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LLB',
    name: 'LLB',
    displayOrder: '22',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7466,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LLL',
    name: 'LLL',
    displayOrder: '23',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7891,
    category: 'DynPricing',
    subCategory: 'RatingTariff',
    code: 'XPOT90',
    name: 'XPOT90',
    value: 'XPOT90',
    displayOrder: '3',
    effDate: '2020-01-17',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7892,
    category: 'DynPricing',
    subCategory: 'RatingTariff',
    code: 'XPOT91',
    name: 'XPOT91',
    value: 'XPOT91',
    displayOrder: '4',
    effDate: '2020-01-17',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7992,
    category: 'DynPricing',
    subCategory: 'ProgramType',
    code: 'XPOT88',
    name: 'XPOT88',
    value: 'XPOT88',
    displayOrder: '1',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7993,
    category: 'DynPricing',
    subCategory: 'ProgramType',
    code: 'XPOT90',
    name: 'XPOT90',
    value: 'XPOT90',
    displayOrder: '2',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7994,
    category: 'DynPricing',
    subCategory: 'ProgramType',
    code: 'XPOT91',
    name: 'XPOT91',
    value: 'XPOT91',
    displayOrder: '3',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7995,
    category: 'DynPricing',
    subCategory: 'ProgramType',
    code: 'XPOT87',
    name: 'XPOT87',
    value: 'XPOT87',
    displayOrder: '1',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7996,
    category: 'DynPricing',
    subCategory: 'LocationType',
    code: 'Corporate',
    name: 'Corporate',
    value: 'Corporate',
    displayOrder: '1',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7997,
    category: 'DynPricing',
    subCategory: 'LocationType',
    code: 'PND',
    name: 'PND',
    value: 'PND',
    displayOrder: '2',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7998,
    category: 'DynPricing',
    subCategory: 'LocationType',
    code: 'Bill-To',
    name: 'Bill-To',
    value: 'Bill-To',
    displayOrder: '3',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8204,
    category: 'DynPricing',
    subCategory: 'BulkUpdateSize',
    code: '200',
    name: '200',
    value: '200',
    displayOrder: '1',
    effDate: '2019-10-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7467,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LLR',
    name: 'LLR',
    displayOrder: '24',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7468,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LMB',
    name: 'LMB',
    displayOrder: '25',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7469,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LMD',
    name: 'LMD',
    displayOrder: '26',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7470,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LMO',
    name: 'LMO',
    displayOrder: '27',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7471,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LNI',
    name: 'LNI',
    displayOrder: '28',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7472,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LNO',
    name: 'LNO',
    displayOrder: '29',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7473,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LOO',
    name: 'LOO',
    displayOrder: '30',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7474,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LSD',
    name: 'LSD',
    displayOrder: '31',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7475,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LSL',
    name: 'LSL',
    displayOrder: '32',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7476,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LSX',
    name: 'LSX',
    displayOrder: '33',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7477,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LTE',
    name: 'LTE',
    displayOrder: '34',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7478,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LTU',
    name: 'LTU',
    displayOrder: '35',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7479,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LTX',
    name: 'LTX',
    displayOrder: '36',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7480,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LTY',
    name: 'LTY',
    displayOrder: '37',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7481,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LVC',
    name: 'LVC',
    displayOrder: '38',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7482,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LWA',
    name: 'LWA',
    displayOrder: '39',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7483,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'LWF',
    name: 'LWF',
    displayOrder: '40',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7484,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NAA',
    name: 'NAA',
    displayOrder: '41',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7485,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NAG',
    name: 'NAG',
    displayOrder: '42',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7486,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NAT',
    name: 'NAT',
    displayOrder: '43',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7487,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NAV',
    name: 'NAV',
    displayOrder: '44',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7488,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NBM',
    name: 'NBM',
    displayOrder: '45',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7489,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NCH',
    name: 'NCH',
    displayOrder: '46',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7490,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NCK',
    name: 'NCK',
    displayOrder: '47',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7491,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NCO',
    name: 'NCO',
    displayOrder: '48',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7492,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NCS',
    name: 'NCS',
    displayOrder: '49',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7493,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NCT',
    name: 'NCT',
    displayOrder: '50',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7494,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NDC',
    name: 'NDC',
    displayOrder: '51',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7495,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NDE',
    name: 'NDE',
    displayOrder: '52',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7496,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NDO',
    name: 'NDO',
    displayOrder: '53',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7497,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NFM',
    name: 'NFM',
    displayOrder: '54',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7498,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NGS',
    name: 'NGS',
    displayOrder: '55',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7499,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NGV',
    name: 'NGV',
    displayOrder: '56',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7500,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NHB',
    name: 'NHB',
    displayOrder: '57',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7501,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NHN',
    name: 'NHN',
    displayOrder: '58',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7502,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NIL',
    name: 'NIL',
    displayOrder: '59',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7503,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NJV',
    name: 'NJV',
    displayOrder: '60',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7504,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NKX',
    name: 'NKX',
    displayOrder: '61',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7505,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NLB',
    name: 'NLB',
    displayOrder: '62',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7506,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NMA',
    name: 'NMA',
    displayOrder: '63',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7507,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NMF',
    name: 'NMF',
    displayOrder: '64',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7508,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NML',
    name: 'NML',
    displayOrder: '65',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7509,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NMO',
    name: 'NMO',
    displayOrder: '66',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7510,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NMP',
    name: 'NMP',
    displayOrder: '67',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7511,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NNA',
    name: 'NNA',
    displayOrder: '68',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7512,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NNC',
    name: 'NNC',
    displayOrder: '69',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7513,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NNF',
    name: 'NNF',
    displayOrder: '70',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7514,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NNW',
    name: 'NNW',
    displayOrder: '71',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7515,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NOF',
    name: 'NOF',
    displayOrder: '72',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7516,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NPB',
    name: 'NPB',
    displayOrder: '73',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7517,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NPR',
    name: 'NPR',
    displayOrder: '74',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7518,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NRD',
    name: 'NRD',
    displayOrder: '75',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7519,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NRG',
    name: 'NRG',
    displayOrder: '76',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7520,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NRM',
    name: 'NRM',
    displayOrder: '77',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7521,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NRO',
    name: 'NRO',
    displayOrder: '78',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7522,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NSA',
    name: 'NSA',
    displayOrder: '79',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7523,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NTH',
    name: 'NTH',
    displayOrder: '80',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7524,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NTP',
    name: 'NTP',
    displayOrder: '81',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7525,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NTR',
    name: 'NTR',
    displayOrder: '82',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7526,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NWF',
    name: 'NWF',
    displayOrder: '83',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7527,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NWN',
    name: 'NWN',
    displayOrder: '84',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7528,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NWR',
    name: 'NWR',
    displayOrder: '85',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7529,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NWY',
    name: 'NWY',
    displayOrder: '86',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7530,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UAK',
    name: 'UAK',
    displayOrder: '87',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7531,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UAQ',
    name: 'UAQ',
    displayOrder: '88',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7532,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UBD',
    name: 'UBD',
    displayOrder: '89',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7533,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UBF',
    name: 'UBF',
    displayOrder: '90',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7534,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UBG',
    name: 'UBG',
    displayOrder: '91',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7535,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UBI',
    name: 'UBI',
    displayOrder: '92',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7536,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UBU',
    name: 'UBU',
    displayOrder: '93',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7537,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UBY',
    name: 'UBY',
    displayOrder: '94',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7538,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCC',
    name: 'UCC',
    displayOrder: '95',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7539,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCF',
    name: 'UCF',
    displayOrder: '96',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7540,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCO',
    name: 'UCO',
    displayOrder: '97',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7541,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCP',
    name: 'UCP',
    displayOrder: '98',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7542,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCQ',
    name: 'UCQ',
    displayOrder: '99',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7543,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCS',
    name: 'UCS',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7544,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCU',
    name: 'UCU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7545,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UCX',
    name: 'UCX',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7546,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UDD',
    name: 'UDD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7547,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UDV',
    name: 'UDV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7548,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UEK',
    name: 'UEK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7549,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UEN',
    name: 'UEN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7550,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UEO',
    name: 'UEO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7551,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UFA',
    name: 'UFA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7552,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UFG',
    name: 'UFG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7553,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UFN',
    name: 'UFN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7554,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UFV',
    name: 'UFV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7555,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UGF',
    name: 'UGF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7556,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UGJ',
    name: 'UGJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7557,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UGN',
    name: 'UGN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7558,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UGV',
    name: 'UGV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7559,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UIB',
    name: 'UIB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7560,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UIV',
    name: 'UIV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7561,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UKA',
    name: 'UKA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7562,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'ULA',
    name: 'ULA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7563,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'ULB',
    name: 'ULB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7564,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'ULI',
    name: 'ULI',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7565,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'ULV',
    name: 'ULV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7566,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'ULX',
    name: 'ULX',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7567,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UMM',
    name: 'UMM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7568,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UMO',
    name: 'UMO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7569,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UMV',
    name: 'UMV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7570,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UNP',
    name: 'UNP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7571,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UOA',
    name: 'UOA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7572,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UOR',
    name: 'UOR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7573,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UPH',
    name: 'UPH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7574,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UPI',
    name: 'UPI',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7575,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UPO',
    name: 'UPO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7576,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UPT',
    name: 'UPT',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7577,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UPW',
    name: 'UPW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7578,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'URD',
    name: 'URD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7579,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'URE',
    name: 'URE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7580,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'URW',
    name: 'URW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7581,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USA',
    name: 'USA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7582,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USB',
    name: 'USB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7583,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USD',
    name: 'USD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7584,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USE',
    name: 'USE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7585,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USJ',
    name: 'USJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7586,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USK',
    name: 'USK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7587,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USL',
    name: 'USL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7588,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USM',
    name: 'USM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7589,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USR',
    name: 'USR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7590,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UST',
    name: 'UST',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7591,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USU',
    name: 'USU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7592,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'USW',
    name: 'USW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7593,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UTA',
    name: 'UTA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7594,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UTF',
    name: 'UTF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7595,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UTU',
    name: 'UTU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7596,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UTZ',
    name: 'UTZ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7597,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UVA',
    name: 'UVA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7598,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UVC',
    name: 'UVC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7599,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UVN',
    name: 'UVN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7600,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UWC',
    name: 'UWC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7601,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UWE',
    name: 'UWE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7602,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'UWY',
    name: 'UWY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7603,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAB',
    name: 'XAB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7604,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAC',
    name: 'XAC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7605,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAE',
    name: 'XAE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7606,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAG',
    name: 'XAG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7607,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAH',
    name: 'XAH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7608,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAK',
    name: 'XAK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7609,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAN',
    name: 'XAN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7610,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAQ',
    name: 'XAQ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7611,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAU',
    name: 'XAU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7612,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XAY',
    name: 'XAY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7613,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBC',
    name: 'XBC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7614,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBD',
    name: 'XBD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7615,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBE',
    name: 'XBE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7616,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBH',
    name: 'XBH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7617,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBJ',
    name: 'XBJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7618,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBN',
    name: 'XBN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7619,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBO',
    name: 'XBO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7620,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBP',
    name: 'XBP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7621,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBR',
    name: 'XBR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7622,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBV',
    name: 'XBV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7623,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBW',
    name: 'XBW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7624,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBX',
    name: 'XBX',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7625,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XBY',
    name: 'XBY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7626,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCA',
    name: 'XCA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7627,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCD',
    name: 'XCD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7628,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCF',
    name: 'XCF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7629,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCJ',
    name: 'XCJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7630,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCK',
    name: 'XCK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7631,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCL',
    name: 'XCL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7632,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCM',
    name: 'XCM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7633,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCN',
    name: 'XCN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7634,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCO',
    name: 'XCO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7635,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCP',
    name: 'XCP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7636,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCQ',
    name: 'XCQ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7637,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCR',
    name: 'XCR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7638,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCU',
    name: 'XCU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7639,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCV',
    name: 'XCV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7640,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCW',
    name: 'XCW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7641,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCY',
    name: 'XCY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7642,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCZ',
    name: 'XCZ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7643,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDE',
    name: 'XDE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7644,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDK',
    name: 'XDK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7645,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDM',
    name: 'XDM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7646,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDP',
    name: 'XDP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7647,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDU',
    name: 'XDU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7648,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDV',
    name: 'XDV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7649,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDY',
    name: 'XDY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7650,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XDZ',
    name: 'XDZ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7651,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEB',
    name: 'XEB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7652,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XED',
    name: 'XED',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7653,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEE',
    name: 'XEE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7654,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEG',
    name: 'XEG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7655,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEJ',
    name: 'XEJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7656,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEK',
    name: 'XEK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7657,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEL',
    name: 'XEL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7658,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEM',
    name: 'XEM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7659,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEN',
    name: 'XEN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7660,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEQ',
    name: 'XEQ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7661,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XER',
    name: 'XER',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7662,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEV',
    name: 'XEV',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7663,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XEW',
    name: 'XEW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7664,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFD',
    name: 'XFD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7665,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFG',
    name: 'XFG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7666,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFK',
    name: 'XFK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7667,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFM',
    name: 'XFM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7668,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFO',
    name: 'XFO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7669,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFR',
    name: 'XFR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7670,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFW',
    name: 'XFW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7671,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XFY',
    name: 'XFY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7672,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XGA',
    name: 'XGA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7673,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XGB',
    name: 'XGB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7674,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XGH',
    name: 'XGH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7675,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XGN',
    name: 'XGN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7676,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XGR',
    name: 'XGR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7677,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XGW',
    name: 'XGW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7678,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XGY',
    name: 'XGY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7679,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XHC',
    name: 'XHC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7680,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XHF',
    name: 'XHF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7681,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XHG',
    name: 'XHG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7682,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XHL',
    name: 'XHL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7683,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XHM',
    name: 'XHM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7684,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XHP',
    name: 'XHP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7685,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XHR',
    name: 'XHR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7686,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XID',
    name: 'XID',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7687,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XIM',
    name: 'XIM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7688,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XIN',
    name: 'XIN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7689,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XIY',
    name: 'XIY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7690,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XJB',
    name: 'XJB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7691,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XJJ',
    name: 'XJJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7692,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XJN',
    name: 'XJN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7693,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XJO',
    name: 'XJO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7694,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XJP',
    name: 'XJP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7695,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XKC',
    name: 'XKC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7696,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XKK',
    name: 'XKK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7697,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XKO',
    name: 'XKO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7698,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XKR',
    name: 'XKR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7699,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XKU',
    name: 'XKU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7700,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLA',
    name: 'XLA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7701,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLB',
    name: 'XLB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7702,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLE',
    name: 'XLE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7703,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLF',
    name: 'XLF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7704,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLL',
    name: 'XLL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7705,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLO',
    name: 'XLO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7706,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLU',
    name: 'XLU',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7707,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XLX',
    name: 'XLX',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7708,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMA',
    name: 'XMA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7709,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMC',
    name: 'XMC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7710,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMD',
    name: 'XMD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7711,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XME',
    name: 'XME',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7712,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMI',
    name: 'XMI',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7713,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMJ',
    name: 'XMJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7714,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XML',
    name: 'XML',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7715,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMM',
    name: 'XMM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7716,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMP',
    name: 'XMP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7717,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XMZ',
    name: 'XMZ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7718,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XNB',
    name: 'XNB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7719,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XNH',
    name: 'XNH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7720,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XNJ',
    name: 'XNJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7721,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XNW',
    name: 'XNW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7722,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XNY',
    name: 'XNY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7723,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XOG',
    name: 'XOG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7724,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XOI',
    name: 'XOI',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7725,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XOM',
    name: 'XOM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7726,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XON',
    name: 'XON',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7727,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XOW',
    name: 'XOW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7728,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPB',
    name: 'XPB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7729,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPF',
    name: 'XPF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7730,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPH',
    name: 'XPH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7731,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPI',
    name: 'XPI',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7732,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPK',
    name: 'XPK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7733,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPL',
    name: 'XPL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7734,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPN',
    name: 'XPN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7735,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPP',
    name: 'XPP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7736,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XPQ',
    name: 'XPQ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7737,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XQB',
    name: 'XQB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7738,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XQC',
    name: 'XQC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7739,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRC',
    name: 'XRC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7740,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRD',
    name: 'XRD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7741,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRF',
    name: 'XRF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7742,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRL',
    name: 'XRL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7743,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRN',
    name: 'XRN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7744,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRO',
    name: 'XRO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7745,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRQ',
    name: 'XRQ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7746,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRY',
    name: 'XRY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7747,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XRZ',
    name: 'XRZ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7748,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSA',
    name: 'XSA',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7749,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSB',
    name: 'XSB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7750,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSC',
    name: 'XSC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7751,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSD',
    name: 'XSD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7752,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSF',
    name: 'XSF',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7753,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSG',
    name: 'XSG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7754,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSJ',
    name: 'XSJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7755,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSL',
    name: 'XSL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7756,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSM',
    name: 'XSM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7757,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSO',
    name: 'XSO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7758,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSP',
    name: 'XSP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7759,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSR',
    name: 'XSR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7760,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSW',
    name: 'XSW',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7761,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSX',
    name: 'XSX',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7762,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XSY',
    name: 'XSY',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7763,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTH',
    name: 'XTH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7764,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTJ',
    name: 'XTJ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7765,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTK',
    name: 'XTK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7766,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTM',
    name: 'XTM',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7767,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTN',
    name: 'XTN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7768,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTO',
    name: 'XTO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7769,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTQ',
    name: 'XTQ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7770,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XTZ',
    name: 'XTZ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7771,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XUK',
    name: 'XUK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7772,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XUR',
    name: 'XUR',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7773,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XVC',
    name: 'XVC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7774,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XVL',
    name: 'XVL',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7775,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XVN',
    name: 'XVN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7776,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWB',
    name: 'XWB',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7777,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWC',
    name: 'XWC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7778,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWE',
    name: 'XWE',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7779,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWG',
    name: 'XWG',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7780,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWK',
    name: 'XWK',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7781,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWN',
    name: 'XWN',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7782,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWO',
    name: 'XWO',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7783,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XWP',
    name: 'XWP',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7784,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XYH',
    name: 'XYH',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7882,
    category: 'DynPricing',
    subCategory: 'RatingTariff',
    code: 'CTS599',
    name: 'CTS599',
    value: 'CTS599',
    displayOrder: '1',
    effDate: '2019-11-14',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7982,
    category: 'DynPricing',
    subCategory: 'RatingTariff',
    code: 'XPOT88',
    name: 'XPOT88',
    value: 'XPOT88',
    displayOrder: '2',
    effDate: '2020-01-17',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8205,
    category: 'DynPricing',
    subCategory: 'RateType',
    code: 'FlatRateX',
    name: 'Flat RateX',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8206,
    category: 'DynPricing',
    subCategory: 'RateType',
    code: 'PctRateByLinehaulX',
    name: 'PctRate By LinehaulX',
    displayOrder: '2',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8207,
    category: 'DynPricing',
    subCategory: 'RateType',
    code: 'PctRateByTotalX',
    name: 'PctRate By TotalX',
    displayOrder: '3',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8208,
    category: 'DynPricing',
    subCategory: 'StatusCd',
    code: 'Active',
    name: 'Active',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8209,
    category: 'DynPricing',
    subCategory: 'StatusCd',
    code: 'Inactive',
    name: 'Inactive',
    displayOrder: '2',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8210,
    category: 'DynPricing',
    subCategory: 'StatusCd',
    code: 'Pending',
    name: 'Pending',
    displayOrder: '3',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8211,
    category: 'DynPricing',
    subCategory: 'RestrictionType',
    code: 'Exclude',
    name: 'Exclude',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8212,
    category: 'DynPricing',
    subCategory: 'RestrictionType',
    code: 'Include',
    name: 'Include',
    displayOrder: '2',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8213,
    category: 'DynPricing',
    subCategory: 'QualifierType',
    code: 'Country',
    name: 'Country',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8215,
    category: 'DynPricing',
    subCategory: 'QualifierType',
    code: 'Location',
    name: 'Location',
    displayOrder: '3',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8217,
    category: 'DynPricing',
    subCategory: 'QualifierType',
    code: 'SIC',
    name: 'SIC',
    displayOrder: '5',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8218,
    category: 'DynPricing',
    subCategory: 'QualifierType',
    code: 'State',
    name: 'State',
    displayOrder: '6',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8219,
    category: 'DynPricing',
    subCategory: 'QualifierType',
    code: 'ZipCode',
    name: 'Zip Code',
    displayOrder: '7',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8220,
    category: 'DynPricing',
    subCategory: 'ApplicationType',
    code: 'Destination',
    name: 'Destination',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8221,
    category: 'DynPricing',
    subCategory: 'ApplicationType',
    code: 'Origin',
    name: 'Origin',
    displayOrder: '2',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8610,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'createDynamicRule',
    value: 'Y',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8611,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'bulkCreateDynamicRule',
    value: 'Y',
    displayOrder: '2',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8612,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '11',
    name: 'dynamicRuleList',
    value: 'Y',
    displayOrder: '3',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8613,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'customerList',
    value: 'Y',
    displayOrder: '4',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8614,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'dynamicShipmentList',
    value: 'Y',
    displayOrder: '5',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8615,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'spotQuoteParameters',
    value: 'Y',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8616,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'dashboard',
    value: 'Y',
    displayOrder: '7',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8617,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'admin',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8618,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'updateDynamicRule',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8619,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'createDynamicRule',
    value: 'Y',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8620,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'bulkCreateDynamicRule',
    value: 'Y',
    displayOrder: '2',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8621,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '5',
    name: 'dynamicRuleList',
    value: 'Y',
    displayOrder: '3',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8622,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'customerList',
    value: 'Y',
    displayOrder: '4',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8623,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'dynamicShipmentList',
    value: 'Y',
    displayOrder: '5',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8624,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'spotQuoteParameters',
    value: 'Y',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8625,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'dashboard',
    value: 'Y',
    displayOrder: '7',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8626,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'admin',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8627,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'updateDynamicRule',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8630,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '7',
    name: 'testHarness',
    value: 'Y',
    displayOrder: '9',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8632,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'testHarness',
    value: 'Y',
    displayOrder: '9',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8001,
    category: 'DynPricing',
    subCategory: 'ElasticsearchOn',
    code: 'RuleList',
    name: 'True',
    value: 'True',
    displayOrder: '1',
    effDate: '2020-02-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7893,
    category: 'DynPricing',
    subCategory: 'RatingTariff',
    code: 'SCP599',
    name: 'SCP599',
    value: 'SCP599',
    displayOrder: '5',
    effDate: '2020-01-17',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 7786,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'NFD',
    name: 'NFD',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 7787,
    category: 'DynPricing',
    subCategory: 'Sic',
    code: 'XCZ',
    name: 'XCZ',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 8565,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'createDynamicRule',
    value: 'Y',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8566,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'bulkCreateDynamicRule',
    value: 'Y',
    displayOrder: '2',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8567,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'dynamicRuleList',
    value: 'Y',
    displayOrder: '3',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8568,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'customerList',
    value: 'Y',
    displayOrder: '4',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8569,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'dynamicShipmentList',
    value: 'Y',
    displayOrder: '5',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8570,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'spotQuoteParameters',
    value: 'Y',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8571,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'dashboard',
    value: 'Y',
    displayOrder: '7',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8572,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'admin',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8573,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'updateDynamicRule',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8574,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'createDynamicRule',
    value: 'Y',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8575,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'bulkCreateDynamicRule',
    value: 'Y',
    displayOrder: '2',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8576,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '10',
    name: 'dynamicRuleList',
    value: 'Y',
    displayOrder: '3',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8577,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'customerList',
    value: 'Y',
    displayOrder: '4',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8578,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'dynamicShipmentList',
    value: 'Y',
    displayOrder: '5',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8579,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'spotQuoteParameters',
    value: 'Y',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8580,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'dashboard',
    value: 'Y',
    displayOrder: '7',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8581,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'admin',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8582,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'updateDynamicRule',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8583,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'createDynamicRule',
    value: 'Y',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8584,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'bulkCreateDynamicRule',
    value: 'Y',
    displayOrder: '2',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8585,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'dynamicRuleList',
    value: 'Y',
    displayOrder: '3',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8586,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'customerList',
    value: 'Y',
    displayOrder: '4',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8587,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'dynamicShipmentList',
    value: 'Y',
    displayOrder: '5',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8588,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'spotQuoteParameters',
    value: 'Y',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8589,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'dashboard',
    value: 'Y',
    displayOrder: '7',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8590,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'admin',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8591,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'updateDynamicRule',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8592,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'createDynamicRule',
    value: 'Y',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8593,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'bulkCreateDynamicRule',
    value: 'Y',
    displayOrder: '2',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8594,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'dynamicRuleList',
    value: 'Y',
    displayOrder: '3',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8595,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'customerList',
    value: 'Y',
    displayOrder: '4',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8596,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'dynamicShipmentList',
    value: 'Y',
    displayOrder: '5',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8597,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'spotQuoteParameters',
    value: 'Y',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8598,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'dashboard',
    value: 'Y',
    displayOrder: '7',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8599,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'admin',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8600,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'updateDynamicRule',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9763,
    category: 'DynPricing',
    subCategory: 'ApplicableType',
    code: 'SystemOverride',
    name: 'System Override',
    displayOrder: '2',
    effDate: '2020-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 9764,
    category: 'DynPricing',
    subCategory: 'ApplicableType',
    code: 'SystemExclude',
    name: 'System Exclude',
    displayOrder: '1',
    effDate: '2020-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 9765,
    category: 'DynPricing',
    subCategory: 'ApplicableType',
    code: 'LaneIncentive',
    name: 'Lane Incentive',
    displayOrder: '2',
    effDate: '2020-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6008486,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '345',
    name: '124P (DEALER) OB US MATRIX',
    value: '345 - 124P (DEALER) OB US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008487,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '346',
    name: '124P (DEALER) INTL MATRIX',
    value: '346 - 124P (DEALER) INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008488,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '350',
    name: '124P (COMPLETE GOODS) OB US MATRIX',
    value: '350 - 124P (COMPLETE GOODS) OB US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008489,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '351',
    name: '124P (COMPLETE GOODS)OB INTL MATRIX',
    value: '351-124P (COMPLETE GOODS)OB INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008490,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '352',
    name: '124P (PARTS) OB US MATRIX',
    value: '352 - 124P (PARTS) OB US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008491,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '357',
    name: '124P (PARTS) OB INTL MATRIX',
    value: '357 - 124P (PARTS) OB INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008492,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '368',
    name: '124P IBC INTL',
    value: '368 - 124P IBC INTL',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008493,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '382',
    name: '124P IBP US',
    value: '382 - 124P IBP US',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008494,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '383',
    name: '124P IBP INTL',
    value: '383 - 124P IBP INTL',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008495,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '384',
    name: '124P IBC US',
    value: '384 - 124P IBC US',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008496,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '388',
    name: '124P (CMC) US MATRIX',
    value: '388 - 124P (CMC) US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008497,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '389',
    name: '124P (CMC) INTL MATRIX',
    value: '389 - 124P (CMC) INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008498,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '390',
    name: '124T US MATRIX',
    value: '390 - 124T US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008499,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '391',
    name: '124T INTL MATRIX',
    value: '391 - 124T INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008500,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '392',
    name: '124T (D2D COMPLETE GOOD) US MATRIX',
    value: '392 - 124T (D2D COMPLETE GOOD) US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008501,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '393',
    name: '124T (D2D COMPLETE GOOD)INTL MATRIX',
    value: '393-124T (D2D COMPLETE GOOD)INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008502,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '394',
    name: '124T (D2D PARTS) US MATRIX',
    value: '394 - 124T (D2D PARTS) US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008503,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '395',
    name: '124T (D2D PARTS) INTL MATRIX',
    value: '395 - 124T (D2D PARTS) INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008504,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '396',
    name: '124T (DEALER) OB US MATRIX',
    value: '396 - 124T (DEALER) OB US MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008505,
    category: 'DynamicPricing',
    subCategory: 'John Deere Lane Pair',
    code: '397',
    name: '124T (DEALER) OB INTL MATRIX',
    value: '397 - 124T (DEALER) OB INTL MATRIX',
    displayOrder: '1',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8671,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'testHarness',
    value: 'Y',
    displayOrder: '9',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9784,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'accessorialCharges',
    value: 'Y',
    displayOrder: '20',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9785,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'accessorialCharges',
    value: 'Y',
    displayOrder: '20',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9786,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '5',
    name: 'accessorialCharges',
    value: 'Y',
    displayOrder: '20',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9787,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '10',
    name: 'accessorialCharges',
    value: 'Y',
    displayOrder: '20',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9788,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '11',
    name: 'accessorialCharges',
    value: 'Y',
    displayOrder: '20',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9789,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'accessorialCharges',
    value: 'Y',
    displayOrder: '20',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9790,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'absoluteMinimumCharges',
    value: 'Y',
    displayOrder: '21',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9791,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'absoluteMinimumCharges',
    value: 'Y',
    displayOrder: '21',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9792,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '5',
    name: 'absoluteMinimumCharges',
    value: 'Y',
    displayOrder: '21',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9793,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '10',
    name: 'absoluteMinimumCharges',
    value: 'Y',
    displayOrder: '21',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9794,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '11',
    name: 'absoluteMinimumCharges',
    value: 'Y',
    displayOrder: '21',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 9795,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'absoluteMinimumCharges',
    value: 'Y',
    displayOrder: '21',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008410,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'shipmentVisibilityLooker',
    value: 'Y',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008506,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'ADC',
    name: 'ADC',
    value: 'ADCN - ADC',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008507,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'ADC',
    name: 'ADC',
    value: 'ADCN - ADC',
    displayOrder: '1',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008508,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'AMS',
    name: 'AMS',
    value: 'AMSB - All Makes / Sunbelt',
    displayOrder: '2',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008509,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'CMC',
    name: 'CMC',
    value: 'CMCN - CMC',
    displayOrder: '3',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008510,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'CTM',
    name: 'CTM',
    value: 'CTRM - carrier terminal',
    displayOrder: '4',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008511,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'CST',
    name: 'CST',
    value: 'CUST - customer',
    displayOrder: '5',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008512,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'DLR',
    name: 'DLR',
    value: 'DELR - dealer',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008513,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'DFC',
    name: 'DFC',
    value: 'DFAC - Deere facility',
    displayOrder: '7',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008514,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'ERC',
    name: 'ERC',
    value: 'ECRC - e-crate return center',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008515,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'FWH',
    name: 'FWH',
    value: 'FFWH - factory / supporting warehouse',
    displayOrder: '9',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008516,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'MTS',
    name: 'MTS',
    value: 'MTSE - marketing / test site / exhibit',
    displayOrder: '10',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008517,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'POR',
    name: 'POR',
    value: 'PORT - port',
    displayOrder: '11',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008518,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'PRT',
    name: 'PRT',
    value: 'PRDC - parts PDC/RDC/depot',
    displayOrder: '12',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008519,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'PRC',
    name: 'PRC',
    value: 'PRTN - parts returns center',
    displayOrder: '13',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008520,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'QCC',
    name: 'QCC',
    value: 'QCCD - QCCD',
    displayOrder: '14',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008521,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'SIN',
    name: 'SIN',
    value: 'SPID - supplier - indirect',
    displayOrder: '15',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008522,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'SUP',
    name: 'SUP',
    value: 'SUPL - supplier',
    displayOrder: '16',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008523,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'TPL',
    name: 'TPL',
    value: 'THPL - 3PL',
    displayOrder: '17',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008524,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'XDC',
    name: 'XDC',
    value: 'XDCK - cross-dock',
    displayOrder: '18',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008525,
    category: 'DynamicPricing',
    subCategory: 'John Deere Location',
    code: 'XPC',
    name: 'XPC',
    value: 'XPAC - XPAC',
    displayOrder: '19',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8200,
    category: 'Pricing',
    subCategory: 'AcUom',
    code: 'Total Charges',
    name: 'Total Charges',
    displayOrder: '15',
    effDate: '2020-12-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6008526,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'pricingLocationList',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008527,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'pricingLocationList',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008528,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'pricingLocationList',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008529,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '6',
    name: 'pricingLocationList',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6008530,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '7',
    name: 'pricingLocationList',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-02-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010058,
    category: 'DynPricing',
    subCategory: 'ApplicableType',
    code: 'OPP',
    name: 'Optimized Pricing Program',
    value: 'OPP',
    displayOrder: '4',
    effDate: '2022-01-24',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010059,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'ApplicableType',
    name: 'Optimized Pricing Program',
    value: 'OPP',
    displayOrder: '4',
    effDate: '2022-01-24',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8100,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'spotQuoteList',
    value: 'Y',
    displayOrder: '6',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8631,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '7',
    name: 'shipmentVisibility',
    value: 'Y',
    displayOrder: '10',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8633,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '17',
    name: 'shipmentVisibility',
    value: 'Y',
    displayOrder: '10',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 8634,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'shipmentVisibility',
    value: 'Y',
    displayOrder: '10',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1542,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'CdiMinimum',
    name: '89',
    value: 'NULL',
    displayOrder: '1',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1543,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'CdiMaximum',
    name: '95',
    value: 'NULL',
    displayOrder: '2',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1545,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'ClassMaximum',
    name: '500',
    value: 'NULL',
    displayOrder: '4',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1544,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'ClassMinimum',
    name: '50',
    value: 'NULL',
    displayOrder: '3',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1546,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'OriginInclude',
    name: 'US,CN',
    value: 'NULL',
    displayOrder: '5',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1547,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'DestinationInclude',
    name: 'US,CN',
    value: 'NULL',
    displayOrder: '6',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010438,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'NewOppPromise',
    name: '5001',
    value: 'NULL',
    displayOrder: '1',
    effDate: '2022-03-05',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010439,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'RenegPromise',
    name: '1000000',
    value: 'NULL',
    displayOrder: '1',
    effDate: '2022-03-05',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010478,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'ThresholdRevenue',
    name: '300000',
    value: 'NULL',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010479,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'ThresholdOR',
    name: '120',
    value: 'NULL',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010678,
    category: 'DynamicPricing',
    subCategory: 'ExportRules',
    code: 'PageSize',
    name: 'PageSize',
    value: '1000',
    displayOrder: '1',
    effDate: '2022-06-06',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1551,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'FakCdiMinimum',
    name: '89',
    displayOrder: '1',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 1552,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'FakCdiMaximum',
    name: '92',
    displayOrder: '2',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6010778,
    category: 'DynPricing',
    subCategory: 'ApplicableType',
    code: 'EarnedDiscount',
    name: 'Earned Discount',
    value: 'EarnedDiscount',
    displayOrder: '5',
    effDate: '2022-07-07',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010418,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'CdiDefault',
    name: '90',
    value: '90',
    displayOrder: '0',
    effDate: '2022-03-08',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1549,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'WeightMinimum',
    name: '1',
    value: 'NULL',
    displayOrder: '7',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 1550,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'WeightMaximum',
    name: '15000',
    value: 'NULL',
    displayOrder: '8',
    effDate: '2021-01-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010558,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'RSD',
    name: '50',
    value: '150',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010559,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'OMS',
    name: '50',
    value: '100',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010560,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'OLG',
    name: '65',
    value: '150',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010561,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'DMS',
    name: '50',
    value: '100',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010562,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'DLG',
    name: '50',
    value: '150',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010563,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'ZHM',
    name: '1',
    value: '30',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010564,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'SSC',
    name: '1',
    value: '30',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010565,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'DID',
    name: '40',
    value: '75',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010566,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'CSD',
    name: '55',
    value: '125',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010567,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'DNC',
    name: '1',
    value: '30',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010568,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'OIP',
    name: '40',
    value: '75',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010569,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'RSO',
    name: '50',
    value: '150',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010570,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'HMS',
    name: '1',
    value: '25',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010571,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'INTERNATIONAL',
    name: '102',
    value: '150',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010572,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'INTRASIC',
    name: '77',
    value: '125',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010573,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '1-250',
    name: '75',
    value: '125',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010574,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '251-500',
    name: '89',
    value: '137',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010575,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '501-750',
    name: '89',
    value: '137',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010576,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '751-1000',
    name: '96',
    value: '150',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010577,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '1001-1250',
    name: '117',
    value: '165',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010578,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '1251-1500',
    name: '125',
    value: '173',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010579,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '1501-1750',
    name: '125',
    value: '173',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010580,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '1751-2000',
    name: '132',
    value: '180',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010581,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '2001-2250',
    name: '132',
    value: '180',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010582,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '2251-2500',
    name: '145',
    value: '193',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010583,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '2501-2750',
    name: '145',
    value: '193',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010584,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '2751-3000',
    name: '154',
    value: '203',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010585,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: '3000+',
    name: '155',
    value: '203',
    displayOrder: '1',
    effDate: '2022-03-10',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010718,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '50 = (50-70)',
    value: '50,55,60,65,70',
    displayOrder: '1',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010719,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '55 = (55-77.5)',
    value: '55,60,65,70,77.5',
    displayOrder: '2',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010720,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '60 = (60-85)',
    value: '60,65,70,77.5,85',
    displayOrder: '3',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010721,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '65 = (65-92.5)',
    value: '65,70,77.5,85,92.5',
    displayOrder: '4',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010722,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '70 = (70-100)',
    value: '70,77.5,85,92.5,100',
    displayOrder: '5',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010723,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '77.5 = (77.5-110)',
    value: '77.5,85,92.5,100,110',
    displayOrder: '6',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010724,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '85 = (85-125)',
    value: '85,92.5,100,110,125',
    displayOrder: '7',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010725,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '92.5 = (92.5-150)',
    value: '92.5,100,110,125,150',
    displayOrder: '8',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010726,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '100= (100-175)',
    value: '100,110,125,150,175',
    displayOrder: '8',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010727,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '110 = (110-200)',
    value: '110,125,150,175,200',
    displayOrder: '10',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010728,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '125 = (125-250)',
    value: '125,150,175,200,250',
    displayOrder: '11',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010729,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '150 = (150-300)',
    value: '150,175,200,250,300',
    displayOrder: '12',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010730,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '175 = (175-400)',
    value: '175,200,250,300,400',
    displayOrder: '13',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010731,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '200 = (200-500)',
    value: '200,250,300,400,500',
    displayOrder: '14',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010732,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '250 = (250-500)',
    value: '250,300,400,500',
    displayOrder: '15',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010733,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '300 = (300-500)',
    value: '300,400,500',
    displayOrder: '16',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010734,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '400 =  (400-500)',
    value: '400,500',
    displayOrder: '17',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010735,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'FAK',
    name: '500 = 500',
    value: '500',
    displayOrder: '18',
    effDate: '2022-06-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010557,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'GUR',
    name: '75',
    value: '100',
    displayOrder: '1',
    effDate: '2022-08-15',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010978,
    category: 'DynamicPricing',
    subCategory: 'CustomerGroups',
    code: 'PageSize',
    name: 'PageSize',
    value: '1000',
    displayOrder: '1',
    effDate: '2022-08-02',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011178,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '4',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011179,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '6',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011180,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011418,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'QuarterlyCdiMaximum',
    name: '93',
    displayOrder: '9',
    effDate: '2022-11-15',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6011419,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'QuarterlyFakCdiMaximum',
    name: '91',
    displayOrder: '9',
    effDate: '2022-11-15',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6011278,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'NotificationTo',
    name: 'javier.deferrari@xpo.com',
    value: 'javier.deferrari@xpo.com',
    displayOrder: '0',
    effDate: '2022-10-18',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011279,
    category: 'SalesRfp',
    subCategory: 'DpRule',
    code: 'NotificationFrom',
    name: 'ITFreightPricingRatingSupport@xpo.com',
    value: 'ITFreightPricingRatingSupport@xpo.com',
    displayOrder: '0',
    effDate: '2022-10-18',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011438,
    category: 'DynamicPricing',
    subCategory: 'trialPeriodType',
    code: 'NONE',
    name: 'NONE',
    displayOrder: '28',
    effDate: '2022-11-04',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6011439,
    category: 'DynamicPricing',
    subCategory: 'trialPeriodType',
    code: 'STANDARD',
    name: 'STANDARD',
    displayOrder: '28',
    effDate: '2022-11-04',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6011440,
    category: 'DynamicPricing',
    subCategory: 'trialPeriodType',
    code: 'QUARTERLY',
    name: 'QPT',
    displayOrder: '28',
    effDate: '2022-11-04',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14177,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011518,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'QuarterlyPolicyDays',
    name: '90',
    displayOrder: '9',
    effDate: '2022-11-15',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6011138,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'dynamicPricingForSalesX',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011139,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011140,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '5',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011141,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '10',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011142,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '11',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011143,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011181,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '3',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011182,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '7',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011183,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '8',
    name: 'dynamicPricingForSales',
    value: 'Y',
    displayOrder: '22',
    effDate: '2020-12-30',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6010451,
    category: 'SalesRfp',
    subCategory: 'OPP',
    code: 'PolicyDays',
    name: '30',
    displayOrder: '1',
    effDate: '2022-09-15',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14258,
    category: 'DynPricing',
    subCategory: 'RuleType',
    code: 'DISCOFLINEHAUL',
    name: 'Additional Discount Off Linehaul',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 6011478,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '0',
    name: 'customerGroups',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011479,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '1',
    name: 'customerGroups',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011480,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '2',
    name: 'customerGroups',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011482,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '12',
    name: 'customerGroups',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011483,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '16',
    name: 'customerGroups',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 6011484,
    category: 'DynamicPricing',
    subCategory: 'Security',
    code: '99',
    name: 'customerGroups',
    value: 'Y',
    displayOrder: '8',
    effDate: '2018-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 14257,
    category: 'DynPricing',
    subCategory: 'RuleType',
    code: 'DISCOFTOTAL',
    name: 'Additional Discount Off Total',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14259,
    category: 'DynPricing',
    subCategory: 'RuleType',
    code: 'OVERRIDECONTRACTDISC',
    name: 'Override Contract Discount',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14260,
    category: 'DynPricing',
    subCategory: 'RuleType',
    code: 'CDIDISC',
    name: 'CDI Discount',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14261,
    category: 'DynPricing',
    subCategory: 'RateType',
    code: 'LINEHAUL_AC_FSC',
    name: 'Discount Applied on Total',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14262,
    category: 'DynPricing',
    subCategory: 'RateType',
    code: 'LINEHAUL',
    name: 'Discount Applied on Linehaul',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14263,
    category: 'DynPricing',
    subCategory: 'RateType',
    code: 'LINEHAUL_FSC',
    name: 'Discount Applied on Linehaul and FSC',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  },
  {
    pricingCodeId: 14277,
    category: 'DynPricing',
    subCategory: 'RuleType',
    code: 'DISCOFCONTRACT',
    name: 'Additional Discount Off Contract',
    displayOrder: '1',
    effDate: '2019-07-01',
    expDate: '2999-12-31',
    auditInfo: null,
    value: '',
  }, // aqui

  {
    pricingCodeId: 15701,
    category: 'DynPricing',
    subCategory: 'AutoAdjust',
    code: 'ApplicationType',
    name: 'Auto Adjust Default',
    value: 'AUTODEFAULT',
    displayOrder: '1',
    effDate: '2021-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 15702,
    category: 'DynPricing',
    subCategory: 'AutoAdjust',
    code: 'ApplicationType',
    name: 'Auto Adjust Broker',
    value: 'AUTOBROKER',
    displayOrder: '2',
    effDate: '2021-03-01',
    expDate: '2999-12-31',
    auditInfo: null,
  },
  {
    pricingCodeId: 15703,
    category: 'DynPricing',
    subCategory: 'AutoAdjust',
    code: 'ApplicationType',
    name: 'Auto Adjust 3PL',
    value: 'AUTO3PL',
    displayOrder: '1',
    effDate: '2022-07-12',
    expDate: '2099-12-31',
    auditInfo: null,
  },
]; // Updated 21-02-2023
