export enum AccessorialChargeFieldNames {
  accessorialChargeNbr = 'ratingAccessorialDetailId',
  versionNbr = 'versionNbr',
  effectiveDate = 'effectiveDate',
  expiryDate = 'expiryDate',
  typeCd = 'typeCd',
  chargeTypeCd = 'chargeTypeCd',
  chargeAmount = 'chargeAmount',
  minimumAmount = 'minimumAmount',
  maxAmount = 'maxAmount'
}
