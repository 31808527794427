<div xpoDialog>
  <h1 mat-dialog-title>
    Alert
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>

  <div mat-dialog-content>
    <div class="xpo-Alert">
      <div class="xpo-Alert-content">
        <div [innerHtml]="message"></div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions><button mat-flat-button (click)="dialogRef.close()">OK</button></div>
</div>
