import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { DynamicRuleDetailComponent } from './dynamic-rule/components/detail/dynamic-rule-detail.component';
import { DynamicRuleListClassicComponent } from './dynamic-rule/components/list/classic/dynamic-rule-list-classic.component';
import { DynamicRuleListComponent } from './dynamic-rule/components/list/dynamic-rule-list.component';
import { DynamicRuleComponent } from './dynamic-rule/dynamic-rule.component';
import { UserPermission } from './shared/enums';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { PermissionGuard } from './shared/guards/permission/permission.guard';
import { DynamicRuleDetailResolverService } from './shared/services/dr-detail-resolver/dynamic-rule-detail-resolver.service';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.DYNAMIC_RULE,
    component: DynamicRuleComponent,
    canActivate: [XpoAuthenticationGuard],
    children: [
      {
        path: AppRoutes.LIST_PAGE,
        component: DynamicRuleListComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedPermission: UserPermission.DynamicRuleList,
        },
      },
      {
        path: AppRoutes.LIST_PAGE_CLASSIC,
        component: DynamicRuleListClassicComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedPermission: UserPermission.DynamicRuleList,
        },
      },
      {
        path: `${AppRoutes.LIST_PAGE_CLASSIC}/:pricingRuleId`,
        component: DynamicRuleListClassicComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedPermission: UserPermission.UpdateDynamicRule,
        },
        resolve: {
          dynamicRuleDetailsData: DynamicRuleDetailResolverService,
        },
      },
      {
        path: `${AppRoutes.PRICING_RULE}/:pricingRuleId`,
        component: DynamicRuleDetailComponent,
        canActivate: [PermissionGuard],
        data: {
          expectedPermission: UserPermission.DynamicPricingForSales,
        },
        resolve: {
          dynamicRuleDetailsData: DynamicRuleDetailResolverService,
        },
      },
    ],
  },
  {
    path: AppRoutes.LANE_MAINTENANCE,
    loadChildren: () => import('./lane-maintenance/lane-maintenance.module').then((m) => m.LaneMaintenanceModule),
  },
  {
    path: AppRoutes.CUSTOMER_EXCLUDE_LIST,
    loadChildren: () => import('./customer-exclude/customer-exclude.module').then((m) => m.CustomerExcludeModule),
  },
  {
    path: AppRoutes.DYNAMIC_SHIPMENTS,
    loadChildren: () => import('./dynamic-shipments/dynamic-shipments.module').then((m) => m.DynamicShipmentsModule),
  },
  {
    path: AppRoutes.RATE_QUOTE,
    loadChildren: () => import('./rate-quote/rate-quote.module').then((m) => m.RateQuoteModule),
  },
  {
    path: AppRoutes.DYNAMIC_PRICING,
    loadChildren: () => import('./dp-dashboard/dp-dashboard.module').then((m) => m.DpDashboardModule),
  },
  {
    path: AppRoutes.DYNAMIC_PRICING_ADMIN_TAB,
    loadChildren: () => import('./admin-tab/admin-tab.module').then((m) => m.AdminTabModule),
  },
  {
    path: AppRoutes.CUSTOMER_GROUP,
    loadChildren: () => import('./customer-group/customer-group.module').then((m) => m.CustomerGroupModule),
  },
  {
    path: AppRoutes.DYNAMIC_RULE_BULK_CREATE,
    loadChildren: () =>
      import('./dynamic-rule-bulk-create/dynamic-rule-bulk-create.module').then((m) => m.DynamicRuleBulkCreateModule),
  },
  {
    path: AppRoutes.SHIPMENT_VISIBILITY,
    loadChildren: () =>
      import('./shipment-visibility/shipment-visibility.module').then((m) => m.ShipmentVisibilityModule),
  },
  {
    path: AppRoutes.SPOT_QUOTE,
    loadChildren: () => import('./spot-quote/spot-quote.module').then((m) => m.SpotQuoteModule),
  },
  {
    path: AppRoutes.ACCESSORIAL_CHARGE,
    loadChildren: () => import('./accessorial-charge/accessorial-charge.module').then((m) => m.AccessorialChargeModule),
  },
  {
    path: AppRoutes.ABSOLUTE_MINIMUM_CHARGE,
    loadChildren: () =>
      import('./absolute-minimum-charge/absolute-minimum-charge.module').then((m) => m.AbsoluteMinimumChargeModule),
  },
  {
    path: AppRoutes.PRICING_LOCATION,
    loadChildren: () => import('./pricing-location/pricing-location.module').then((m) => m.PricingLocationModule),
  },
  {
    path: AppRoutes.CREATE_OPP_RULE,
    loadChildren: () => import('./create-opp-rule/create-opp-rule.module').then((m) => m.CreateOppRuleModule),
  },
  {
    path: AppRoutes.PRICING_DETAILS,
    loadChildren: () => import('./pricing-details/pricing-details.module').then((m) => m.PricingDetailsModule),
  },
  {
    path: AppRoutes.RFP,
    loadChildren: () => import('./dpt/dpt.module').then((m) => m.DptModule),
  },

  {
    path: AppRoutes.PRICING_CODES,
    loadChildren: () => import('./pricing-codes/pricing-codes.module').then((m) => m.PricingCodesModule),
  },
  {
    path: AppRoutes.DPT_DISCOUNT,
    loadChildren: () => import('./dpt-discount/dpt-discount.module').then((m) => m.DptDiscountModule),
  },
  {
    path: '',
    redirectTo: `${AppRoutes.DYNAMIC_RULE}/${AppRoutes.LIST_PAGE}`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
