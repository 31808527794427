import { Injectable, Type } from '@angular/core';
import { DynamicPriceRule } from '@xpo-ltl/sdk-dynamicpricing';
import { XrtInMemoryService, XrtInMemorySource, XrtInMemorySourceWithConfig } from '@xpo/ngx-xrt';
import { DynamicRule } from '../../models/dynamic-rule';
import { DynamicPricingService } from '../dynamic-pricing/dynamic-pricing.service';
import { RuleListService } from '../dynamic-rule-list/rule-list.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicRuleXrtService extends XrtInMemoryService {
  constructor(dynamicPricingService: DynamicPricingService, ruleList: RuleListService) {
    super(
      new Map<Type<any>, XrtInMemorySource | XrtInMemorySourceWithConfig>([
        [
          DynamicPriceRule,
          {
            source: dynamicPricingService.getDynamicRulesListBasedOnSearch.bind(dynamicPricingService),
            config: { quickSearchColumn: ruleList.getColumnsForQuickSearch() },
          },
        ],
        [
          DynamicRule,
          {
            source: dynamicPricingService.getDynamicRulesList.bind(dynamicPricingService),
            config: { quickSearchColumn: ruleList.getColumnsForQuickSearch() },
          },
        ],
      ])
    );
  }
}
