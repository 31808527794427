<button
  xpoSmallButton
  mat-icon-button
  matTooltip="Delete"
  matTooltipPosition="above"
  type="button"
  (click)="deleteAmc()"
  *ngIf="false"
>
  <mat-icon>delete</mat-icon>
</button>
