export enum PricingLocationFieldNames {
  pricingLocationId = 'pricingLocationId',
  description = 'description',
  accountNumber = 'acctNbr',
  locationType = 'locationTypeCd',
  locationTypeCdExtended = 'locationTypeCdExtended',
  effectiveDate = 'effectiveDate',
  effectiveAsOfDate = 'effectiveAsOfDate',
  expiryDate = 'expiryDate',
  origin = 'originLane',
  destination = 'destinationLane',
  lanePairType = 'lanePairTypeCd',
  ruleSetNbr = 'rulesetNbr',
  ruleSetName = 'ruleSetName',
  ruleSet = 'ruleSet',
  madCode = 'madCd',
}
