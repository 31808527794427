import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { AcctId } from '@xpo-ltl/sdk-common';
import {
  BulkCreateDynamicPricingCustomerExcludeResp,
  BulkCreateDynamicPricingCustomerExcludeRqst,
  BulkUpdateDynamicPricingCustomerExcludeResp,
  BulkUpdateDynamicPricingCustomerExcludeRqst,
  BulkUpdateDynamicPricingRuleByTypeResp,
  BulkUpdateDynamicPricingRuleByTypeRqst,
  BulkUpdateDynamicPricingRuleResp,
  BulkUpdateDynamicPricingRuleRqst,
  CreateDynamicPriceRuleCustomerExcludeResp,
  CreateDynamicPricingRuleHeaderResp,
  CreateRatingAccessorialDetailResp,
  CreateRatingAccessorialDetailRqst,
  DynamicCustomerExclude,
  DynamicPriceRule,
  DynamicPriceRuleDownload,
  DynamicPricingApiService,
  DynamicShipment,
  DynamicShipmentCount,
  DynamicShipmentDashboard,
  ExportDynamicPricingRulesFileResp,
  ExportDynamicPricingRulesFileRqst,
  ExportDynamicPricingRulesResp,
  ExportDynamicPricingRulesRqst,
  GetDynamicPriceRuleCustomerExcludePath,
  GetDynamicPriceRuleCustomerExcludeResp,
  GetDynamicPricingRuleHeaderPath,
  GetDynamicPricingRuleHeaderResp,
  GetDynamicPricingSpotParameterPath,
  GetDynamicPricingSpotParameterResp,
  GetDynamicShipmentDashboardDetailsQuery,
  GetDynamicShipmentDashboardDetailsResp,
  GetSpotQuoteQuery,
  GetSpotQuoteResp,
  ListDynamicPriceRuleCustomerExcludesResp,
  ListDynamicPriceRuleCustomerExcludesRqst,
  ListDynamicPricingRateQuotesQuery,
  ListDynamicPricingRateQuotesResp,
  ListDynamicPricingRuleHeadersResp,
  ListDynamicPricingRuleHeadersRqst,
  ListDynamicPricingRulesDownloadResp,
  ListDynamicPricingShipmentsQuery,
  ListDynamicPricingShipmentsResp,
  ListDynamicShipmentCountsResp,
  ListDynamicShipmentsResp,
  ListSpotQuotesQuery,
  ListSpotQuotesResp,
  PriceRuleCustomerExclude,
  SearchDynamicPriceRuleUnfilteredResp,
  SearchDynamicPriceRuleUnfilteredRqst,
  SpotRateQuote,
  UpdateDynamicPriceRuleCustomerExcludeRqst,
  UpdateDynamicPricingRuleHeaderPath,
  UpdateDynamicPricingRuleHeaderResp,
  UpdateDynamicPricingSpotParameterPath,
  UpdateDynamicPricingSpotParameterResp,
  UpdateRatingAccessorialDetailResp,
  UpdateRatingAccessorialDetailRqst,
} from '@xpo-ltl/sdk-dynamicpricing';
import {
  AccessorialService,
  ListPricingAnalystsQuery,
  ListPricingAnalystsResp,
  ListPricingCustomerAccountQuery,
  ListPricingCustomerAccountResp,
  ListSalesRfpAccessorialsQuery,
  PricingApiService,
} from '@xpo-ltl/sdk-pricing';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { forkJoin, Observable, of } from 'rxjs/index';
import { catchError, map, shareReplay, switchMap } from 'rxjs/internal/operators';
import { AccessorialChargeStatus } from '../../enums';
import { SpotQuoteRequestType } from '../../enums/spot-quote-request-type.enum';
import { CustomerAcctId } from '../../models/customer-acct-id';
import { SalesAgent } from '../../models/sales-agent';
import { SearchRule } from '../../models/search-rule';
import { SearchSpotQuote } from '../../models/search-spot-quote';
import { SpotQuoteExtended } from '../../models/spot-quote-extended';
import { ConstantsService } from '../constants/constants.service';
import { RuleListService } from '../dynamic-rule-list/rule-list.service';
import { SearchFormService } from '../search-form/search-form.service';
import { SpotQuoteService } from '../spot-quote/spot-quote.service';
import { UserService } from '../user/user.service';
import { DynamicPricingStorageService } from './dynamic-pricing-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPricingService {
  dynamicRuleListCache$: Observable<DynamicPriceRule[]>;
  rulesListCache$: Observable<DynamicPriceRule[]>;
  spotQuoteListCache$: Observable<SpotQuoteExtended[]>;
  accessorialChargeListCache$: Observable<any[]>;
  globalAccessorialListCache$: Observable<AccessorialService[]>;

  constructor(
    private http: HttpClient,
    private dynamicPricingApiService: DynamicPricingApiService,
    private pricingApiService: PricingApiService,
    private storageService: DynamicPricingStorageService,
    private constants: ConstantsService,
    private rulesList: RuleListService,
    private searchForm: SearchFormService,
    private spotQuoteService: SpotQuoteService,
    private userService: UserService,
    private snackBar: XpoSnackBar
  ) {}

  getAll(): Observable<any> {
    return this.http.get('./assets/mock-up/data/lane-maintenance.json');
  }

  getSpotQuoteParametersBasedOnId(spotParameterId: number): Observable<GetDynamicPricingSpotParameterResp> {
    const pathParams = new GetDynamicPricingSpotParameterPath();
    pathParams.dynSpotParameterId = spotParameterId;
    return this.dynamicPricingApiService.getDynamicPricingSpotParameter(pathParams);
  }

  updateSpotQuoteParametersBasedOnId(
    spotQuoteData: any,
    spotParameterId: number
  ): Observable<UpdateDynamicPricingSpotParameterResp> {
    const pathParams = new UpdateDynamicPricingSpotParameterPath();
    pathParams.dynSpotParameterId = spotParameterId;
    spotQuoteData.dynSpotParameter.spotParameterId = spotParameterId;
    return this.dynamicPricingApiService.updateDynamicPricingSpotParameter(spotQuoteData, pathParams);
  }

  getDynamicRuleBasedOnId(pricingRuleId: number): Observable<GetDynamicPricingRuleHeaderResp> {
    const pathParams = new GetDynamicPricingRuleHeaderPath();
    pathParams.priceRuleId = pricingRuleId;
    return this.dynamicPricingApiService.getDynamicPricingRuleHeader(pathParams);
  }

  createDynamicRuleHdr(data: DynamicPriceRule): Observable<CreateDynamicPricingRuleHeaderResp> {
    return this.dynamicPricingApiService.createDynamicPricingRuleHeader({ dynPrcRule: data }).pipe(
      map((resp: CreateDynamicPricingRuleHeaderResp) => {
        this.clearRulesListCache();
        return resp;
      })
    );
  }

  createNewVersion(data: DynamicPriceRule, ruleDetails: any): Observable<CreateDynamicPricingRuleHeaderResp> {
    data.priceRuleId = ruleDetails.priceRuleId;
    data.logicalRuleId = ruleDetails.logicalRuleId;
    data.ruleVersionNbr = ruleDetails.ruleVersionNbr;
    return this.dynamicPricingApiService.createDynamicPricingRuleHeader({ dynPrcRule: data }).pipe(
      map((resp: CreateDynamicPricingRuleHeaderResp) => {
        this.clearRulesListCache();
        return resp;
      })
    );
  }

  updateDynamicRuleHdrBasedOnId(
    data: DynamicPriceRule,
    ruleDetails: any
  ): Observable<UpdateDynamicPricingRuleHeaderResp> {
    const pathParams = new UpdateDynamicPricingRuleHeaderPath();
    pathParams.priceRuleId = ruleDetails.priceRuleId;
    data.priceRuleId = ruleDetails.priceRuleId;
    data.logicalRuleId = ruleDetails.logicalRuleId;
    data.ruleVersionNbr = ruleDetails.ruleVersionNbr;
    return this.dynamicPricingApiService.updateDynamicPricingRuleHeader({ dynPrcRule: data }, pathParams).pipe(
      map((resp: UpdateDynamicPricingRuleHeaderResp) => {
        this.clearRulesListCache();
        return resp;
      })
    );
  }

  bulkUpdateRules(request: BulkUpdateDynamicPricingRuleRqst): Observable<BulkUpdateDynamicPricingRuleResp> {
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: true,
      loadingOverlayMessage: 'Uploading...',
      toastOnError: true,
    };
    return this.dynamicPricingApiService.bulkUpdateDynamicPricingRule(request, dataOptions);
  }

  bulkUpdateRulesByType(
    request: BulkUpdateDynamicPricingRuleByTypeRqst
  ): Observable<BulkUpdateDynamicPricingRuleByTypeResp> {
    return this.dynamicPricingApiService.bulkUpdateDynamicPricingRuleByType(request);
  }

  requestDynamicPricingRulesList(
    request: ListDynamicPricingRuleHeadersRqst,
    levelOfDetail: string,
    dataOptions?: DataOptions,
    acctMadCSearchInd?: boolean
  ): Observable<DynamicPriceRule[]> {
    return this.dynamicPricingApiService.listDynamicPricingRulesHeader(request, dataOptions).pipe(
      map((resp: ListDynamicPricingRuleHeadersResp) => {
        if (acctMadCSearchInd) {
          return resp;
        } else if (levelOfDetail === this.constants.SEARCH) {
          return resp.dynamicPriceRules;
        } else {
          return this.rulesList.formatDateForDownload(resp.dynamicPriceRules);
        }
      }),
      shareReplay(1)
    );
  }

  listDynamicPricingShipments(
    queryParams: ListDynamicPricingShipmentsQuery
  ): Observable<ListDynamicPricingShipmentsResp> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.dynamicPricingApiService.listDynamicPricingShipments(queryParams, {}, { headers });
  }

  getDynamicRulesListBasedOnSearch(): Observable<DynamicPriceRule[]> {
    if (!this.dynamicRuleListCache$) {
      const params = this.storageService.getDynamicRulesQueryParams();
      if (params && this.rulesList.checkValueInForm(params)) {
        const request = this.rulesList.handleRulesListParams(params, this.constants.SEARCH);
        this.dynamicRuleListCache$ = this.requestDynamicPricingRulesList(request, this.constants.SEARCH);
      } else {
        this.dynamicRuleListCache$ = of([]);
      }
    }
    return this.dynamicRuleListCache$;
  }

  getDynamicRulesList(): Observable<DynamicPriceRule[]> {
    if (!this.rulesListCache$) {
      const request = this.rulesList.handleRulesListParams(new SearchRule(), this.constants.SEARCH);
      this.rulesListCache$ = this.requestDynamicPricingRulesList(request, this.constants.SEARCH);
    }
    return this.rulesListCache$;
  }

  getSpotQuote(spotQteNbr: number): Observable<GetSpotQuoteResp> {
    const request = new GetSpotQuoteQuery();
    request.spotQteNbr = spotQteNbr;
    return this.dynamicPricingApiService.getSpotQuote(request);
  }

  getSpotQuoteCustomerRequestList(spotRateQuotes: SpotRateQuote[]): Array<Observable<ListPricingCustomerAccountResp>> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    const customers = spotRateQuotes
      .filter((spotRateQuote: SpotRateQuote) => spotRateQuote.spotQuote.customerInstanceId)
      .map((spotRateQuote: SpotRateQuote) => spotRateQuote.spotQuote.customerInstanceId.toString());
    const customerList = [...new Set(customers)];
    const customerListSplitted = Array<Array<string>>();
    const customerListAux = customerList;
    while (customerListAux.length) {
      const aux = customerListAux.splice(0, 50);
      customerListSplitted.push(aux);
    }
    const requestList: Array<Observable<ListPricingCustomerAccountResp>> = [];
    customerListSplitted.forEach((records: Array<string>) => {
      const customerAccountRequest = this.pricingApiService
        .listPricingCustomerAccount(
          {
            ...new ListPricingCustomerAccountQuery(),
            acctInstId: records,
          },
          {},
          { headers }
        )
        .pipe(
          map((result) => {
            return {
              ...result,
              typeRequest: SpotQuoteRequestType.customers,
            };
          }),
          catchError(() => {
            return of(undefined);
          })
        );
      requestList.push(customerAccountRequest);
    });
    return requestList;
  }

  getSpotQuoteSalesAgentRequestList(spotRateQuotes: SpotRateQuote[]): Array<Observable<ListPricingAnalystsResp>> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    const agents = spotRateQuotes
      .filter((spotRateQuote: SpotRateQuote) => spotRateQuote.spotQuote.salesAgentEmplid)
      .map((spotRateQuote: SpotRateQuote) => spotRateQuote.spotQuote.salesAgentEmplid.toString());
    const agentList = [...new Set(agents)];
    const agentListSplitted = Array<Array<string>>();
    const agentListAux = agentList;
    while (agentListAux.length) {
      const aux = agentListAux.splice(0, 50);
      agentListSplitted.push(aux);
    }
    const requestList: Array<Observable<ListPricingAnalystsResp>> = [];
    agentListSplitted.forEach((records: Array<string>) => {
      /**
       * This code is a workaround to prevent an error in the API.
       * It's not returning any data when sending just one manager,
       * we need to send at least two
       */
      const managers = records.length > 1 ? records.join(',') : [records[0], records[0]].join(',');
      /**
       * end workaround
       * */
      const agentAccountRequest = this.pricingApiService
        .listPricingAnalysts(
          {
            ...new ListPricingAnalystsQuery(),
            managerEmplId: managers,
          },
          {},
          { headers }
        )
        .pipe(
          map((result) => {
            return {
              ...result,
              typeRequest: SpotQuoteRequestType.salesAgent,
            };
          }),
          catchError(() => {
            return of(undefined);
          })
        );
      requestList.push(agentAccountRequest);
    });
    return requestList;
  }

  listSpotQuotes(populateSalesAgentWithCurrentUser: boolean): Observable<Array<SpotQuoteExtended>> {
    if (!this.spotQuoteListCache$) {
      const filters = this.storageService.getSpotQuoteListParams();
      if (filters) {
        const request = this.handleSpotQuoteListParams(filters);
        const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
        this.spotQuoteListCache$ = this.dynamicPricingApiService
          .listSpotQuotes(
            request,
            {
              loadingOverlayEnabled: true,
            },
            { headers }
          )
          .pipe(
            switchMap((value: ListSpotQuotesResp) => {
              if (value.spotRateQuotes.length) {
                const customerRequestList = this.getSpotQuoteCustomerRequestList(value.spotRateQuotes);
                let salesAgentRequestList = [];
                if (populateSalesAgentWithCurrentUser) {
                  salesAgentRequestList = [
                    of({
                      pricingAnalysts: [
                        {
                          employeeId: this.userService.User.employeeId,
                          employeeFullName: `${this.userService.User.givenName} ${this.userService.User.lastName}`,
                          emailAddr: this.userService.User.emailAddress,
                        },
                      ],
                      typeRequest: SpotQuoteRequestType.salesAgent,
                    }),
                  ];
                } else {
                  salesAgentRequestList = this.getSpotQuoteSalesAgentRequestList(value.spotRateQuotes);
                }
                if (customerRequestList.length || salesAgentRequestList.length) {
                  return forkJoin([...customerRequestList, ...salesAgentRequestList]).pipe(
                    switchMap((recordsList: Array<ListPricingCustomerAccountResp | ListPricingAnalystsResp>) => {
                      const customerMap = new Map<string, CustomerAcctId>();
                      const salesAgentMap = new Map<string, SalesAgent>();
                      recordsList
                        .filter((list) => list !== undefined)
                        .forEach((parent: ListPricingCustomerAccountResp | ListPricingAnalystsResp) => {
                          switch (parent['typeRequest']) {
                            case SpotQuoteRequestType.customers:
                              (<ListPricingCustomerAccountResp>parent).custDetails.forEach((children) => {
                                customerMap.set(children.acctId.acctInstId, {
                                  acctMadCd: children.acctId.acctMadCd,
                                  acctName: children.acctId.acctName,
                                });
                              });
                              break;
                            case SpotQuoteRequestType.salesAgent:
                              (<ListPricingAnalystsResp>parent).pricingAnalysts.forEach((children) => {
                                salesAgentMap.set(children.employeeId, {
                                  emailAddr: children.emailAddr,
                                  employeeFullName: children.employeeFullName,
                                });
                              });
                              break;
                          }
                        });

                      return this.getSpotQuotesExtended(value.spotRateQuotes, customerMap, salesAgentMap);
                    })
                  );
                } else {
                  return this.getSpotQuotesExtended(value.spotRateQuotes);
                }
              } else {
                return of([]);
              }
            }),
            catchError((error) => {
              console.error(error);
              this.snackBar.error(error && error.message ? error.message : 'Sorry, we had an error getting the data');
              return of([]);
            }),
            shareReplay(1)
          );
      } else {
        return of([]);
      }
    }
    return this.spotQuoteListCache$;
  }

  getSpotQuotesExtended(
    spotRateQuoteList: Array<SpotRateQuote>,
    customerMap: Map<string, CustomerAcctId> = null,
    salesAgentMap: Map<string, SalesAgent> = null
  ): Observable<Array<SpotQuoteExtended>> {
    const currentDate = moment();
    const spotQuotesExtended = spotRateQuoteList.map((spotRateQuote) => {
      const customerAcct =
        customerMap && spotRateQuote.spotQuote.customerInstanceId
          ? customerMap.get(spotRateQuote.spotQuote.customerInstanceId.toString())
          : null;
      const salesAgent =
        salesAgentMap && spotRateQuote.spotQuote.salesAgentEmplid
          ? salesAgentMap.get(spotRateQuote.spotQuote.salesAgentEmplid)
          : null;

      const adjustedChargeAmount = spotRateQuote.rateQuote ? spotRateQuote.rateQuote.adjustedChargeAmount : 0;
      const newRecord: SpotQuoteExtended = {
        ...new SpotQuoteExtended(),
        ...spotRateQuote.spotQuote,
        customerCode: customerAcct ? customerAcct.acctMadCd : '',
        customerName: customerAcct ? customerAcct.acctName : '',
        salesAgentFullName: salesAgent ? salesAgent.employeeFullName : '',
        salesAgentEmailAddress: salesAgent ? salesAgent.emailAddr : '',
        adjustedChargeAmount: adjustedChargeAmount,
        spotQteStatusCd: this.spotQuoteService.statusBasedOnData(
          currentDate,
          spotRateQuote.spotQuote,
          adjustedChargeAmount
        ),
      };
      return newRecord;
    });
    return of(spotQuotesExtended);
  }

  getDynamicRulesForDownload(): Observable<DynamicPriceRule[]> {
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };
    const request = this.rulesList.handleRulesListParams(new SearchRule(), this.constants.DOWNLOAD);
    return this.requestDynamicPricingRulesList(request, this.constants.DOWNLOAD, dataOptions);
  }

  getDynamicRulesBySearchForDownload(): Observable<DynamicPriceRuleDownload[]> {
    const dataOptions: DataOptions = {
      loadingOverlayEnabled: false,
    };
    const params = this.storageService.getDynamicRulesQueryParams();
    if (params && this.rulesList.checkValueInForm(params)) {
      const request = this.rulesList.handleRulesListParams(params, this.constants.DOWNLOAD_GRID);
      return this.dynamicPricingApiService.listDynamicPricingRulesDownload(request, dataOptions).pipe(
        map((response: ListDynamicPricingRulesDownloadResp) => {
          return this.rulesList.formatDateForDownload(response.dynamicPriceRuleDownloads);
        })
      );
    } else {
      return of(null);
    }
  }

  getShipmentCountsList(data: any): Observable<DynamicShipmentCount[]> {
    return this.dynamicPricingApiService.listDynamicShipmentCounts(data).pipe(
      map((shipmentCountResp: ListDynamicShipmentCountsResp) => {
        return shipmentCountResp.dynShipmentCounts;
      })
    );
  }

  getDynamicShipmentsList(): Observable<DynamicShipment[]> {
    const params = this.storageService.getDynamicShipmentsQueryParams();
    if (params && this.searchForm.checkValueInForm(params)) {
      return this.dynamicPricingApiService.listDynamicShipments(params).pipe(
        map((res: ListDynamicShipmentsResp) => {
          return res.dynShipments;
        })
      );
    } else {
      return of([]);
    }
  }

  getCustomerExcludeList(): any {
    const bodyParams = this.storageService.getCustomerExcludeListParams();
    if (!this.storageService.getCustomerExcludeListCache()) {
      if (bodyParams && this.rulesList.checkValueInForm(bodyParams)) {
        const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
        const cacheList = this.dynamicPricingApiService
          .listDynamicPriceRuleCustomerExcludes(
            bodyParams,
            {
              loadingOverlayEnabled: true,
            },
            { headers }
          )
          .pipe(
            map((customerExclude: ListDynamicPriceRuleCustomerExcludesResp) => {
              const customer = customerExclude.dynCustomerExcludes;
              return _.map(customer, (dynamicCustomerExclude: DynamicCustomerExclude) => {
                if (dynamicCustomerExclude.acctId) {
                  const customerData: any = dynamicCustomerExclude.dynPriceRuleCustomerExclude;
                  customerData.customerName = dynamicCustomerExclude.acctId.acctName;
                  customerData.madCode = dynamicCustomerExclude.acctId.acctMadCd;
                  customerData.listInfo = customerExclude.listInfo;
                }
                return dynamicCustomerExclude.dynPriceRuleCustomerExclude;
              });
            }),
            shareReplay(1)
          );
        this.storageService.setCustomerExcludeListCache(cacheList);
      } else {
        this.storageService.setCustomerExcludeListCache(of([]));
      }
    }
    return this.storageService.getCustomerExcludeListCache();
  }

  getCustomerExcludeListToValidateOPP(accountCd: string): Observable<DynamicCustomerExclude[]> {
    const request = new ListDynamicPriceRuleCustomerExcludesRqst();
    request.dynPriceRuleCustomerExclude = new DynamicCustomerExclude();
    request.dynPriceRuleCustomerExclude.acctId = new AcctId();
    request.dynPriceRuleCustomerExclude.acctId.acctMadCd = accountCd;
    request.dynPriceRuleCustomerExclude.dynPriceRuleCustomerExclude = new PriceRuleCustomerExclude();
    request.dynPriceRuleCustomerExclude.dynPriceRuleCustomerExclude.restrictionTypeCd = 'Exclude';
    return this.dynamicPricingApiService.listDynamicPriceRuleCustomerExcludes(request).pipe(
      map((resp: ListDynamicPriceRuleCustomerExcludesResp) => {
        return resp.dynCustomerExcludes;
      })
    );
  }

  getCustomerExclude(customerNbr: any): any {
    const queryParams = new GetDynamicPriceRuleCustomerExcludePath();
    queryParams.customerNbr = customerNbr;
    return this.dynamicPricingApiService.getDynamicPriceRuleCustomerExclude(queryParams).pipe(
      map((customerExclude: GetDynamicPriceRuleCustomerExcludeResp) => {
        return customerExclude.dynCustomerExclude;
      })
    );
  }

  addCustomerExclude(customerData: any): Observable<CreateDynamicPriceRuleCustomerExcludeResp> {
    return this.dynamicPricingApiService.createDynamicPriceRuleCustomerExclude(customerData);
  }

  bulkCreateCustomerExclude(
    customersData: BulkCreateDynamicPricingCustomerExcludeRqst
  ): Observable<BulkCreateDynamicPricingCustomerExcludeResp> {
    return this.dynamicPricingApiService.bulkCreateDynamicPricingCustomerExclude(customersData);
  }

  updateCustomer(
    customerData: UpdateDynamicPriceRuleCustomerExcludeRqst,
    customerExcludeId: any
  ): Observable<CreateDynamicPriceRuleCustomerExcludeResp> {
    const queryParams = {
      priceRuleCustomerExcludeId: customerExcludeId,
    };
    return this.dynamicPricingApiService.updateDynamicPriceRuleCustomerExclude(customerData, queryParams);
  }

  bulkUpdateCustomerExclude(
    customerExcludeData: DynamicCustomerExclude,
    customerExcludeIdList: Array<number>
  ): Observable<BulkUpdateDynamicPricingCustomerExcludeResp> {
    const request: BulkUpdateDynamicPricingCustomerExcludeRqst = {
      dynCustomerExclude: customerExcludeData,
      priceRuleCustomerExcludeIds: customerExcludeIdList,
    };

    return this.dynamicPricingApiService.bulkUpdateDynamicPricingCustomerExclude(request);
  }

  clearRulesListCache() {
    this.dynamicRuleListCache$ = null;
    this.rulesListCache$ = null;
  }

  clearSpotQuoteListCache() {
    this.spotQuoteListCache$ = null;
  }

  clearAccessorialChargeListCache() {
    this.accessorialChargeListCache$ = null;
  }

  getDashboard(params: GetDynamicShipmentDashboardDetailsQuery): Observable<DynamicShipmentDashboard> {
    return this.dynamicPricingApiService.getDynamicShipmentDashboardDetails(params).pipe(
      map((res: GetDynamicShipmentDashboardDetailsResp) => {
        return res.dynamicShipmentDashboard;
      })
    );
  }

  // TODO: use this method once the data is available in elasticsearch
  // getElasticDocumentDynamicPriceRule(pricingRuleId: number): Observable<GetElasticDocumentDynamicPriceRuleResp> {
  //   const pathParams = new GetElasticDocumentDynamicPriceRulePath();
  //   const queryParams = new GetElasticDocumentDynamicPriceRuleQuery();
  //   pathParams.priceRuleId = pricingRuleId.toString();
  //   return this.dynamicPricingApiService.getElasticDocumentDynamicPriceRule(pathParams, queryParams);
  // }

  getDynamicRulesFromElastic(
    request: SearchDynamicPriceRuleUnfilteredRqst
  ): Observable<SearchDynamicPriceRuleUnfilteredResp> {
    return this.dynamicPricingApiService.searchDynamicPriceRuleUnfiltered(request);
  }

  exportDynamicPricingRules(request: ExportDynamicPricingRulesRqst): Observable<ExportDynamicPricingRulesResp> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.dynamicPricingApiService.exportDynamicPricingRules(
      request,
      { loadingOverlayEnabled: true, loadingOverlayMessage: 'Downloading' },
      { headers }
    );
  }

  exportDynamicPricingRulesFile(
    request: ExportDynamicPricingRulesFileRqst
  ): Observable<ExportDynamicPricingRulesFileResp> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.dynamicPricingApiService.exportDynamicPricingRulesFile(
      request,
      { loadingOverlayEnabled: true, loadingOverlayMessage: 'Downloading' },
      { headers }
    );
  }

  handleSpotQuoteListParams(params: SearchSpotQuote): ListSpotQuotesQuery {
    return {
      ...new ListSpotQuotesQuery(),
      ...params,
    };
  }

  createRatingAccessorialDetail(
    request: CreateRatingAccessorialDetailRqst
  ): Observable<CreateRatingAccessorialDetailResp> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    if (!request.ratingAccessorialDetail.expiryDate) {
      request.ratingAccessorialDetail.expiryDate = this.constants.DEFAULT_EXPIRATION_DATE_STRING;
    }
    return this.dynamicPricingApiService.createRatingAccessorialDetail(request, {}, { headers });
  }

  updateRatingAccessorialDetail(
    request: UpdateRatingAccessorialDetailRqst
  ): Observable<UpdateRatingAccessorialDetailResp> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.dynamicPricingApiService.updateRatingAccessorialDetail(
      request,
      { ratingAccessorialDetailId: request.ratingAccessorialDetail.ratingAccessorialDetailId },
      {},
      { headers }
    );
  }

  getAccessorialChargeStatusBasedOnDates(effectiveDate: Moment, expiryDate: Moment): AccessorialChargeStatus {
    const currentDate = moment();
    if (currentDate.isSameOrAfter(effectiveDate) && currentDate.isSameOrBefore(expiryDate)) {
      return AccessorialChargeStatus.Active;
    }
    if (currentDate.isAfter(expiryDate)) {
      return AccessorialChargeStatus.Expired;
    }
    return AccessorialChargeStatus.Pending;
  }

  listGlobalAccessorials(): Observable<AccessorialService[]> {
    if (!this.globalAccessorialListCache$) {
      const queryParams = new ListSalesRfpAccessorialsQuery();
      this.globalAccessorialListCache$ = this.pricingApiService.listGlobalAccessorials(queryParams).pipe(
        map((response) => response.globalAccessorials),
        shareReplay(1)
      );
    }
    return this.globalAccessorialListCache$;
  }

  listDynamicPricingRateQuotes(cnfmNbr: number): Observable<ListDynamicPricingRateQuotesResp> {
    const request = new ListDynamicPricingRateQuotesQuery();
    request.cnfmNbr = cnfmNbr;
    return this.dynamicPricingApiService.listDynamicPricingRateQuotes(request);
  }

  getCustomerExcludeByCode(madCode: string): Observable<ListDynamicPriceRuleCustomerExcludesResp> {
    const request = new ListDynamicPriceRuleCustomerExcludesRqst();
    request.dynPriceRuleCustomerExclude = new DynamicCustomerExclude();
    request.dynPriceRuleCustomerExclude.acctId = new AcctId();
    request.dynPriceRuleCustomerExclude.dynPriceRuleCustomerExclude = new PriceRuleCustomerExclude();
    request.dynPriceRuleCustomerExclude.dynPriceRuleCustomerExclude.restrictionTypeCd = 'Exclude';
    request.dynPriceRuleCustomerExclude.dynPriceRuleCustomerExclude.effectiveDate = new Date();
    request.dynPriceRuleCustomerExclude.acctId.acctMadCd = madCode;
    return this.dynamicPricingApiService.listDynamicPriceRuleCustomerExcludes(request);
  }
}
