import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Papa } from 'ngx-papaparse';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { take } from 'rxjs/operators';
import { ConstantsService } from '../../../shared/services/constants/constants.service';
import { DynamicRuleService } from '../../../shared/services/dr/dynamic-rule.service';
import { DynamicRuleUploadData } from './dynamic-rule-upload-data';

@Component({
  selector: 'app-dynamic-rule-upload',
  templateUrl: './dynamic-rule-upload.component.html',
  styleUrls: ['./dynamic-rule-upload.component.scss'],
})
export class DynamicRuleUploadComponent implements OnInit {
  fileToUpload: File = null;
  form: FormGroup;
  recordsToAdd: DynamicRuleUploadData[];
  errorMessages: string[];
  isProcessing: boolean = false;

  private qualifierTypeNameMap = new Map<string, string>([
    ['STATE', this.constants.STATE],
    ['SIC', this.constants.SIC],
    ['COUNTRY', this.constants.COUNTRY],
    ['LOCATION', this.constants.LOCATION],
    ['REGION', this.constants.REGION],
    ['DISTRICT', this.constants.DISTRICT],
    ['ZIP', this.constants.ZIPCODE],
  ]);

  constructor(
    public dialogRef: MatDialogRef<DynamicRuleUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dynamicRule: DynamicRuleService,
    private constants: ConstantsService,
    private papa: Papa
  ) {}

  ngOnInit() {
    this.form = this.data.form;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.errorMessages = [];
    this.recordsToAdd = [];
  }

  processUploadFile() {
    this.isProcessing = true;
    const options = {
      complete: (results, file) => {
        try {
          this.validateHeader(results.data);
        } catch (error) {
          this.fileToUpload = null;
          this.errorMessages.push(error);
          this.isProcessing = false;
          return;
        }

        const accountsToLookup: string[] = [];
        results.data.forEach((rowData: string[], index: number) => {
          if (index > 0) {
            const dynamicRuleUploadData = this.processRowData(index, rowData);
            this.recordsToAdd.push(dynamicRuleUploadData);
            if (dynamicRuleUploadData.type === this.constants.LOCATION && !dynamicRuleUploadData.errorMessage) {
              accountsToLookup.push(dynamicRuleUploadData.value);
            }
          }
        });
        // Records processed.. clear fileToUpload
        this.fileToUpload = null;

        // now validate location
        this.dynamicRule
          .lookupAccountNumbers(accountsToLookup)
          .pipe(take(1))
          .subscribe((accountMap) => {
            this.recordsToAdd.forEach((dynamicRuleUploadData: DynamicRuleUploadData) => {
              if (dynamicRuleUploadData.type === this.constants.LOCATION && !dynamicRuleUploadData.errorMessage) {
                if (isNumeric(dynamicRuleUploadData.value)) {
                  if (!accountMap.has(dynamicRuleUploadData.value)) {
                    dynamicRuleUploadData.errorMessage = 'Location: ' + dynamicRuleUploadData.value + ' not found!';
                  }
                } else {
                  if (!accountMap.has(dynamicRuleUploadData.value)) {
                    dynamicRuleUploadData.errorMessage = 'Location: ' + dynamicRuleUploadData.value + ' not found!';
                  } else {
                    dynamicRuleUploadData.value =
                      dynamicRuleUploadData.value + '-' + accountMap.get(dynamicRuleUploadData.value);
                  }
                }
              }
            });

            let errorsFound = false;
            this.recordsToAdd.forEach((dynamicRuleUploadData: DynamicRuleUploadData) => {
              if (dynamicRuleUploadData.errorMessage) {
                errorsFound = true;
                let msg = 'Row # ' + dynamicRuleUploadData.rowNumber + ' : ' + dynamicRuleUploadData.errorMessage;
                if (this.errorMessages.length < 6) {
                  if (this.errorMessages.length === 5) {
                    msg = 'More than 5 errors found ...';
                  }
                  this.errorMessages.push(msg);
                }
              }
            });

            if (!errorsFound) {
              this.processPendingUpload();
            }
            this.isProcessing = false;
          });
      },
    };

    this.papa.parse(this.fileToUpload, options);
  }

  private validateHeader(data: string[][]) {
    if (!data || !data[0]) {
      throw new Error('No CSV Header found!');
    }
    const headerRecord = data[0];
    if (headerRecord.length !== 4) {
      throw new Error(
        'Invalid CSV Header found! Must contain 4 columns in the following order: "Type","Value","Orig/Dest","Include/Exclude"'
      );
    }
    if (
      !headerRecord[0]
        .trim()
        .toUpperCase()
        .startsWith('TYPE') ||
      !headerRecord[1]
        .trim()
        .toUpperCase()
        .startsWith('VALUE') ||
      !headerRecord[2]
        .trim()
        .toUpperCase()
        .startsWith('ORIG') ||
      !headerRecord[3]
        .trim()
        .toUpperCase()
        .startsWith('INCLUDE')
    ) {
      throw new Error(
        'Invalid CSV Header found! Must contain 4 columns in the following order: "Type","Value","Orig/Dest","Include/Exclude"'
      );
    }
  }

  // todo: I don't like the following method and would like it refactored... however,
  // this was done rapidly and added to last minute to support other changes for dynamic pricing
  // I'd prefer to create a class specific to validation and processing Row Data
  private processRowData(rowNumber: number, rowData: string[]): DynamicRuleUploadData {
    const data = new DynamicRuleUploadData();
    data.rowNumber = rowNumber;
    if (!rowData || rowData.length < 2) {
      data.errorMessage = 'Invalid Data! Must contain at least 2 Columns!';
      return data;
    }

    data.type = rowData[0].trim().toUpperCase();
    data.value = rowData[1].trim();
    if (rowData.length >= 3) {
      data.originDestination = rowData[2].trim().toUpperCase();
    }
    if (rowData.length >= 4) {
      data.includeExclude = rowData[3].trim().toUpperCase();
    }

    if (data.value.length === 0) {
      data.errorMessage = 'Missing Value!';
      return data;
    }

    if (this.qualifierTypeNameMap.has(data.type)) {
      if (!rowData || rowData.length !== 4) {
        data.errorMessage = 'Invalid Data! Qualifier Data Must contain 4 Columns!';
        return data;
      }

      data.isQualifier = true;
      data.value = data.value.toUpperCase(); // uppercase all qualifier values
      data.type = this.qualifierTypeNameMap.get(data.type);

      if (data.originDestination.startsWith('ORIG')) {
        data.originDestination = this.constants.ORIG;
      } else if (data.originDestination.startsWith('DEST')) {
        data.originDestination = this.constants.DEST;
      } else {
        data.errorMessage =
          'Invalid Origin/Destination: ' + data.originDestination + '! Must be one of "Origin" or "Destination"';
        return data;
      }

      if (data.includeExclude.startsWith('IN')) {
        data.includeExclude = this.constants.IN;
      } else if (data.includeExclude.startsWith('EX')) {
        data.includeExclude = this.constants.EX;
      } else {
        data.errorMessage =
          'Invalid Include/Exclude: ' + data.includeExclude + '! Must be one of "Include" or "Exclude"';
        return data;
      }

      if (data.type === 'STATE') {
        data.type = this.constants.STATE;
        if (_.filter(this.dynamicRule.STATE_VALUES, { code: data.value }).length === 0) {
          data.errorMessage = 'Invalid State: ' + data.value;
          return data;
        }
      } else if (data.type === 'SIC') {
        data.type = this.constants.SIC;
        if (_.filter(this.dynamicRule.SIC_VALUES, { code: data.value }).length === 0) {
          data.errorMessage = 'Invalid SIC: ' + data.value;
          return data;
        }
      } else if (data.type === 'COUNTRY') {
        data.type = this.constants.COUNTRY;
        if (data.value !== 'US' && data.value !== 'CN') {
          data.errorMessage = 'Invalid Country: ' + data.value + '! Must be one of "US" or "CN"';
          return data;
        }
      }
    } else if (this.dynamicRule.FIELD_NAMES_MAP.has(data.type)) {
      if (data.type === 'EFFECTIVE_DATE' || data.type === 'EXPIRATION_DATE') {
        if (new Date(data.value).toString().indexOf('Invalid') >= 0) {
          data.errorMessage = 'Type \'' + data.type + '\' must contain a valid date (\'MM/DD/YYYY\')!';
          return data;
        }
      } else if (data.type === 'RATE_TYPE') {
        const filteredCodes = _.filter(this.dynamicRule.RATE_TYPES, { name: data.value });
        if (filteredCodes.length === 0) {
          data.errorMessage =
            'Invalid Rate Type \'' +
            data.value +
            '\' must be one of: ' +
            _.join(_.map(this.dynamicRule.RATE_TYPES, 'name'), ', ');
          return data;
        } else {
          data.value = filteredCodes[0].code;
        }
      } else if (
        data.type === 'PERCENT_OFF_TOTAL_CHARGES' ||
        data.type === 'MINIMUM_O/R' ||
        data.type === 'MINIMUM_YIELD' ||
        data.type === 'MINIMUM_WEIGHT' ||
        data.type === 'MAXIMUM_WEIGHT'
      ) {
        if (!isNumeric(data.value)) {
          data.errorMessage = 'Type \'' + data.type + '\' must be a number!';
          return data;
        }
      } else if (
        data.type === 'TRAFFIC_TYPE_INTERSTATE' ||
        data.type === 'TRAFFIC_TYPE_INTRASTATE' ||
        data.type === 'TRAFFIC_TYPE_INTERNATIONAL' ||
        data.type === 'APPLICATION_TYPE_OB/PPD' ||
        data.type === 'APPLICATION_TYPE_OB/COL' ||
        data.type === 'APPLICATION_TYPE_IB/COL' ||
        data.type === 'APPLICATION_TYPE_THIRDPARTY'
      ) {
        if (data.value !== 'Y' && data.value !== 'N') {
          data.errorMessage = 'Type \'' + data.type + '\' must be either \'Y\' or \'N\'!';
          return data;
        }
      }
    } else {
      data.errorMessage = 'Invalid Type Column! Please refer to documentation on valid Type values."';
      return data;
    }

    return data;
  }

  processPendingUpload() {
    this.dynamicRule.clearSelectedLists(this.form);
    this.form.get('origQualifyBy').reset();
    this.form.get('destQualifyBy').reset();

    this.recordsToAdd.forEach((dynamicRuleUploadData: DynamicRuleUploadData) => {
      if (!dynamicRuleUploadData.errorMessage) {
        if (dynamicRuleUploadData.isQualifier) {
          this.dynamicRule.addItemToSelectedList(this.form, dynamicRuleUploadData);
        } else {
          this.dynamicRule.updateField(this.form, dynamicRuleUploadData.type, dynamicRuleUploadData.value);
        }
      }
    });
    this.fileToUpload = null;
    this.recordsToAdd = [];
    this.errorMessages = [];
    this.dialogRef.close();
  }
}
