import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Operators, XpoFilterColumnCriteria, XpoFilterCriteria } from '@xpo-ltl/ngx-ltl-board';
import { SpotParameter, SpotPremium, SpotQuote } from '@xpo-ltl/sdk-dynamicpricing';
import moment, { Moment } from 'moment';
import { SpotQuoteIdRendererComponent } from '../../../spot-quote/components/list/cell-render/spot-quote-id-renderer.component';
import { SpotQuoteListFieldNames } from '../../enums/field-names/spot-quote-list-field-names.enum';
import { SpotQuoteStatus } from '../../enums/spot-quote-status.enum';
import { SearchSpotQuote } from '../../models/search-spot-quote';
import { ConstantsService } from '../constants/constants.service';
import { FormatDateService } from '../format-date/format-date.service';

@Injectable({
  providedIn: 'root',
})
export class SpotQuoteService {
  constructor(
    private constantsService: ConstantsService,
    private formatDateService: FormatDateService,
    private decimalPipe: DecimalPipe
  ) {}

  createSpotQuoteForm(spotQuoteData?: SpotParameter): FormGroup {
    const backHaul = spotQuoteData.spotPremium.find((i: SpotPremium) => {
        return i.laneTypeCd === this.constantsService.BACK_HAUL;
      }),
      headHaul = spotQuoteData.spotPremium.find((i: SpotPremium) => {
        return i.laneTypeCd === this.constantsService.HEAD_HAUL;
      }),
      neutralHaul = spotQuoteData.spotPremium.find((i: SpotPremium) => {
        return i.laneTypeCd === this.constantsService.NEUTRAL;
      });

    return new FormGroup({
      sqMargin: new FormControl(spotQuoteData ? spotQuoteData.marginPercentage : ''),
      backHaulMargin: new FormControl(backHaul ? backHaul.marginPercentage : ''),
      headHaulMargin: new FormControl(headHaul ? headHaul.marginPercentage : ''),
      neutralMargin: new FormControl(neutralHaul ? neutralHaul.marginPercentage : ''),
      sqMinWt: new FormControl(spotQuoteData ? spotQuoteData.spotMinimumWeight : ''),
      sqMaxWt: new FormControl(spotQuoteData ? spotQuoteData.spotMaximumWeight : ''),
      sqNumDaysValid: new FormControl(spotQuoteData ? spotQuoteData.daysValidNbr : ''),
      costPerMile: new FormControl(spotQuoteData ? spotQuoteData.costPersonMileRate : ''),
      billingCost: new FormControl(spotQuoteData ? spotQuoteData.billingCostAmount : ''),
      dockPercentile: new FormControl(spotQuoteData ? spotQuoteData.docCostPercentage : ''),
      crossDockProb: new FormControl(spotQuoteData ? spotQuoteData.crossDockProbabilityNbr : ''),
      dockVolCuFt: new FormControl(spotQuoteData ? spotQuoteData.shipmentVolumeCubicFeet : ''),
      dockStartDate: new FormControl(spotQuoteData ? new Date(spotQuoteData.docCostRangeStartDate) : ''),
      dockEndDate: new FormControl(spotQuoteData ? new Date(spotQuoteData.docCostRangeEndDate) : ''),
      pdPercentile: new FormControl(spotQuoteData ? spotQuoteData.pndCostPercentage : ''),
      pdStartDate: new FormControl(spotQuoteData ? new Date(spotQuoteData.pndCostRangeStartDate) : ''),
      pdEndDate: new FormControl(spotQuoteData ? new Date(spotQuoteData.pndCostRangeEndDate) : ''),
      liabilityRatePerLb: new FormControl(spotQuoteData ? spotQuoteData.liabilityPersonLbRate : ''),
      claimsStartDate: new FormControl(spotQuoteData ? new Date(spotQuoteData.claimsStartDate) : ''),
      claimsEndDate: new FormControl(spotQuoteData ? new Date(spotQuoteData.claimsEndDate) : ''),
      effectiveDate: new FormControl(spotQuoteData ? spotQuoteData.effectiveDate : ''),
      expiryDate: new FormControl(spotQuoteData ? spotQuoteData.expiryDate : ''),
    });
  }

  saveSpotQuoteData(spotQuoteData: any) {
    return {
      dynSpotParameter: {
        costPersonMileRate: spotQuoteData.costPerMile,
        marginPercentage: spotQuoteData.sqMargin,
        spotMinimumWeight: spotQuoteData.sqMinWt,
        spotMaximumWeight: spotQuoteData.sqMaxWt,
        spotPremium: this.getSpotPremiumData(spotQuoteData),
        daysValidNbr: spotQuoteData.sqNumDaysValid,
        billingCostAmount: spotQuoteData.billingCost,
        docCostPercentage: spotQuoteData.dockPercentile,
        crossDockProbabilityNbr: spotQuoteData.crossDockProb,
        shipmentVolumeCubicFeet: spotQuoteData.dockVolCuFt,
        docCostRangeStartDate: spotQuoteData.dockStartDate,
        docCostRangeEndDate: spotQuoteData.dockEndDate,
        pndCostPercentage: spotQuoteData.pdPercentile,
        pndCostRangeStartDate: spotQuoteData.pdStartDate,
        pndCostRangeEndDate: spotQuoteData.pdEndDate,
        liabilityPersonLbRate: spotQuoteData.liabilityRatePerLb,
        claimsStartDate: spotQuoteData.claimsStartDate,
        claimsEndDate: spotQuoteData.claimsEndDate,
        effectiveDate: spotQuoteData.effectiveDate,
        expiryDate: spotQuoteData.expiryDate,
      },
    };
  }

  getSpotPremiumData(data) {
    return [
      {
        laneTypeCd: this.constantsService.BACK_HAUL,
        marginPercentage: data.backHaulMargin,
      },
      {
        laneTypeCd: this.constantsService.HEAD_HAUL,
        marginPercentage: data.headHaulMargin,
      },
      {
        laneTypeCd: this.constantsService.NEUTRAL,
        marginPercentage: data.neutralMargin,
      },
    ];
  }

  statusBasedOnData(currentDate: Moment, data: SpotQuote, adjustedChargeAmount: number) {
    const spotQteEffectiveDate = moment(data.spotQteEffectiveDate);
    const spotQteExpDate = moment(data.spotQteExpDate);

    switch (data.spotQteStatusCd) {
      case '1':
      case '2':
        if (
          currentDate.startOf('day').isSameOrAfter(spotQteEffectiveDate.startOf('day')) &&
          currentDate.startOf('day').isSameOrBefore(spotQteExpDate.startOf('day')) &&
          data.minimumQteAmount < adjustedChargeAmount &&
          data.maxQteAmount > adjustedChargeAmount &&
          data.totalChargeAmount >= adjustedChargeAmount
        ) {
          return SpotQuoteStatus.Opportunity;
        }
        return SpotQuoteStatus.Available;
      case '3':
        return SpotQuoteStatus.UsedForShipment;
      default:
        return 'Unknown';
    }
  }

  transformFiltersToXpoFilterCriteria(filterCriteria: SearchSpotQuote): XpoFilterCriteria {
    let result: XpoFilterCriteria;
    const transformerMap = {
      beginDate: {
        filterName: 'beginDate',
        mapTo: SpotQuoteListFieldNames.spotQteEffectiveDate,
        operator: Operators.Equals,
        type: 'date',
        custom: false,
      },
      endDate: {
        filterName: 'endDate',
        mapTo: SpotQuoteListFieldNames.spotQteExpDate,
        operator: Operators.Equals,
        type: 'date',
        custom: false,
      },
      spotQteNbr: {
        filterName: 'spotQteNbr',
        operator: Operators.Equals,
        type: 'string',
        custom: false,
      },
      originZip: {
        filterName: 'originZip',
        operator: Operators.Equals,
        type: 'string',
        custom: false,
      },
      customerInstanceId: {
        filterName: 'customerInstanceId',
        operator: Operators.Equals,
        type: 'string',
        custom: false,
      },
      salesAgentEmplid: {
        filterName: 'salesAgentEmplid',
        operator: Operators.Equals,
        type: 'string',
        custom: false,
      },
    };

    Object.keys(filterCriteria).forEach((fieldName: string) => {
      const transformer = transformerMap[fieldName];
      const filterValue = filterCriteria[fieldName];

      if (!transformer.custom && filterValue) {
        let value: any;

        switch (transformer.type) {
          case 'date':
            value = this.formatDateService.transformDateWithFormat(
              filterValue,
              this.constantsService.dateServiceFormat
            );
            break;

          case 'array':
            value = [filterValue];
            break;

          case 'string':
            value = filterValue.toString();
            break;
        }

        const filter: XpoFilterColumnCriteria = {
          conditions: [
            {
              operator: transformer.operator,
              value,
            },
          ],
        };

        const filterName = transformer.hasOwnProperty('mapTo') ? transformer.mapTo : transformer.filterName;
        result = {
          ...result,
          [filterName]: filter,
        };
      }
    });

    return result;
  }

  getColumnsForGrid() {
    const stringOperators = [
      Operators.Equals,
      Operators.NotEquals,
      Operators.Contains,
      Operators.NotContains,
      Operators.StartsWith,
      Operators.EndsWith,
      Operators.OneOf,
      Operators.Empty,
      Operators.NotEmpty,
    ];

    return [
      {
        headerName: 'Spot Quote Nbr',
        field: SpotQuoteListFieldNames.spotQteNbr,
        cellRendererFramework: SpotQuoteIdRendererComponent,
        width: 100,
        filter: 'xpoNumberFilterComponent',
        pinned: 'left',
      },
      {
        headerName: 'Request Date',
        field: SpotQuoteListFieldNames.spotQteRequestDate,
        width: 150,
        filter: 'xpoDateOnlyFilterComponent',
        valueGetter: (params: any) => {
          return params.data ? this.formatDateService.transformDate(params.data.spotQteRequestDate) : '';
        },
      },
      {
        headerName: 'Customer Instance Id',
        field: SpotQuoteListFieldNames.customerInstanceId,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Customer Code',
        field: SpotQuoteListFieldNames.customerCode,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Customer Name',
        field: SpotQuoteListFieldNames.customerName,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Sales Agent Id',
        field: SpotQuoteListFieldNames.salesAgentEmplid,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Sales Agent Name',
        field: SpotQuoteListFieldNames.salesAgentFullName,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Sales Agent Email',
        field: SpotQuoteListFieldNames.salesAgentEmailAddress,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Status',
        field: SpotQuoteListFieldNames.spotQteStatusCd,
        width: 100,
        filter: 'XpoEnumFilterComponent',
        filterParams: {
          enumValues: {
            [SpotQuoteStatus.Opportunity]: [SpotQuoteStatus.Opportunity],
            [SpotQuoteStatus.Available]: [SpotQuoteStatus.Available],
            [SpotQuoteStatus.UsedForShipment]: [SpotQuoteStatus.UsedForShipment],
          },
        },
      },
      {
        headerName: 'OZip',
        field: SpotQuoteListFieldNames.originZip,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'OSic',
        field: SpotQuoteListFieldNames.originSicCd,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'DZip',
        field: SpotQuoteListFieldNames.destinationZip,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'DSic',
        field: SpotQuoteListFieldNames.destinationSicCd,
        width: 150,
        filter: 'xpoStringFilterComponent',
        filterParams: {
          operators: stringOperators,
        },
      },
      {
        headerName: 'Effective Date',
        field: SpotQuoteListFieldNames.spotQteEffectiveDate,
        width: 150,
        filter: 'xpoDateOnlyFilterComponent',
        valueGetter: (params: any) => {
          return params.data ? this.formatDateService.transformDate(params.data.spotQteEffectiveDate) : '';
        },
      },
      {
        headerName: 'Expiry Date',
        field: SpotQuoteListFieldNames.spotQteExpDate,
        width: 150,
        filter: 'xpoDateOnlyFilterComponent',
        valueGetter: (params: any) => {
          return params.data ? this.formatDateService.transformDate(params.data.spotQteExpDate) : '';
        },
      },
      {
        headerName: 'SQ Charges',
        field: SpotQuoteListFieldNames.totalChargeAmount,
        width: 150,
        filter: 'xpoNumberFilterComponent',
        valueFormatter: (params) => this.decimalString(params.data.totalChargeAmount),
        type: 'numericColumn',
      },
      {
        headerName: 'SQ Min',
        field: SpotQuoteListFieldNames.minimumQteAmount,
        width: 150,
        filter: 'xpoNumberFilterComponent',
        valueFormatter: (params) => this.decimalString(params.data.minimumQteAmount),
        type: 'numericColumn',
      },
      {
        headerName: 'Class Charges',
        field: SpotQuoteListFieldNames.adjustedChargeAmount,
        width: 150,
        filter: 'xpoNumberFilterComponent',
        valueFormatter: (params) => this.decimalString(params.data.adjustedChargeAmount),
        type: 'numericColumn',
      },
    ];
  }

 decimalString(value: number, format: string = this.constantsService.DECIMAL_STRING_FORMAT_DEFAULT) {
    return this.decimalPipe.transform(value, format);
  }
}
