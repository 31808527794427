import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  XpoBoardApi,
  XpoBoardOptions,
  XpoBoardReadyEvent,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
} from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { formatChecker, InlineEditingValidators, XpoInlineEditingBase } from '@xpo-ltl/ngx-ltl-grid';
import { CellEditionErrorCellRendererComponent } from '@xpo-ltl/ngx-ltl-grid';
import { DynamicPriceStatusCd } from '@xpo-ltl/sdk-common';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { DecimalEditorComponent } from '../../../../lib/ag-grid/cell-editors/decimal-editor/decimal-editor.component';
import { NumericEditorComponent } from '../../../../lib/ag-grid/cell-editors/numeric-editor/numeric-editor.component';
import { StringEditorComponent } from '../../../../lib/ag-grid/cell-editors/string-editor/string-editor.component';
import { LaneTypeDiscountRangeModeEnum, LaneTypeDiscountRangeStatusEnum } from '../../../shared/enums';
import { LaneTypeDiscountRange } from '../../../shared/models';
import { LaneDiscountDataSource } from '../../../shared/services/lane-discount-data-source/lane-discount-data-source';
import { LaneDiscountService } from '../../../shared/services/lane-discount/lane-discount.service';
import { IconActionCellRendererComponent } from './cell-renderers';
import { NewPeriodModalComponent } from './new-period-modal/new-period-modal.component';

@Component({
  selector: 'app-dynamic-rule-lane-discounts',
  templateUrl: './dynamic-rule-lane-discounts.component.html',
  styleUrls: ['./dynamic-rule-lane-discounts.component.scss'],
})
export class DynamicRuleLaneDiscountsComponent extends XpoInlineEditingBase implements OnInit, OnDestroy {
  isEditing: boolean;
  private unsubscriber = new Unsubscriber();
  periods: Map<string, Array<any>> = new Map<string, Array<any>>();
  readonly LaneTypeDiscountRangeModeEnum = LaneTypeDiscountRangeModeEnum;
  readonly DynamicPriceStatusCd = DynamicPriceStatusCd;
  readonly LaneTypeDiscountRangeStatusEnum = LaneTypeDiscountRangeStatusEnum;

  currentPeriodSubject = new BehaviorSubject<LaneTypeDiscountRange>(null);
  currentPeriod$ = this.currentPeriodSubject.asObservable();

  private boardApi: XpoBoardApi;
  gridApi: GridApi;
  colApi: ColumnApi;
  gridData: any;
  keyField = 'discountSequenceNbr';
  customGridOptions: GridOptions;
  boardOptions: XpoBoardOptions = {
    preloadViewData: false,
    suppressRecordCounts: true,
    suppressGridSettingsPopover: true,
    suppressViewSwitcher: false,
  };
  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];
  currentViewId: string;
  currentPeriodHasVisibleDiscounts: boolean;
  currentDate: Date;
  canAddPeriod: boolean = false;
  canEditDiscount: boolean = false;
  canAddDiscount: boolean = false;
  canDeleteDiscount: boolean = false;
  hasPendingPeriod: boolean = false;
  currentMode: LaneTypeDiscountRangeModeEnum;

  @Input() editable: boolean;

  get currentPeriod() {
    return this.currentPeriodSubject.value;
  }

  get isCurrentPeriodEditable() {
    const hasInvalidDiscountData = this.laneDiscountService.hasInvalidDiscountData;
    return (
      !hasInvalidDiscountData &&
      ((this.currentPeriod && this.currentPeriod.isPending) ||
        (this.laneDiscountService.dynamicRule &&
          this.laneDiscountService.dynamicRule.statusCd === DynamicPriceStatusCd.PENDING))
    );
  }

  constructor(
    public controlContainer: ControlContainer,
    public dataSource: LaneDiscountDataSource,
    public laneDiscountService: LaneDiscountService,
    private confirmDialog: XpoConfirmDialog,
    public dialog: MatDialog
  ) {
    super();
  }

  setCurrentPeriod(periodId: string) {
    const period = this.laneDiscountService.getPeriod(periodId);
    this.currentMode = period.mode;
    this.currentPeriodSubject.next(period);
  }

  onChangeMode(newMode: LaneTypeDiscountRangeModeEnum) {
    this.changeCurrentPeriodMode(newMode);
  }

  ngOnInit() {
    this.currentDate = moment().toDate();
    this.customGridOptions = {
      enableCellTextSelection: true,
      pagination: false,
      singleClickEdit: true,
      frameworkComponents: {
        xpoStringFilterComponent: XpoStringFilterComponent,
        xpoNumberFilterComponent: XpoNumberFilterComponent,
        numericEditor: NumericEditorComponent,
        decimalEditor: DecimalEditorComponent,
        stringEditor: StringEditorComponent,
        cellEditionError: CellEditionErrorCellRendererComponent,
        iconActionCellRendererComponent: IconActionCellRendererComponent,
      },
      isRowSelectable: () => {
        return false;
      },
      defaultColDef: {
        suppressMenu: true,
        sortable: false,
        resizable: true,
        filter: false,
        editable: () => this.isCurrentPeriodEditable,
        cellRenderer: 'cellEditionError',
        cellRendererParams: (params) => {
          return {
            error: params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
          };
        },
        cellClassRules: {
          'xpo-AgGrid-editableCell--error': (params) =>
            params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
        },
        valueSetter: (params) => {
          this.handleValueSetter(params, this.keyField);
          return true;
        },
        tooltipValueGetter: (params) => {
          return params.data && this.getErrorDescription(params, params.colDef.headerTooltip);
        },
      },
      onDisplayedColumnsChanged: () => {
        if (this.dataSource && this.gridApi) {
          this.switchView();
        }
        this.loadActionButtonsVisibility();
      },
      onCellEditingStarted: () => {
        this.gridData = this.currentPeriod.data;
        this.onStartEditing();
      },
      onCellValueChanged: (event: any) => {
        if (event.newValue !== event.oldValue) {
          const change = {
            sequenceNumber: event.data.discountSequenceNbr,
            atts: {
              [event.colDef.field]: event.newValue || undefined,
            },
          };
          this.laneDiscountService.updateDiscount(change.sequenceNumber, change.atts);
        }
      },
      onCellEditingStopped: (params) => {
        if (params.colDef.field === 'externalRuleCdHeadHaul' && params.newValue !== params.oldValue) {
          const rowData = [];
          this.gridApi.forEachNode((node) => {
            rowData.push(node.data);
          });

          const newList = this.laneDiscountService.DiscountList;
          if (rowData.length > 1) {
            newList.map((list) => {
              list.data.map((discount) => {
                const found = rowData.find((node) => {
                  return (
                    node.effectiveDate === discount.effectiveDate &&
                    node.expiryDate === discount.expiryDate &&
                    node.discountSequenceNbr === discount.discountSequenceNbr
                  );
                });
                if (found) {
                  const wordLength = params.value.length - 1;
                  const reg = /\XSS/g;
                  const lastLetter = this.lastLetterIsString(params.value);
                  if (lastLetter) {
                    if (!this.lastLetterIsString(discount.externalRuleCdHeadHaul)) {
                      discount.externalRuleCdHeadHaul =
                        discount.externalRuleCdHeadHaul + params.value[params.value.length - 1];
                      discount.externalRuleCdBackHaul =
                        discount.externalRuleCdBackHaul + params.value[params.value.length - 1];
                      discount.externalRuleCdNeutral =
                        discount.externalRuleCdNeutral + params.value[params.value.length - 1];
                    } else {
                      discount.externalRuleCdHeadHaul =
                        discount.externalRuleCdHeadHaul.slice(0, discount.externalRuleCdHeadHaul.length - 1) +
                        lastLetter;
                      discount.externalRuleCdBackHaul =
                        discount.externalRuleCdBackHaul.slice(0, discount.externalRuleCdBackHaul.length - 1) +
                        lastLetter;
                      discount.externalRuleCdNeutral =
                        discount.externalRuleCdNeutral.slice(0, discount.externalRuleCdNeutral.length - 1) + lastLetter;
                    }
                  } else if (!lastLetter && this.lastLetterIsString(discount.externalRuleCdHeadHaul)) {
                    discount.externalRuleCdHeadHaul = discount.externalRuleCdHeadHaul.slice(
                      0,
                      discount.externalRuleCdHeadHaul.length - 1
                    );
                    discount.externalRuleCdBackHaul = discount.externalRuleCdBackHaul.slice(
                      0,
                      discount.externalRuleCdBackHaul.length - 1
                    );
                    discount.externalRuleCdNeutral = discount.externalRuleCdNeutral.slice(
                      0,
                      discount.externalRuleCdNeutral.length - 1
                    );
                  }
                }
                return discount;
              });
              return list;
            });
          }
          this.laneDiscountService.overrideList(newList);
        }
      },
    };
    this.loadActionButtonsVisibility();
    this.viewTemplates = this.getBoardViewTemplates();
    this.laneDiscountService.discountList$.subscribe((discounts) => {
      this.views = discounts.map((period) => {
        const viewTemplate = this.viewTemplates.find((template) => template.id === period.mode);
        return viewTemplate.createView({
          id: period.id,
          name: period.label,
          isActive: this.currentViewId && this.currentViewId === period.id ? true : false,
        });
      });
    });
  }

  lastLetterIsString(word): string {
    const pattern = /[0-9]/g;
    const wordLength = word.length - 1;
    const lastLetter = word[wordLength];
    if (lastLetter.match(pattern)) {
      return null;
    }
    return lastLetter;
  }

  loadActionButtonsVisibility(): void {
    if (this.editable) {
      const rule = this.laneDiscountService.dynamicRule;
      if (rule && this.currentPeriod) {
        const periodStatus =
          rule.statusCd !== DynamicPriceStatusCd.PENDING && this.currentPeriod
            ? this.currentPeriod.getStatus()
            : LaneTypeDiscountRangeStatusEnum.pending;
        const periodEditable = this.isCurrentPeriodEditable;
        const periodActive = periodStatus === LaneTypeDiscountRangeStatusEnum.active;
        this.canAddPeriod = periodActive;
        this.canEditDiscount = periodEditable;
        this.canDeleteDiscount = periodEditable;
        this.canAddDiscount = periodEditable;
      } else {
        this.canAddPeriod = false;
        this.canEditDiscount = this.isCurrentPeriodEditable;
        this.canDeleteDiscount = this.isCurrentPeriodEditable;
        this.canAddDiscount = true;
      }
    } else {
      this.canAddPeriod = false;
      this.canEditDiscount = false;
      this.canDeleteDiscount = false;
      this.canAddDiscount = false;
    }

    if (this.colApi) {
      this.showExpirationReason(!this.canAddDiscount);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onBoardReady(event: XpoBoardReadyEvent): void {
    this.boardApi = event.boardApi;
  }

  onGridReady(event: AgGridEvent) {
    this.gridApi = event.api;
    this.colApi = event.columnApi;
    const newState = this.dataSource.currentState;
    this.gridData = newState && newState.data && newState.data.consumerData;
    this.currentPeriodHasVisibleDiscounts = this.laneDiscountService.periodHasVisibleDiscounts(newState.viewId);
    if (newState.viewId !== this.currentViewId) {
      this.switchView();
      this.loadActionButtonsVisibility();
    }
  }

  switchView() {
    this.currentViewId = this.dataSource.currentState.viewId;
    this.setCurrentPeriod(this.currentViewId);
    this.hasPendingPeriod = this.laneDiscountService.hasPendingPeriod();
    if (this.isCurrentPeriodEditable) {
      this.editDiscounts();
    }
  }

  showExpirationReason(canAddDiscount) {
    setTimeout(() => {
      this.colApi.setColumnsVisible(['expirationReasonCopy'], canAddDiscount);
    }, 200);
  }

  getBoardViewTemplates(): Array<XpoAgGridBoardViewTemplate> {
    return [LaneTypeDiscountRangeModeEnum.simple, LaneTypeDiscountRangeModeEnum.advanced].map((mode) => {
      return new XpoAgGridBoardViewTemplate({
        id: mode,
        name: 'Lane Type Discount - '.concat(mode),
        keyField: this.keyField,
        availableColumns: this.getColumnsForGrid(mode),
      });
    });
  }

  getColumnsForGrid(mode: LaneTypeDiscountRangeModeEnum) {
    const discountPercentageValidator = (params: any) => {
      const isValidDiscount: formatChecker = {
        fail:
          isNaN(params.editedRow.columns[params.column]) ||
          parseFloat(params.editedRow.columns[params.column]) < 0 ||
          parseFloat(params.editedRow.columns[params.column]) > 1,
        errorDescription: 'Discount should be between 0 and 1',
      };
      return isValidDiscount;
    };
    const ruleCodeValidator = (params: any) => {
      const isInvalid: formatChecker = {
        fail: !params.editedRow.columns[params.column],
        errorDescription: 'Rule code missing',
      };
      return isInvalid;
    };
    const basic_columns = [
      {
        headerName: ' ',
        field: 'discountSequenceNbr',
        cellRenderer: 'iconActionCellRendererComponent',
        cellClassRules: {
          'xpo-AgGrid-editableCell': () => false,
        },
        cellRendererParams: () => {
          return {
            isExistingRule:
              this.laneDiscountService.dynamicRule && this.laneDiscountService.dynamicRule.priceRuleId ? true : false,
            canDelete: this.canDeleteDiscount,
          };
        },
        width: 70,
        pinned: 'left',
        editable: false,
      },
      {
        headerName: 'Min Weight',
        field: 'minimumWeight',
        width: 150,
        cellEditor: 'numericEditor',
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return InlineEditingValidators.isValidNumber(params);
          },
        },
      },
      {
        headerName: 'Max Weight',
        field: 'maximumWeight',
        width: 150,
        cellEditor: 'numericEditor',
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return InlineEditingValidators.isValidNumber(params);
          },
        },
      },
    ];
    const simple_columns = [
      {
        headerName: 'Rule Code',
        field: 'externalRuleCdHeadHaul',
        width: 150,
        cellEditor: 'stringEditor',
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return ruleCodeValidator(params);
          },
        },
      },
      {
        headerName: 'Discount',
        field: 'headHaulDiscountPercentage',
        width: 150,
        cellEditor: 'decimalEditor',
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return discountPercentageValidator(params);
          },
        },
      },
      {
        headerName: 'Expiration Reason',
        field: 'expirationReasonCopy',
        flex: true,
        hide: true,
      },
    ];
    const advanced_columns = [
      {
        headerName: 'Headhaul',
        children: [
          {
            headerName: 'Rule Code',
            field: 'externalRuleCdHeadHaul',
            width: 150,
            cellEditor: 'stringEditor',
            cellConfig: {
              allowEmpty: false,
              formatValidator: (params: any) => {
                return ruleCodeValidator(params);
              },
            },
          },
          {
            headerName: 'Discount',
            field: 'headHaulDiscountPercentage',
            width: 150,
            cellEditor: 'decimalEditor',
            cellConfig: {
              allowEmpty: false,
              formatValidator: (params: any) => discountPercentageValidator(params),
            },
          },
        ],
      },
      {
        headerName: 'Backhaul',
        children: [
          {
            headerName: 'Rule Code',
            field: 'externalRuleCdBackHaul',
            width: 150,
            cellEditor: 'stringEditor',

            cellConfig: {
              allowEmpty: false,
              formatValidator: (params: any) => {
                return ruleCodeValidator(params);
              },
            },
          },
          {
            headerName: 'Discount',
            field: 'backHaulDiscountPercentage',
            width: 150,
            cellEditor: 'decimalEditor',
            cellConfig: {
              allowEmpty: false,
              formatValidator: (params: any) => discountPercentageValidator(params),
            },
          },
        ],
      },
      {
        headerName: 'Neutral',
        children: [
          {
            headerName: 'Rule Code',
            field: 'externalRuleCdNeutral',
            width: 150,
            cellEditor: 'stringEditor',
            cellConfig: {
              allowEmpty: false,
              formatValidator: (params: any) => {
                return ruleCodeValidator(params);
              },
            },
          },
          {
            headerName: 'Discount',
            field: 'neutralDiscountPercentage',
            width: 150,
            cellEditor: 'decimalEditor',
            cellConfig: {
              allowEmpty: false,
              formatValidator: (params: any) => discountPercentageValidator(params),
            },
          },
        ],
      },
      {
        headerName: 'Expiration Reason',
        field: 'expirationReasonCopy',
        width: 250,
        hide: true,
      },
    ];
    if (mode === LaneTypeDiscountRangeModeEnum.advanced) {
      return [...basic_columns, ...advanced_columns];
    } else {
      return [...basic_columns, ...simple_columns];
    }
  }

  addDiscount(): void {
    this.laneDiscountService.addDiscountToPeriod(
      this.dataSource.currentState.viewId,
      this.laneDiscountService.discountListSubject.value[0]
    );
    this.dataSource.refresh();
  }

  editDiscounts() {
    super.onStartEditing();
    this.gridApi.refreshCells({
      force: true,
    });
  }

  changeCurrentPeriodMode(newMode: LaneTypeDiscountRangeModeEnum) {
    const period = this.currentPeriodSubject.value;
    if (
      newMode === LaneTypeDiscountRangeModeEnum.simple &&
      newMode !== period.mode &&
      period.getMode() === LaneTypeDiscountRangeModeEnum.advanced
    ) {
      const confirmConfig: XpoConfirmDialogConfig = {
        confirmButtonText: 'Change',
        rejectButtonText: 'Cancel',
        icon: 'warning',
      };
      this.confirmDialog
        .confirm(
          'Once the mode change, you will lose the unsaved data for backhaul and neutral lane types.',
          'Are you sure you want to change this period to simple mode?',
          confirmConfig
        )
        .subscribe((confirmed) => {
          if (confirmed) {
            this.laneDiscountService.updatePeriodMode(this.dataSource.currentState.viewId, newMode);
          } else {
            this.currentMode = LaneTypeDiscountRangeModeEnum.advanced;
          }
        });
    } else {
      this.laneDiscountService.updatePeriodMode(this.dataSource.currentState.viewId, newMode);
    }
  }

  onClickChangeMode(event: any) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  onAddNewPeriod() {
    this.dialog.open(NewPeriodModalComponent, { width: '600px' });
  }

  getTableSize(mode) {
    const base = mode === LaneTypeDiscountRangeModeEnum.advanced ? 185 : 135;
    return base + this.dataSource.currentState.visibleRows * 30;
  }
}
