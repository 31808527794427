import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AcOverrideDataSourceService } from '../../../../shared/services/accessorial-charge-override/ac-override-data-source-service';
import { AcOverrideService } from '../../../../shared/services/accessorial-charge-override/ac-override.service';

@Component({
  selector: 'app-ui-icon-action',
  templateUrl: './ui-icon-action.component.html',
  styleUrls: ['./ui-icon-action.component.scss'],
})
export class UiIconAcActionComponent implements ICellRendererAngularComp {
  params;
  showDelete: boolean;
  constructor(private acOverrideService: AcOverrideService, public dataSource: AcOverrideDataSourceService) {}

  agInit(params: any): void {
    this.params = params;
    this.showDelete = params.isExistingRule;
  }

  refresh(params: any): boolean {
    return false;
  }

  deleteAc() {
    const selectedNode = this.params.node;
    this.acOverrideService.deleteAc(selectedNode.data, this.params.value);
    this.dataSource.refresh();
  }
}
