import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { Code } from '@xpo-ltl/sdk-common';
import {
  BulkUpdateDynamicPricingRuleResp,
  DynamicPriceRuleDownload,
  ExportDynamicPricingRulesFileResp,
  ExportDynamicPricingRulesFileRqst,
  ExportDynamicPricingRulesResp,
  ExportDynamicPricingRulesRqst,
} from '@xpo-ltl/sdk-dynamicpricing';
import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import * as _ from 'lodash';
import { Papa } from 'ngx-papaparse';
import { retry } from 'rxjs/internal/operators';
import { ConstantsService } from '../constants/constants.service';
import { DynamicPricingService } from '../dynamic-pricing/dynamic-pricing.service';
import { FormatDateService } from '../format-date/format-date.service';
import { PricingWorkbenchService } from '../pricing-workbench/pricing-workbench.service';
@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  disableInd: boolean;
  disabledBySearchInd: boolean = true;
  pageSizeForExport: number;
  constructor(
    private dynamicPricingService: DynamicPricingService,
    private snackbar: XpoSnackBar,
    private constantsService: ConstantsService,
    private formatDate: FormatDateService,
    private papa: Papa,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {
    this.pricingWorkbenchService.getPricingCodes().subscribe((resp: PricingCode[]) => {
      this.pageSizeForExport = Number(
        _.find(resp, { subCategory: this.constantsService.EXPORT_RULES, code: this.constantsService.PAGE_SIZE }).value
      );
    });
  }

  generateCsv(fileName: string, data: any) {
    const csvData = this.papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    this.generateFile(url, fileName);
  }

  generateCsvFromString(fileName: string, data: string) {
    const url = 'data:text/csv;charset=utf-8,' + encodeURI(data);
    this.generateFile(url, fileName);
  }

  private generateFile(url: string, fileName: string) {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
  }

  downloadBySearch() {
    this.setDisabledBySearchInd(true);
    this.dynamicPricingService
      .getDynamicRulesBySearchForDownload()
      .subscribe((response: DynamicPriceRuleDownload[]) => {
        const records = response.map((record) => {
          return this.getFieldsForDownloadFile(record);
        });
        const fileName = `Dynamic_Rules - ${this.formatDate.getTimeStamp()}.csv`;
        this.generateCsv(fileName, records);
        this.setDisabledBySearchInd(false);
      });
  }

  exportDynamicPricingRules(request: ExportDynamicPricingRulesRqst): void {
    this.setDisabledBySearchInd(true);
    const totalRecords = [];
    const totalRecordsSize = request.pageSize,
      pageNum = request.pageNumber,
      totalPageNums = Math.ceil(totalRecordsSize / this.pageSizeForExport);
    this.batchDownloadRules(request, pageNum, totalPageNums, totalRecords);
  }

  private batchDownloadRules(request, pageNum, totalPageNums, totalRecords) {
    if (pageNum > totalPageNums) {
      const fileName = `Dynamic_Rules - ${this.formatDate.getTimeStamp()}.csv`;
      this.generateCsv(fileName, totalRecords);
      this.setDisabledBySearchInd(false);
      return;
    }
    request.pageSize = this.pageSizeForExport;
    request.pageNumber = pageNum;

    this.dynamicPricingService
      .exportDynamicPricingRules(request)
      .pipe(retry(3))
      .subscribe(
        (response: ExportDynamicPricingRulesResp) => {
          if (response && response.dynamicPriceRuleDownloads && response.dynamicPriceRuleDownloads.length) {
            totalRecords = totalRecords.concat(
              response.dynamicPriceRuleDownloads.map((record) => {
                return this.getFieldsForDownloadFile(record);
              })
            );
            pageNum++;
            this.batchDownloadRules(request, pageNum, totalPageNums, totalRecords);
          } else {
            this.snackbar.info('Records not found');
            this.setDisabledBySearchInd(false);
          }
        },
        (error) => {
          this.snackbar.error('Error downloading the file, please try again');
          this.setDisabledBySearchInd(false);
        }
      );
  }

  exportDynamicPricingRulesFile(request: ExportDynamicPricingRulesFileRqst) {
    this.setDisabledBySearchInd(true);
    const totalDataString = '',
      pageNum = request.pageNumber,
      lastPage = false;
    this.batchDownloadRulesFile(request, pageNum, lastPage, totalDataString);
  }

  private batchDownloadRulesFile(request, pageNum, lastPage, totalDataString) {
    if (lastPage) {
      const fileName = `Dynamic_Rule_Discounts - ${this.formatDate.getTimeStamp()}.csv`;
      this.generateCsvFromString(fileName, totalDataString);
      this.setDisabledBySearchInd(false);
      return;
    }
    request.pageSize = this.pageSizeForExport;
    request.pageNumber = pageNum;

    this.dynamicPricingService
      .exportDynamicPricingRulesFile(request)
      .pipe(retry(3))
      .subscribe(
        (response: ExportDynamicPricingRulesFileResp) => {
          if (response && response.content) {
            if (pageNum > 1) {
              response.content = response.content.substring(response.content.indexOf('\n') + 1);
            }
            totalDataString = totalDataString.concat(response.content);
            pageNum++;
            this.batchDownloadRulesFile(request, pageNum, lastPage, totalDataString);
          } else {
            lastPage = true;
            this.batchDownloadRulesFile(request, pageNum, lastPage, totalDataString);
            this.setDisabledBySearchInd(false);
          }
        },
        (error) => {
          console.error(error);
          this.snackbar.error('Error downloading the file, please try again');
          this.setDisabledBySearchInd(false);
        }
      );
  }

  getFieldsForDownloadFile(input: DynamicPriceRuleDownload) {
    const {
      priceRuleId,
      ruleDescription,
      effectiveDate,
      expiryDate,
      weightRanges,
      externalRuleCdHeadHaul,
      externalRuleCdBackHaul,
      externalRuleCdNeutral,
      discountPercentageHeadHaul,
      discountPercentageBackHaul,
      discountPercentageNeutral,
      rateTypeCd,
      minimumCommodityClassNbr,
      maximumCommodityClassNbr,
      headHaulLaneInd,
      backHaulLaneInd,
      neutralLaneInd,
      outboundPrepaidInd,
      outboundColInd,
      inboundPrepaidInd,
      bill2PrepaidInd,
      bill2ColInd,
      thirdPartyInd,
      minimumShipmentsNbr,
      interStateInd,
      intraStateInd,
      internationalInd,
      intraSicInd,
      ratingTariff,
      rulesTariff,
      minimumYieldAmount,
      minimumOperatingRatioNbr,
      correlationId,
      recordVersionNbr,
      customerLastShipmentInDays,
      laneLastShipmentInDays,
      statusCd,
      applicableTypeCd,
      logicalRuleId,
      ruleVersionNbr,
      agreementAcctId,
      agreementAcctCd,
      currentOwnerEmployeeId,
      designationType,
      laneId,
      applicationType,
      cdiRuleInd,
      customerGroupId,
      parentAcctId,
      locationOrigInclusions,
      locationOrigExclusions,
      locationDestInclusions,
      locationDestExclusions,
      countryOrigInclusions,
      countryOrigExclusions,
      countryDestInclusions,
      countryDestExclusions,
      stateOrigInclusions,
      stateOrigExclusions,
      stateDestInclusions,
      stateDestExclusions,
      zipCodeOrigInclusions,
      zipCodeOrigExclusions,
      zipCodeDestInclusions,
      zipCodeDestExclusions,
      sicOrigInclusions,
      sicOrigExclusions,
      sicDestInclusions,
      sicDestExclusions,
      regionOrigInclusions,
      regionOrigExclusions,
      regionDestInclusions,
      regionDestExclusions,
      districtOrigInclusions,
      districtOrigExclusions,
      districtDestInclusions,
      districtDestExclusions,
      thresholdAmount,
      thresholdPeriod,
      autoAdjustAllowedInd,
      marsInd,
      fakInd,
      trialPeriodTypeCd,
    } = input;
    return {
      priceRuleId,
      ruleDescription,
      effectiveDate: this.formatDate.transformDateWithFormat(effectiveDate, this.constantsService.dateServiceFormat),
      expiryDate: this.formatDate.transformDateWithFormat(expiryDate, this.constantsService.dateServiceFormat),
      weightRanges: ` ${weightRanges}`,
      externalRuleCdHeadHaul,
      externalRuleCdBackHaul,
      externalRuleCdNeutral,
      discountPercentageHeadHaul,
      discountPercentageBackHaul,
      discountPercentageNeutral,
      rateTypeCd,
      minimumCommodityClassNbr,
      maximumCommodityClassNbr,
      headHaulLaneInd,
      backHaulLaneInd,
      neutralLaneInd,
      outboundPrepaidInd,
      outboundColInd,
      inboundPrepaidInd,
      bill2PrepaidInd,
      bill2ColInd,
      thirdPartyInd,
      minimumShipmentsNbr,
      interStateInd,
      intraStateInd,
      internationalInd,
      intraSicInd,
      ratingTariff,
      rulesTariff,
      minimumYieldAmount,
      minimumOperatingRatioNbr,
      correlationId,
      recordVersionNbr,
      customerLastShipmentInDays,
      laneLastShipmentInDays,
      statusCd,
      applicableTypeCd,
      logicalRuleId,
      ruleVersionNbr,
      agreementAcctId,
      agreementAcctCd,
      currentOwnerEmployeeId,
      designationType,
      laneId,
      applicationType,
      cdiRuleInd,
      customerGroupId,
      parentAcctId,
      locationOrigInclusions,
      locationOrigExclusions,
      locationDestInclusions,
      locationDestExclusions,
      countryOrigInclusions,
      countryOrigExclusions,
      countryDestInclusions,
      countryDestExclusions,
      stateOrigInclusions,
      stateOrigExclusions,
      stateDestInclusions,
      stateDestExclusions,
      zipCodeOrigInclusions,
      zipCodeOrigExclusions,
      zipCodeDestInclusions,
      zipCodeDestExclusions,
      sicOrigInclusions,
      sicOrigExclusions,
      sicDestInclusions,
      sicDestExclusions,
      regionOrigInclusions,
      regionOrigExclusions,
      regionDestInclusions,
      regionDestExclusions,
      districtOrigInclusions,
      districtOrigExclusions,
      districtDestInclusions,
      districtDestExclusions,
      thresholdAmount,
      thresholdPeriod,
      autoAdjustAllowedInd,
      marsInd,
      fakInd,
      trialPeriodTypeCd,
    };
  }

  mapResponse(response: any) {
    response.forEach((element) => {
      Object.keys(element).forEach((data) => {
        if (typeof element[data] === 'object' && element[data]) {
          element[data].forEach((item) => {
            let first = '';
            Object.keys(item).forEach((internalKeys, index) => {
              if (!index) {
                first = item[internalKeys];
              } else {
                element[first + internalKeys] = item[internalKeys];
              }
            });
          });
          delete element[data];
        }
      });
    });
    return response;
  }

  getDisabledBySearchInd(): boolean {
    return this.disabledBySearchInd;
  }

  setDisabledBySearchInd(ind: boolean): void {
    this.disabledBySearchInd = ind;
  }
}
