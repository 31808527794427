import { AfterViewChecked, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'xpo-string-cell-editor',
  templateUrl: './string-editor.component.html',
  styleUrls: ['./string-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--string',
  },
})
export class StringEditorComponent implements ICellEditorAngularComp, AfterViewChecked {
  @ViewChild('input', { static: true }) input;
  params: any;
  value: string;
  maxLength: number;

  ngAfterViewChecked(): void {
    this.input.nativeElement.focus();
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.maxLength = this.params.maxLength;
  }

  getValue(): any {
    return this.value;
  }

  isCancelAfterEnd(): boolean {
    return this.value && this.value.toString().length > this.maxLength;
  }

  onFocusIn(): void {
    this.input.nativeElement.select();
  }

  onFocusOut(e): void {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }
}
