import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { AlertDialogComponent } from './alert/alert-dialog.component';

@NgModule({
  declarations: [AlertDialogComponent],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    XpoButtonModule,
    MatIconModule,
  ],
})
export class DialogModule {}
