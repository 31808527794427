import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ListInfo } from '@xpo-ltl/sdk-common';
import {
  CreateCustomerGroupResp,
  CreateCustomerGroupRqst,
  CustomerGroup,
  DeleteCustomerGroupPath,
  DownloadCustomerGroupsFileResp,
  DownloadCustomerGroupsFileRqst,
  DynamicPricingApiService,
  GetCustomerGroupPath,
  GetCustomerGroupQuery,
  ListCustomerGroupMembersPath,
  ListCustomerGroupMembersQuery,
  ListCustomerGroupMembersResp,
  ListCustomerGroupsQuery,
  ListCustomerGroupsResp,
  UpdateCustomerGroupRqst,
  UpsertCustomerGroupFileResp,
} from '@xpo-ltl/sdk-dynamicpricing';
import { CustomerHierarchyAccount } from '@xpo-ltl/sdk-pricingagmtmgmt';
import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ConstantsService } from '../constants/constants.service';
import { DownloadService } from '../download/download.service';
import { FormatDateService } from '../format-date/format-date.service';
import { PrcAgmtMgmtApiService } from '../pricing-agreement-management/prc-agmt-mgmt-api.service';
import { PricingWorkbenchService } from '../pricing-workbench/pricing-workbench.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersGroupsService {
  pageSizeForDownloadCustomerGroups;
  constructor(
    private dynamicPricingApiService: DynamicPricingApiService,
    private prcAgmtService: PrcAgmtMgmtApiService,
    private downloadService: DownloadService,
    private formatDate: FormatDateService,
    private constants: ConstantsService,
    private snackbar: XpoSnackBar,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {
    this.pricingWorkbenchService.getPricingCodes().subscribe((resp: PricingCode[]) => {
      this.pageSizeForDownloadCustomerGroups = Number(
        _.find(resp, { subCategory: this.constants.CUSTOMER_GROUPS, code: this.constants.PAGE_SIZE }).value
      );
    });
  }

  private hideCustomerDummyGroup(listCustomerGroupsResp: ListCustomerGroupsResp) {
    listCustomerGroupsResp.customerGroups = listCustomerGroupsResp.customerGroups.filter(
      (group) => group.customerGroupId !== 0
    );
    return listCustomerGroupsResp;
  }

  listCustomerGroups(query?: ListCustomerGroupsQuery): Observable<ListCustomerGroupsResp> {
    return this.dynamicPricingApiService.listCustomerGroups(query).pipe(map(this.hideCustomerDummyGroup));
  }

  getCustomersGroupsByName(name): Observable<CustomerGroup[]> {
    const queryParams = new ListCustomerGroupsQuery();
    queryParams.name = name;
    queryParams.listInfo = new ListInfo();
    queryParams.listInfo.numberOfRows = 500;
    return this.dynamicPricingApiService.listCustomerGroups(queryParams).pipe(
      map(this.hideCustomerDummyGroup),
      map((result) => result.customerGroups)
    );
  }

  downloadListCustomerGroups(request: DownloadCustomerGroupsFileRqst): void {
    const startAt = 0,
      pageIndex = 1,
      totalDataString = '';
    this.batchDownloadCustomerGroupsListFile(request, startAt, pageIndex, undefined, totalDataString);
  }

  private batchDownloadCustomerGroupsListFile(request, startAt, pageIndex, totalRecords, totalDataString) {
    console.log(startAt, totalRecords);
    if (startAt >= totalRecords) {
      console.log(startAt, totalRecords);
      const fileName = `Customers_group - ${this.formatDate.getTimeStamp()}.csv`;
      this.downloadService.generateCsvFromString(fileName, totalDataString);
      return;
    }
    request.listInfo = new ListInfo();
    request.listInfo.startAt = startAt;
    request.listInfo.numberOfRows = this.pageSizeForDownloadCustomerGroups;

    this.dynamicPricingApiService
      .downloadCustomerGroupsFile(request)
      .pipe(retry(3))
      .subscribe(
        (response: DownloadCustomerGroupsFileResp) => {
          if (response && response.content) {
            if (startAt > 0) {
              response.content = response.content.substring(response.content.indexOf('\n'));
            }
            totalDataString = totalDataString.concat(response.content);
            startAt = pageIndex * this.pageSizeForDownloadCustomerGroups + 1;
            pageIndex++;
            totalRecords = response.listInfo.totalRowCount;
            this.batchDownloadCustomerGroupsListFile(request, startAt, pageIndex, totalRecords, totalDataString);
          } else {
            this.snackbar.info('Customer Groups not found');
          }
        },
        (error) => {
          this.snackbar.error('Error downloading the file, please try again');
        }
      );
  }

  deleteCustomerGroup(customerGroupId: number): Observable<void> {
    const deleteCustomerGroupPath = new DeleteCustomerGroupPath();
    deleteCustomerGroupPath.customerGroupId = customerGroupId;
    return this.dynamicPricingApiService.deleteCustomerGroup(deleteCustomerGroupPath);
  }

  getCustomers(customerGroupId, startAt, madCd?): Observable<ListCustomerGroupMembersResp> {
    const getCustomerGroupPath = new ListCustomerGroupMembersPath();
    getCustomerGroupPath.customerGroupId = customerGroupId;
    const listCustomerGroupMembersQuery = new ListCustomerGroupMembersQuery();
    listCustomerGroupMembersQuery.listInfo = new ListInfo();
    listCustomerGroupMembersQuery.listInfo.startAt = startAt;
    listCustomerGroupMembersQuery.listInfo.numberOfRows = 2000;
    listCustomerGroupMembersQuery.madCd = madCd;
    return this.dynamicPricingApiService.listCustomerGroupMembers(getCustomerGroupPath, listCustomerGroupMembersQuery, {
      loadingOverlayEnabled: true,
    });
  }

  getCustomerGroup(customerGroupId, getByArray?): Observable<any> {
    const getCustomerGroupPath = new GetCustomerGroupPath();
    getCustomerGroupPath.customerGroupId = !!customerGroupId ? customerGroupId : null;
    const getCustomerGroupQuery = new GetCustomerGroupQuery();
    return this.dynamicPricingApiService.getCustomerGroup(getCustomerGroupPath, getCustomerGroupQuery).pipe(
      map((data: any) => {
        return getByArray ? [data.customerGroup] : data.customerGroup;
      })
    );
  }

  getCustomerDetails(madCds, instIds): Observable<CustomerHierarchyAccount[]> {
    return this.prcAgmtService
      .listCustomerHierarchy(madCds, instIds, { toastErrorMessage: 'Error to get customer data.' })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateCustomerGroup(customerGroup: CustomerGroup): Observable<CustomerGroup> {
    const updateCustomerGroupRqst = new UpdateCustomerGroupRqst();
    updateCustomerGroupRqst.customerGroup = customerGroup;
    return this.dynamicPricingApiService
      .updateCustomerGroup(updateCustomerGroupRqst)
      .pipe(map((data) => data.customerGroup));
  }

  createCustomerGroup(customerGroup): Observable<CreateCustomerGroupResp> {
    const createCustomerGroupRqst = new CreateCustomerGroupRqst();
    createCustomerGroupRqst.customerGroup = customerGroup;
    return this.dynamicPricingApiService.createCustomerGroup(createCustomerGroupRqst);
  }

  upsertCustomerGroupFile(request): Observable<UpsertCustomerGroupFileResp> {
    return this.dynamicPricingApiService.upsertCustomerGroupFile(request);
  }
}
