<div class="SelectCellEditorComponent">
  <mat-form-field class="SelectCellEditorComponent__form-field">
    <mat-label></mat-label>
    <input
      type="text"
      #matSelect
      placeholder="Pick one"
      aria-label="Number"
      matInput
      id="matSelect"
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      (selectionChange)="onEventSelection()"
      (closed)="onEventSelection()"
      autoActiveFirstOption
      #auto="matAutocomplete"
    >
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
        {{ option.key }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
