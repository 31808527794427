<div class="lane-discounts" [ngStyle]="{ 'height.px': getTableSize(currentMode) }">
  <xpo-board
    id="myUniqueId"
    [dataSource]="dataSource"
    [viewTemplates]="viewTemplates"
    [boardOptions]="boardOptions"
    [views]="views"
    (boardReady)="onBoardReady($event)"
  >
    <div class="action-bar" *ngIf="editable">
      <div class="left-bar">
        <div *ngIf="canEditDiscount">
          <mat-button-toggle-group xpoSmallButton [(value)]="currentMode" (change)="onChangeMode($event.value)">
            <mat-button-toggle [value]="LaneTypeDiscountRangeModeEnum.simple">
              Simple
            </mat-button-toggle>
            <mat-button-toggle [value]="LaneTypeDiscountRangeModeEnum.advanced">
              Advanced
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <span *ngIf="!canEditDiscount && currentPeriod && currentPeriod.isExpired" class="warn">
          This period cannot be edited since it has expired.
        </span>
        <div *ngIf="!canEditDiscount && currentPeriod && currentPeriod.isActive" class="warn">
          This period cannot be edited since it is in progress.
          <span *ngIf="!hasPendingPeriod">Please, add a new one.</span>
        </div>
      </div>
      <div class="right-bar">
        <!-- <xpo-button-group>
          <ng-template [ngIf]="isEditing" [ngIfElse]="noEditing">
            <button type="button" mat-stroked-button xpoSmallButton (click)="onConfirm()">
              Confirm
            </button>
            <button type="button" mat-stroked-button xpoSmallButton (click)="onCancel()">Cancel</button>
          </ng-template>
          <ng-template #noEditing>
            <button
              mat-stroked-button
              xpoSmallButton
              *ngIf="canEditDiscount && currentPeriod && currentPeriod.hasVisibleDiscounts"
              (click)="editDiscounts()"
            >
              Edit Discounts
            </button>
            <button type="button" *ngIf="canAddDiscount" mat-stroked-button xpoSmallButton (click)="addDiscount()">
              Add Discount
            </button>
          </ng-template>
        </xpo-button-group> -->
        <button type="button" *ngIf="canAddDiscount" mat-stroked-button xpoSmallButton (click)="addDiscount()">
          Add Discount
        </button>
        <button
          mat-stroked-button
          xpoSmallButton
          *ngIf="!canEditDiscount && canAddPeriod && currentPeriod && currentPeriod.isActive && !hasPendingPeriod"
          type="button"
          (click)="onAddNewPeriod()"
        >
          Add new period
        </button>
      </div>
    </div>

    <xpo-ag-grid-board
      rowSelection="multiple"
      enableMovableColumns="true"
      enableSorting="true"
      selectionMode="cell"
      [customGridOptions]="customGridOptions"
      (gridReady)="onGridReady($event)"
    >
    </xpo-ag-grid-board>
  </xpo-board>
</div>
