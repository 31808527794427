import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AmcOverrideDataSourceService } from '../../../../shared/services/absolute-minimum-charge-override/amc-override-data-source.service';
import { AmcOverrideService } from '../../../../shared/services/absolute-minimum-charge-override/amc-override.service';

@Component({
  selector: 'ui-icon-action-amc',
  templateUrl: './ui-icon-action.component.html',
  styleUrls: ['./ui-icon-action.component.scss'],
})
export class UiIconActionComponent implements ICellRendererAngularComp {
  params;
  showDelete: boolean;
  constructor(private amcOverrideService: AmcOverrideService, public dataSource: AmcOverrideDataSourceService) {}

  agInit(params: any): void {
    this.params = params;
    this.showDelete = params.isExistingRule;
  }

  refresh(params: any): boolean {
    return false;
  }

  deleteAmc() {
    const selectedNode = this.params.node;
    this.amcOverrideService.deleteAmc(selectedNode.data);
    this.dataSource.refresh();
  }
}
