import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ThresholdPeriodEmptyValidator(previousValue: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (previousValue && !control.value) {
      return {
        thresholdPeriodEmpty: true,
      };
    }

    return null;
  };
}
