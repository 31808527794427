export enum AccessorialChargeFieldLabels {
  accessorialChargeNbr = 'AC Nbr',
  versionNbr = 'Version Nbr',
  effectiveDate = 'Effective Date',
  expiryDate = 'Expiry Date',
  typeCd = 'Type',
  chargeAmount = 'Charge Amount',
  minAmt = 'Min Amount',
  maxAmt = 'Max Amount'
}
