<div xpoDialog class="app-new-period-modal">
  <h1 mat-dialog-title>
    Add new Period
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>

  <div mat-dialog-content>
    <form [formGroup]="formGroup" *ngIf="formGroup">
      <div class="row">
        <div class="col-xs-12 col-lg-4">
          <mat-form-field class="full-width">
            <input
              matInput
              [formControlName]="LaneDiscountFieldNames.effectiveDate"
              [placeholder]="LaneDiscountFieldLabels.effectiveDate"
              [matDatepicker]="effectiveDatePicker"
              [min]="minEffectiveDate"
              [max]="maxEffectiveDate"
            />
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker></mat-datepicker>
            <mat-error *ngIf="formGroup.get(LaneDiscountFieldNames.effectiveDate).invalid">
              {{ getErrorMessage(LaneDiscountFieldNames.effectiveDate) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-lg-4">
          <mat-form-field class="full-width">
            <input
              matInput
              [formControlName]="LaneDiscountFieldNames.ruleCode"
              [placeholder]="LaneDiscountFieldLabels.ruleCode"
            />
            <mat-error *ngIf="formGroup.get(LaneDiscountFieldNames.ruleCode).invalid">
              {{ getErrorMessage(LaneDiscountFieldNames.ruleCode) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-12 col-lg-4">
          <mat-form-field class="full-width">
            <input
              matInput
              [formControlName]="LaneDiscountFieldNames.ruleDiscount"
              [placeholder]="LaneDiscountFieldLabels.ruleDiscount"
              type="number"
            />
            <mat-error *ngIf="formGroup.get(LaneDiscountFieldNames.ruleDiscount).invalid">
              {{ getErrorMessage(LaneDiscountFieldNames.ruleDiscount) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <mat-form-field class="full-width">
            <textarea
              matInput
              rows="3"
              [formControlName]="LaneDiscountFieldNames.ruleDescription"
              [placeholder]="LaneDiscountFieldLabels.ruleDescription"
            ></textarea>
            <mat-error *ngIf="formGroup.get(LaneDiscountFieldNames.ruleDescription).invalid">
              {{ getErrorMessage(LaneDiscountFieldNames.ruleDescription) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-xs-12">
          <mat-form-field class="full-width">
            <textarea
              matInput
              rows="3"
              [formControlName]="LaneDiscountFieldNames.expirationReason"
              [placeholder]="LaneDiscountFieldLabels.expirationReason"
            ></textarea>
            <mat-error *ngIf="formGroup.get(LaneDiscountFieldNames.expirationReason).invalid">
              {{ getErrorMessage(LaneDiscountFieldNames.expirationReason) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div mat-dialog-actions>
    <button type="button" mat-button [mat-dialog-close]>Cancel</button>
    <button type="submit" mat-flat-button (click)="submit()" [disabled]="formGroup.invalid || submitting">
      Create
    </button>
  </div>
</div>
