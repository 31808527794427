export enum RuleListFieldNames {
  priceRuleId = 'priceRuleId',
  logicalRuleId = 'logicalRuleId',
  ruleVersionNbr = 'ruleVersionNbr',
  externalRuleId = 'externalRuleId',
  ruleDescription = 'ruleDescription',
  statusCd = 'statusCd',
  designationType = 'designationType',
  laneId = 'laneId',
  ruleRate = 'ruleRate',
  rateTypeCd = 'rateTypeCd',
  applicableTypeCd = 'applicableTypeCd',
  effectiveDate = 'effectiveDate',
  expiryDate = 'expiryDate',
  dynShipmentCountRateQuoteDateCount = 'dynShipmentCount.rateQuoteDateCount',
  dynShipmentCountRateQuoteDateAmount = 'dynShipmentCount.rateQuoteDateAmount',
  dynShipmentCountShipmentCount = 'dynShipmentCount.shipmentCount',
  dynShipmentCountShipmentAmount = 'dynShipmentCount.shipmentAmount',
  marsInd = 'marsInd',
  amcOverrideCd = 'amcOverrideCd',
  accessorialOverrideCd = 'accessorialOverrideCd',
  fakInd = 'fakInd',
  trialPeriodTypeCd = 'trialPeriodTypeCd',
  ruleTypeCd = 'ruleTypeCd',
  customerGroupId = 'customerGroupId'
}
