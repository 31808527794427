import { DynamicPriceStatusCd } from '@xpo-ltl/sdk-common';

export class SearchRule {
  effectiveDate: string;
  dynamicCode: string;
  acctMadCd: string;
  customerCode: string;
  statusCd: DynamicPriceStatusCd;
  ruleId: number;
  exactEffectiveDate: string;
  expiryDate: string;
  designationType: string;
  laneId: string;
  logicalRuleId: number;
  marsInd: boolean;
  overrideCd: string;
  fakInd: boolean;
  trialPeriodTypeCd: string;
}
