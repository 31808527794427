<button
  *ngIf="isVisible"
  xpoSmallButton
  mat-icon-button
  matTooltip="Delete"
  matTooltipPosition="above"
  type="button"
  (click)="deleteDiscount()"
>
  <mat-icon>delete</mat-icon>
</button>

<div
*ngIf="!isVisible && hasValue">
  {{params.rowIndex + 1}}
</div>
