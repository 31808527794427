import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'xpo-select-cell-editor',
  templateUrl: './select-cell-editor.component.html',
  styleUrls: ['./select-cell-editor.component.scss'],
})
export class SelectCellEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnInit {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) matSelect: any;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  params: ICellEditorParams;
  selected: any;
  options: any[];
  isOpen: boolean = false;
  registerCellChangeCallback: (params: ICellEditorParams) => any;

  constructor() {}

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.key.toLowerCase().includes(filterValue));
  }

  agInit(params: any) {
    const valueToShow = params.options ? params.options.find((o) => o.value === params.value) : params.value;
    this.myControl.setValue(valueToShow?.key);
    this.params = params;
    this.options = (params as any).options;
    this.registerCellChangeCallback = (params as any).registerCellChangeCallback || (() => undefined);
  }

  onEventSelection() {
    this.params.stopEditing();
    this.registerCellChangeCallback(this.params);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.matSelect.openPanel();
      document.getElementById('matSelect').focus();
    });
  }

  getValue(): any {
    return this.myControl.value;
  }
}
