import { Injectable } from '@angular/core';
import { User } from '@xpo-ltl/sdk-common';
import { AdminPersonnel, PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, retryWhen, skipWhile, switchMap } from 'rxjs/operators';
import { genericRetryStrategy } from '../../utils/rxjs-utils';
import { ConstantsService } from '../constants/constants.service';
import { PricingWorkbenchService } from '../pricing-workbench/pricing-workbench.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  userPermissions = new BehaviorSubject<Array<string>>(undefined);
  userPermissions$ = this.userPermissions.asObservable();

  constructor(
    private constants: ConstantsService,
    private userService: UserService,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {
    this.userService.user$.pipe(skipWhile((user) => !user)).subscribe((user: User) => {
      this.loadPermissions(user);
    });
  }

  loadPermissions(user: User) {
    this.getPermissions(user).subscribe((permissions) => {
      this.userPermissions.next(permissions);
    });
  }

  getPermissions(user: User): Observable<Array<string>> {
    return this.pricingWorkbenchService.getAnalyst(user.employeeId).pipe(
      switchMap((personnel: AdminPersonnel) => {
        const employeeRoleType = personnel.employeeRoleType ? personnel.employeeRoleType.trim() : '';
        const additionalRoleType = personnel.additionalRoleType ? personnel.additionalRoleType.trim() : '';
        const roles = [];
        if (employeeRoleType && !roles.includes(employeeRoleType)) {
          roles.push(employeeRoleType);
        }
        if (additionalRoleType) {
          additionalRoleType.split(',').forEach((additionalRole) => {
            const role = additionalRole ? additionalRole.trim() : '';
            if (role && !roles.includes(role)) {
              roles.push(role);
            }
          });
        }
        return this.pricingWorkbenchService.getPricingCodes().pipe(
          switchMap((response: PricingCode[]) => {
            const permissions = _.filter(response, (permission) => {
              return permission.category === this.constants.DYNAMIC_PRICING &&
                permission.subCategory === this.constants.SECURITY &&
                roles.includes(permission.code)
                ? true
                : false;
            }).map((permission: PricingCode) => permission.name);
            const result = [...new Set(permissions)];
            return of(result);
          })
        );
      }),
      retryWhen(
        genericRetryStrategy({
          scalingDuration: 2000,
          excludedStatusCodes: [404],
        })
      ),
      catchError(() => {
        return of([]);
      })
    );
  }

  hasPermission(permission: string): Observable<boolean> {
    if (this.userPermissions.value) {
      return of(this.userPermissions.value.includes(permission));
    } else {
      return this.userService.getUser().pipe(
        skipWhile((user) => !user),
        switchMap((user: User) => {
          return this.getPermissions(user).pipe(
            map((data) => {
              return data.includes(permission);
            })
          );
        })
      );
    }
  }
}
