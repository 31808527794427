import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AlertDialogComponent } from '../../../dialogs/alert/alert-dialog.component';
import { ErrorPipe } from '../../pipes/error/error.pipe';
import { ConstantsService } from '../constants/constants.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog, private constant: ConstantsService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(this.constant.InterceptorSkipHeader)) {
      const headers = req.headers.delete(this.constant.InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          /** Logic for good response */
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          try {
            const errorMsg = this.buildErrorMsg(err.error, err.status);
            if (errorMsg) {
              this.dialog.open(AlertDialogComponent, {
                data: { message: errorMsg },
              });
            }
          } catch (e) {
            console.error(err);
          }
        }
        // Need to change this to throwError so that we can catch the error in respective components where the APi's are being called
        // Will modify as part of Angular upgrade as this needs regression testing
        return of(err);
      })
    );
  }

  buildErrorMsg(data: any, status: number): string {
    return status !== 500 ? new ErrorPipe().transform(data) : '';
  }
}
