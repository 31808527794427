export enum SpotQuoteListFieldNames {
  spotQteNbr = 'spotQteNbr',
  spotQteRequestDate = 'spotQteRequestDate',
  customerInstanceId = 'customerInstanceId',
  customerCode = 'customerCode',
  customerName = 'customerName',
  spotQteStatusCd = 'spotQteStatusCd',
  originZip = 'originZip',
  originSicCd = 'originSicCd',
  destinationZip = 'destinationZip',
  destinationSicCd = 'destinationSicCd',
  spotQteEffectiveDate = 'spotQteEffectiveDate',
  spotQteExpDate = 'spotQteExpDate',
  totalChargeAmount = 'totalChargeAmount',
  minimumQteAmount = 'minimumQteAmount',
  salesAgentEmplid = 'salesAgentEmplid',
  salesAgentEmailAddress = 'salesAgentEmailAddress',
  salesAgentFullName = 'salesAgentFullName',
  adjustedChargeAmount = 'adjustedChargeAmount',
}
