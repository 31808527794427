import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoUpload, XpoUploadConfig } from '@xpo-ltl/ngx-ltl-core/upload';
import { throwError } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { DynamicRulesFileExportOperationEnum } from '../../shared/enums';
import { SnackbarStatus } from '../../shared/enums/snackbar-status.enum';
import { ErrorPipe } from '../../shared/pipes/error/error.pipe';
import { DynamicRuleService } from '../../shared/services/dr/dynamic-rule.service';
import { PricingWorkbenchService } from '../../shared/services/pricing-workbench/pricing-workbench.service';

@Component({
  selector: 'app-dynamic-rule-bulk-create',
  templateUrl: './dynamic-rule-bulk-create.component.html',
  styleUrls: ['./dynamic-rule-bulk-create.component.scss'],
})
export class DynamicRuleBulkCreateComponent implements OnInit {
  @ViewChild(XpoUpload, { static: true }) xpoUpload: XpoUpload;
  readonly UploadTypeEnum = DynamicRulesFileExportOperationEnum;
  // We'll toggle this from "Upload with Error" button
  throwError = false;
  processing = false;
  formGroup: FormGroup;
  // Set up the configuration object
  basicConfig: XpoUploadConfig = {
    // We don't want to upload files when dropped in for this example
    uploadFilesOnDrop: false,

    // allow only the following extensions
    accept: '.csv,.xls,.xlsx',
    // Once the file upload finishes sending all the content of the file
    onComplete: () => {
      setTimeout(() => {
        this.processing = false;
        this.basicConfig.clearUploaded();
        this.snackBar.success('File Upload Successful');
      }, 1000);
    },
    // When any error happens during the upload
    onError: (_file, err) => {
      this.processing = false;
      const errorMessage = err.status !== 500 ? new ErrorPipe().transform(err.error) : 'File Upload Failed';
      this.snackBar.open({
        message: 'Error',
        detailedMessage: errorMessage,
        status: SnackbarStatus.error,
      });
    },
    // Once user selects the desired file:
    onFilesChange: () => {},
    // Starts the uploading process
    uploadFile: (file: File) => {
      if (!this.processing) {
        this.processing = true;
        const request = (fileData: File) => {
          return this.dynamicRuleService.uploadDynamicPricingRulesFile(
            fileData,
            this.formGroup.get('uploadType').value
          );
        };
        // If you've set this.throwError to true we'll throw an error for simulation purposes.
        return this.throwError
          ? throwError('error message')
          : request(file).pipe(
              tap(() => {
                this.formGroup.reset();
              })
            );
      }
    },
  };

  get hasFiles(): boolean {
    return this.xpoUpload.files.length > 0;
  }

  constructor(
    private dynamicRuleService: DynamicRuleService,
    private snackBar: XpoSnackBar,
    private pwbService: PricingWorkbenchService
  ) {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      uploadType: new FormControl(null, [Validators.required]),
    });
    this.pwbService.getUploadRulesFileSize().subscribe((resp: number) => {
      this.basicConfig.maxUploadSizeMB = resp;
    });
  }
}
