import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { XpoGridAppliedFiltersModule, XpoGridFacetFilterModule, XpoNgxGridModule } from '@xpo-ltl/ngx-grid';
import { XpoBoardModule, XpoGridToolbarModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoCheckboxModule } from '@xpo-ltl/ngx-ltl-core/checkbox';
import { XpoCommonModule } from '@xpo-ltl/ngx-ltl-core/common';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoDatePickerModule } from '@xpo-ltl/ngx-ltl-core/datepicker';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoEmptyStateModule } from '@xpo-ltl/ngx-ltl-core/empty-state';
import { XpoFacetFilterModule } from '@xpo-ltl/ngx-ltl-core/facet-filter';
import { XpoFormFieldModule } from '@xpo-ltl/ngx-ltl-core/form-field';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { XpoTooltipModule } from '@xpo-ltl/ngx-ltl-core/tooltip';
import { XpoTrayModule } from '@xpo-ltl/ngx-ltl-core/tray';
import { XpoLtlPopoverModule } from '@xpo-ltl/ngx-ltl-core/xpo-ltl-popover';
import { AgGridModule } from 'ag-grid-angular';
import { PapaParseModule } from 'ngx-papaparse';
import { AcOverrideComponent } from '../../ac-override/ac-override.component';
import { AmcOverrideComponent } from '../../amc-override/amc-override.component';
import { DynamicRuleLaneDiscountsComponent } from '../../lane-discounts/dynamic-rule-lane-discounts.component';
import { DynamicRuleDetailComponent } from '../dynamic-rule-detail.component';

@NgModule({
  declarations: [
    DynamicRuleDetailComponent,
    AmcOverrideComponent,
    AcOverrideComponent,
    DynamicRuleLaneDiscountsComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    XpoCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    XpoAgGridBoardModule,
    XpoBoardModule,
    XpoIconModule,
    XpoButtonModule,
    XpoDatePickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatDialogModule,
    XpoSnackBarModule,
    PapaParseModule,
    XpoConfirmDialogModule,
    XpoDialogModule,
    AgGridModule,
    XpoCommonModule,
    XpoFormFieldModule,
    XpoCheckboxModule,
    XpoTabsModule,
    XpoEmptyStateModule,
    XpoTooltipModule,
    XpoLtlPopoverModule,
    MatTableModule,
    MatExpansionModule,
    XpoNgxGridModule,
    XpoFacetFilterModule,
    XpoGridFacetFilterModule,
    XpoGridToolbarModule,
    MatSidenavModule,
    XpoTrayModule,
    MatPaginatorModule,
    XpoGridAppliedFiltersModule,
  ],
  exports: [DynamicRuleDetailComponent],
})
export class DynamicRuleCreationModule {}
