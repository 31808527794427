<div class="amc-override-container" [ngStyle]="{ 'height.px': getTableSize() }">
  <xpo-board
    id="amcOverride"
    [dataSource]="dataSource"
    [viewTemplates]="viewTemplates"
    [boardOptions]="boardOptions"
    [views]="views"
    (boardReady)="onBoardReady($event)"
  >
    <xpo-board-actions>
      <button class="unfocus-button" id="unfocus-button" (click)="unfocus()"></button>
      <div class="left-bar">
        <button
          *ngIf="getDefaultAmcInd && editable"
          type="button"
          mat-stroked-button
          xpoSmallButton
          (click)="getDefaultAmc()"
        >
          Load Default AMC
        </button>
      </div>
      <xpo-button-group>
        <button
          type="button"
          mat-flat-button
          style="margin-left: 5px; margin-right: 5px;"
          (click)="deleteAmc()"
          *ngIf="!getDefaultAmcInd && editable"
          xpoSmallButton
        >
          Delete all AMC
        </button>
      </xpo-button-group>
    </xpo-board-actions>

    <xpo-ag-grid-board
      rowSelection="multiple"
      enableMovableColumns="true"
      enableSorting="true"
      selectionMode="cell"
      [customGridOptions]="customGridOptions"
      (gridReady)="onGridReady($event)"
    >
    </xpo-ag-grid-board>
  </xpo-board>
</div>
