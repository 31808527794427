import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DynamicRuleTrayService } from '../../../service/dynamic-rule-tray.service';

@Component({
  selector: 'app-discount-cell',
  templateUrl: './discount-cell.component.html',
  styleUrls: ['./discount-cell.component.scss'],
})
export class DiscountCellComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  ruleId: number;
  readOnly: boolean;
  discounts = [];
  constructor(private route: ActivatedRoute, private dynamicRuleTrayService: DynamicRuleTrayService) {}

  agInit(params: any): void {
    this.discounts = params.data.priceRuleDiscounts;
    this.ruleId = params.value;
    this.params = params;
  }

  getDiscountText() {
    if (this.discounts) {
      if (this.discounts.length > 1) {
        return null;
      } else {
        const sameDiscount =
          this.discounts[0].backHaulDiscountPercentage === this.discounts[0].headHaulDiscountPercentage &&
          this.discounts[0].backHaulDiscountPercentage === this.discounts[0].neutralDiscountPercentage;
        return sameDiscount
          ? this.discounts[0].backHaulDiscountPercentage
          : `${this.discounts[0].headHaulDiscountPercentage}/${this.discounts[0].backHaulDiscountPercentage}/${this.discounts[0].neutralDiscountPercentage}`;
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.ruleId = params.value;
    return true;
  }

  openDetail() {
    this.dynamicRuleTrayService.setDetailData({ showTray: true, ...this.params.data });
  }
}
