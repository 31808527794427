import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { isMoment, Moment } from 'moment';
import moment from 'moment-timezone';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class FormatDateService {
  constructor(private datePipe: DatePipe, private constants: ConstantsService) {}

  formattingDate(date) {
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 34, 56, 789)
    ).toISOString();
  }

  formatDatePicker(date) {
    return new Date(moment(date).format(this.constants.dateServiceFormat));
  }

  formatDatePickerString(date) {
    return new Date(
      moment(date)
        .tz(this.constants.DATE_FORMAT_MOMENT)
        .format()
    );
  }

  transformMoment(date: Moment) {
    return date.utc().format(this.constants.dateServiceFormat);
  }

  transformDate(date) {
    return this.transformDateWithFormat(date, this.constants.DATE_FORMAT_MOMENT);
  }

  transformDateWithFormat(date, format): string {
    let value = date;
    if (!(date instanceof Date) && !isMoment(date)) {
      value = isNaN(date) ? date : parseInt(date, 10);
    }
    return moment(value)
      .utc()
      .format(format);
  }

  transformDateTime(date) {
    return moment(date)
      .tz(this.constants.PST_TIMEZONE)
      .format(this.constants.DATE_FORMAT_TIME);
  }

  getTimeStamp() {
    const now = new Date(),
      year = now.getFullYear(),
      month = now.getMonth() + 1,
      date = now.getDate(),
      hour = now.getHours(),
      min = now.getMinutes(),
      sec = now.getSeconds();
    return year + '-' + month + '-' + date + '-' + hour + min + sec;
  }
}
