export enum AppRoutes {
  BLANK_SPACE = '',
  BULK_UPDATE = 'bulk-update',
  CREATE = 'create',
  CUSTOMER_EXCLUDE_LIST = 'list-customer-exclude',
  DASHBOARD = 'dashboard',
  DATA_ENTRY_PAGE = 'data-entry-page',
  DETAIL_PAGE = 'detail-page',
  DYNAMIC_PRICING = 'dynamic-pricing',
  DYNAMIC_PRICING_ADMIN_TAB = 'dynamic-pricing-admin-tab',
  DYNAMIC_RULE = 'dynamic-rule',
  DYNAMIC_RULE_BULK_CREATE = 'dynamic-rule-bulk-create',
  DYNAMIC_SHIPMENTS = 'dynamic-shipments',
  LANDING_PAGE = '',
  LANE_MAINTENANCE = 'lane-maintenance',
  LIST_PAGE = 'list-page',
  BY_CUSTOMER = 'by-customer',
  LIST_PAGE_CLASSIC = 'list-page-classic',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  RATE_QUOTE = 'rate-quote',
  RESULT_PAGE = 'result',
  ROOT = '/',
  SHIPMENT_VISIBILITY = 'shipment-visibility',
  SPOT_QUOTE = 'spot-quote',
  SPOT_QUOTE_PARAMETERS = 'parameters',
  UPDATE_PRICING_RULE = 'update-pricing-rule',
  PRICING_RULE = 'pricing-rule',
  ACCESSORIAL_CHARGE = 'accessorial-charge',
  ABSOLUTE_MINIMUM_CHARGE = 'absolute-minimum-charge',
  PRICING_LOCATION = 'pricing-location',
  RULE_SET_INFORMATION = 'rule-set',
  SELF_ADJUSTMENT_RULES = 'self-adjustment-rules',
  CUSTOMER_GROUP = 'customer-group',
  CREATE_OPP_RULE = 'create-dpt-rule',
  PRICING_DETAILS = 'pricing-details',
  RFP = 'rfp',
  PRICING_CODES = 'pricing-codes',
  DPT_DISCOUNT = 'dpt-discount',
  UPDATE = 'update',
}
