export enum CustomerExcludeFieldLabels {
  priceRuleCustomerExcludeId = 'ID',
  customerNbr = 'Customer Nbr',
  madCode = 'Customer Code',
  customerName = 'Customer Name',
  effectiveDate = 'Effective Date',
  expiryDate = 'Expiry Date',
  restrictionTypeCd = 'Customer Type',
  programType = 'Program Type',
  locationType = 'Location Type',
}
