import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicRulesFileExportOperationEnum } from '../../../../shared/enums';
@Component({
  selector: 'app-download-type',
  templateUrl: './download-type.component.html',
  styleUrls: ['./download-type.component.scss'],
})
export class DownloadTypeComponent implements OnInit {
  readonly RuleListFileFormat = DynamicRulesFileExportOperationEnum;
  onSelect: EventEmitter<DynamicRulesFileExportOperationEnum> = new EventEmitter(null);
  formGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<DownloadTypeComponent>) {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      fileFormat: new FormControl(DynamicRulesFileExportOperationEnum.DP_RULES_CREATE, [Validators.required]),
      onlySelectedRows: new FormControl(),
    });
  }

  submit() {
    this.onSelect.emit(this.formGroup.get('fileFormat').value);
    this.dialogRef.close();
  }
}
