<div class="app-dynamic-rule-detail" *ngIf="!pricingDetail">
  <xpo-card>
    <xpo-card-content>
      <form [formGroup]="dynamicRuleForm" *ngIf="dynamicRuleForm && dynamicRuleForm.controls">
        <div class="box">
          <div class="title">
            <h3>Rule Header</h3>
            <span *ngIf="showHeader()">
              <span class="app-dynamic-rule-detail__rule-header">Rule Id:</span> {{ dynamicRuleData.priceRuleId }}
              <span class="app-dynamic-rule-detail__rule-header">Logical Id:</span>{{ dynamicRuleData.logicalRuleId }}
              <span class="app-dynamic-rule-detail__rule-header">Version Number:</span>
              {{ dynamicRuleData.ruleVersionNbr }}
            </span>
          </div>
          <div class="row">
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
              <mat-form-field class="full-width">
                <mat-label>Rule Name</mat-label>
                <input matInput formControlName="dynamicRuleName" />
                <mat-error *ngIf="formControls.dynamicRuleName.invalid">
                  {{ getErrorMessage(formControls.dynamicRuleName) }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
              <mat-form-field class="full-width">
                <mat-label>Status</mat-label>
                <input matInput formControlName="status" />
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
              <mat-form-field class="full-width">
                <mat-label>Effective Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="effectiveDatePicker"
                  formControlName="effectiveDate"
                  [min]="dynamicRuleData?.statusCd === constantsService.ACTIVE ? null : minEffectiveDate"
                />
                <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #effectiveDatePicker></mat-datepicker>
                <mat-error *ngIf="formControls.effectiveDate.invalid">
                  {{ getErrorMessage(formControls.effectiveDate, 'effectiveDate') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2">
              <mat-form-field class="full-width">
                <mat-label>Expiry Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="expirationDatePicker"
                  formControlName="expiryDate"
                  [min]="minExpiryDate"
                />
                <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationDatePicker></mat-datepicker>
                <mat-error *ngIf="formControls.expiryDate.invalid">
                  {{ getErrorMessage(formControls.expiryDate, 'expiryDate') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2" *ngIf="editable">
              <mat-form-field class="full-width">
                <mat-label>Current Owner</mat-label>
                <input matInput type="text" disabled [value]="currentOwner.employeeFullName" />
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2 flex">
              <div class="flex align-center">
                <mat-label class="ng-star-inserted">Self Adjustment Enabled</mat-label>
                <mat-checkbox formControlName="autoAdjustAllowedInd"></mat-checkbox>
              </div>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2" *ngIf="formControls?.autoAdjustAllowedInd?.value">
              <mat-form-field class="full-width">
                <mat-label>Auto Adjust Group</mat-label>
                <mat-select formControlName="autoAdjustGroup">
                  <mat-option [value]="null">&nbsp;</mat-option>
                  <mat-option [value]="option.value" *ngFor="let option of dropDownOptions.autoAdjustGroups">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formControls.autoAdjustGroup.invalid">
                  {{ getErrorMessage(formControls.autoAdjustGroup) }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2 flex">
              <div class="flex align-center">
                <mat-label class="ng-star-inserted">MARS</mat-label>
                <mat-checkbox formControlName="marsInd"></mat-checkbox>
              </div>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2 flex" *ngIf="priceRuleId">
              <div class="flex align-center">
                <mat-label class="ng-star-inserted">FAK Rule</mat-label>
                <mat-checkbox formControlName="fakInd"></mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="box flex-column">
          <mat-expansion-panel [expanded]="!priceRuleId">
            <mat-expansion-panel-header>
              <mat-panel-title> Rule Discounts </mat-panel-title>
            </mat-expansion-panel-header>
            <xpo-empty-state *ngIf="dynamicRuleData && !!(hasInvalidDiscountData$ | async)">
              <xpo-empty-state-icon>
                <mat-icon>error</mat-icon>
              </xpo-empty-state-icon>
              <xpo-empty-state-header>Discounts data corrupted</xpo-empty-state-header>
              <xpo-empty-state-description>
                Please, contact IT to fix it.
              </xpo-empty-state-description>
            </xpo-empty-state>
            <app-dynamic-rule-lane-discounts [editable]="editable"> </app-dynamic-rule-lane-discounts>
          </mat-expansion-panel>
        </div>

        <div class="box flex-column">
          <mat-expansion-panel [expanded]="!priceRuleId">
            <mat-expansion-panel-header>
              <mat-panel-title> Absolute Minimum Charges</mat-panel-title>
              <mat-panel-description> Override </mat-panel-description>
            </mat-expansion-panel-header>
            <app-amc-override [ruleId]="priceRuleId" [editable]="enabledOverrides()"></app-amc-override>
          </mat-expansion-panel>
        </div>

        <div class="box flex-column">
          <mat-expansion-panel [expanded]="!priceRuleId">
            <mat-expansion-panel-header>
              <mat-panel-title> Accessorial Charges </mat-panel-title>
              <mat-panel-description> Override </mat-panel-description>
            </mat-expansion-panel-header>
            <app-ac-override [ruleId]="priceRuleId" [editable]="enabledOverrides()"></app-ac-override>
          </mat-expansion-panel>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="box full-width">
              <h3>Rating Information</h3>
              <div class="row">
                <div *ngIf="false">
                  <mat-label class="ng-star-inserted">CDI</mat-label>
                  <mat-checkbox formControlName="cdiRuleInd"></mat-checkbox>
                </div>
                <div class="col-xs-6 col-sm-3">
                  <mat-form-field class="full-width">
                    <mat-label>Rule Type</mat-label>
                    <mat-select formControlName="ruleTypeCd">
                      <mat-option [value]="option.code" *ngFor="let option of dropDownOptions.ruleTypes">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formControls.ruleTypeCd.invalid">
                      {{ getErrorMessage(formControls.ruleTypeCd) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6 col-sm-3">
                  <mat-form-field class="full-width">
                    <mat-label>Rate Type</mat-label>
                    <mat-select formControlName="rateType">
                      <mat-option [value]="option.code" *ngFor="let option of dropDownOptions.rateTypes">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formControls.rateType.invalid">
                      {{ getErrorMessage(formControls.rateType) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6 col-sm-3">
                  <mat-form-field class="full-width">
                    <mat-label>Trial Period</mat-label>
                    <mat-select formControlName="trialPeriodTypeCd">
                      <ng-container *ngFor="let option of dropDownOptions.trialPeriod">
                        <mat-option
                          [value]="option.code"
                          *ngIf="
                            !(option.code === 'NONE' && formControls.applicableType.value === constantsService.OPP)
                          "
                        >
                          {{ option.name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="formControls.trialPeriodTypeCd.invalid">
                      {{ getErrorMessage(formControls.trialPeriodTypeCd) }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6 col-sm-3">
                  <mat-form-field class="full-width">
                    <mat-label>Rating Tariff</mat-label>
                    <input matInput formControlName="ratingTariff" type="text" class="uppercase" />
                    <mat-error *ngIf="formControls.ratingTariff.invalid">
                      {{ getErrorMessage(formControls.ratingTariff, 'ratingTariff') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6 col-sm-3"></div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-7">
            <div class="box full-width">
              <h3>Rule Details (optional)</h3>
              <div class="row">
                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-3">
                  <mat-form-field class="full-width">
                    <mat-label>Designation Type</mat-label>
                    <input matInput formControlName="designationType" type="text" />
                    <mat-error *ngIf="formControls.designationType.invalid">
                      {{ getErrorMessage(formControls.designationType, 'designationType') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-3">
                  <mat-form-field class="full-width">
                    <mat-label>Lane</mat-label>
                    <input matInput formControlName="laneId" type="text" />
                    <mat-error *ngIf="formControls.laneId.invalid">
                      {{ getErrorMessage(formControls.laneId, 'laneId') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-3">
                  <mat-form-field class="full-width">
                    <mat-label>Applicable Type</mat-label>
                    <mat-select formControlName="applicableType">
                      <mat-option [value]="null">&nbsp;</mat-option>
                      <mat-option [value]="option.code" *ngFor="let option of dropDownOptions.applicableType">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xs-6 col-sm-4 col-md-4 col-lg-3">
                  <mat-form-field class="full-width">
                    <mat-label>Agreement Acc.</mat-label>
                    <input matInput formControlName="agreementAcctCd" />
                    <mat-error *ngIf="formControls.agreementAcctCd.invalid">
                      {{ getErrorMessage(formControls.agreementAcctCd, 'agreementAcctCd') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="box full-width">
              <h3>Class Range</h3>
              <div class="row">
                <div class="col-xs-6">
                  <mat-form-field class="full-width">
                    <mat-label>Minimum</mat-label>
                    <input matInput formControlName="minClass" type="number" />
                    <mat-error *ngIf="formControls.minClass.invalid">
                      {{ getErrorMessage(formControls.minClass, 'minClass') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6">
                  <mat-form-field class="full-width">
                    <mat-label>Maximum</mat-label>
                    <input matInput formControlName="maxClass" type="number" />
                    <mat-error *ngIf="formControls.maxClass.invalid">
                      {{ getErrorMessage(formControls.maxClass, 'maxClass') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="box full-width">
              <h3>Thresholds</h3>
              <div class="row">
                <div class="col-xs-6">
                  <mat-form-field class="full-width">
                    <mat-label>Period</mat-label>
                    <mat-select formControlName="thresholdPeriod">
                      <mat-option [value]="null">&nbsp;</mat-option>
                      <mat-option [value]="option" *ngFor="let option of thresholdPeriodOptions">
                        {{ option }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formControls.thresholdPeriod.invalid">
                      {{ getErrorMessage(formControls.thresholdPeriod, 'thresholdPeriod') }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6">
                  <mat-form-field class="full-width">
                    <mat-label>Amount</mat-label>
                    <input matInput formControlName="thresholdAmount" type="number" />
                    <mat-error *ngIf="formControls.thresholdAmount.invalid">{{
                      getErrorMessage(formControls.thresholdAmount, 'thresholdAmount')
                    }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xs-6">
                  <mat-form-field class="full-width">
                    <mat-label>Min. Shipments</mat-label>
                    <input matInput formControlName="minimumShipmentsNbr" type="number" />
                    <mat-error *ngIf="formControls.minimumShipmentsNbr.invalid">{{
                      getErrorMessage(formControls.minimumShipmentsNbr, 'minimumShipmentsNbr')
                    }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="box full-width">
              <h3>Traffic Type</h3>
              <div class="row" formGroupName="trafficType">
                <div class="col-xs-12 col-sm-6" *ngFor="let type of trafficTypes">
                  <mat-checkbox formControlName="{{ type.formControl }}">
                    {{ type.name }}
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" *ngIf="formControls.trafficType.invalid">
                <div class="col-xs-12">
                  <mat-error>
                    At least one checkbox is required for Traffic Type
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-lg-3">
            <div class="box full-width">
              <h3>Application Type</h3>
              <div class="row" formGroupName="applicationType">
                <div class="col-xs-12 col-sm-6" *ngFor="let type of applicationTypes">
                  <mat-checkbox formControlName="{{ type.formControl }}">
                    {{ type.name }}
                  </mat-checkbox>
                </div>
              </div>
              <div class="row" *ngIf="formControls.applicationType.invalid">
                <div class="col-xs-12">
                  <mat-error>
                    At least one checkbox is required for Application Type
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <h3>Customer Group</h3>
          <div fxLayout="row">
            <mat-form-field class="customer-group-field">
              <input
                type="text"
                aria-label="Customer group"
                matInput
                formControlName="customerGroupId"
                [matAutocomplete]="auto"
              />

              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                [displayWith]="getName.bind(this)"
                (optionSelected)="ValidateCustomerGroup()"
              >
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let option of filteredOptions" [value]="option.customerGroupId">
                    {{ option.name }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>

              <mat-error
                *ngIf="formControls.customerGroupId.errors && formControls.customerGroupId.errors.invalidLocation"
              >
                Remove include location on Origin or Destination qualify.
              </mat-error>
              <mat-error *ngIf="formControls.customerGroupId.errors && formControls.customerGroupId.errors.incorrect">
                Select a valid customer group.
              </mat-error>
              <mat-hint>
                if you select customer group, you cannot include a location in Origin or Destination Qualify.
              </mat-hint>
            </mat-form-field>

            <span
              class="clear-customer-group"
              *ngIf="!!dynamicRuleForm.value.customerGroupId && editable"
              (click)="clearCustomerGroup()"
            >
              clear
            </span>
          </div>
        </div>

        <div class="box">
          <h3>Stop Loss (optional)</h3>
          <div class="row visible-sm">
            <div class="col-xs-12 flex align-center">
              <span>Customer has not shipped in (days)</span>
              <mat-form-field class="margin-left-10">
                <input matInput formControlName="customerShipmentDays" type="number" />
                <mat-error *ngIf="formControls.customerShipmentDays.invalid">
                  {{ getErrorMessage(formControls.customerShipmentDays, 'customerShipmentDays') }}
                </mat-error>
              </mat-form-field>
              <span class="margin-left-10">or there has been no shipments in this leg for the last(days)</span>
              <mat-form-field class="margin-left-10">
                <input matInput formControlName="laneShipmentDays" type="number" />
                <mat-error *ngIf="formControls.laneShipmentDays.invalid">
                  {{ getErrorMessage(formControls.laneShipmentDays, 'laneShipmentDays') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row visible-xs">
            <div class="col-xs-6">
              <span>Customer has not shipped in (days)</span>
            </div>
            <div class="col-xs-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="customerShipmentDays" type="number" />
                <mat-error *ngIf="formControls.customerShipmentDays.invalid">
                  {{ getErrorMessage(formControls.customerShipmentDays, 'customerShipmentDays') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-6">
              <span>or there has been no shipments in this leg for the last(days)</span>
            </div>
            <div class="col-xs-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="laneShipmentDays" type="number" />
                <mat-error *ngIf="formControls.laneShipmentDays.invalid">
                  {{ getErrorMessage(formControls.laneShipmentDays, 'laneShipmentDays') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="box full-width">
              <h3>Origin Qualify</h3>
              <div class="row">
                <div class="col-xs-12">
                  <mat-form-field>
                    <mat-label>From</mat-label>
                    <mat-select formControlName="origQualifyBy">
                      <mat-option [value]="option.code" *ngFor="let option of dropDownOptions.qualifyByOptions">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-lg-6">
                  <div *ngIf="dynamicRuleForm.value.origQualifyBy" class="app-dynamic-rule-detail__options-border">
                    <div fxLayout="row" fxLayoutGap="30px">
                      <div>Origin {{ dynamicRuleForm.value.origQualifyBy }} available</div>
                      <mat-radio-group aria-label="Select an option" formControlName="origInEx">
                        <mat-radio-button
                          [value]="constantsService.IN"
                          [disabled]="
                            !!dynamicRuleForm.value.customerGroupId &&
                            dynamicRuleForm.value.origQualifyBy === constantsService.LOCATION
                          "
                          >Include</mat-radio-button
                        >
                        <mat-radio-button [value]="constantsService.EX">Exclude</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div *ngIf="showInputOption(constantsService.ORIG)">
                      <mat-form-field>
                        <input matInput formControlName="origInput" />
                      </mat-form-field>
                    </div>
                    <div *ngIf="showZipOption(constantsService.ORIG)">
                      <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex="30">
                          <input matInput formControlName="origLowZip" />
                          <mat-error *ngIf="formControls.origLowZip.invalid">
                            {{ getErrorMessage(formControls.origLowZip) }}
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="30">
                          <input matInput formControlName="origHighZip" />
                          <mat-error *ngIf="formControls.origHighZip.invalid">
                            {{ getErrorMessage(formControls.origHighZip) }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div
                      *ngIf="showCheckBoxOption(constantsService.ORIG)"
                      class="app-dynamic-rule-detail__options-align"
                    >
                      <div formArrayName="origOptionList">
                        <div
                          *ngFor="let option of dynamicRuleForm.controls.origOptionList['controls']; let i = index"
                          [formGroupName]="i"
                        >
                          <mat-checkbox class="example-margin" formControlName="selected">
                            {{ option.value.name }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!dynamicRuleForm.value.origQualifyBy" class="app-dynamic-rule-detail__options-border">
                    Please, select an option
                  </div>
                </div>
                <div class="col-xs-12 col-lg-1">
                  <div class="arrow-buttons">
                    <button
                      type="button"
                      mat-icon-button
                      [disabled]="disableForwardBtn(constantsService.ORIG)"
                      (click)="addToSelectedList(constantsService.ORIG)"
                    >
                      <xpo-icon iconName="arrow-forward" class="arrow-forward"></xpo-icon>
                    </button>
                    <button
                      type="button"
                      mat-icon-button
                      [disabled]="disableBackBtn(constantsService.ORIG)"
                      (click)="removeFromSelectedList(constantsService.ORIG)"
                    >
                      <xpo-icon iconName="arrow-back" class="arrow-back"></xpo-icon>
                    </button>
                  </div>
                </div>
                <div class="col-xs-12 col-lg-5">
                  <div class="app-dynamic-rule-detail__options-border">
                    <div>Origin Selected</div>
                    <mat-error *ngIf="formControls.origSelectedList.invalid">
                      {{ getErrorMessage(formControls.origSelectedList) }}
                    </mat-error>
                    <div formArrayName="origSelectedList" class="app-dynamic-rule-detail__options-align">
                      <div
                        class="app-dynamic-rule-detail__text-wrap"
                        *ngFor="let option of dynamicRuleForm.controls.origSelectedList['controls']; let i = index"
                        [formGroupName]="i"
                        fxLayout="row"
                        fxLayoutGap="5%"
                      >
                        <mat-checkbox fxFlex="5" class="example-margin" formControlName="selected"> </mat-checkbox>
                        <div fxFlex="50">{{ displaySelectedValue(option.value, constantsService.ORIG) }}</div>
                        <div fxFlex="25">{{ option.value.qualifyCd }}</div>
                        <div fxFlex="10">{{ option.value.inExValue }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-md-6">
            <div class="box full-width">
              <h3>Destination Qualify</h3>
              <div class="row">
                <div class="col-xs-12">
                  <mat-form-field>
                    <mat-label>To</mat-label>
                    <mat-select formControlName="destQualifyBy">
                      <mat-option [value]="option.code" *ngFor="let option of dropDownOptions.qualifyByOptions">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-lg-6">
                  <div *ngIf="dynamicRuleForm.value.destQualifyBy" class="app-dynamic-rule-detail__options-border">
                    <div fxLayout="row" fxLayoutGap="30px">
                      <div>Destination {{ dynamicRuleForm.value.destQualifyBy }} available</div>
                      <mat-radio-group aria-label="Select an option" formControlName="destInEx">
                        <mat-radio-button
                          [value]="constantsService.IN"
                          [disabled]="
                            !!dynamicRuleForm.value.customerGroupId &&
                            dynamicRuleForm.value.destQualifyBy === constantsService.LOCATION
                          "
                          >Include</mat-radio-button
                        >
                        <mat-radio-button [value]="constantsService.EX">Exclude</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div *ngIf="showInputOption(constantsService.DEST)">
                      <mat-form-field>
                        <input matInput formControlName="destInput" />
                      </mat-form-field>
                    </div>
                    <div *ngIf="showZipOption(constantsService.DEST)">
                      <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field fxFlex="30">
                          <input matInput formControlName="destLowZip" />
                          <mat-error *ngIf="formControls.destLowZip.invalid">
                            {{ getErrorMessage(formControls.destLowZip) }}
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="30">
                          <input matInput formControlName="destHighZip" />
                          <mat-error *ngIf="formControls.destHighZip.invalid">
                            {{ getErrorMessage(formControls.destHighZip) }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div
                      *ngIf="showCheckBoxOption(constantsService.DEST)"
                      class="app-dynamic-rule-detail__options-align"
                    >
                      <div formArrayName="destOptionList">
                        <div
                          *ngFor="let option of dynamicRuleForm.controls.destOptionList['controls']; let i = index"
                          [formGroupName]="i"
                        >
                          <mat-checkbox class="example-margin" formControlName="selected">
                            {{ option.value.name }}
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!dynamicRuleForm.value.destQualifyBy" class="app-dynamic-rule-detail__options-border">
                    Please, select an option
                  </div>
                </div>
                <div class="col-xs-12 col-lg-1">
                  <div class="arrow-buttons">
                    <button
                      type="button"
                      mat-icon-button
                      [disabled]="disableForwardBtn(constantsService.DEST)"
                      (click)="addToSelectedList(constantsService.DEST)"
                    >
                      <xpo-icon iconName="arrow-forward" class="arrow-forward"></xpo-icon>
                    </button>
                    <button
                      type="button"
                      mat-icon-button
                      [disabled]="disableBackBtn(constantsService.DEST)"
                      (click)="removeFromSelectedList(constantsService.DEST)"
                    >
                      <xpo-icon iconName="arrow-back" class="arrow-back"></xpo-icon>
                    </button>
                  </div>
                </div>
                <div class="col-xs-12 col-lg-5">
                  <div class="app-dynamic-rule-detail__options-border">
                    <div>Destination Selected</div>
                    <mat-error *ngIf="formControls.destSelectedList.invalid">
                      {{ getErrorMessage(formControls.destSelectedList) }}
                    </mat-error>
                    <div formArrayName="destSelectedList" class="app-dynamic-rule-detail__options-align">
                      <div
                        class="app-dynamic-rule-detail__text-wrap"
                        *ngFor="let option of dynamicRuleForm.controls.destSelectedList['controls']; let i = index"
                        [formGroupName]="i"
                        fxLayout="row"
                        fxLayoutGap="5%"
                      >
                        <mat-checkbox fxFlex="5" class="example-margin" formControlName="selected"> </mat-checkbox>
                        <div fxFlex="50">{{ displaySelectedValue(option.value, constantsService.DEST) }}</div>
                        <div fxFlex="25">{{ option.value.qualifyCd }}</div>
                        <div fxFlex="10">{{ option.value.inExValue }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </xpo-card-content>
  </xpo-card>
  <div class="action-bar" *ngIf="dynamicRuleForm && dynamicRuleForm.controls">
    <xpo-button-group>
      <button
        *ngIf="!priceRuleId"
        mat-flat-button
        (click)="createDynamicPricingRule()"
        [disabled]="laneDiscountService.editingDiscountData$ | async"
      >
        Create
      </button>
      <button
        *ngIf="showUpdateButtons"
        mat-stroked-button
        (click)="updateRuleStatus()"
        [disabled]="laneDiscountService.editingDiscountData$ | async"
      >
        {{ getButtonLabel() }}
      </button>
      <button
        *ngIf="showUpdateButtons"
        mat-flat-button
        (click)="save()"
        [disabled]="laneDiscountService.editingDiscountData$ | async"
      >
        Save
      </button>
      <button
        *ngIf="showUpdateButtons"
        mat-flat-button
        (click)="createNewVersion()"
        [disabled]="laneDiscountService.editingDiscountData$ | async"
      >
        Create New Version
      </button>
    </xpo-button-group>
  </div>
</div>
