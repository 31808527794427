import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { XpoGrid, XpoGridConfig, XpoGridReadyEvent, XPO_GRID_SETTINGS } from '@xpo-ltl/ngx-grid';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ConditionalFilterCombiner, Operators, XpoBoardOptions, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { AuditInfo, Code, DynamicPriceStatusCd, XrtSortExpression } from '@xpo-ltl/sdk-common';
import {
  ExportDynamicPricingRulesFileRqst,
  ExportDynamicPricingRulesRqst,
  ListDynamicPricingRuleHeadersResp,
  ListDynamicPricingRulesResp,
} from '@xpo-ltl/sdk-dynamicpricing';
import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import { FilterChangedEvent, GridApi, IServerSideDatasource, SortChangedEvent } from 'ag-grid-community';
import _ from 'lodash';
import moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DynamicRuleBulkCreateComponent } from '../../../dynamic-rule-bulk-create/components/dynamic-rule-bulk-create.component';
import { DynamicRulesFileExportOperationEnum, SearchRuleApiName } from '../../../shared/enums';
import { AppRoutes } from '../../../shared/enums/app-routes.enum';
import { RuleListFieldNames } from '../../../shared/enums/field-names/rule-list-field-names-enum';
import { SearchRule } from '../../../shared/models/search-rule';
import { ConstantsService } from '../../../shared/services/constants/constants.service';
import { DownloadService } from '../../../shared/services/download/download.service';
import { DynamicRuleDataSource } from '../../../shared/services/dr-data-source/dynamic-rule-data-source';
import { DynamicRuleRequestTransformerService } from '../../../shared/services/dr-request-transformer/dynamic-rule-request-transformer.service';
import { DynamicPricingStorageService } from '../../../shared/services/dynamic-pricing/dynamic-pricing-storage.service';
import { RuleListService } from '../../../shared/services/dynamic-rule-list/rule-list.service';
import { PricingWorkbenchService } from '../../../shared/services/pricing-workbench/pricing-workbench.service';
import { DynamicRuleGridService, DynamicRuleRequestEnum } from '../../service/dynamic-rule-grid.service';
import { DynamicRuleTrayService } from '../../service/dynamic-rule-tray.service';
import { DownloadTypeComponent } from '../dialogs/download-type/download-type.component';
import { EditRuleComponent } from '../dialogs/edit-rule/edit-rule.component';

@Component({
  selector: 'app-dynamic-rule-list',
  templateUrl: './dynamic-rule-list.component.html',
  styleUrls: ['./dynamic-rule-list.component.scss'],
  providers: [DynamicRuleDataSource],
})
export class DynamicRuleListComponent implements OnDestroy, AfterViewInit {
  private unsubscriber = new Unsubscriber();
  gridRef: XpoGrid;
  private gridApi: GridApi;

  boardOptions: XpoBoardOptions = {
    preloadViewData: true,
    suppressViewSwitcher: true,
    enableQueryParamStatePersistance: true,
  };

  customGridOptions: XpoGridConfig;
  defaultFilters: SearchRule = {
    effectiveDate: moment().format(this.constants.DATE_FORMAT_MOMENT),
    dynamicCode: null,
    acctMadCd: null,
    customerCode: null,
    statusCd: DynamicPriceStatusCd.ACTIVE,
    ruleId: null,
    exactEffectiveDate: null,
    expiryDate: null,
    designationType: null,
    laneId: null,
    logicalRuleId: null,
    marsInd: null,
    overrideCd: null,
    fakInd: null,
    trialPeriodTypeCd: null,
  };

  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  selectedRows = [];
  downloadAllRows = false;
  pricingDetailView: boolean;

  get isEditButtonDisable() {
    return !this.selectedRows || this.selectedRows.length === 0;
  }

  get isDownloadButtonDisable() {
    return this.downloadService.disabledBySearchInd;
  }

  dataLength: number = 0;
  pageSize: number = 100;
  startAt: number = 0;
  pageEvent: PageEvent;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatDrawerContainer, { static: true }) detailTray: MatDrawerContainer;
  ruleDetailData: any;
  trayOpen: boolean = false;
  criteria: SearchRule = _.cloneDeep(this.defaultFilters);
  filterOnGrid = {};
  defaultSort = [
    {
      column: 'effectiveDate',
      direction: 'desc',
      isDescendingSort: false,
    },
  ];
  ruleTypes: Code[] = [];
  rateTypes: Code[] = [];
  pricingCodes = {
    rateTypes: [],
    ruleTypes: [],
  };

  constructor(
    private constants: ConstantsService,
    private ruleList: RuleListService,
    private storageService: DynamicPricingStorageService,
    private transformerService: DynamicRuleRequestTransformerService,
    private downloadService: DownloadService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private confirmDialog: XpoConfirmDialog,
    private dynamicRuleTrayService: DynamicRuleTrayService,
    private dynamicRuleGridService: DynamicRuleGridService,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {
    this.pricingWorkbenchService.getPricingCodes().subscribe((response: PricingCode[]) => {
      this.pricingCodes.ruleTypes = _.filter(response, { subCategory: this.constants.RULE_TYPE });
      this.pricingCodes.rateTypes = _.filter(response, { subCategory: this.constants.RATE_TYPE });
    });

    this.pricingDetailView = !!this.route.snapshot?.data?.filters;
    if (this.pricingDetailView) {
      this.defaultFilters.effectiveDate = null;
      this.defaultFilters.statusCd = null;
      this.ruleList.setClearCache(true);
    } else {
      this.ruleList.setClearCache(false);
    }
    this.customGridOptions = {
      gridSettingsParams: {
        keyField: RuleListFieldNames.priceRuleId,
      },
      gridOptions: {
        enableCellTextSelection: true,
        suppressPaginationPanel: true,
        columnDefs: this.ruleList.getColumnsForGrid(this.pricingDetailView, this.pricingCodes),
        rowModelType: 'serverSide',
        rowSelection: 'multiple',
        getRowNodeId: (data) => (data ? data[RuleListFieldNames.priceRuleId] : null),
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: false,
          icons: {
            menu: `<mat-icon class="material-icons" style="font-size: 18px;">filter_list</mat-icon>`,
          },
        },
        onSelectionChanged: (event) => this.onSelectionChanged(event),
        onSortChanged: (event) => this.onSortChanged(event),
        onFilterChanged: (event) => this.onFilterChanged(event),
      },
      gridSettings: [XPO_GRID_SETTINGS.rowIndexAutoWidth, XPO_GRID_SETTINGS.selectAll],
    };

    if (this.boardOptions.preloadViewData) {
      this.downloadService.setDisabledBySearchInd(false);
    }
  }

  private getQuery(): DynamicRuleRequestEnum {
    return {
      criteria: this.transformerService.transformSearchRuleToXpoFilterCriteria(this.criteria),
      pageNumber: this.paginator.pageIndex + 1,
      sortOrder: this.getSortedColumn(this.paginator.pageIndex),
      dynamicPricingDetail: this.pricingDetailView,
      filterOnGrid: this.filterOnGrid,
    };
  }

  private getRequiredData(): void {
    const request = this.getQuery();
    if (request.criteria.acctMadCd && request.criteria.acctMadCd.conditions[0].value) {
      request.pageNumber = this.paginator.pageIndex;
      this.dynamicRuleGridService.getDataPricingDetails(request).subscribe((response: ListDynamicPricingRuleHeadersResp) => {
        this.gridApi.setServerSideDatasource(this.getRowData(response.dynamicPriceRules));
        this.dataLength = response.listInfo.totalRowCount;
        this.clearSelectedRows();
      });
    } else {
      this.dynamicRuleGridService.getDynamicList(request).subscribe((response) => {
        this.gridApi.setServerSideDatasource(this.getRowData(response.result ? response.result : response));
        this.dataLength = response.header ? response.header.resultCount : response.length;
        this.clearSelectedRows();
      });
    }

  }

  openedChange(open: boolean): void {
    this.trayOpen = open;
    if (this.dynamicRuleTrayService.getReload()) {
      this.getRequiredData();
      this.dynamicRuleTrayService.setReload(false);
    }
  }

  onAddClick(): void {
    this.dynamicRuleTrayService.setDetailData({ showTray: true });
  }

  onUploadClick(): void {
    const dialogRef = this.dialog.open(DynamicRuleBulkCreateComponent, {
      height: '680px',
      width: '50%',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getRequiredData();
      }
    });
  }

  ngOnDestroy(): void {
    this.ruleList.MaxRecordsDisplayed = 0;
    this.dynamicRuleTrayService.setDetailData(null);
    this.unsubscriber.complete();
  }

  onSelectionChanged(event: any) {
    this.selectedRows = event.api.getSelectedRows();
  }

  private getSortedColumn(page: number): XrtSortExpression[] {
    const sortGrid = this.gridRef?.gridOptions.columnApi
      .getColumnState()
      .filter((d) => !!d.sort)
      ?.map((sort) => {
        return {
          column: sort.colId,
          direction: sort.sort,
          isDescendingSort: false,
        };
      });

    return (sortGrid && sortGrid.length) || page ? sortGrid : this.defaultSort;
  }

  private onSortChanged(event: SortChangedEvent) {
    this.getRequiredData();
  }

  private onFilterChanged(event: FilterChangedEvent) {
    const filterModel = this.gridApi.getFilterModel();
    const filterKeys = Object.keys(filterModel);
    let filters = {};
    filterKeys.forEach((filter) => {
      const newFilter = {};
      newFilter[filter] = {};
      newFilter[filter][filterModel[filter].type] =
        filterModel[filter].filter ?? moment(filterModel[filter].dateFrom).format(this.constants.dateServiceFormat);
      filters = { ...newFilter, ...filters };
    });
    this.filterOnGrid = filters;
    this.getRequiredData();
  }

  onSearchClick(event: SearchRule, pageNumber?: number) {
    this.criteria = event;
    this.getRequiredData();
    this.gridApi?.setFilterModel(null);

    if (!pageNumber) {
      this.startAt = 0;
      this.paginator.firstPage();
    }

    const filtersFromSearchForm = Object.entries(event)
      .filter((filter) => filter[1])
      .map((filter) => SearchRuleApiName[filter[0]]);

    [].some((filter) => {
      if (filter === SearchRuleApiName.effectiveDate) {
        return (
          !filtersFromSearchForm.includes(SearchRuleApiName.effectiveDate) &&
          !filtersFromSearchForm.includes(SearchRuleApiName.exactEffectiveDate)
        );
      } else if (filter === SearchRuleApiName.expiryDate) {
        return (
          !filtersFromSearchForm.includes(SearchRuleApiName.effectiveDate) &&
          !filtersFromSearchForm.includes(SearchRuleApiName.expiryDate)
        );
      } else {
        return !filtersFromSearchForm.includes(filter);
      }
    });
  }

  showConfirmApplyFilterDialog(search) {
    const confirmConfig: XpoConfirmDialogConfig = {
      confirmButtonText: 'Yes, Search',
      confirmButtonColor: 'warn',
      rejectButtonText: 'Cancel',
    };
    this.confirmDialog
      .confirm(
        'You have applied filters in the grid. ' +
          'If you continue will lose these applied filters. Are you sure you want to continue with this new search criteria?',
        'Warning',
        confirmConfig
      )
      .subscribe((result) => {
        if (result) {
          search();
        }
      });
  }

  onDownloadClick() {
    const dialogRef = this.dialog.open(DownloadTypeComponent, {
      width: '600px',
      disableClose: true,
    });
    dialogRef.componentInstance.onSelect.subscribe((fileFormat: DynamicRulesFileExportOperationEnum) => {
      this.downloadFile(fileFormat);
    });
  }

  onClearClick() {
    this.storageService.setDynamicRulesQueryParams(undefined);
  }

  navigateToClassicList() {
    this.router.navigate([`../${AppRoutes.LIST_PAGE_CLASSIC}`], { relativeTo: this.route });
  }

  ngAfterViewInit(): void {
    this.initializeMaxResultCount();
    this.initAfterViewInitWatchers();
    this.subscribeToDetail();
  }

  private subscribeToDetail(): void {
    this.dynamicRuleTrayService.dynamicPricingData.pipe(takeUntil(this.unsubscriber.done)).subscribe((result) => {
      if (result && result.showTray) {
        this.ruleDetailData = result;
        this.detailTray.open();
      }
    });
  }

  onPaginationChanged(event): PageEvent {
    this.getRequiredData();
    return event;
  }

  onGridReady(event: XpoGridReadyEvent) {
    this.gridApi = event.gridRef.gridOptions.api;
    this.gridRef = event.gridRef;
    this.getRequiredData();
  }

  onEditClick(): void {
    const dialogRef = this.dialog.open(EditRuleComponent, {
      data: this.selectedRows,
      width: '600px',
      disableClose: true,
    });
    dialogRef.componentInstance.onSave.subscribe(() => {
      this.getRequiredData();
    });
  }

  private clearSelectedRows() {
    this.selectedRows = [];
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  }

  private initializeMaxResultCount() {
    const maxRecordsElement: Element = document.querySelector('.ruleListGrid__maxRecords');
    if (maxRecordsElement) {
      maxRecordsElement.textContent = '';
    }
  }

  private initAfterViewInitWatchers(): void {
    this.ruleList.maxRecordsDisplayed$
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done))
      .subscribe(({ resultCount }: { resultCount: number }) => {
        // need to add element to ag-grids paginator component inline with paging elements
        // don't have direct access in template so need to grab from DOM and add if not present
        // or update message if already created.
        let message = '';
        const paginatorElement: Element = document.querySelector('.ag-paging-panel');
        const maxRecordsElement: Element = document.querySelector('.ruleListGrid__maxRecords');

        if (resultCount > this.dataLength) {
          const convertToCommaSeparatedString = (num: number): string =>
            num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          const maxResultCountString = convertToCommaSeparatedString(this.dataLength);
          const resultCountString = convertToCommaSeparatedString(resultCount);
          message = `${resultCountString} rules have been found. Only the first ${maxResultCountString} have been loaded.`;
        }

        if (!maxRecordsElement) {
          const child = document.createElement('span');
          const styles = {
            color: '#D50000',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '380px',
            whiteSpace: 'nowrap',
            width: '100%',
            margin: '0',
          };
          Object.assign(child.style, styles);
          child.textContent = message;
          child.className = 'ruleListGrid__maxRecords';
          child.setAttribute('data-test', 'ruleListGridMaxRecords');

          if (paginatorElement) {
            paginatorElement.prepend(child);
          }
        } else {
          maxRecordsElement.textContent = message;
        }
      });
  }

  private downloadFile(fileFormat: DynamicRulesFileExportOperationEnum): void {
    let pageSize = this.dataLength;
    const downloadCriteria: any = _.clone(
      this.transformerService.transformSearchRuleToXpoFilterCriteria(this.criteria)
    );
    if (this.selectedRows.length && !this.downloadAllRows) {
      const priceRuleIdFilter = {
        conditions: this.selectedRows.map((item) => ({ operator: Operators.Equals, value: item.priceRuleId })),
      };
      pageSize = priceRuleIdFilter.conditions.length ? priceRuleIdFilter.conditions.length : pageSize;
      downloadCriteria.priceRuleId =
        priceRuleIdFilter.conditions.length > 1
          ? {
              ...priceRuleIdFilter,
              combiner: ConditionalFilterCombiner.Or,
            }
          : priceRuleIdFilter;
    }
    const filters = this.transformerService.transform(downloadCriteria);
    const requestData = {
      pageNumber: 1,
      pageSize: pageSize,
      filter: {
        ...filters,
      },
      sortExpressions: [
        {
          isDescendingSort: true,
          column: 'priceRuleId',
          direction: 'desc',
        },
      ],
    };

    switch (fileFormat) {
      case DynamicRulesFileExportOperationEnum.DP_RULES_CREATE:
        this.downloadService.exportDynamicPricingRules({
          ...new ExportDynamicPricingRulesRqst(),
          ...requestData,
        });
        break;

      case DynamicRulesFileExportOperationEnum.DP_RULE_DISCOUNTS_UPDATE:
        this.downloadService.exportDynamicPricingRulesFile({
          ...new ExportDynamicPricingRulesFileRqst(),
          ...requestData,
          format: DynamicRulesFileExportOperationEnum.DP_RULE_DISCOUNTS_UPDATE,
        });
        break;
    }
  }

  private getRowData(data): IServerSideDatasource {
    return {
      getRows(params) {
        return params.successCallback(data, data.length);
      },
    };
  }
}
