import { Component, Input, OnInit } from '@angular/core';
import {
  XpoBoardApi,
  XpoBoardOptions,
  XpoBoardReadyEvent,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
} from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  CellEditionErrorCellRendererComponent,
  DatepickerEditorComponent,
  formatChecker,
  XpoInlineEditingBase,
} from '@xpo-ltl/ngx-ltl-grid';
import { ActionCd, Code } from '@xpo-ltl/sdk-common';
import { PriceRuleAccessorialOverride } from '@xpo-ltl/sdk-dynamicpricing';
import { AccessorialService } from '@xpo-ltl/sdk-pricing';
import { PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import moment from 'moment';
import { DecimalEditorComponent } from '../../../../lib/ag-grid/cell-editors/decimal-editor/decimal-editor.component';
import { SearchAccessorialCharge } from '../../../shared/models/search-accessorial-charge';
import { AcOverrideDataSourceService } from '../../../shared/services/accessorial-charge-override/ac-override-data-source-service';
import { AcOverrideService } from '../../../shared/services/accessorial-charge-override/ac-override.service';
import { AccessorialChargeService } from '../../../shared/services/accessorial-charge/accessorial-charge.service';
import { ConstantsService } from '../../../shared/services/constants/constants.service';
import { DynamicPricingStorageService } from '../../../shared/services/dynamic-pricing/dynamic-pricing-storage.service';
import { DynamicPricingService } from '../../../shared/services/dynamic-pricing/dynamic-pricing.service';
import { PricingWorkbenchService } from '../../../shared/services/pricing-workbench/pricing-workbench.service';
import { SelectCellEditorComponent } from '../select-cell-editor/select-cell-editor.component';
import { AcOverrideEnum } from './ac-override-grid.enum';
import { UiIconAcActionComponent } from './ui-icon-action/ui-icon-action.component';

@Component({
  selector: 'app-ac-override',
  templateUrl: './ac-override.component.html',
  styleUrls: ['./ac-override.component.scss'],
})
export class AcOverrideComponent extends XpoInlineEditingBase implements OnInit {
  isEditing: boolean;
  keyField = 'accessorialOverrideSequenceNbr';
  @Input() editable: boolean;
  @Input() ruleId;

  viewTemplates: XpoAgGridBoardViewTemplate[];
  private typeList: String[];
  private chargeTypeList: String[];
  private typeOriginalList;
  chargeTypeOriginalList;
  uomTypeOriginalList;
  uomTypeList;
  views: XpoAgGridBoardView[];
  customGridOptions: GridOptions;
  gridApi: GridApi;
  colApi: ColumnApi;
  gridData: any;
  boardOptions: XpoBoardOptions = {
    preloadViewData: false,
    suppressRecordCounts: true,
    suppressGridSettingsPopover: true,
    suppressViewSwitcher: false,
  };

  defaultFilters: SearchAccessorialCharge = {
    beginDate: moment()
      .startOf('day')
      .toDate(),
    effectiveDate: null,
    expiryDate: null,
    typeCd: null,
  };
  chargeRequiredData = false;
  private boardApi: XpoBoardApi;

  constructor(
    public dataSource: AcOverrideDataSourceService,
    private constants: ConstantsService,
    private dynamicPricingService: DynamicPricingService,
    private acOverrideService: AcOverrideService,
    private accessorialChargeService: AccessorialChargeService,
    private dynamicPricingStorageService: DynamicPricingStorageService,
    private pricingWorkbenchService: PricingWorkbenchService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getRequiredData();
    this.customGridOptions = {
      enableCellTextSelection: true,
      pagination: false,
      singleClickEdit: true,
      frameworkComponents: {
        xpoStringFilterComponent: XpoStringFilterComponent,
        xpoNumberFilterComponent: XpoNumberFilterComponent,
        decimalEditor: DecimalEditorComponent,
        datepicker: DatepickerEditorComponent,
        iconActionComponent: UiIconAcActionComponent,
        cellEditionError: CellEditionErrorCellRendererComponent,
        selectEditor: SelectCellEditorComponent,
      },
      isRowSelectable: () => {
        return false;
      },
      defaultColDef: {
        suppressMenu: true,
        sortable: false,
        resizable: true,
        filter: false,
        editable: (params) => {
          return this.editable;
        },
        cellRenderer: 'cellEditionError',
        cellRendererParams: (params) => {
          return {
            error: params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
          };
        },
        cellClassRules: {
          'xpo-AgGrid-editableCell--error': (params) =>
            params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
        },
        valueSetter: (params) => {
          this.handleValueSetter(params, this.keyField);
          return true;
        },
        tooltipValueGetter: (params) => {
          return params.data && this.getErrorDescription(params, params.colDef.headerTooltip);
        },
      },
      onCellEditingStopped: (params) => {
        const amc = params.data;
        if (params.oldValue !== params.newValue && amc.priceRuleHeaderId) {
          amc.listActionCd = ActionCd.UPDATE;
        }
        if (!amc.listActionCd) {
          amc.listActionCd = ActionCd.ADD;
        }
      },
      onCellEditingStarted: () => {
        this.gridData = this.dataSource.list;
        this.onStartEditing();
      },
    };
    this.viewTemplates = this.getBoardViewTemplates();
    this.views = this.getBoardViews(this.viewTemplates[0]);

    this.acOverrideService.acList$.subscribe((d) => {
      this.dataSource.refresh();
    });
  }

  getBoardViewTemplates(): Array<XpoAgGridBoardViewTemplate> {
    return [
      new XpoAgGridBoardViewTemplate({
        id: '1',
        name: 'AC',
        keyField: '1',
        availableColumns: this.getColumnsForGrid(),
      }),
    ];
  }

  private TypeCellRenderer(params, list) {
    const value = list ? list.find((d) => d.value === params.value) : params.value;
    return value && value.key ? value.key : null;
  }

  private getBoardViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    return [
      viewTemplate.createView({
        id: 'AC',
        name: 'Override',
      }),
    ];
  }

  getRequiredData(): void {
    this.chargeTypeList = [];

    this.pricingWorkbenchService.getPricingCodes().subscribe((response: PricingCode[]) => {
      this.chargeTypeOriginalList = _.filter(response, {
        category: this.constants.PRICING,
        subCategory: this.constants.AC_CHARGE_TYPE,
      });
      this.uomTypeOriginalList = _.filter(response, {
        category: this.constants.PRICING,
        subCategory: this.constants.AC_UOM,
      });
      this.uomTypeList = this.uomTypeOriginalList.map((d) => {
        return { key: d.name, value: d.code };
      });
      this.chargeTypeList = this.chargeTypeOriginalList.map((d) => {
        return { key: d.name, value: d.code };
      });
      this.dynamicPricingService.listGlobalAccessorials().subscribe((data) => {
        this.typeOriginalList = data.map((accessorial: AccessorialService) => {
          const code = new Code();
          code.code = accessorial.accessorialCd;
          code.name = `${accessorial.accessorialCd} (${accessorial.accessorialDesc.slice(4)})`;
          return code;
        });
        this.typeList = this.typeOriginalList.map((d) => {
          return { key: d.name, value: d.code };
        });
        this.dataSource.refresh();
      });
    });
  }

  getColumnsForGrid() {
    const autocompleteValidator = (fieldName, params: any, list: any) => {
      params.value = params.editedRow.columns[params.column];
      const fail: boolean = !this.TypeCellRenderer(params, list);
      const errorDescription = 'Should be a valid';
      this.acOverrideService.setErrorMessage(fieldName + params.editedRow.id, fail ? errorDescription : null);
      return { fail: fail };
    };
    const numberValidator = (fieldName, params: any, maxNumber: any) => {
      const twoDecimalRegex = /^\d+(\.\d{0,2})?$/;
      const value = params.editedRow.columns[params.column];
      const isValid: formatChecker = {
        fail: isNaN(value) || parseFloat(value) < 0 || parseFloat(value) > maxNumber || value === '',
        errorDescription: 'Should be between 0 and ' + maxNumber,
      };
      if (!isValid.fail && !twoDecimalRegex.test(value)) {
        isValid.errorDescription = 'Should have 2 decimals max';
        isValid.fail = true;
      }
      this.acOverrideService.setErrorMessage(
        fieldName + params.editedRow.id,
        isValid.fail ? isValid.errorDescription : null
      );
      return isValid;
    };
    const enabled = (params) => {
      if (this.editable) {
        // mark police cells as red
        return { backgroundColor: '#FFFFFF' };
      }
      return { backgroundColor: '#F3F3F3' };
    };
    return [
      {
        headerName: '',
        field: 'accessorialOverrideSequenceNbr',
        cellRenderer: 'iconActionComponent',
        cellClassRules: {
          'xpo-AgGrid-editableCell': () => false,
        },
        cellRendererParams: () => {
          return {
            isExistingRule: this.editable,
          };
        },
        width: 70,
        pinned: 'left',
        editable: false,
      },
      {
        headerName: AcOverrideEnum.typeFieldName,
        field: AcOverrideEnum.type,
        flex: 3,
        cellEditor: 'selectEditor',
        cellRenderer: (params) => this.TypeCellRenderer(params, this.typeList),
        cellEditorParams: this.getTypeList.bind(this),
        cellStyle: enabled,
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params) => autocompleteValidator(AcOverrideEnum.typeFieldName, params, this.typeList),
        },
      },
      {
        headerName: AcOverrideEnum.chargeTypeCdFieldName,
        field: AcOverrideEnum.chargeTypeCd,
        flex: 2,
        cellEditor: 'selectEditor',
        cellRenderer: (params) => this.TypeCellRenderer(params, this.chargeTypeList),
        cellEditorParams: this.getChargeTypeList.bind(this),
        cellStyle: enabled,
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params) =>
            autocompleteValidator(AcOverrideEnum.chargeTypeCdFieldName, params, this.chargeTypeList),
        },
      },
      {
        headerName: AcOverrideEnum.chargeAmountFieldName,
        field: AcOverrideEnum.chargeAmount,
        flex: 1,
        cellEditor: 'decimalEditor',
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return numberValidator(AcOverrideEnum.chargeAmountFieldName, params, 999999999.99);
          },
        },
        cellStyle: enabled,
      },
      {
        headerName: AcOverrideEnum.unitOfMeasureCdFieldName,
        field: AcOverrideEnum.unitOfMeasureCd,
        cellEditor: 'selectEditor',
        cellRenderer: (params) => this.TypeCellRenderer(params, this.uomTypeList),
        cellEditorParams: this.getUomTypeList.bind(this),
        flex: 2,
        cellStyle: enabled,
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params) =>
            autocompleteValidator(AcOverrideEnum.unitOfMeasureCdFieldName, params, this.uomTypeList),
        },
      },

      {
        headerName: AcOverrideEnum.effectiveDateFieldName,
        field: AcOverrideEnum.effectiveDate,
        width: 100,
        editable: false,
        cellStyle: { backgroundColor: '#F3F3F3' },
        cellRenderer: (params) => {
          return params.value ? moment(new Date(`${params.value} `)).format(this.constants.DATE_FORMAT_MOMENT) : null;
        },
      },
      {
        headerName: AcOverrideEnum.expiryDateFieldName,
        field: AcOverrideEnum.expiryDate,
        width: 95,
        editable: false,
        cellStyle: { backgroundColor: '#F3F3F3' },
        cellRenderer: (params) => {
          return params.value ? moment(new Date(`${params.value} `)).format(this.constants.DATE_FORMAT_MOMENT) : null;
        },
      },
    ];
  }

  private getTypeList() {
    return { options: this.typeList };
  }

  private getUomTypeList() {
    return { options: this.uomTypeList };
  }

  private getChargeTypeList() {
    return { options: this.chargeTypeList };
  }
  getTableSize() {
    const size = 150 + this.dataSource.currentState.visibleRows * 30;
    return size > 270 ? 270 : size;
  }

  onBoardReady(event: XpoBoardReadyEvent): void {
    this.boardApi = event.boardApi;
  }

  onGridReady(event: AgGridEvent) {
    this.gridApi = event.api;
    this.colApi = event.columnApi;
    const newState = this.dataSource.currentState;
    this.gridData = newState && newState.data && newState.data.consumerData;
  }

  addAc(): void {
    this.acOverrideService.addAcOverride();
    this.dataSource.refresh();
  }

  getDefaultAc(): void {
    this.dynamicPricingStorageService.setAccessorialChargeListParams(this.defaultFilters);
    this.accessorialChargeService.listAccessorialCharges().subscribe((result: any) => {
      this.acOverrideService.replaceAcList(
        result.map((d, i) => {
          const acOverride = new PriceRuleAccessorialOverride();
          acOverride.chargeAmount = d.chargeAmount;
          acOverride.chargeTypeCd = d.chargeTypeCd;
          acOverride.correlationId = d.correlationId;
          acOverride.dynEventId = d.dynEventId;
          acOverride.maxAmount = d.maxAmount;
          acOverride.minAmount = d.minimumAmount;
          acOverride.expiryDate = d.expiryDate;
          acOverride.effectiveDate = d.effectiveDate;
          acOverride.minAmount = d.minimumAmount;
          acOverride.listActionCd = ActionCd.ADD;
          acOverride.typeCd = d.typeCd;
          acOverride.unitOfMeasureCd = d.unitOfMeasureCd;
          acOverride.accessorialOverrideSequenceNbr = i + 1;
          return acOverride;
        })
      );
      this.dataSource.refresh();
    });
  }

  unfocus() {
    if (this.gridApi.getFocusedCell()) {
      this.gridApi.setFocusedCell(0, 'accessorialOverrideSequenceNbr');
    }
  }
}
