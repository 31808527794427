import { Injectable } from '@angular/core';
import { ActionCd, DynamicPriceStatusCd } from '@xpo-ltl/sdk-common';
import { PriceRuleDiscount } from '@xpo-ltl/sdk-dynamicpricing';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { LaneTypeDiscountRangeModeEnum, LaneTypeDiscountRangeStatusEnum } from '../../enums';
import { LaneTypeDiscountRange, NewRulePeriod } from '../../models';
import { DynamicRule } from '../../models/dynamic-rule';
import { ConstantsService } from '../constants/constants.service';
import { FormatDateService } from '../format-date/format-date.service';

@Injectable({
  providedIn: 'root',
})
export class LaneDiscountService {
  dynamicRule: DynamicRule;
  discountListSubject = new BehaviorSubject<Array<LaneTypeDiscountRange>>([]);
  discountList$ = this.discountListSubject.asObservable();
  invalidDiscountDataSubject = new BehaviorSubject<boolean>(false);
  invalidDiscountData$ = this.invalidDiscountDataSubject.asObservable();
  editingDiscountDataSubject = new BehaviorSubject<boolean>(false);
  editingDiscountData$ = this.editingDiscountDataSubject.asObservable();
  discountDataChangedSubject = new Subject<any>();
  discountDataChanged$ = this.discountDataChangedSubject.asObservable();

  get DiscountList(): Array<LaneTypeDiscountRange> {
    return this.discountListSubject.value;
  }

  get editingDiscountData(): boolean {
    return this.editingDiscountDataSubject.value;
  }

  set editingDiscountData(value: boolean) {
    this.editingDiscountDataSubject.next(value);
  }

  get hasInvalidDiscountData(): boolean {
    return this.invalidDiscountDataSubject.value;
  }

  constructor(private constantsService: ConstantsService, private formatDateService: FormatDateService) {}

  processDiscounts(rule: DynamicRule) {
    const periods: Array<LaneTypeDiscountRange> = new Array<LaneTypeDiscountRange>();
    let invalidDiscounts: boolean = false;
    this.dynamicRule = { ...rule };
    const discounts =
      this.dynamicRule && this.dynamicRule.priceRuleDiscounts
        ? this.dynamicRule.priceRuleDiscounts.map((discount) => {
            if (!discount.effectiveDate) {
              discount.effectiveDate = this.formatDateService.transformDateWithFormat(
                this.dynamicRule.effectiveDate,
                this.constantsService.dateServiceFormat
              );
            }
            if (!discount.expiryDate) {
              discount.expiryDate = this.formatDateService.transformDateWithFormat(
                this.dynamicRule.expiryDate,
                this.constantsService.dateServiceFormat
              );
            }
            return discount;
          })
        : [];
    discounts.forEach((discount) => {
      const effectiveDate = discount.effectiveDate;
      const effectiveDateString = this.formatDateService.transformDate(effectiveDate);
      const expiryDate = discount.expiryDate;
      const expiryDateString = this.formatDateService.transformDate(expiryDate);
      const label = `${effectiveDateString} - ${expiryDateString}`;
      const period = periods.find((element) => element.label === label);
      if (period) {
        period.data.push(discount);
      } else {
        periods.push(
          new LaneTypeDiscountRange({
            label,
            effectiveDate,
            expiryDate,
            data: [discount],
            isNewPeriod: false,
          })
        );
      }
    });
    periods.forEach((period) => {
      period.mode = period.getMode();
    });
    if (periods.length) {
      if (rule.statusCd === DynamicPriceStatusCd.PENDING && periods.length > 1) {
        invalidDiscounts = true;
      } else {
        invalidDiscounts = true;
        if (this.dynamicRule) {
          invalidDiscounts = !this.validateDiscountDates(
            moment(this.dynamicRule.effectiveDate),
            moment(this.dynamicRule.expiryDate),
            periods
          );
        }
      }
    } else {
      const period = new LaneTypeDiscountRange();
      const discount = new PriceRuleDiscount();
      discount.discountSequenceNbr = 1;
      discount.listActionCd = ActionCd.ADD;
      period.data.push(discount);
      periods.push(period);
    }
    this.invalidDiscountDataSubject.next(invalidDiscounts);
    this.discountListSubject.next(
      periods.sort((a, b) => moment(b.effectiveDate).valueOf() - moment(a.effectiveDate).valueOf())
    );
  }

  discountDataChanged(persist: boolean) {
    this.discountDataChangedSubject.next(persist);
  }

  createNewPeriod(period: NewRulePeriod) {
    const basePeriod = this.DiscountList.find((p) => p.isActive);
    const baseDiscount = basePeriod.data[0];
    const newPeriodEffectiveDateString = this.formatDateService.transformDate(period.effectiveDate);
    const newPeriodExpiryDate = this.formatDateService.transformDateWithFormat(
      this.dynamicRule.expiryDate,
      this.constantsService.dateServiceFormat
    );
    const newPeriodExpiryDateString = this.formatDateService.transformDate(newPeriodExpiryDate);
    const newPeriodLabel = `${newPeriodEffectiveDateString} - ${newPeriodExpiryDateString}`;
    const maxSequenceNbr = this.getMaxSequenceNumber();
    const newPeriod = new LaneTypeDiscountRange({
      label: newPeriodLabel,
      effectiveDate: period.effectiveDate,
      expiryDate: newPeriodExpiryDate,
      data: [
        {
          ...baseDiscount,
          auditInfo: null,
          correlationId: null,
          discountSequenceNbr: maxSequenceNbr + 1,
          effectiveDate: period.effectiveDate,
          expiryDate: newPeriodExpiryDate,
          externalRuleCdHeadHaul: period.ruleCode,
          headHaulDiscountPercentage: period.ruleDiscount,
          externalRuleCdBackHaul: period.ruleCode,
          backHaulDiscountPercentage: period.ruleDiscount,
          externalRuleCdNeutral: period.ruleCode,
          neutralDiscountPercentage: period.ruleDiscount,
          discountDescription: period.ruleDescription,
          expirationReason: period.expirationReason,
          listActionCd: ActionCd.ADD,
        },
      ],
      isNewPeriod: true,
    });
    const newExpiryDateForBasePeriod = moment(period.effectiveDate)
      .startOf('day')
      .subtract(1, 'day');
    const effectiveDateBasePeriod = moment(basePeriod.effectiveDate);
    this.updatePeriodRange(basePeriod.id, effectiveDateBasePeriod, newExpiryDateForBasePeriod, false);
    this.DiscountList.unshift(newPeriod);
    this.discountListSubject.next(this.DiscountList);
    let validDiscounts = false;
    if (this.dynamicRule) {
      validDiscounts = this.validateDiscountDates(
        moment(this.dynamicRule.effectiveDate),
        moment(this.dynamicRule.expiryDate)
      );
    }
    this.invalidDiscountDataSubject.next(!validDiscounts);
    this.discountDataChanged(true);
    return newPeriod.id;
  }

  hasPendingPeriod(): boolean {
    return this.DiscountList.some((period) => period.getStatus() === LaneTypeDiscountRangeStatusEnum.pending);
  }

  periodHasVisibleDiscounts(periodId: string): boolean {
    return this.DiscountList.some(
      (period) =>
        period.id === periodId &&
        period.data &&
        period.data.some((discount) => discount.listActionCd !== ActionCd.DELETE)
    );
  }

  periodVisibleDiscounts(period: LaneTypeDiscountRange): Array<PriceRuleDiscount> {
    return period.data.filter((discount) => discount.listActionCd !== ActionCd.DELETE);
  }

  validateDiscountDates(
    effectiveDate: Moment,
    expiryDate: Moment,
    periods: Array<LaneTypeDiscountRange> = null
  ): boolean {
    const data = periods ? periods : this.DiscountList;
    const ruleEffectiveDate = effectiveDate.startOf('day').valueOf();
    const ruleExpiryDate = expiryDate.startOf('day').valueOf();

    const effectiveDates = data.map((period) =>
      moment(period.effectiveDate, 'YYYY-MM-DD')
        .startOf('day')
        .valueOf()
    );
    const expiryDates = data.map((period) =>
      moment(period.expiryDate, 'YYYY-MM-DD')
        .startOf('day')
        .valueOf()
    );
    const minEffectiveDate = _.min(effectiveDates);
    const maxExpiryDate = _.max(expiryDates);

    const sameDates = minEffectiveDate === ruleEffectiveDate && maxExpiryDate === ruleExpiryDate;
    return sameDates;
  }

  getDiscountListFlat(): Array<PriceRuleDiscount> {
    return _.flatMap(this.DiscountList, (period) => period.data).map((result: any) => {
      delete result.expirationReasonCopy;
      return result;
    });
  }

  hasEmptyRecords(): boolean {
    const records = this.getDiscountListFlat().filter((discount) => discount.listActionCd !== ActionCd.DELETE);
    return (
      !records.length ||
      records.some((discount) => {
        return (
          isNaN(discount.minimumWeight) ||
          isNaN(discount.maximumWeight) ||
          !discount.externalRuleCdHeadHaul ||
          !discount.headHaulDiscountPercentage ||
          !discount.externalRuleCdBackHaul ||
          !discount.backHaulDiscountPercentage ||
          !discount.externalRuleCdNeutral ||
          !discount.neutralDiscountPercentage
        );
      })
    );
  }

  getPeriod(periodId: string) {
    return this.DiscountList.find((period) => period.id === periodId);
  }

  getPeriodBySequenceNbr(sequenceNbr: number) {
    return this.DiscountList.find((period) =>
      period.data.some((discount) => discount.discountSequenceNbr === sequenceNbr)
    );
  }

  getDiscountFromPeriod(periodId: string, sequenceNbr: number): PriceRuleDiscount {
    const period = this.DiscountList.find((range) => range.id === periodId);
    return period ? period.data.find((discount) => discount.discountSequenceNbr === sequenceNbr) : null;
  }

  removeDiscount(sequenceNumber: number): void {
    const period = this.getPeriodBySequenceNbr(sequenceNumber);
    const discount = this.getDiscountFromPeriod(period.id, sequenceNumber);
    if (discount.listActionCd === ActionCd.ADD) {
      const discountIndex = period.data.findIndex((data) => data.discountSequenceNbr === sequenceNumber);
      period.data.splice(discountIndex, 1);
    } else {
      discount.listActionCd = ActionCd.DELETE;
    }
  }

  updateRuleDates(effectiveDate: Moment, expiryDate: Moment) {
    if (effectiveDate) {
      if (this.dynamicRule) {
        this.dynamicRule.effectiveDate = effectiveDate.toDate();
      }
      const firstPeriod = this.DiscountList.sort((a, b) => {
        return moment(a.effectiveDate).valueOf() - moment(b.effectiveDate).valueOf();
      })[0];
      this.updatePeriodRange(firstPeriod.id, effectiveDate, moment(firstPeriod.expiryDate), true);
    }
    if (expiryDate) {
      if (this.dynamicRule) {
        this.dynamicRule.expiryDate = expiryDate.toDate();
      }
      const lastPeriod = this.DiscountList.sort((a, b) => {
        return moment(b.expiryDate).valueOf() - moment(a.expiryDate).valueOf();
      })[0];
      this.updatePeriodRange(lastPeriod.id, moment(lastPeriod.effectiveDate), expiryDate, true);
    }
  }

  updatePeriodRange(periodId: string, effectiveDate: Moment, expiryDate: Moment, refresh: boolean = true) {
    const period = this.getPeriod(periodId);
    if (!moment(period.effectiveDate).isSame(effectiveDate) || !moment(period.expiryDate).isSame(expiryDate)) {
      const effectiveDateString = this.formatDateService.transformDate(effectiveDate.toDate());
      const expiryDateString = this.formatDateService.transformDate(expiryDate.toDate());
      const label = `${effectiveDateString} - ${expiryDateString}`;
      period.effectiveDate = this.formatDateService.transformMoment(effectiveDate);
      period.expiryDate = this.formatDateService.transformMoment(expiryDate);
      period.label = label;
      period.data
        .filter((discount) => discount.listActionCd !== ActionCd.DELETE)
        .forEach((discount) => {
          discount.effectiveDate = period.effectiveDate;
          discount.expiryDate = period.expiryDate;
          discount.listActionCd = discount.listActionCd === ActionCd.ADD ? ActionCd.ADD : ActionCd.UPDATE;
        });
      if (refresh) {
        this.discountListSubject.next(this.DiscountList);
      }
    }
  }

  updatePeriodMode(periodId: string, mode: LaneTypeDiscountRangeModeEnum): void {
    const period = this.getPeriod(periodId);
    period.mode = mode;
    if (period.mode === LaneTypeDiscountRangeModeEnum.simple) {
      period.data.forEach((discount) => {
        discount.externalRuleCdBackHaul = discount.externalRuleCdHeadHaul;
        discount.backHaulDiscountPercentage = discount.headHaulDiscountPercentage;
        discount.externalRuleCdNeutral = discount.externalRuleCdHeadHaul;
        discount.neutralDiscountPercentage = discount.headHaulDiscountPercentage;
        discount.listActionCd = discount.listActionCd === ActionCd.NO_ACTION ? ActionCd.UPDATE : discount.listActionCd;
      });
    }
    this.discountListSubject.next(this.DiscountList);
  }

  updateDiscount(sequenceNumber: number, atts: any): void {
    const period = this.getPeriodBySequenceNbr(sequenceNumber);
    const discount = this.getDiscountFromPeriod(period.id, sequenceNumber);
    Object.assign(discount, {
      ...atts,
      listActionCd: discount.listActionCd === ActionCd.NO_ACTION ? ActionCd.UPDATE : discount.listActionCd,
    });
    if (period.mode === LaneTypeDiscountRangeModeEnum.simple) {
      discount.externalRuleCdBackHaul = discount.externalRuleCdHeadHaul;
      discount.backHaulDiscountPercentage = discount.headHaulDiscountPercentage;
      discount.externalRuleCdNeutral = discount.externalRuleCdHeadHaul;
      discount.neutralDiscountPercentage = discount.headHaulDiscountPercentage;
    }
  }

  getMaxSequenceNumber() {
    const sequenceNumbers = _.flatMap(this.DiscountList, (range) => range.data).map(
      (discount) => discount.discountSequenceNbr
    );
    return sequenceNumbers.length ? Math.max(...sequenceNumbers) : 0;
  }

  overrideList(newList): void {
    let actualList = this.DiscountList;
    actualList = newList;
    this.discountListSubject.next(this.DiscountList);
  }

  addDiscountToPeriod(periodId: string, discount?: any): number {
    const period = this.DiscountList.find((data) => data.id === periodId);
    const maxSequenceNbr = this.getMaxSequenceNumber();
    const priceRuleDiscount = new PriceRuleDiscount();
    priceRuleDiscount.effectiveDate = period.effectiveDate;

    const index = discount.data.length - 1;
    if (
      discount.data[index].externalRuleCdBackHaul === discount.data[index].externalRuleCdHeadHaul &&
      discount.data[index].externalRuleCdNeutral
    ) {
      priceRuleDiscount.externalRuleCdBackHaul = discount.data[index].externalRuleCdBackHaul;
      priceRuleDiscount.externalRuleCdHeadHaul = discount.data[index].externalRuleCdHeadHaul;
      priceRuleDiscount.externalRuleCdNeutral = discount.data[index].externalRuleCdNeutral;
    }

    priceRuleDiscount.expiryDate = period.expiryDate;
    priceRuleDiscount.listActionCd = ActionCd.ADD;
    priceRuleDiscount.discountSequenceNbr = maxSequenceNbr + 1;
    priceRuleDiscount.priceRuleHeaderId = this.dynamicRule ? this.dynamicRule.priceRuleId : null;
    period.data.push(priceRuleDiscount);
    return priceRuleDiscount.discountSequenceNbr;
  }
}
