export enum AbsoluteMinimumChargeFieldNames {
  absoluteMinimumChargeNbr = 'ratingAmcDetailId',
  versionNbr = 'versionNbr',
  beginDate = 'beginDate',
  endDate = 'endDate',
  effectiveDate = 'effectiveDate',
  expiryDate = 'expiryDate',
  typeCd = 'typeCd',
  minMiles = 'minimumMilesNbr',
  maxMiles = 'maxMilesNbr',
  chargeAmount = 'chargeAmount',
  minChargeAmount = 'minChargeAmount',
  maxChargeAmount = 'maxChargeAmount'
}
