import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ActionCd } from '@xpo-ltl/sdk-common';
import {
  CreateDynamicPricingRuleHeaderResp,
  CustomerGroup,
  PriceRuleAccessorialOverride,
  PriceRuleAmcOverride,
  PriceRuleDiscount,
  UpdateDynamicPricingRuleHeaderResp,
} from '@xpo-ltl/sdk-dynamicpricing';
import { CustomerHierarchyAccount } from '@xpo-ltl/sdk-pricingagmtmgmt';
import { AdminPersonnel, GetSalesRfpResp } from '@xpo-ltl/sdk-pricingworkbench';
import * as _ from 'lodash';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import {
  distinctUntilChanged,
  filter,
  finalize,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { OptimizedPricing } from '../../../create-opp-rule/enums/optimized-pricing.enum';
import { AlertDialogComponent } from '../../../dialogs/alert/alert-dialog.component';
import { conditionalValidator } from '../../../shared/custom-validators/required-if-conditional.validator';
import { AccesorialsType } from '../../../shared/enums';
import { AppRoutes } from '../../../shared/enums/app-routes.enum';
import { DynamicRuleDetailFieldLabels } from '../../../shared/enums/field-labels/dynamic-rule-detail-field-labels.enum';
import { ThresholdPeriod } from '../../../shared/enums/threshold-period.enum';
import { YesNoEnum } from '../../../shared/enums/util.enum';
import { DynamicRule } from '../../../shared/models/dynamic-rule';
import { AmcOverrideService } from '../../../shared/services/absolute-minimum-charge-override/amc-override.service';
import { AcOverrideService } from '../../../shared/services/accessorial-charge-override/ac-override.service';
import { ConstantsService } from '../../../shared/services/constants/constants.service';
import { CustomersGroupsService } from '../../../shared/services/customers-groups/customers-groups.service';
import { DynamicRuleDetailResolverService } from '../../../shared/services/dr-detail-resolver/dynamic-rule-detail-resolver.service';
import { DynamicRuleService, PricingCodesObj } from '../../../shared/services/dr/dynamic-rule.service';
import { RuleListService } from '../../../shared/services/dynamic-rule-list/rule-list.service';
import { FormatDateService } from '../../../shared/services/format-date/format-date.service';
import { LaneDiscountService } from '../../../shared/services/lane-discount/lane-discount.service';
import { PrcAgmtMgmtApiService } from '../../../shared/services/pricing-agreement-management/prc-agmt-mgmt-api.service';
import { PricingWorkbenchService } from '../../../shared/services/pricing-workbench/pricing-workbench.service';
import { DynamicRuleTrayService } from '../../service/dynamic-rule-tray.service';
import { DynamicRuleUploadComponent } from '../dynamic-rule-upload/dynamic-rule-upload.component';

@Component({
  selector: 'app-dynamic-rule-detail-page',
  templateUrl: './dynamic-rule-detail.component.html',
  styleUrls: ['./dynamic-rule-detail.component.scss'],
})
export class DynamicRuleDetailComponent implements OnInit, OnDestroy, OnChanges {
  dynamicRuleData: DynamicRule;
  dynamicRuleForm: FormGroup;
  trafficTypes;
  applicationTypes;
  quoteCount: number;
  rateCount: number;
  sicStateForm: FormGroup;
  priceRuleId: number;
  editable: boolean;
  dropDownOptions: PricingCodesObj;
  minEffectiveDate: Date;
  minExpiryDate: Date;
  today = new Date();
  currentOwner: AdminPersonnel = new AdminPersonnel();
  customerGroupList: CustomerGroup[];
  editableFields = {
    laneId: DynamicRuleDetailFieldLabels.laneId,
    dynamicRuleName: DynamicRuleDetailFieldLabels.dynamicRuleName,
    designationType: DynamicRuleDetailFieldLabels.designationType,
    agreementAcctCd: DynamicRuleDetailFieldLabels.agreementAcctCd,
    expiryDate: DynamicRuleDetailFieldLabels.expiryDate,
  };
  thresholdPeriodOptions: Array<ThresholdPeriod> = [
    ThresholdPeriod.MONTH,
    ThresholdPeriod.QUARTER,
    ThresholdPeriod.YEAR,
  ];
  unsubscriber: Unsubscriber = new Unsubscriber();
  overrideDatesChange: Boolean = false;
  subscription: Subscription;
  filteredOptions = [];
  isLoading: boolean = false;
  selectedGroup: boolean = false;
  searchGroupById: boolean = false;
  get showUpdateButtons(): boolean {
    return this.priceRuleId && this.editable;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.dynamicRuleForm.controls;
  }

  get hasInvalidDiscountData$() {
    return this.laneDiscountService.invalidDiscountData$;
  }

  @Input() pricingRuleId: number;
  @Input() salesRfpData: GetSalesRfpResp;
  @Output() dataEvent = new EventEmitter<boolean>();
  pricingDetail: boolean = false;

  constructor(
    private dynamicRule: DynamicRuleService,
    public constantsService: ConstantsService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: XpoSnackBar,
    private dialog: MatDialog,
    private pwbService: PricingWorkbenchService,
    private prcAgmtService: PrcAgmtMgmtApiService,
    private ruleListService: RuleListService,
    public laneDiscountService: LaneDiscountService,
    private customerGroupService: CustomersGroupsService,
    private amcOverrideService: AmcOverrideService,
    private acOverrideService: AcOverrideService,
    private formatDateService: FormatDateService,
    private dynamicRuleDetailResolverService: DynamicRuleDetailResolverService,
    private dynamicRuleTrayService: DynamicRuleTrayService
  ) {}

  showHeader(): boolean {
    return !!this.dynamicRuleData;
  }

  private fillFormWithSalesRfpData(): void {
    const minimumCommodityClassNbr = this.dropDownOptions.salesRfp.find(
      (code) => code.code === OptimizedPricing.class_min
    );
    const maximumCommodityClassNbr = this.dropDownOptions.salesRfp.find(
      (code) => code.code === OptimizedPricing.class_max
    );
    const origInclusions = this.dropDownOptions.salesRfp.find((code) => code.code === OptimizedPricing.orig_include);
    const destInclusions = this.dropDownOptions.salesRfp.find((code) => code.code === OptimizedPricing.dest_include);
    const minimumWeight = this.dropDownOptions.salesRfp.find((code) => code.code === OptimizedPricing.weight_min);
    const maximumWeight = this.dropDownOptions.salesRfp.find((code) => code.code === OptimizedPricing.weight_max);

    const dataFromSales: any = {
      effectiveDate: new Date(`${this.salesRfpData.salesRfpHeader.proposedEffectiveDate} `),
      expiryDate: this.salesRfpData.salesRfpHeader.proposedExpirationDate
        ? new Date(`${this.salesRfpData.salesRfpHeader.proposedExpirationDate} `)
        : new Date('12/31/2999 '),
      amcOverrides: this.salesRfpData.amcs.map((amc, index) => {
        return {
          chargeAmount: amc.chargeAmount,
          effectiveDate: amc.effectiveDate,
          expiryDate: amc.expirationDate,
          typeCd: amc.amcTypeCd,
          minMilesNbr: amc.minMiles,
          maxMilesNbr: amc.maxMiles,
          listActionCd: ActionCd.ADD,
          amcOverrideSequenceNbr: index + 1,
        };
      }),
      acOverrides: this.salesRfpData.requestedAccessorials.map((ac, index) => {
        return {
          chargeAmount: ac.accessorialOverrideChargeAmount,
          chargeTypeCd: 'FlatRate',
          typeCd: ac.accessorialCd,
          unitOfMeasureCd: ac.accessorialUnitOfMeasure,
          accessorialOverrideSequenceNbr: index + 1,
          listActionCd: ActionCd.ADD,
        };
      }),
      statusCd: this.constantsService.PENDING,
    };

    const trialPeriodTypeCd = this.salesRfpData.salesRfpHeader.dynamicPricingPeriodCd
      .toUpperCase()
      .includes(OptimizedPricing.standard)
      ? OptimizedPricing.standard
      : this.salesRfpData.salesRfpHeader.dynamicPricingPeriodCd.toUpperCase().includes(OptimizedPricing.quarterly)
      ? OptimizedPricing.quarterly
      : null;
    dataFromSales.ruleDescription =
      this.salesRfpData.salesRfpHeader.negotiatingCustomerMadCd + ': D ALL US/CN OB/IB/TP';
    dataFromSales.ratingTariff = this.salesRfpData.salesRfpTariff ? this.salesRfpData.salesRfpTariff.tariffCd : '';
    dataFromSales.applicableTypeCd = this.constantsService.OPP;
    dataFromSales.ruleTypeCd = this.constantsService.OVERRIDECONTRACTDISC;
    dataFromSales.trialPeriodTypeCd = trialPeriodTypeCd;

    dataFromSales.fakInd = this.salesRfpData.fakOverrides && this.salesRfpData.fakOverrides.length > 0;
    dataFromSales.cdiRuleInd = false;
    dataFromSales.laneId = dataFromSales.fakInd ? 'OPPFAK' : this.constantsService.OPP;
    dataFromSales.designationType = OptimizedPricing.ac_portfolio;
    dataFromSales.interStateInd = true;
    dataFromSales.intraStateInd = true;
    dataFromSales.internationalInd = true;

    dataFromSales.outboundPrepaidInd = true;
    dataFromSales.outboundColInd = true;
    dataFromSales.inboundPrepaidInd = true;
    dataFromSales.thirdPartyInd = true;
    dataFromSales.marsInd = true;

    dataFromSales.minimumCommodityClassNbr = minimumCommodityClassNbr ? minimumCommodityClassNbr.name : 1;
    dataFromSales.maximumCommodityClassNbr = maximumCommodityClassNbr ? maximumCommodityClassNbr.name : 500;

    dataFromSales.qualifyBy = [
      {
        qualifierCd: this.constantsService.COUNTRY,
        origInclusions: origInclusions ? origInclusions.name : 'US,CN',
        destInclusions: destInclusions ? origInclusions.name : 'US,CN',
        origExclusions: '',
        destExclusions: '',
      },
      {
        qualifierCd: this.constantsService.LOCATION,
        origInclusions: this.salesRfpData.salesRfpHeader.negotiatingCustomerMadCd,
        destInclusions: this.salesRfpData.salesRfpHeader.negotiatingCustomerMadCd,
        origExclusions: '',
        destExclusions: '',
      },
    ];
    dataFromSales.priceRuleDiscounts = [
      {
        backHaulDiscountPercentage: this.salesRfpData.salesRfpHeader.dynamicPricingDiscountPercentage / 100,
        headHaulDiscountPercentage: this.salesRfpData.salesRfpHeader.dynamicPricingDiscountPercentage / 100,
        neutralDiscountPercentage: this.salesRfpData.salesRfpHeader.dynamicPricingDiscountPercentage / 100,
        minimumWeight: minimumWeight ? minimumWeight.name : 1,
        maximumWeight: maximumWeight ? maximumWeight.name : 5000,
        externalRuleCdBackHaul: `${AccesorialsType.discountXss}${this.salesRfpData.salesRfpHeader.dynamicPricingDiscountPercentage}D`,
        externalRuleCdHeadHaul: `${AccesorialsType.discountXss}${this.salesRfpData.salesRfpHeader.dynamicPricingDiscountPercentage}D`,
        externalRuleCdNeutral: `${AccesorialsType.discountXss}${this.salesRfpData.salesRfpHeader.dynamicPricingDiscountPercentage}D`,
        discountSequenceNbr: 1,
      },
    ];

    this.dynamicRuleForm = _.cloneDeepWith(this.dynamicRule.createDynamicRuleForm(dataFromSales));
    this.laneDiscountService.processDiscounts(dataFromSales);
    this.amcOverrideService.processAmcOverride(dataFromSales);
    this.acOverrideService.processAcOverride(dataFromSales);
  }

  initializeComponent(data: DynamicRule) {
    this.unsubscriber.complete();
    this.unsubscriber = new Unsubscriber();

    this.trafficTypes = this.dynamicRule.getTrafficTypes();
    this.applicationTypes = this.dynamicRule.getApplicationTypes();

    this.dynamicRuleData = data;

    this.rateCount = this.dynamicRuleData ? this.dynamicRuleData.shipmentCount : 0;
    this.quoteCount = this.dynamicRuleData ? this.dynamicRuleData.rateQuoteDateCount : 0;
    this.priceRuleId = this.dynamicRuleData ? this.dynamicRuleData.priceRuleId : null;

    this.dropDownOptions = this.dynamicRule.getDropDownOptions();

    if (this.salesRfpData) {
      this.fillFormWithSalesRfpData();
    } else {
      this.dynamicRuleForm = _.cloneDeepWith(this.dynamicRule.createDynamicRuleForm(this.dynamicRuleData));
      this.laneDiscountService.processDiscounts(this.dynamicRuleData);
      this.amcOverrideService.processAmcOverride(this.dynamicRuleData);
      this.acOverrideService.processAcOverride(this.dynamicRuleData);
    }

    this.sicStateForm = this.dynamicRule.getSicStateOptions();
    if (this.dynamicRuleData && this.dynamicRuleData.currentOwnerEmployeeId) {
      this.pwbService
        .getAnalyst(this.dynamicRuleData.currentOwnerEmployeeId)
        .pipe(takeUntil(this.unsubscriber.done), take(1))
        .subscribe(
          (resp: AdminPersonnel) => {
            this.currentOwner = resp ? resp : new AdminPersonnel();
          },
          () => {
            this.currentOwner.employeeFullName = '';
          }
        );
    } else {
      this.currentOwner.employeeFullName = '';
    }
    this.pricingDetail = !!this.route.snapshot?.data?.readonly;
    this.editable = this.dynamicRuleData
      ? this.pricingDetail
        ? false
        : this.ruleListService.isEditableRule(this.dynamicRuleData.statusCd, this.dynamicRuleData.expiryDate)
      : true;
    this.laneDiscountService.invalidDiscountData$.pipe(takeUntil(this.unsubscriber.done)).subscribe((invalid) => {
      if (invalid) {
        this.editable = false;
        this.disableEffectiveDate();
      }
    });
    if (this.editable) {
      this.disableEffectiveDate();
    } else {
      this.dynamicRuleForm.disable();
    }
    this.minEffectiveDate = this.today;
    this.minExpiryDate = this.today;

    this.subscribeToFormValueChanges();
    this.laneDiscountService.discountDataChanged$
      .pipe(
        takeUntil(this.unsubscriber.done),
        filter((persist) => persist)
      )
      .subscribe(() => {
        if (this.priceRuleId && this.editable) {
          this.updateDynamicRule();
        }
      });
  }

  ngOnInit() {
    this.laneDiscountService.discountList$.subscribe((discountList) => {
      if (discountList[0]?.data[0]?.externalRuleCdHeadHaul && this.dynamicRuleForm) {
        this.dynamicRuleForm.controls.cdiRuleInd.setValue(false);
        const pattern = /[0-9]/g;
        const letter =
          discountList[0]?.data[0]?.externalRuleCdHeadHaul[discountList[0]?.data[0]?.externalRuleCdHeadHaul.length - 1];
        if (letter.match(pattern)) {
          this.dynamicRuleForm.controls.ruleTypeCd.setValue(this.constantsService.DISCOFTOTAL);
          this.dynamicRuleForm.controls.rateType.setValue(this.constantsService.LINEHAUL_AC_FSC);
        } else {
          switch (letter) {
            case 'H':
              this.dynamicRuleForm.controls.ruleTypeCd.setValue(this.constantsService.DISCOFLINEHAUL);
              this.dynamicRuleForm.controls.rateType.setValue(this.constantsService.LINEHAUL);
              break;
            case 'L':
              this.dynamicRuleForm.controls.ruleTypeCd.setValue(this.constantsService.DISCOFCONTRACT);
              this.dynamicRuleForm.controls.rateType.setValue(this.constantsService.LINEHAUL);
              break;
            case 'D':
              this.dynamicRuleForm.controls.ruleTypeCd.setValue(this.constantsService.OVERRIDECONTRACTDISC);
              this.dynamicRuleForm.controls.rateType.setValue(this.constantsService.LINEHAUL);
              break;
            case 'C':
              this.dynamicRuleForm.controls.ruleTypeCd.setValue(this.constantsService.CDIDISC);
              this.dynamicRuleForm.controls.rateType.setValue(this.constantsService.LINEHAUL_FSC);
              this.dynamicRuleForm.controls.cdiRuleInd.setValue(true);
              break;
            default:
              this.dynamicRuleForm.controls.ruleTypeCd.setValue(null);
              this.dynamicRuleForm.controls.rateType.setValue(null);
              this.snackbar.warn('Enter a valid Rule Code');
              break;
          }
        }
      } else if (this.dynamicRuleForm) {
        this.dynamicRuleForm.controls.cdiRuleInd.setValue(false);
        this.dynamicRuleForm.controls.ruleTypeCd.setValue(null);
        this.dynamicRuleForm.controls.rateType.setValue(null);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.pricingRuleId?.currentValue) {
      this.dynamicRuleDetailResolverService.getDynamicRule(changes?.pricingRuleId.currentValue).subscribe((r) => {
        this.dynamicRuleForm = null;
        this.initializeComponent(r);
      });
    } else {
      this.initializeComponent(null);
    }
  }

  private customerGroupAutocompleteInit(): void {
    let firstCharge = true;
    this.dynamicRuleForm.controls.customerGroupId.valueChanges
      .pipe(
        startWith(''),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap((value) => {
          if (this.selectedGroup && this.dynamicRuleData.customerGroupId) {
            this.selectedGroup = false;
            return this.customerGroupService.getCustomerGroup(this.dynamicRuleData.customerGroupId, true);
          } else {
            return this.customerGroupService.getCustomersGroupsByName(value).pipe(
              finalize(() => {
                this.isLoading = false;
              })
            );
          }
        })
      )
      .subscribe((data) => {
        if (firstCharge && this.dynamicRuleForm.controls.customerGroupId.value) {
          firstCharge = false;
          this.filteredOptions = data;
          this.updateCustomerGroupId();
        }
        this.filteredOptions = data;
      });
  }

  private updateCustomerGroupId(): void {
    this.dynamicRuleForm.controls.customerGroupId.setValue(this.dynamicRuleForm.controls.customerGroupId.value);
  }

  getName(id: string) {
    if (id && this.filteredOptions && this.filteredOptions.length) {
      return this.filteredOptions.find((group) => group.customerGroupId === id)?.name;
    } else if (id && this.dynamicRuleData.customerGroupId) {
      this.searchGroupById = true;
    }
  }

  ngOnDestroy(): void {
    const result = this.subscription ? this.subscription.unsubscribe() : null;
    this.unsubscriber.complete();
  }

  updateDiscountRange(paramEffectiveDate: Moment, paramExpiryDate: Moment) {
    const singlePeriod = this.laneDiscountService.DiscountList.length === 1;

    const effectiveDate = paramEffectiveDate
      ? paramEffectiveDate
      : singlePeriod && this.formControls && this.formControls.effectiveDate.value
      ? moment(this.formControls.effectiveDate.value)
      : null;

    const expiryDate = paramExpiryDate
      ? paramExpiryDate
      : singlePeriod && this.formControls && this.formControls.expiryDate.value
      ? moment(this.formControls.expiryDate.value)
      : null;

    if (
      (singlePeriod && effectiveDate && expiryDate && effectiveDate <= expiryDate) ||
      (!singlePeriod && (effectiveDate || expiryDate))
    ) {
      this.laneDiscountService.updateRuleDates(effectiveDate, expiryDate);
      this.updateOverrideDates();
    }
  }

  subscribeToFormValueChanges() {
    this.formControls.effectiveDate.valueChanges
      .pipe(
        takeUntil(this.unsubscriber.done),
        distinctUntilChanged(),
        filter((value) => value)
      )
      .subscribe((val) => {
        const momentValue = moment(val);
        if (momentValue.isSameOrAfter(this.today, 'day')) {
          this.minExpiryDate = new Date(val);
        }
        this.formControls.expiryDate.markAsTouched();
        this.updateDiscountRange(momentValue, null);
      });
    this.formControls.expiryDate.valueChanges
      .pipe(
        takeUntil(this.unsubscriber.done),
        distinctUntilChanged(),
        filter((value) => value)
      )
      .subscribe((val) => {
        const momentValue = moment(val);
        this.updateDiscountRange(null, momentValue);
      });

    this.formControls.origQualifyBy.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((val) => {
      this.onQualifyByChange(this.constantsService.ORIG, val);
    });
    this.formControls.destQualifyBy.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((val) => {
      this.onQualifyByChange(this.constantsService.DEST, val);
    });
    this.formControls.origLowZip.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((val) => {
      this.onZipCodeChange('origHighZip', val);
    });
    this.formControls.origHighZip.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((val) => {
      this.onZipCodeChange('origLowZip', val);
    });
    this.formControls.destLowZip.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((val) => {
      this.onZipCodeChange('destHighZip', val);
    });
    this.formControls.destHighZip.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((val) => {
      this.onZipCodeChange('destLowZip', val);
    });
    if (this.formControls.cdiRuleInd.value) {
      this.formControls.rateType.disable();
    }
    /** LDP-2304
    this.formControls.cdiRuleInd.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((val) => {
      if (val) {
        this.formControls.rateType.patchValue(DynamicPriceRateTypeCd.PCT_RATE_BY_TOTAL);
        this.formControls.rateType.disable();
      } else {
        this.formControls.rateType.enable();
      }
    });*/
    this.formControls.applicableType.valueChanges.subscribe((val) => {
      if (val === this.constantsService.EARNED_DISCOUNT) {
        this.formControls.agreementAcctCd.setValidators([Validators.required]);
        this.formControls.thresholdPeriod.setValidators([Validators.required]);
        this.formControls.agreementAcctCd.updateValueAndValidity();
        this.formControls.agreementAcctCd.markAsTouched();
      } else {
        this.formControls.agreementAcctCd.setValidators([]);
        this.formControls.thresholdPeriod.setValidators([]);
        this.formControls.agreementAcctCd.updateValueAndValidity();
      }
      if (val === this.constantsService.OPP) {
        this.formControls.trialPeriodTypeCd.setValue(null);
        this.formControls.trialPeriodTypeCd.enable();
        this.formControls.trialPeriodTypeCd.setValidators([Validators.required]);
      } else {
        this.formControls.trialPeriodTypeCd.disable();
        this.formControls.trialPeriodTypeCd.setValue(null);
        this.formControls.trialPeriodTypeCd.setValidators([]);
      }
      this.formControls.trialPeriodTypeCd.updateValueAndValidity();
    });

    if (this.formControls.thresholdPeriod.enabled) {
      this.onThresholdPeriodChange(this.formControls.thresholdPeriod.value, this.formControls.thresholdPeriod.value);
      this.formControls.thresholdPeriod.valueChanges
        .pipe(
          takeUntil(this.unsubscriber.done),
          distinctUntilChanged(),
          pairwise() // gets a pair of old and new value
        )
        .subscribe(([prev, next]: [any, any]) => {
          this.onThresholdPeriodChange(prev, next);
        });
      this.formControls.thresholdPeriod.valueChanges
        .pipe(takeUntil(this.unsubscriber.done), distinctUntilChanged(), take(1))
        .subscribe((value: any) => {
          this.onThresholdPeriodChange(value, value);
        });
    }

    this.formControls.autoAdjustGroup.setValidators(
      conditionalValidator(() => this.formControls.autoAdjustAllowedInd.value === true, Validators.required)
    );

    this.formControls.autoAdjustAllowedInd.valueChanges.subscribe(() => {
      this.formControls.autoAdjustGroup.updateValueAndValidity();
      this.formControls.autoAdjustGroup.markAsTouched();
    });

    this.initializeIncrementalPricingRequiredFields(
      this.formControls.thresholdAmount,
      this.formControls.minimumShipmentsNbr,
      this.dynamicRule.thresholdAmountValidators,
      this.dynamicRule.minimumShipmentsNbrValidators
    );

    this.initializeIncrementalPricingRequiredFields(
      this.formControls.minimumShipmentsNbr,
      this.formControls.thresholdAmount,
      this.dynamicRule.minimumShipmentsNbrValidators,
      this.dynamicRule.thresholdAmountValidators
    );
    this.selectedGroup =
      this.priceRuleId && !this.filteredOptions.length && this.dynamicRuleForm.controls.customerGroupId.value;
    this.customerGroupAutocompleteInit();
  }

  initializeIncrementalPricingRequiredFields(
    primaryControl: AbstractControl,
    secondaryControl: AbstractControl,
    primaryValidators: ValidatorFn[],
    secondaryValidators: ValidatorFn[]
  ) {
    primaryControl.valueChanges.pipe(takeUntil(this.unsubscriber.done)).subscribe((value: string) => {
      if (value === null) {
        if (secondaryControl.value === null) {
          primaryControl.setValidators([...primaryValidators, Validators.required]);
          primaryControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
          secondaryControl.setValidators([...secondaryValidators, Validators.required]);
          secondaryControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        } else {
          primaryControl.setValidators(primaryValidators);
          primaryControl.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false,
          });
        }
      } else if (secondaryControl.value === null) {
        secondaryControl.setValidators(secondaryValidators);
        secondaryControl.updateValueAndValidity({
          onlySelf: true,
          emitEvent: false,
        });
      }
    });
  }

  onThresholdPeriodChange(prev: any, next: any) {
    if (next) {
      this.formControls.thresholdAmount.enable();
      this.formControls.minimumShipmentsNbr.enable();

      let thresholdAmountValidators = this.dynamicRule.thresholdAmountValidators;
      if (this.formControls.minimumShipmentsNbr.value === null) {
        thresholdAmountValidators = [...thresholdAmountValidators, Validators.required];
      }
      this.formControls.thresholdAmount.setValidators(thresholdAmountValidators);

      let minimumShipmentsNbrValidators = this.dynamicRule.minimumShipmentsNbrValidators;
      if (this.formControls.thresholdAmount.value === null) {
        minimumShipmentsNbrValidators = [...minimumShipmentsNbrValidators, Validators.required];
      }
      this.formControls.minimumShipmentsNbr.setValidators(minimumShipmentsNbrValidators);

      if (
        prev === null &&
        this.formControls.thresholdAmount.value === null &&
        this.dynamicRuleData &&
        this.dynamicRuleData.thresholdAmount
      ) {
        this.formControls.thresholdAmount.patchValue(this.dynamicRuleData.thresholdAmount);
      }
      if (
        prev === null &&
        this.formControls.minimumShipmentsNbr.value === null &&
        this.dynamicRuleData &&
        this.dynamicRuleData.minimumShipmentsNbr
      ) {
        this.formControls.minimumShipmentsNbr.patchValue(this.dynamicRuleData.minimumShipmentsNbr);
      }

      this.formControls.agreementAcctCd.setValidators([Validators.required]);
    } else {
      this.formControls.thresholdAmount.disable();
      this.formControls.minimumShipmentsNbr.disable();

      this.formControls.thresholdAmount.patchValue(null);
      this.formControls.minimumShipmentsNbr.patchValue(null);

      this.formControls.thresholdAmount.updateValueAndValidity();
      this.formControls.minimumShipmentsNbr.setValidators(this.dynamicRule.minimumShipmentsNbrValidators);

      this.formControls.agreementAcctCd.setValidators(null);
    }
    this.formControls.thresholdAmount.updateValueAndValidity();
    this.formControls.minimumShipmentsNbr.updateValueAndValidity();
    this.formControls.agreementAcctCd.updateValueAndValidity();
  }

  getErrorMessage(value, name?) {
    if (value.hasError('required')) {
      return 'This is required';
    } else if (name === 'expiryDate' && value.hasError('matDatepickerMin')) {
      return 'Expiry Date should be greater than today and effective Date';
    } else if (name === 'effectiveDate' && value.hasError('matDatepickerMin')) {
      return 'Effective Date should be equal to today or in future';
    } else if (name === 'effectiveDate' && value.hasError('matDatepickerMax')) {
      return 'Effective Date should be less than expiry Date';
    } else if (name === 'maxClass' && value.hasError('max')) {
      return 'Max Class should be less than 500';
    } else if (name === 'maxClass' && value.hasError('min')) {
      return 'Max Class should be greater than or equal to Min Class';
    } else if (name === 'minClass' && value.hasError('min')) {
      return 'Min Class should be greater than 0';
    } else if (name === 'minClass' && value.hasError('max')) {
      return 'Min Class should be less than or equal to Max Class and less than 500';
    } else if ((name === 'customerShipmentDays' || name === 'laneShipmentDays') && value.hasError('min')) {
      return `${name} should be greater than 0`;
    } else if ((name === 'customerShipmentDays' || name === 'laneShipmentDays') && value.hasError('max')) {
      return `${name} should be less than or equal to  9999`;
    } else if (value.hasError('pattern')) {
      return 'Invalid Zip';
    } else if (name === 'agreementAcctCd') {
      return 'The Agreement Account doesn\'t exist';
    } else if (name === 'designationType') {
      return 'Designation type should be less than 20';
    } else if (name === 'laneId' && value.hasError('max')) {
      return 'Lane should be less than 100';
    } else if (name === 'dynamicCode' && value.hasError('maxlength')) {
      return 'Max length is 10 characters';
    } else if (name === 'ratingTariff' && value.hasError('maxlength')) {
      return 'Max length is 6 characters';
    } else if (name === 'thresholdAmount' && value.hasError('min')) {
      return 'Should be greater or equal than 1';
    } else if (name === 'thresholdAmount' && value.hasError('max')) {
      return 'Should be less or equal than 99999999';
    } else if (name === 'minimumShipmentsNbr' && value.hasError('min')) {
      return 'Should be greater or equal than 1';
    } else if (name === 'minimumShipmentsNbr' && value.hasError('max')) {
      return 'Should be less or equal than 9999';
    } else if (name === 'thresholdPeriod' && value.hasError('thresholdPeriodEmpty')) {
      return 'New Threshold Period can\'t be empty';
    }
  }

  onQualifyByChange(type, val) {
    const formValue = this.dynamicRuleForm.value,
      option = this.dynamicRule.getOrigDestOptions(type);
    switch (val) {
      case this.constantsService.SIC:
        const sicList = <FormArray>_.cloneDeep(this.sicStateForm.get('sic'));
        this.dynamicRuleForm.setControl(
          option.optionList,
          this.dynamicRule.removeSelectedRows(formValue[option.selectedList], sicList, 'name', option.value)
        );
        break;
      case this.constantsService.STATE:
        const stateList = <FormArray>_.cloneDeep(this.sicStateForm.get('state'));
        this.dynamicRuleForm.setControl(
          option.optionList,
          this.dynamicRule.removeSelectedRows(formValue[option.selectedList], stateList, 'name', option.value)
        );
        break;
      case this.constantsService.COUNTRY:
        const countryList = <FormArray>_.cloneDeep(this.dynamicRule.getCountryOptions());
        this.dynamicRuleForm.setControl(
          option.optionList,
          this.dynamicRule.removeSelectedRows(formValue[option.selectedList], countryList, 'name', option.value)
        );
        break;
    }
  }

  onZipCodeChange(zipType, val) {
    if (val) {
      if (isNumeric(val)) {
        this.formControls[zipType].setValidators(Validators.pattern(this.constantsService.US_ZIP));
      } else {
        this.formControls[zipType].setValidators(Validators.pattern(this.constantsService.CANADA_ZIP));
      }
    } else {
      this.formControls[zipType].setValidators(Validators.pattern(this.constantsService.US_CANADA_ZIP));
      this.formControls[zipType].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  addToSelectedList(type) {
    this.dynamicRule.addToSelectedList(this.dynamicRuleForm, type);
  }

  showInputOption(type) {
    return this.showOptionsBasedOnQualifyBy(type) === this.constantsService.INPUT_OPTION;
  }

  showZipOption(type) {
    return this.showOptionsBasedOnQualifyBy(type) === this.constantsService.ZIP_OPTION;
  }

  showCheckBoxOption(type) {
    return this.showOptionsBasedOnQualifyBy(type) === this.constantsService.CHECK_BOX_OPTION;
  }

  showOptionsBasedOnQualifyBy(type) {
    const option = this.dynamicRule.getOrigDestOptions(type);
    switch (this.dynamicRuleForm.get(option.qualifyBy).value) {
      case this.constantsService.SIC:
      case this.constantsService.STATE:
      case this.constantsService.COUNTRY:
        return this.constantsService.CHECK_BOX_OPTION;
      case this.constantsService.ZIPCODE:
        return this.constantsService.ZIP_OPTION;
      default:
        return this.constantsService.INPUT_OPTION;
    }
  }

  disableForwardBtn(type) {
    const option = this.dynamicRule.getOrigDestOptions(type);
    const formValue = this.dynamicRuleForm.value;
    switch (formValue[option.qualifyBy]) {
      case this.constantsService.SIC:
      case this.constantsService.STATE:
      case this.constantsService.COUNTRY:
        return !formValue[option.inEx] || _.filter(formValue[option.optionList], { selected: true }).length === 0;
      case this.constantsService.ZIPCODE:
        return (
          !formValue[option.inEx] ||
          !formValue[option.lowZip] ||
          this.formControls[option.lowZip].invalid ||
          this.formControls[option.highZip].invalid
        );
      default:
        return !formValue[option.inEx] || !formValue[option.input];
    }
  }

  disableBackBtn(type) {
    const formValue = this.dynamicRuleForm.value,
      option = this.dynamicRule.getOrigDestOptions(type);
    return _.filter(formValue[option.selectedList], { selected: true }).length === 0;
  }

  displaySelectedValue(optionVal, type) {
    const value = this.dynamicRule.getOrigDestOptions(type).value;
    if (optionVal.qualifyCd === this.constantsService.LOCATION) {
      const val = optionVal[value].split('-');
      return _.compact(val)[0];
    } else {
      return optionVal[value];
    }
  }

  enabledOverrides(): boolean {
    return this.dynamicRuleForm.get('status').value === this.constantsService.PENDING && this.editable;
  }

  disableEffectiveDate() {
    if (
      this.dynamicRuleData &&
      this.dynamicRuleData.ruleVersionNbr > 1 &&
      this.dynamicRuleData.statusCd === this.constantsService.PENDING
    ) {
      this.dynamicRuleForm.get('effectiveDate').disable();
    }
  }

  removeFromSelectedList(type) {
    this.dynamicRule.removeFromSelectedList(this.dynamicRuleForm, type);
  }

  getButtonLabel() {
    switch (this.dynamicRuleData.statusCd) {
      case this.constantsService.PENDING:
        return this.constantsService.ACTIVE;
      case this.constantsService.ACTIVE:
        return this.constantsService.IN_ACTIVE;
      default:
        return 'ERROR!';
    }
  }

  agreementAcctCdErrorAction() {
    this.formControls['agreementAcctCd'].setErrors({ incorrect: true });
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.snackbar.error('Incorrect agreement account');
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }

  agreementAcctCdPndBillToErrorAction() {
    this.formControls['agreementAcctCd'].setErrors({ incorrect: true });
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.snackbar.error('Agreement account should not be a PND or Bill To Account it should be Corporate ');
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getDiscountData() {
    return this.laneDiscountService.getDiscountListFlat().map((discount) => {
      if (discount.listActionCd === ActionCd.ADD) {
        return {
          ...new PriceRuleDiscount(),
          ...discount,
          discountSequenceNbr: null,
        };
      } else {
        return discount;
      }
    });
  }

  createDynamicRule() {
    const priceRuleData = {
      ...this.dynamicRuleForm.getRawValue(),
      priceRuleDiscounts: this.getDiscountData(),
      amcOverrides: this.getAmcOverridesData(),
      acOverrides: this.getAcOverridesData(),
    };
    this.dynamicRule
      .createDynamicRule(priceRuleData)
      .pipe(take(1))
      .subscribe((resp: CreateDynamicPricingRuleHeaderResp) => {
        this.snackbar.open({
          message: 'Successfully created',
          detailedMessage: `Dynamic Pricing Rule Header: ${resp.dynPrcRule.priceRuleId}`,
          status: 'success',
          matConfig: {
            duration: 20000,
            verticalPosition: 'top',
          },
        });
        this.dynamicRuleTrayService.setReload(true);
        this.dynamicRuleTrayService.setDetailData({ showTray: true, ...resp.dynPrcRule });
        this.dataEvent.emit(true);
      });
  }

  createDynamicPricingRule() {
    if (this.dynamicRuleForm.valid && this.validateDiscounts() && this.validateOverrides()) {
      if (!!this.dynamicRuleForm.getRawValue().agreementAcctCd) {
        const acctCd = this.dynamicRuleForm.getRawValue().agreementAcctCd;
        const acctId = this.getAcctId(acctCd);
        this.prcAgmtService
          .listCustomerHierarchy(acctCd, acctId)
          .pipe(take(1))
          .subscribe((data: CustomerHierarchyAccount[]) => {
            if (data.length > 0) {
              const { type: acctPartyRole, customerNbr: acctInstId } = data[0];
              if (this.formControls['applicableType'].value === this.constantsService.EARNED_DISCOUNT) {
                if (acctPartyRole === this.constantsService.CORPORATE) {
                  this.formControls['agreementAcctId'].setValue(acctInstId);
                  this.createDynamicRule();
                } else {
                  this.agreementAcctCdPndBillToErrorAction();
                }
              } else {
                this.formControls['agreementAcctId'].setValue(acctInstId);
                this.createDynamicRule();
              }
            } else {
              this.agreementAcctCdErrorAction();
            }
          });
      } else {
        this.createDynamicRule();
      }
    } else {
      this.validateFormFields();
    }
  }

  validateDiscounts() {
    const hasEmptyRecords = this.laneDiscountService.hasEmptyRecords();
    const effectiveDate = this.dynamicRuleForm.get('effectiveDate').value;
    const expiryDate = this.dynamicRuleForm.get('expiryDate').value;
    const invalidDiscountDates = !this.laneDiscountService.validateDiscountDates(
      moment(effectiveDate),
      moment(expiryDate)
    );

    if (hasEmptyRecords || invalidDiscountDates) {
      this.snackbar.open({
        message: 'Validation error',
        detailedMessage: 'Discount information is missing, please review rule discounts.',
        status: 'error',
        matConfig: {
          duration: 20000,
          verticalPosition: 'top',
        },
      });
    }
    return !hasEmptyRecords && !invalidDiscountDates;
  }

  save() {
    switch (this.dynamicRuleData.statusCd) {
      case this.constantsService.PENDING:
        if (this.dynamicRuleForm.valid && this.validateDiscounts() && this.validateOverrides()) {
          this.updateDynamicPricingRule();
        } else {
          this.validateFormFields();
        }
        break;
      case this.constantsService.ACTIVE:
        if (this.validateDiscounts() && this.validateOverrides()) {
          const editable = Object.keys(this.editableFields);
          const showAlertMessage = this.getDirtyValues().some((field) => !editable.includes(field));
          if (showAlertMessage) {
            const editableLabels = Object.values(this.editableFields).join(', ');
            this.dialog.open(AlertDialogComponent, {
              data: {
                message:
                  `Rule cannot be saved as some other fields are changed other than ${editableLabels}.` +
                  ' Please create a new rule version',
              },
              width: '400px',
            });
          } else if (
            !this.getDirtyValues().includes('expiryDate') ||
            (this.formControls.expiryDate.valid && this.getDirtyValues().includes('expiryDate'))
          ) {
            this.updateDynamicPricingRule();
          }

          break;
        }
    }
  }

  private updateOverrideDates(): void {
    this.overrideDatesChange = true;
  }

  private showErrorMessage(from, additionalInfo?): void {
    this.snackbar.open({
      message: 'Validation error',
      detailedMessage: `${from} information is missing, ${additionalInfo} please review it.`,
      status: 'error',
      matConfig: {
        duration: 20000,
        verticalPosition: 'top',
      },
    });
  }

  private validateOverrides(): boolean {
    const button: any = document.getElementById('unfocus-button');
    if (button) {
      button.click();
    }
    const validAmc = this.amcOverrideService.isValid();
    const validAc = this.acOverrideService.isValid();
    if (validAmc) {
      this.showErrorMessage('Absolute Minimum Charges Overrides ', validAmc);
    }
    if (validAc) {
      this.showErrorMessage('Accessorial Charges Overrides ', validAc);
    }
    return !validAmc && !validAc;
  }

  private getAmcOverridesData(): PriceRuleAmcOverride[] {
    const expiryDate = this.dynamicRuleForm.get('expiryDate').value;
    const effectiveDate = this.dynamicRuleForm.get('effectiveDate').value;
    return this.amcOverrideService.getAmcListFlat().map((amc) => {
      amc.expiryDate = this.formatDateService.transformDateWithFormat(
        expiryDate,
        this.constantsService.dateServiceFormat
      );
      amc.effectiveDate = this.formatDateService.transformDateWithFormat(
        effectiveDate,
        this.constantsService.dateServiceFormat
      );
      if (amc.listActionCd === ActionCd.ADD) {
        return {
          ...new PriceRuleAmcOverride(),
          ...amc,
        };
      } else if (
        (amc.listActionCd === ActionCd.UPDATE || amc.listActionCd === ActionCd.NO_ACTION) &&
        this.overrideDatesChange
      ) {
        this.overrideDatesChange = false;
        return amc;
      } else {
        return amc;
      }
    });
  }

  private getAcOverridesData(): PriceRuleAccessorialOverride[] {
    const button: any = document.getElementById('unfocus-button-ac');
    if (button) {
      button.click();
    }
    const expiryDate = this.dynamicRuleForm.get('expiryDate').value;
    const effectiveDate = this.dynamicRuleForm.get('effectiveDate').value;
    return this.acOverrideService.getAcListFlat().map((ac) => {
      ac.expiryDate = this.formatDateService.transformDateWithFormat(
        expiryDate,
        this.constantsService.dateServiceFormat
      );
      ac.effectiveDate = this.formatDateService.transformDateWithFormat(
        effectiveDate,
        this.constantsService.dateServiceFormat
      );
      ac.minAmount = 0;
      ac.maxAmount = 0;
      if (ac.listActionCd === ActionCd.ADD) {
        const acOverride = new PriceRuleAccessorialOverride();
        ac.typeCd = ac.typeCd.slice(0, 3);
        return {
          ...acOverride,
          ...ac,
        };
      } else {
        return ac;
      }
    });
  }

  private getOverridesIndicator(list) {
    let indicator = YesNoEnum.shortNo;
    list.map((item) => {
      if (item.listActionCd !== ActionCd.DELETE) {
        indicator = YesNoEnum.shortYes;
      }
    });
    return indicator;
  }

  updateDynamicRule() {
    const priceRuleData = {
      ...this.dynamicRuleForm.getRawValue(),
      priceRuleDiscounts: this.getDiscountData(),
      amcOverrides: this.getAmcOverridesData(),
      acOverrides: this.getAcOverridesData(),
    };
    priceRuleData.accessorialOverrideCd = this.getOverridesIndicator(priceRuleData.acOverrides);
    priceRuleData.amcOverrideCd = this.getOverridesIndicator(priceRuleData.amcOverrides);
    this.dynamicRule
      .updateDynamicRule(priceRuleData, this.getRuleDetails())
      .pipe(take(1))
      .subscribe((resp: UpdateDynamicPricingRuleHeaderResp) => {
        this.snackbar.success('Dynamic Pricing Rule Header was Successfully Updated!');
        this.dynamicRuleData = resp.dynPrcRule;
        this.dynamicRuleForm = this.dynamicRule.createDynamicRuleForm(this.dynamicRuleData);
        this.laneDiscountService.processDiscounts(this.dynamicRuleData);
        this.amcOverrideService.processAmcOverride(this.dynamicRuleData);
        this.acOverrideService.processAcOverride(this.dynamicRuleData);
        this.subscribeToFormValueChanges();
        this.disableEffectiveDate();
        this.dynamicRuleTrayService.setReload(true);
        this.dynamicRuleTrayService.setDetailData({ showTray: true, ...resp.dynPrcRule });
      });
  }

  updateDynamicPricingRule() {
    if (!!this.dynamicRuleForm.getRawValue().agreementAcctCd) {
      const acctCd = this.dynamicRuleForm.getRawValue().agreementAcctCd;
      const acctId = this.getAcctId(acctCd);
      this.prcAgmtService
        .listCustomerHierarchy(acctCd, acctId)
        .pipe(take(1))
        .subscribe((data: CustomerHierarchyAccount[]) => {
          if (data.length > 0) {
            const { type: acctPartyRole, customerNbr: acctInstId } = data[0];
            if (this.formControls['applicableType'].value === this.constantsService.EARNED_DISCOUNT) {
              if (acctPartyRole === this.constantsService.CORPORATE) {
                this.formControls['agreementAcctId'].setValue(acctInstId);
                this.updateDynamicRule();
              } else {
                this.agreementAcctCdPndBillToErrorAction();
              }
            } else {
              this.formControls['agreementAcctId'].setValue(acctInstId);
              this.updateDynamicRule();
            }
          } else {
            this.agreementAcctCdErrorAction();
          }
        });
    } else {
      if (this.formControls.agreementAcctId.value) {
        this.formControls.agreementAcctId.setValue('');
      }
      this.updateDynamicRule();
    }
  }

  setNewVersion() {
    const priceRuleData = {
      ...this.dynamicRuleForm.getRawValue(),
      priceRuleDiscounts: this.getDiscountData(),
      amcOverrides: this.getAmcOverridesData(),
      acOverrides: this.getAcOverridesData(),
    };
    this.dynamicRule
      .createNewVersion(priceRuleData, this.getRuleDetails())
      .pipe(take(1))
      .subscribe((resp: CreateDynamicPricingRuleHeaderResp) => {
        this.snackbar.open({
          message: 'Successfully created',
          detailedMessage: `Dynamic Pricing Rule Header: ${resp.dynPrcRule.priceRuleId}`,
          status: 'success',
          matConfig: {
            duration: 20000,
            verticalPosition: 'top',
          },
        });
        this.dynamicRuleTrayService.setReload(true);
        this.dynamicRuleTrayService.setDetailData({ showTray: true, ...resp.dynPrcRule });
      });
  }

  createNewVersion() {
    switch (this.dynamicRuleData.statusCd) {
      case this.constantsService.PENDING:
        this.dialog.open(AlertDialogComponent, {
          data: {
            message: 'A new version cannot be created for a Pending Rule.',
          },
          width: '400px',
        });
        break;
      case this.constantsService.ACTIVE:
        if (this.dynamicRuleForm.valid && this.validateDiscounts() && this.validateOverrides()) {
          if (!!this.dynamicRuleForm.getRawValue().agreementAcctCd) {
            const account = this.dynamicRuleForm.getRawValue().agreementAcctCd;
            this.prcAgmtService
              .listCustomerHierarchy([account], this.getAcctId(account))
              .pipe(take(1))
              .subscribe((data) => {
                if (data.length > 0) {
                  const { type: acctPartyRole, customerNbr: acctInstId } = data[0];
                  if (this.formControls['applicableType'].value === this.constantsService.EARNED_DISCOUNT) {
                    if (acctPartyRole === this.constantsService.CORPORATE) {
                      this.formControls['agreementAcctId'].setValue(acctInstId);
                      this.setNewVersion();
                    } else {
                      this.agreementAcctCdPndBillToErrorAction();
                    }
                  } else {
                    this.formControls['agreementAcctId'].setValue(acctInstId);
                    this.setNewVersion();
                  }
                } else {
                  this.agreementAcctCdErrorAction();
                }
              });
          } else {
            this.setNewVersion();
          }
        } else {
          this.validateFormFields();
        }
    }
  }

  updateRuleStatus() {
    if (this.dynamicRuleForm.valid && this.validateDiscounts() && this.validateOverrides()) {
      const priceRuleData = {
        ...this.dynamicRuleForm.getRawValue(),
        priceRuleDiscounts: this.getDiscountData(),
        amcOverrides: this.getAmcOverridesData(),
        acOverrides: this.getAcOverridesData(),
      };
      priceRuleData.accessorialOverrideCd = this.getOverridesIndicator(priceRuleData.acOverrides);
      priceRuleData.amcOverrideCd = this.getOverridesIndicator(priceRuleData.amcOverrides);
      this.dynamicRule
        .updateDynamicRuleStatus(priceRuleData, this.getButtonLabel(), this.getRuleDetails())
        .pipe(take(1))
        .subscribe((resp: UpdateDynamicPricingRuleHeaderResp) => {
          this.snackbar.success('Successfully updated the status of Dynamic Pricing Rule Header');
          this.dynamicRuleData = resp.dynPrcRule;
          this.dynamicRuleForm = this.dynamicRule.createDynamicRuleForm(this.dynamicRuleData);
          this.laneDiscountService.processDiscounts(this.dynamicRuleData);
          this.amcOverrideService.processAmcOverride(this.dynamicRuleData);
          this.acOverrideService.processAcOverride(this.dynamicRuleData);
          this.editable = this.dynamicRuleData
            ? this.ruleListService.isEditableRule(this.dynamicRuleData.statusCd, this.dynamicRuleData.expiryDate)
            : true;
          if (this.editable) {
            this.disableEffectiveDate();
          } else {
            setTimeout(() => this.dynamicRuleForm.disable());
          }
          this.subscribeToFormValueChanges();
          this.dynamicRuleTrayService.setReload(true);
          this.dynamicRuleTrayService.setDetailData({ showTray: true, ...resp.dynPrcRule });
        });
    } else {
      this.validateFormFields();
    }
  }

  uploadDynamicPricingRules() {
    this.dialog.open(DynamicRuleUploadComponent, {
      data: { form: this.dynamicRuleForm },
    });
  }

  navigateToRuleDetailPage(ruleId: number) {
    this.router.navigate([`${AppRoutes.DYNAMIC_RULE}/${AppRoutes.UPDATE_PRICING_RULE}/${ruleId}`]);
  }

  getRuleDetails() {
    return {
      priceRuleId: this.priceRuleId,
      logicalRuleId: this.dynamicRuleData.logicalRuleId,
      ruleVersionNbr: this.dynamicRuleData.ruleVersionNbr,
    };
  }

  validateFormFields() {
    this.markFormGroupTouched(this.dynamicRuleForm);
    const firstElementWithError = document.querySelector('.ng-invalid');
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  private getDirtyValues(): Array<string> {
    const dirtyValues = [];
    Object.keys(this.dynamicRuleForm.controls).forEach((name) => {
      const currentControl = this.dynamicRuleForm.controls[name];
      if (currentControl.dirty) {
        dirtyValues.push(name);
      }
    });
    return dirtyValues;
  }

  getAcctId(account: any): number[] {
    return !isNaN(account) ? [+account] : null;
  }

  private getCustomersGroups(): void {
    this.customerGroupService.listCustomerGroups().subscribe((data) => {
      this.customerGroupList = data.customerGroups;
    });
  }

  clearCustomerGroup(): void {
    this.dynamicRuleForm.controls.customerGroupId.setValue('');
    this.dynamicRuleForm.controls.customerGroupId.markAsDirty();
  }

  ValidateCustomerGroup(): void {
    const originList = this.dynamicRuleForm.controls.origSelectedList.value;
    const destinyList = this.dynamicRuleForm.controls.destSelectedList.value;
    const locationIncluded =
      !!originList.find((data) => {
        return data.qualifyCd === this.constantsService.LOCATION && data.inExValue === this.constantsService.IN;
      }) ||
      !!destinyList.find((data) => {
        return data.qualifyCd === this.constantsService.LOCATION && data.inExValue === this.constantsService.IN;
      });
    if (this.formControls.customerGroupId.value && locationIncluded) {
      this.formControls.customerGroupId.setErrors({ invalidLocation: true });
    } else if (this.formControls.customerGroupId.errors && this.formControls.customerGroupId.errors.invalidLocation) {
      this.formControls.customerGroupId.setErrors(null);
    }
  }
}
