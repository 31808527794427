import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RatingApiService, RtgRule, ValidateRatingRulesRqst } from '@xpo-ltl-2.0/sdk-rating';
import {
  BulkCreateRatingAmcDetailsResp,
  BulkCreateRatingAmcDetailsRqst,
  DynamicPricingApiService,
  ListRatingAccessorialDetailsQuery,
  RatingAmcDetail,
  UpdateRatingAmcDetailRqst,
} from '@xpo-ltl/sdk-dynamicpricing';
import moment, { Moment } from 'moment';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { AbsoluteMinimumChargeStatus, AbsoluteMinimumChargeTypeCd, RatingRuleGroup } from '../../enums';
import { ConstantsService } from '../constants/constants.service';
import { DynamicPricingStorageService } from '../dynamic-pricing/dynamic-pricing-storage.service';
import { FormatDateService } from '../format-date/format-date.service';

@Injectable({
  providedIn: 'root',
})
export class AbsoluteMinimumChargeService {
  refreshList = new BehaviorSubject<any>(false);
  refreshList$ = this.refreshList.asObservable();

  constructor(
    private formatDateService: FormatDateService,
    private dynamicPricingApiService: DynamicPricingApiService,
    private ratingService: RatingApiService,
    private storageService: DynamicPricingStorageService,
    private constants: ConstantsService
  ) {}

  getTypeCodes() {
    return [
      AbsoluteMinimumChargeTypeCd.miles,
      AbsoluteMinimumChargeTypeCd.international,
      AbsoluteMinimumChargeTypeCd.intrasic,
    ];
  }

  listAbsoluteMinimumCharges(): Observable<Array<RatingAmcDetail>> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    const filters = this.storageService.getAccessorialChargeListParams();
    const request: ListRatingAccessorialDetailsQuery = {
      beginDate: filters.beginDate
        ? this.formatDateService.transformDateWithFormat(filters.beginDate, this.constants.dateServiceFormat)
        : null,
      effectiveDate: filters.effectiveDate
        ? this.formatDateService.transformDateWithFormat(filters.effectiveDate, this.constants.dateServiceFormat)
        : null,
      expiryDate: filters.expiryDate
        ? this.formatDateService.transformDateWithFormat(filters.expiryDate, this.constants.dateServiceFormat)
        : null,
      typeCd: filters.typeCd,
      endDate: null,
    };
    return this.dynamicPricingApiService
      .listRatingAmcDetails(request, {}, { headers })
      .pipe(map((value) => value.ratingAmcDetails));
  }

  listDefaultAbsoluteMinimumCharges(): Observable<Array<RatingAmcDetail>> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    const request: ListRatingAccessorialDetailsQuery = {
      beginDate: this.formatDateService.transformDateWithFormat(moment().toDate(), this.constants.dateServiceFormat),
      effectiveDate: null,
      expiryDate: null,
      typeCd: null,
      endDate: null,
    };
    return this.dynamicPricingApiService
      .listRatingAmcDetails(request, {}, { headers })
      .pipe(map((value) => value.ratingAmcDetails));
  }

  update(request: UpdateRatingAmcDetailRqst): Observable<RatingAmcDetail> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.dynamicPricingApiService
      .updateRatingAmcDetail(
        request,
        {
          ratingAmcDetailId: request.ratingAmcDetail.ratingAmcDetailId,
        },
        {},
        { headers }
      )
      .pipe(map((value) => value.ratingAmcDetail));
  }

  create(request: BulkCreateRatingAmcDetailsRqst): Observable<Array<RatingAmcDetail>> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.dynamicPricingApiService
      .bulkCreateRatingAmcDetails(request, {}, { headers })
      .pipe(map((value: BulkCreateRatingAmcDetailsResp) => value.ratingAmcDetails));
  }

  getStatusBasedOnDates(effectiveDate: Moment, expiryDate: Moment): AbsoluteMinimumChargeStatus {
    const currentDate = moment();
    if (currentDate.isSameOrAfter(effectiveDate) && currentDate.isSameOrBefore(expiryDate)) {
      return AbsoluteMinimumChargeStatus.Active;
    }
    if (currentDate.isAfter(expiryDate)) {
      return AbsoluteMinimumChargeStatus.Expired;
    }
    return AbsoluteMinimumChargeStatus.Pending;
  }

  validateRatingRules(ratingAmcDetails: Array<RatingAmcDetail>, keyEffectiveDate) {
    const requests = [];

    const globalRequest = new ValidateRatingRulesRqst();
    globalRequest.ratingRules = new Array<RtgRule>();

    ratingAmcDetails.forEach((detail) => {
      if (detail.typeCd === AbsoluteMinimumChargeTypeCd.miles) {
        const minMiles = parseInt(detail.minimumMilesNbr, 10);
        const maxMiles = parseInt(detail.maxMilesNbr, 10);
        const batchSize = 300;
        const ranges = [];

        let minValueAccumulator = minMiles;
        let nextMaxValueCandidate = minValueAccumulator + batchSize - 1;
        let nextValue = nextMaxValueCandidate > maxMiles ? maxMiles : nextMaxValueCandidate;
        while (minValueAccumulator <= maxMiles) {
          ranges.push(`${minValueAccumulator.toString()}-${nextValue.toString()}`);

          minValueAccumulator = nextValue + 1;
          nextMaxValueCandidate = nextValue + batchSize;
          nextValue = nextMaxValueCandidate > maxMiles ? maxMiles : nextMaxValueCandidate;
        }

        ranges.forEach((range) => {
          const rating = new RtgRule();
          rating.groupCd = RatingRuleGroup.dynamicPricing;
          rating.typeCd = AbsoluteMinimumChargeTypeCd.miles;
          rating.effectiveDate = detail.effectiveDate;
          rating.expiryDate = detail.expiryDate;
          rating.qualifierCd = range;
          rating.value1 = rating.qualifierCd;
          rating.value2 = detail.chargeAmount.toFixed(2);

          const scopedRequest = new ValidateRatingRulesRqst();
          scopedRequest.ratingRules = new Array<RtgRule>();
          scopedRequest.ratingRules.push(rating);
          scopedRequest.keyEffectiveDate = keyEffectiveDate ? keyEffectiveDate : detail.effectiveDate;
          requests.push(this.ratingService.validateRatingRules(scopedRequest));
        });
      } else {
        const rating = new RtgRule();
        rating.groupCd = RatingRuleGroup.dynamicPricing;
        rating.typeCd = AbsoluteMinimumChargeTypeCd.miles;
        rating.effectiveDate = detail.effectiveDate;
        rating.expiryDate = detail.expiryDate;
        rating.value2 = detail.chargeAmount.toFixed(2);
        rating.qualifierCd = detail.typeCd;
        rating.value1 = rating.qualifierCd;
        globalRequest.ratingRules.push(rating);
        globalRequest.keyEffectiveDate = keyEffectiveDate ? keyEffectiveDate : detail.effectiveDate;
      }
    });

    if (globalRequest.ratingRules.length) {
      requests.push(this.ratingService.validateRatingRules(globalRequest));
    }

    return concat(...requests).pipe(toArray());
  }
}
