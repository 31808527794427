import { AfterViewChecked, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

export enum ValidationKeys {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
}

@Component({
  selector: 'xpo-numeric-cell-editor',
  templateUrl: './numeric-editor.component.html',
  styleUrls: ['./numeric-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--numeric',
  },
})
export class NumericEditorComponent implements ICellEditorAngularComp, AfterViewChecked {
  @ViewChild('input', { static: true }) input;
  params: any;
  value: number;
  maxLength: number;

  ngAfterViewChecked(): void {
    this.input.nativeElement.focus();
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.maxLength = this.params.maxLength;
  }

  getValue(): any {
    return this.value;
  }

  onKeyDown(event): void {
    if (!this.isAllowedKey(event.key) && !this.isKeyPressedNumeric(event.key)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  isCancelAfterEnd(): boolean {
    return this.value && this.value.toString().length > this.maxLength;
  }

  onFocusIn(): void {
    this.input.nativeElement.select();
  }

  onFocusOut(e): void {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }

  private isKeyPressedNumeric(value): boolean {
    return !!/\d/.test(value);
  }

  isAllowedKey(key): boolean {
    const allowed = [
      ValidationKeys.BACKSPACE,
      ValidationKeys.DELETE,
      ValidationKeys.ARROW_LEFT,
      ValidationKeys.ARROW_RIGHT,
    ];
    return allowed.includes(key);
  }
}
