import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicRuleTrayService {
  dynamicRuleSubject = new BehaviorSubject<any>(null);
  dynamicPricingData = this.dynamicRuleSubject.asObservable();

  private reloadList: boolean = false;

  constructor() {}

  setDetailData(dynamicPricingData): void {
    this.dynamicRuleSubject.next(dynamicPricingData);
  }

  setReload(conditional: boolean): void {
    this.reloadList = conditional;
  }

  getReload(): boolean {
    return this.reloadList;
  }
}
