import { DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SdkInfrastructureModule as InfV2Module } from '@xpo-ltl-2.0/sdk-infrastructure';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { SdkRatingModule } from '@xpo-ltl-2.0/sdk-rating';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlReleaseNotesModule } from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoMessagingPopoverModule } from '@xpo-ltl/ngx-ltl-core/messaging-popover';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkDynamicPricingModule } from '@xpo-ltl/sdk-dynamicpricing';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkPricingModule } from '@xpo-ltl/sdk-pricing';
import { SdkPricingAgmtMgmtModule } from '@xpo-ltl/sdk-pricingagmtmgmt';
import { SdkPricingWorkbenchModule } from '@xpo-ltl/sdk-pricingworkbench';
import { SdkRfpMgmtModule } from '@xpo-ltl/sdk-rfpmgmt';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { PapaParseModule } from 'ngx-papaparse';
import { environment } from '../environments/environment';
import { NotificationComponent } from './app-notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './dialogs/dialog.module';
import { DptDiscountListComponent } from './dpt-discount/components/dpt-discount-list/dpt-discount-list.component';
import { DynamicRuleModule } from './dynamic-rule/dynamic-rule.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { YesNoPipe } from './shared/pipes/yes-no/yes-no.pipe';
import { AppHttpInterceptor } from './shared/services/interceptor/interceptor.service';
import { UserPermissionService } from './shared/services/user-permission/user-permission.service';
import { UserService } from './shared/services/user/user.service';
import { SharedModule } from './shared/shared.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, NotificationComponent, LandingPageComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoMessagingPopoverModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkInfrastructureModule,
    InfV2Module,
    SdkUserPreferenceModule,
    SdkPricingModule,
    SdkDynamicPricingModule,
    SdkRatingModule,
    SdkPricingAgmtMgmtModule,
    SdkPricingWorkbenchModule,
    SdkRfpMgmtModule,
    PapaParseModule,
    MatMenuModule,
    DynamicRuleModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.DYN_PRC }),
    AppRoutingModule, // Keep last
    XpoPageNotFoundRoutingModule, // Keep Laster
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    NotificationService,
    DatePipe,
    UserService,
    UserPermissionService,
    DecimalPipe,
    YesNoPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
