import { Component } from '@angular/core';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LaneDiscountDataSource } from '../../../../../shared/services/lane-discount-data-source/lane-discount-data-source';
import { LaneDiscountService } from '../../../../../shared/services/lane-discount/lane-discount.service';

@Component({
  selector: 'ui-icon-action-cell-renderer',
  templateUrl: './icon-action-cell-renderer.component.html',
  styleUrls: ['./icon-action-cell-renderer.component.scss'],
})
export class IconActionCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  isVisible: boolean;
  isExistingRule: boolean;
  hasValue: boolean;

  constructor(
    private laneDiscountService: LaneDiscountService,
    public dataSource: LaneDiscountDataSource,
    private confirmDialog: XpoConfirmDialog
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.hasValue = !!params.value;
    this.isVisible = params.canDelete;
    this.isExistingRule = params.isExistingRule;
  }

  deleteDiscount(): void {
    const sequenceNbr = this.params.data.discountSequenceNbr;
    const period = this.laneDiscountService.getPeriodBySequenceNbr(sequenceNbr);
    const periodVisibleDiscounts = this.laneDiscountService.periodVisibleDiscounts(period);
    if (this.isExistingRule && periodVisibleDiscounts.length === 1) {
      const confirmConfig: XpoConfirmDialogConfig = {
        confirmButtonText: 'Delete',
        rejectButtonText: 'Cancel',
        icon: 'warning',
      };
      this.confirmDialog
        .confirm(
          'Deleting this record will not reset the expiration date of the previous time period.',
          'Are you sure you want to delete this discount?',
          confirmConfig
        )
        .subscribe((confirmed) => {
          if (confirmed) {
            this.laneDiscountService.removeDiscount(sequenceNbr);
            this.dataSource.refresh();
          }
        });
    } else {
      this.laneDiscountService.removeDiscount(sequenceNbr);
      this.dataSource.refresh();
    }
  }

  refresh(params: any): boolean {
    this.isVisible = params.canDelete;
    this.isExistingRule = params.isExistingRule;
    return false;
  }
}
