import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppRoutes } from '../../enums/app-routes.enum';
import { UserPermissionService } from '../../services/user-permission/user-permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private router: Router, private userPermissionService: UserPermissionService) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.userPermissionService.hasPermission(route.data.expectedPermission).pipe(
      tap((hasPermission) => {
        if (!hasPermission) {
          this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
        }
      })
    );
  }
}
