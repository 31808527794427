import { ActionCd } from '@xpo-ltl/sdk-common';
import { PriceRuleDiscount } from '@xpo-ltl/sdk-dynamicpricing';
import moment from 'moment';
import { LaneTypeDiscountRangeModeEnum, LaneTypeDiscountRangeStatusEnum } from '../enums';
export class LaneTypeDiscountRange {
  id: string;
  label?: string = 'Unspecified date range';
  effectiveDate: string;
  expiryDate: string;
  data: Array<PriceRuleDiscount> = [];
  deleted?: boolean = false;
  mode?: LaneTypeDiscountRangeModeEnum = LaneTypeDiscountRangeModeEnum.simple;
  isNewPeriod?: boolean = true;
  status?: LaneTypeDiscountRangeStatusEnum = LaneTypeDiscountRangeStatusEnum.pending;

  get isUsingSimpleMode(): boolean {
    return this.mode === LaneTypeDiscountRangeModeEnum.simple;
  }

  get isUsingAdvancedMode(): boolean {
    return this.mode === LaneTypeDiscountRangeModeEnum.advanced;
  }

  get isExpired(): boolean {
    return this.status === LaneTypeDiscountRangeStatusEnum.expired;
  }

  get isActive(): boolean {
    return this.status === LaneTypeDiscountRangeStatusEnum.active;
  }

  get isPending(): boolean {
    return this.status === LaneTypeDiscountRangeStatusEnum.pending;
  }

  get hasVisibleDiscounts(): boolean {
    return this.data && this.data.some((discount) => discount.listActionCd !== ActionCd.DELETE);
  }

  getStatus(): LaneTypeDiscountRangeStatusEnum {
    if (this.isNewPeriod || (!this.effectiveDate && !this.expiryDate)) {
      return LaneTypeDiscountRangeStatusEnum.pending;
    }
    const currentMoment = moment().startOf('day');
    const effectiveMoment = moment(this.effectiveDate).startOf('day');
    const expiryMoment = moment(this.expiryDate).startOf('day');
    if (currentMoment.isSameOrAfter(effectiveMoment) && currentMoment.isSameOrBefore(expiryMoment)) {
      return LaneTypeDiscountRangeStatusEnum.active;
    }
    if (currentMoment.isAfter(expiryMoment)) {
      return LaneTypeDiscountRangeStatusEnum.expired;
    }
    return LaneTypeDiscountRangeStatusEnum.pending;
  }

  getMode(): LaneTypeDiscountRangeModeEnum {
    if (!this.data.length) {
      return LaneTypeDiscountRangeModeEnum.simple;
    } else {
      const advancedDetected = this.data.some((discount) => {
        return (
          discount.externalRuleCdHeadHaul !== discount.externalRuleCdBackHaul ||
          discount.externalRuleCdHeadHaul !== discount.externalRuleCdNeutral ||
          discount.headHaulDiscountPercentage !== discount.backHaulDiscountPercentage ||
          discount.headHaulDiscountPercentage !== discount.neutralDiscountPercentage
        );
      });
      return advancedDetected ? LaneTypeDiscountRangeModeEnum.advanced : LaneTypeDiscountRangeModeEnum.simple;
    }
  }

  constructor(init?: Partial<LaneTypeDiscountRange>) {
    Object.assign(this, init, {
      id: this.getUniqueId(),
    });
    this.status = this.getStatus();
  }

  private getUniqueId() {
    return Date.now() + (Math.random() * 100000).toFixed().toString();
  }
}
