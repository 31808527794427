<div class="amc-override-container" [ngStyle]="{ 'height.px': getTableSize() }">
  <xpo-board
    id="amcOverride"
    [dataSource]="dataSource"
    [viewTemplates]="viewTemplates"
    [boardOptions]="boardOptions"
    [views]="views"
    (boardReady)="onBoardReady($event)"
  >
    <xpo-board-actions>
      <button class="unfocus-button-ac" id="unfocus-button-ac" (click)="unfocus()"></button>
      <div class="left-bar">
        <button *ngIf="false" type="button" mat-stroked-button xpoSmallButton (click)="getDefaultAc()">
          Load Default AC
        </button>
      </div>
      <xpo-button-group>
        <button type="button" mat-flat-button class="button-add-ac" (click)="addAc()" *ngIf="editable" xpoSmallButton>
          Add AC
        </button>
      </xpo-button-group>
    </xpo-board-actions>

    <xpo-ag-grid-board
      rowSelection="multiple"
      enableMovableColumns="true"
      enableSorting="true"
      selectionMode="cell"
      [customGridOptions]="customGridOptions"
      (gridReady)="onGridReady($event)"
    >
    </xpo-ag-grid-board>
  </xpo-board>
</div>
