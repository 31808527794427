import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { DataOptions } from '@xpo-ltl/data-api';
import { ListInfo } from '@xpo-ltl/sdk-common';
import { PricingApiService, UpdateSalesRfpRqst, UpdateSalesRfpStatusRqst } from '@xpo-ltl/sdk-pricing';
import {
  CancelSalesRfpRequestPath,
  CustomerHierarchyAccount,
  ListClassOverridesResp,
  ListClassOverridesRqst,
  ListCustomerHierarchyQuery,
  ListCustomerHierarchyResp,
  ListPricingAgreementsRqst,
  ListPricingContractsForShipmentResp,
  ListPricingContractsForShipmentRqst,
  ListVerifyReportsPath,
  ListVerifyReportsQuery,
  ListVerifyReportsResp,
  PricingAgmtMgmtApiService,
  RuleSetId,
} from '@xpo-ltl/sdk-pricingagmtmgmt';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class PrcAgmtMgmtApiService {
  constructor(
    private pricingAgmtMgmtApiService: PricingAgmtMgmtApiService,
    private pricingApiService: PricingApiService,
    private config: ConfigManagerService,
    private http: HttpClient,
    private constants: ConstantsService
  ) {}

  listPricingContractsForShipment(
    request: ListPricingContractsForShipmentRqst
  ): Observable<ListPricingContractsForShipmentResp> {
    return this.pricingAgmtMgmtApiService.listPricingContractsForShipment(request);
  }

  listVerifyReports(salesRfpId): Observable<ListVerifyReportsResp> {
    const query = new ListVerifyReportsQuery();
    query.salesRfpId = salesRfpId;
    const request = new ListVerifyReportsPath();
    request.requestId = 0;
    return this.pricingAgmtMgmtApiService.listVerifyReports(request, query);
  }

  listClassOverrides(agreementInstId: number): Observable<ListClassOverridesResp> {
    const classOverrideRequest = new ListClassOverridesRqst();
    const ids = new RuleSetId();
    ids.agreementInstId = agreementInstId;
    classOverrideRequest.ruleSetIds = [];
    classOverrideRequest.ruleSetIds.push(ids);
    return this.pricingAgmtMgmtApiService.listClassOverrides(classOverrideRequest);
  }

  listPricingAgreements(request: ListPricingAgreementsRqst): Observable<ListClassOverridesResp> {
    return this.pricingAgmtMgmtApiService.listPricingAgreements(request).pipe(
      mergeMap((info) => {
        let id = null;
        if (info && info.customerAgreements.length) {
          id = info.customerAgreements[0].customerAgreements[0]?.agreementInstId;
        }
        return id ? this.listClassOverrides(id) : of(null);
      })
    );
  }

  updateSalesRfpStatus(salesRfpId, newStatus): Observable<void> {
    const request = new UpdateSalesRfpStatusRqst();
    request.salesRfpId = salesRfpId;
    request.rfpStatusCd = newStatus;
    return this.pricingApiService.updateSalesRfpStatus(request);
  }

  cancelSalesRfpRequest(salesRfpId): Observable<void> {
    const request = new CancelSalesRfpRequestPath();
    request.salesRfpId = salesRfpId;
    return this.pricingAgmtMgmtApiService.cancelSalesRfpRequest(request);
  }

  formatDate(dateToChange) {
    const newDate = new Date(dateToChange);
    const date = moment(newDate).format(this.constants.dateServiceFormat);
    return `${date}T10:00:00.000Z`;
  }

  updateSalesRfp(salesRfpId, data) {
    const apiUrl: string = this.config.getSetting(ConfigManagerProperties.apiUrl);
    const pricingApiEndpoint: string = this.config.getSetting(ConfigManagerProperties.pricingApiEndpoint);
    const request = new UpdateSalesRfpRqst();
    request.salesRfp = data;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${apiUrl}${pricingApiEndpoint}/salesrfps/${salesRfpId}`;
    return this.http.put(url, request, { headers: headers });
  }

  listCustomerHierarchy(madCds, instIds, dataOpts?: DataOptions): Observable<CustomerHierarchyAccount[]> {
    const queryParams = new ListCustomerHierarchyQuery();
    const listInfo = new ListInfo();

    listInfo.levelOfDetail = 'Customer';
    queryParams.listInfo = listInfo;
    queryParams.customerAccountCds = madCds;
    queryParams.customerAccountIds = instIds;

    return this.pricingAgmtMgmtApiService
      .listCustomerHierarchy(queryParams, dataOpts)
      .pipe(map((data: ListCustomerHierarchyResp) => data.customerHierarchy));
  }
}
