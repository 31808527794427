export enum UserPermission {
  Admin = 'admin',
  BulkCreateDynamicRule = 'bulkCreateDynamicRule',
  CreateDynamicRule = 'createDynamicRule',
  CustomerList = 'customerList',
  Dashboard = 'dashboard',
  DynamicRuleList = 'dynamicRuleList',
  DynamicShipmentList = 'dynamicShipmentList',
  LaneMaintenance = 'laneMaintenance',
  ShipmentVisibility = 'shipmentVisibility',
  SpotQuoteList = 'spotQuoteList',
  SpotQuoteParameters = 'spotQuoteParameters',
  TestHarness = 'testHarness',
  UpdateDynamicRule = 'updateDynamicRule',
  AccessorialCharges = 'accessorialCharges',
  AbsoluteMinimumCharges = 'absoluteMinimumCharges',
  PricingLocationList = 'pricingLocationList',
  CreateOppRule = 'createOppRule',
  DynamicPricingForSales = 'dynamicPricingForSales',
  CustomerGroups = 'customerGroups',
  PricingCodes = 'pricingCodes',
}
